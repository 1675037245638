import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Routes } from '../../constants';
import {
  faLightbulb,
  faLifeRing,
  faGraduationCap,
  faVideo,
  faBookmark,
  faUser,
  faWrench,
  faPaperPlane,
  faBook,
} from '@fortawesome/pro-solid-svg-icons';

export interface CardSubMenuType {
  title: string;
  route: string;
}

export interface CardType {
  title: string;
  body: string;
  route: string;
  icon: IconProp;
  subItems?: Array<CardSubMenuType>;
}

export const cards: Array<CardType> = [
  {
    title: 'Orientation & Tutorials',
    body: 'Learn how to interact with your course and StraighterLine efficiently and effectively.',
    route: Routes.orientationTutorial,
    icon: faLightbulb,
    subItems: [],
  },
  {
    title: 'Support Requests',
    body: 'Create a new support request or view your current or past requests.',
    route: Routes.supportRequest,
    icon: faLifeRing,
    subItems: [],
  },
  {
    title: 'Academics',
    body: 'Academic resources and information to help you complete your course.',
    route: Routes.academicsCourses,
    icon: faGraduationCap,
    subItems: [
      {
        title: 'Courses',
        route: Routes.academicsCourses,
      },
      {
        title: 'Grading',
        route: Routes.academicsGrading,
      },
      {
        title: 'Exams',
        route: Routes.academicsExams,
      },
      {
        title: 'Written Assignments',
        route: Routes.academicsWrittenAssignments,
      },
      {
        title: 'Labs',
        route: Routes.academicsLabs,
      },
      {
        title: 'Course Retakes',
        route: Routes.academicsCourseRetakes,
      },
      {
        title: 'MTA Training Courses',
        route: Routes.academicsMTATrainingCourses,
      },
      {
        title: 'CompTIA Training Courses',
        route: Routes.academicsComptiaTrainingCourses,
      },
    ],
  },
  {
    title: 'Proctoring',
    body: 'A complete guide for your final, proctored exams.',
    route: Routes.proctoring,
    icon: faVideo,
    subItems: [],
  },
  {
    title: 'eTextbooks',
    body: 'Learn how to access and use your eTextbooks.',
    route: Routes.etextbooks,
    icon: faBookmark,
    subItems: [],
  },
  {
    title: 'Billing and Account Management',
    body: 'Resources to help you manage your Membership, billing, and account.',
    route: Routes.billing,
    icon: faUser,
    subItems: [
      {
        title: 'Billing',
        route: Routes.billing,
      },
      {
        title: 'Account Management',
        route: Routes.accountManagement,
      },
      {
        title: 'Pop Up Blockers',
        route: Routes.popUpBlockers,
      },
    ],
  },
  {
    title: 'Credit Transfer',
    body: 'Information about how to request and manage your transcripts.',
    route: Routes.creditTransfer,
    icon: faPaperPlane,
    subItems: [
      {
        title: 'Credit Transfer',
        route: Routes.creditTransfer,
      },
      {
        title: 'ACE Transcripts',
        route: Routes.creditTransferaceTranscripts,
      },
    ],
  },
  {
    title: 'System Check',
    body: "Check your computer's readiness to ensure you have the best experience.",
    route: Routes.systemCheck,
    icon: faWrench,
    subItems: [],
  },
  {
    title: 'Student Handbook',
    body: 'Comprehensive guide to all StraighterLine policies and procedures.',
    route: Routes.studentHandbookStudentCodeOfConduct,
    icon: faBook,
    subItems: [
      {
        title: 'Student Code of Conduct',
        route: Routes.studentHandbookStudentCodeOfConduct,
      },
      {
        title: 'Assessment Policies and Guidelines',
        route: Routes.studentHandbookPoliciesAndGuidlines,
      },
      {
        title: 'Course Design and Instructional Approach',
        route: Routes.studentHandbookCourseDesign,
      },
      {
        title: 'Terms of Use',
        route: Routes.studentHandbookTermsOfUse,
      },
      {
        title: 'Accessibility',
        route: Routes.studentHandbookAccessibility,
      },
      {
        title: 'Privacy Policy',
        route: Routes.studentHandbookPrivacyPolicy,
      },
      {
        title: 'Refunds',
        route: Routes.studentHandbookRefunds,
      },
    ],
  },
];
