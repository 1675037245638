import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { themeColors } from '../../themes/colors';

const Loader = () => {
  const { showLoader } = useSelector((state: RootState) => state.app);
  return (
    <Backdrop
      sx={{
        color: themeColors.white,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={showLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
