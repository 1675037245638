import {
  helpCenterImagesUrl,
  Urls,
  Routes,
} from '../../../constants';
import { ContentType, ListType, interfaceType } from '../types';

export const Billing: ContentType = {
  header: 'Billing',
  subHeader: '',
  sections: [
    {
      title: 'Pricing',
      sections: [
        `StraighterLine courses start at $79 with a $99 Monthly Membership. You must have an active membership to purchase or access a course.`,
      ],
    },
    {
      title: 'What is a StraighterLine Membership?',
      sections: [
        `The StraighterLine Monthly Membership is a $99 month-to-month subscription that begins at course enrollment.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: 'Membership Benefits:',
          points: [
            `No limit on course enrollments`,
            `Start as soon as you are ready, immediate access to your course(s)`,
            `Start and stop your membership at any time and resume your courses when you are ready`,
          ],
        },
      ],
    },
    {
      title: 'Membership Billing',
      sections: [
        `StraighterLine Memberships automatically renew each month, at which time your original payment method will be charged $99. Monthly charges are billed on the same day you originally enrolled and may take a few days after the billing date to appear on your account.`,
        `<i><strong>Note:</strong> If your billing date is scheduled for a date that does not occur in a given month, you will be billed on the last day of the month instead. For example, if you enrolled on March 31st, your next billing date will be April 30th.</i>`,
      ],
    },
    {
      title: 'Payment Methods',
      sections: [
        `StraighterLine Memberships can be paid for via credit card or debit card. The method chosen at the time of your enrollment will be used throughout the duration of your membership, unless you update your payment source.`,
      ],
    },
    {
      title:
        '<a id="hc_billing_membership_cancellation"></a>Membership Cancellation',
      sections: [
        `You can cancel your membership at anytime from the <a href="${Urls.BILLING_PREFERENCE}" title="Billing Preferences">Billing Preferences</a> page in your <a href="${window.location.origin}" title="Dashboard">Dashboard.</a> Please remember to cancel <strong>at least one day prior to your next billing date</strong> to ensure your account is not charged for another billing cycle.`,
        `Your membership will remain active until the end of your billing cycle (this date can be found at the top right side of your  <a href="${window.location.origin}" title="Dashboard">Dashboard</a> or on your <a href="${Urls.BILLING_PREFERENCE}" title="Billing Preferences">Billing Preferences</a> page). At that time, your membership will expire and you will no longer have access to your course(s). Your <a href="${window.location.origin}" title="Dashboard">Dashboard</a> will remain available for your future course and transcript needs and you can renew your membership at any time to resume your coursework.`,
        {
          type: interfaceType.MODAL_TYPE,
          title: `Cancel your Monthly Membership`,
          bannerImage: helpCenterImagesUrl.Membership_Billing_Preferences,
          modalData: [
            {
              image: helpCenterImagesUrl.Membership_Billing_Preferences,
              description: `From your Dashboard, select Account Settings, and then select Billing Preferences from the Account Settings Menu.`,
            },
            {
              image: helpCenterImagesUrl.Membership_Cancel,
              description: `You can fill out the Billing Settings form to cancel your membership. Select "Billing and Membership Services" as the Case Reason, and note that you want to cancel your membership in the "Description" field.`,
            },
            {
              image: helpCenterImagesUrl.Membership_Confirmation,
              description: `You will receive an email from StraighterLine within 24-48 hours, confirming your membership cancellation.`,
            },
          ],
        },
      ],
    },
    {
      title: 'Failed Payments',
      sections: [
        `If we cannot process your Monthly Membership payment, your StraighterLine Membership will be expired. To regain access to your courses, you must <a href="/help-center/account/billing/#hc_billing_membership_renewals" title="Membership Renewals">renew your membership</a> from the <a href="${Urls.BILLING_PREFERENCE}" title="Billing Preferences">Billing Preferences</a> page in your <a href="${window.location.origin}" title="Dashboard">Dashboard.</a>`,
      ],
    },
    {
      title: 'Employee Tuition Reimbursement',
      sections: [
        `StraighterLine is happy to partner with companies and employees interested in establishing tuition reimbursement relationships. Please, email <a href="mailto:partnerships@straighterline.com" title="partnerships@straighterline.com">partnerships@straighterline.com</a> for more information.`,
      ],
    },
    {
      title: 'Store Credit',
      sections: [
        `Occasionally, students are issued Store Credit on their StraighterLine account that can be used for any future course purchase. If you have Store Credit that you would like to use, simply select Store Credit as your payment method during checkout. If you would like to check on the balance of your Store Credit, please contact a Student Advisor.`,
      ],
    },
    {
      title: 'Refunds',
      sections: [
        `Membership and course fees are not refundable. To learn more about our refund policy, please visit: <a href='https://learn.beta.straighterline.com/help-center/student-handbook/refunds' title="https://learn.beta.straighterline.com/help-center/student-handbook/refunds">https://learn.beta.straighterline.com/help-center/student-handbook/refunds</a>`,
      ],
    },
    {
      title: 'FAQs',
      sections: [
        `Q: Where can I find my next billing date for my membership?</br>A: Your next billing date can be found at the top right side of your dashboard page or on the <a href="${Urls.BILLING_PREFERENCE}" title="Billing Preferences">Billing Preferences</a> page in your <a href="${window.location.origin}" title="Dashboard">Dashboard.</a>`,
        `Q: What happens if I cancel my StraighterLine Membership without completing my course(s)?
        </br>A: If you need to cancel your membership before your course(s) are complete, don't worry! You can restart your membership at anytime, without penalty and you can resume your coursework when you are ready.`,
        `Q: Do I have access to my course(s) after I cancel my StraighterLine Membership?
        </br>A: That depends. Course access is available until your billing cycle ends. If you cancel before your next billing date, then you will have access until that date. For example, if you canceled on September 2nd and your next billing date is on the 17th, you will have 15 days of access to your courses before your account fully expires.`,
        `Q: Can I request a transcript if I do not have a StraighterLine Membership?
        </br>A: Yes. Transcripts can be requested at any time and do not require an active membership.`,
        `Q: Can I cancel my StraighterLine Membership before all my assignments are graded?
        </br>A: Yes. In the case where you completed your coursework, you may cancel your membership before your last assignment(s) are graded and a final grade is posted to your course. If you need to resubmit the assignment based on feedback or do not successfully pass the course and wish to retake it, you will need to renew your membership to regain access to the course.`,
        `Q: Will my StraighterLine Membership automatically be canceled when I complete my course?
        </br>A: <strong>No.</strong> You must cancel your membership at least one day prior to your next billing date to ensure your account is not charged for another billing cycle.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'Q: What features do I have access to at StraighterLine without a StraighterLine Membership?<br/></br>A: Without a membership, your access is restricted to your <a href="${window.location.origin}" title="Dashboard">Dashboard.</a>, where you will be able to:',
          points: [
            `View and request <a href="${window.location.origin}${Routes.transcript}" title="Transcripts">Transcripts</a>,`,
            `Access <a href="/help-center/" title="Help Center">Help Center</a> resources,`,
            `View your past purchase history,`,
            `Manage your <a href="${window.location.origin}/account/account-settings" title="Account Settings">Account Settings</a>,`,
            `and <a href="${Urls.BILLING_PREFERENCE}" title="renew your StraighterLine Membership">renew your StraighterLine Membership</a>.`,
          ],
        },
        `Additionally, our Student Advisors are here to assist you with any questions you may have.`,
        `Q: Will I lose my course progress if I cancel my StraighterLine Membership?
        </br>A: No. Your <strong>course progress will be saved</strong> for when you are ready to return. If you are away for a significant amount of time and your course content has been updated, the support team will work with you to ensure your course is brought current and prior work is carried over when possible.`,
        `Q: Can I get a refund for a StraighterLine Membership charge?
        </br>A: No. StraighterLine Membership are <strong>non-refundable</strong>. We do not provide refunds for partial-month memberships. Cancel or change your payment method at least one day prior to your next billing date to ensure your account is not charged for another billing cycle.`,
      ],
    },
  ],
};
