import { createAsyncThunk } from '@reduxjs/toolkit';
import { APIs, EMPTY_STRING } from '../../../constants';
import axiosInstance from '../../../http-requests/httpClient';
import { AppDispatch, RootState } from '../../../store';
import { showToast } from '../../../store/app.slice';
import { SLICE_TRANSCRIPT_HISTORY } from '../../../store/slices';
import { SnackbarAnchorSeverity } from '../../../themes/properties';
import { ErrorResponse, GetTranscriptResponse } from '../../../types/contract';

export const getTranscripts = createAsyncThunk(
  `${SLICE_TRANSCRIPT_HISTORY + APIs.transcript}`,
  async (_, thunkAPI) => {
    try {
      let { profile }: RootState = thunkAPI.getState() as RootState;
      const response = await axiosInstance.get(
        APIs.transcript.replace('%s', profile.profile?.id ?? EMPTY_STRING),
      );
      const transcriptRes: Array<GetTranscriptResponse> = [
        ...response.data.processingTranscripts,
        ...response.data.transcripts,
      ];
      return transcriptRes;
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);
