import { themeColors, hexToRGB } from '../../../../themes/colors';
import { spacing } from '../../../../themes/theme';

export default {
  root: {
    bgcolor: hexToRGB(themeColors.lightBlue, 0.6),
    borderRadius: `${spacing * 1}px`,
    p: `${spacing * 2}px ${spacing * 3}px`,
  },
  flexBox: { display: 'flex' },
  textKey: { fontWeight: 900 },
  boxAddressValue: {
    mt: `${spacing * 2}px`,
  },
  textValue: {
    ml: `${spacing * 2}px`,
  },
  iconEdit: {
    color: themeColors.darkBlue,
    '& .svg-inline--fa': {
      fontSize: `${spacing * 2}px`,
    },
  },
  boxMailingAddress: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxEditAddress: {
    alignItems: 'center',
    p: `${spacing * 1}px ${spacing * 2}px`,
    '&:hover': {
      bgcolor: hexToRGB(themeColors.darkBlue, 0.1),
      borderRadius: `${spacing * 3}px`,
      cursor: 'pointer',
    },
  },
  textEdit: {
    fontSize: `${spacing * 1.5}px`,
    ml: `${spacing * 1}px`,
    color: themeColors.darkBlue,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  button: {
    borderRadius: `${spacing * 4}px`,
    ml: `${spacing * 1.5}px`,
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 900,
    bgcolor: themeColors.primary,
    color: themeColors.white,
    px: `${spacing * 2}px`,
  },
  buttonCancel: {
    bgcolor: hexToRGB(themeColors.lightBlue, 0.6),
    color: themeColors.primary,
    border: `1px solid ${themeColors.primary}`,
    '&:hover': {
      bgcolor: hexToRGB(themeColors.lightBlue, 0.6),
      color: themeColors.primary,
      cursor: 'pointer',
    },
  },
};
