import { AcrobatiqAccessParams } from '../types/contract';

export const acrobatiqAccessFormHtml = (providerData: AcrobatiqAccessParams) => `
<!doctype html>
<html>
<body class="page-bare  lti-launch-index">
        <div style="display:none;">
            <form id="ltiAcrobatiqAccessForm" name="ltiAcrobatiqAccessForm" method="POST"
                action="${providerData.URL}"
                encType="application/x-www-form-urlencoded">
                <input type="hidden" name="id_token" value="${providerData.token}">
                <input type="hidden" name="state" value="${providerData.state}">
                <button type="submit" name="LTIForm">Click to continue to your course.</button>
            </form>
        </div>
        <script type="text/javascript">
	        document.ltiAcrobatiqAccessForm.submit();
        </script>
</body>
</html>
    `;
