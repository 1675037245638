import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  root: {
    width: 364,
    maxHeight: `${spacing * 57}px`,
    '@media (max-width: 1440px)': { width: 420 },
    '@media (max-width: 1200px)': { width: 500 },
    '@media (max-width: 767px)': { width: '100%' },
  },
  banner: {
    width: 364,
    height: 180,
    borderRadius: 2,
    cursor: 'pointer',
    '@media (max-width: 1440px)': { width: 420 },
    '@media (max-width: 1200px)': { width: 500 },
    '@media (max-width: 767px)': { width: '100%' },
  },
  boxRoot: { position: 'relative', height: '100%' },
  blurBanner: {
    height: '100%',
    width: 364,
    backdropFilter: 'blur(6px)',
    borderRadius: 2,
    '@media (max-width: 1440px)': { width: 420 },
    '@media (max-width: 1200px)': { width: 500 },
    '@media (max-width: 767px)': { width: '100%' },
  },
  textCourseNameHeader: {
    color: themeColors.white,
    fontSize: 16,
    fontWeight: 700,
    position: 'absolute',
    top: 16,
    left: 16,
    mixBlendMode: 'difference',
    filter: 'invert(0.3)',
  },
  textCourseStatusHeader: {
    fontWeight: 900,
    fontSize: 11,
    bgcolor: themeColors.midGrey,
    color: themeColors.primary,
    width: 'fit-content',
    textTransform: 'uppercase',
    position: 'absolute',
    bottom: 16,
    left: 16,
    py: 0.25,
    px: 1.5,
    borderRadius: 5,
  },
  iconCollapse: {
    position: 'absolute',
    bottom: 0,
    color: 'white',
    width: '100%',
    textAlign: 'center',
    mb: 0.5,
    cursor: 'pointer',
  },
  boxCourseHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 1,
  },
  textCourseName: { fontSize: 16, fontWeight: 700 },
  textCourseDesc: { fontSize: 15 },
  boxStatusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 1,
  },
  dividerBottom: { mt: 1.5 },
  upArrow: {
    color: themeColors.grey,
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    mt: 0.25,
  },
  cardContent: {
    '&.MuiCardContent-root': {
      paddingBottom: 0.25,
    },
  },
  cardBoxShadow:
    '0px 2px 1px -10px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
};

export default Styles;
