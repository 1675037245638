import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
} from '@mui/material';
import { VariantTypography } from '../../../../themes/properties';
import Styles from './styles';
import {
  systemCheck,
  TableRowType,
  SuggestionType,
} from '../../../../assets/help-center-data/system-check.data';
import {
  PointType,
  interfaceType,
  SectionType,
} from '../../../../assets/help-center-data/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

import Points from '../Points.component';
import { useEffect } from 'react';

const OrientationAndTutorials = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  const table = (
    <TableContainer>
      <Table sx={Styles.tableRoot}>
        <TableHead>
          <TableRow>
            {systemCheck.tableColumns.map((col: string) => (
              <TableCell key={col}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {systemCheck.tableData.map((data: TableRowType) => {
            let infoRow;
            if (typeof data.information === 'boolean') {
              infoRow = (
                <Box sx={Styles.infoContainer}>
                  <Box sx={Styles.boxInfo}>
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </Box>
                  <Typography
                    variant={VariantTypography.body2}
                    sx={Styles.textInfo}
                  >
                    {systemCheck.constants.passed}
                  </Typography>
                </Box>
              );
            } else {
              const info = data.information as SuggestionType;
              infoRow = (
                <Box>
                  <Box sx={{ ...Styles.infoContainer, color: info.color }}>
                    <Box sx={Styles.boxInfo}>
                      <FontAwesomeIcon icon={info.icon} />
                    </Box>
                    <Typography
                      variant={VariantTypography.body2}
                      sx={Styles.textInfo}
                    >
                      {info.text}
                    </Typography>
                  </Box>

                  <Typography
                    variant={VariantTypography.body2}
                    sx={Styles.textInfoLink}
                  >
                    <a href={info.link}>{info.linkText}</a>
                  </Typography>
                </Box>
              );
            }
            return (
              <TableRow>
                <TableCell>{data.category}</TableCell>
                <TableCell>{data.recommended}</TableCell>
                <TableCell>{data.detected}</TableCell>
                <TableCell>{infoRow}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const sectionView = systemCheck.sections.map((sectionData: SectionType) => (
    <Box sx={Styles.boxSection} key={sectionData.title}>
      <Typography
        sx={Styles.txtSectionTitle}
        variant={VariantTypography.h5}
        dangerouslySetInnerHTML={{ __html: sectionData.title }}
      />
      {sectionData.sections.map((section: string | PointType) => {
        let view = null;
        if (typeof section === 'string') {
          const text = section.toString();
          view = (
            <Typography
              dangerouslySetInnerHTML={{ __html: text }}
              sx={Styles.textSectionDescription}
              variant={VariantTypography.body1}
            />
          );
        } else if (section.type === interfaceType.POINT_TYPE) {
          const sectionData = section as PointType;
          view = <Points data={sectionData} />;
        }
        return <>{view}</>;
      })}
      <Divider sx={Styles.divder} />
    </Box>
  ));

  return (
    <Box sx={Styles.root}>
      <Typography variant={VariantTypography.h2} sx={Styles.mainHeading}>
        {systemCheck.header}
      </Typography>
      <Typography variant={VariantTypography.body1} sx={Styles.subHeading}>
        {systemCheck.subHeader}
      </Typography>
      <Typography variant={VariantTypography.body2} sx={Styles.title}>
        {systemCheck.title}
      </Typography>
      {table}
      {sectionView}
    </Box>
  );
};

export default OrientationAndTutorials;
