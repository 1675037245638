import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import Styles from './CourseCardActionButtons.style';

interface Props {
  buttons: ReactNode[];
}

const CourseCardActionButtons = ({ buttons }: Props) => {
  return <Box sx={Styles.container}>{buttons}</Box>;
};

export default CourseCardActionButtons;
