import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  btnNext: {
    bgcolor: themeColors.primary,
    border: `1px solid ${themeColors.grey}`,
    color: themeColors.white,
    height: `${spacing * 5}px`,
    width: `${spacing * 25}px`,
    borderRadius: `${spacing * 25}px`,
    '&:hover': {
      bgcolor: themeColors.black,
    },
    letterSpacing: `${spacing * 0.25}px`,
    fontSize: `${spacing * 1.5}px`,
    transition: 'width 0.5s', // Apply transition to width property
  },
  buttonIcons: {
    color: themeColors.white,
  },
};

export default Styles;
