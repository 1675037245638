import { themeColors } from '../../themes/colors';

const Styles = {
  buttonPurchase: {
    fontSize: 12,
    border: `1px solid ${themeColors.grey}`,
    borderRadius: 50,
    flexGrow: 1,
    fontWeight: 900,
  },

  buttonPreview: {
    color: themeColors.white,
    backgroundColor: themeColors.primary,
    fontSize: 12,
    border: `1px solid ${themeColors.grey}`,
    borderRadius: 50,
    flexGrow: 1,
    fontWeight: 900,
    '&:hover': {
      bgcolor: themeColors.black,
    },
  },
};

export default Styles;
