import { TableCell, TableRow, Typography } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { dataTestIds } from '../../../constants';
import Styles from './styles';

const Header = () => {
  return (
    <TableHead sx={Styles.rootTableHeader}>
      <TableRow>
        <TableCell sx={Styles.headerCourse}>
          <Typography
            data-testid={dataTestIds.transcriptViewCourse.textTableHeaderCourse}
          >
            COURSE
          </Typography>
        </TableCell>
        <TableCell sx={Styles.headerCompletionDate}>
          <Typography
            data-testid={
              dataTestIds.transcriptViewCourse.textTableHeaderCompletionDate
            }
          >
            COMPLETION DATE
          </Typography>
        </TableCell>
        <TableCell sx={Styles.headerGrade}>
          <Typography
            sx={Styles.textGrade}
            data-testid={
              dataTestIds.transcriptViewCourse.textTableHeaderFinalGrade
            }
          >
            FINAL GRADE
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default Header;
