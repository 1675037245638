import Feature from '../../models/Feature.model';
import TranscriptType from '../../models/Transcript.model';

export const isTranscriptFeeFeatureEnable = (data: TranscriptType | null) => {
  return (
    data?.isFeeFeatureEnabled &&
    data?.college &&
    !data?.college?.isPartner &&
    (data?.mailTranscript || data?.emailTranscript)
  );
};
