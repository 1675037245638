import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Course from '../../models/Course.model';
import Styles from './CourseCard.style';
import {
  CourseButton,
  dataTestIds,
  EMPTY_STRING,
  Routes,
  takeSurveyUrl,
} from '../../constants';
import { CourseStatus } from '../../types/global';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getTranscriptEligibleCourses, openPage } from '../../utils/utils';
import CourseCardSkeleton from './CourseCardSkeleton.component';
import {
  getAssignmentList,
  getLMSProviderDetails,
  proctoredExamUrl,
  takeCourseSurvey as takeCourseSurveyAPI,
} from '../../module/dashboard/redux/dashboardAsync.actions';
import Profile from '../../models/Profile.model';
import { showRenewMembershipModal as renewMembershipModalHandler } from '../../module/dashboard/redux/dashboardSlice';
import { isDateExpired } from '../../utils/dateUtils';
import {
  acrobatiqProviderHandler,
  isAcrobatiqCourse,
} from '../../utils/acrobatiqLogin.util';
import MenuIcons from './CourseCard.menu';
import CourseCardActionButtons from './CourseCardActionButtons.component';
import CourseCardProgress from './CourseCardProgress.component';

interface Props {
  profile: Profile | null;
  course: Course;
}

const CourseCard = ({ profile, course }: Props) => {
  const hasMembership = !!profile?.membershipExpire;
  const isMembershipExpired = isDateExpired(
    profile?.membershipExpire ?? EMPTY_STRING,
  );

  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !hasMembership || isMembershipExpired,
  );

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const isCourseCompleted = useMemo(
    () =>
      course.status.toLocaleLowerCase() ===
      CourseStatus.Complete.toLocaleLowerCase(),
    [course.status],
  );

  const courses: Array<Course> = useSelector(
    (state: RootState) => state.dashboard.courses,
  );

  const buttonText = useMemo(() => {
    switch (course.status.toLocaleLowerCase()) {
      case CourseStatus.NotStarted.toLocaleLowerCase():
        return CourseButton[CourseStatus.NotStarted];
      case CourseStatus.Complete.toLocaleLowerCase(): {
        setIsButtonDisabled(false);
        if (course.isSurveyTaken) {
          const eligibleCourses = getTranscriptEligibleCourses(courses);
          const courseEligibilityExsists = eligibleCourses.find(
            (c) => c.id === course.id,
          );
          setIsButtonDisabled(!courseEligibilityExsists);
          return CourseButton[CourseStatus.Complete];
        } else {
          return CourseButton.takeCourseSurvey;
        }
      }
      default:
        return CourseButton[CourseStatus.Progress];
    }
  }, [course.status, course.isSurveyTaken]);

  const handleButtonClick = () => {
    if (isCourseCompleted) {
      if (course.isSurveyTaken) {
        navigate(Routes.transcript);
      } else {
        takeCourseSurvey();
      }
    } else {
      courseProviderHandler();
    }
  };

  const listCheckHandler = () => {
    if (!isMembershipExpired && hasMembership && course.isActive) {
      dispatch(getAssignmentList(course.courseIdentifier));
    } else if (!course.isActive && course?.expirationDate != null) {
      //dispatch(getAssignmentList(course.courseIdentifier));
    } else {
      dispatch(renewMembershipModalHandler(true));
    }
  };

  const downloadPDFHandler = () => {
    const syllabusUri = course.syllabusUri ?? EMPTY_STRING;
    if (!isMembershipExpired && hasMembership && syllabusUri) {
      openPage(syllabusUri, true);
    }
  };

  const calenderHanlder = () => {
    if (!isMembershipExpired && hasMembership && course.isActive) {
      dispatch(proctoredExamUrl(course));
    } else if (!course.isActive && course?.expirationDate != null) {
      //dispatch(proctoredExamUrl(course));
    } else {
      dispatch(renewMembershipModalHandler(true));
    }
  };

  const bannerHandler = () => {
    if (!isMembershipExpired && hasMembership && course.isActive) {
      dispatch(getAssignmentList(course.courseIdentifier));
    } else if (!course.isActive && course.expirationDate != null) {
      //dispatch(getAssignmentList(course.courseIdentifier));
    } else {
      dispatch(renewMembershipModalHandler(true));
    }
  };

  const takeCourseSurvey = () => {
    const email = profile?.email ?? EMPTY_STRING;
    const courseShortName = course.courseIdentifier;
    const courseSKU = course.courseSKU;
    const url = takeSurveyUrl
      .replace('%s1', encodeURIComponent(courseShortName))
      .replace('%s2', encodeURIComponent(courseSKU))
      .replace('%s3', encodeURIComponent(email));
    openPage(url, true);
    dispatch(
      takeCourseSurveyAPI({
        course: course,
        courseIdentifier: course.courseIdentifier,
      }),
    );
  };

  const moodleProviderHandler = (
    courseIdentifier: string,
    linkId: string,
    resume: boolean,
  ) => {
    dispatch(
      getLMSProviderDetails({
        courseIdentifier: courseIdentifier,
        linkId: linkId,
        resume: resume,
      }),
    );
  };

  const courseProviderHandler = () => {
    const resume = true;
    if (isAcrobatiqCourse(course.cwpCode)) {
      acrobatiqProviderHandler(
        course.providerCourseName,
        course.providerCourseId,
        course.resourceLinkId,
        resume,
      );
    } else {
      moodleProviderHandler(
        course.courseIdentifier,
        course.resourceLinkId,
        resume,
      );
    }
  };

  return (
    <CourseCardSkeleton
      course={course}
      bannerHandler={bannerHandler}
      statusText={course.status}
      contentSlot={<CourseCardProgress course={course} />}
      actionSlot={
        <CourseCardActionButtons
          buttons={[
            <Button
              sx={Styles.actionButton}
              disabled={
                (isButtonDisabled || !course.isActive) &&
                buttonText != CourseButton[CourseStatus.Complete]
              }
              onClick={handleButtonClick}
              key="action"
              data-testid={dataTestIds.courseCard.actionButton}
            >
              {buttonText}
            </Button>,
          ]}
        />
      }
      menuIcons={
        <MenuIcons
          hasProctorUrl={course.isActive}
          checkList={{ display: true, handler: listCheckHandler }}
          pdf={{ display: !!course.syllabusUri, handler: downloadPDFHandler }}
          calendar={{ display: true, handler: calenderHanlder }}
        />
      }
    />
  );
};

export default CourseCard;
