import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Dashboard from '../module/dashboard/Dashboard.component';
import TranscriptHistory from '../module/transcriptHistory/TranscriptHistory.component';
import HelpCenterHome from '../module/helpCenter/HelpCenterHome.componet';
import Account from '../module/account/Account.component';
import OrientationAndTutorials from '../module/helpCenter/HelpCenterServices/OrientationAndTutorials/OrientationAndTutorials';
import {
  features,
  getPageTitle,
  routePathConstants,
  Routes as RoutesConstants,
  screenBreakpoints,
} from '../constants';
import SupportRequests from '../module/helpCenter/HelpCenterServices/SupportRequests';
import Academics from '../module/helpCenter/HelpCenterServices/Academics/index.component';
import CreditTransfer from '../module/helpCenter/HelpCenterServices/creditTransfer/index.component';
import Etextbook from '../module/helpCenter/HelpCenterServices/eTextBooks/index.component';
import Proctoring from '../module/helpCenter/HelpCenterServices/Proctoring/index.component';
import BillingAndAccountManagement from '../module/helpCenter/HelpCenterServices/BillingAndAccountManagement/index.component';
import StudentHandbook from '../module/helpCenter/HelpCenterServices/StudentHandbook/index.component';
import SystemCheck from '../module/helpCenter/HelpCenterServices/systemCheck/SystemCheck.component';
import HelpCenterNavigation from '../module/helpCenter/index.component';
import { coursesData } from '../assets/help-center-data/academics/courses.data';
import { gradingData } from '../assets/help-center-data/academics/grading.data';
import { examsData } from '../assets/help-center-data/academics/exams.data';
import { writtenAssignmentsData } from '../assets/help-center-data/academics/writtenAssignments.data';
import { labsData } from '../assets/help-center-data/academics/labs.data';
import { courseRetakesData } from '../assets/help-center-data/academics/courseRetake.data';
import { mtaTrainigCoursesData } from '../assets/help-center-data/academics/mtaTrainigCourses.data';
import { comptiaTrainingCoursesData } from '../assets/help-center-data/academics/comptiaTrainingCourses.data';
import { getCreditTransferData } from '../assets/help-center-data/creditTransfer/creditTransfer.data';
import { aceTranscriptsData } from '../assets/help-center-data/creditTransfer/aceTranscripts.data';
import { ETextBooks } from '../assets/help-center-data/eTextBooks.data';
import { ProctoringData } from '../assets/help-center-data/proctoring.data';
import { PopUpBlockers } from '../assets/help-center-data/biilingAndAccountManagement/popUpBlockers.data';
import { Billing } from '../assets/help-center-data/biilingAndAccountManagement/billing.data';
import { AccountManagement } from '../assets/help-center-data/biilingAndAccountManagement/accountManagement.data';
import { TermsOfUse } from '../assets/help-center-data/studentHandbook/termsOfUse.data';
import { PrivacyPolicy } from '../assets/help-center-data/studentHandbook/privacyPolicy.data';
import { AssessmentPoliciesAndGuidlines } from '../assets/help-center-data/studentHandbook/assessmentPolicesAndGuidlines.data';
import { CourseDesign } from '../assets/help-center-data/studentHandbook/courseDesign.data';
import { Refunds } from '../assets/help-center-data/studentHandbook/refunds.data';
import { Accessibility } from '../assets/help-center-data/studentHandbook/accessibility.data';
import { StudentCodeOfConduct } from '../assets/help-center-data/studentHandbook/studentCodeOfConduct.data';
import { Grid, useMediaQuery } from '@mui/material';
import Styles from '../App.style';
import NavBar from '../common-components/navbar/Navbar.component';
import { Location } from '@remix-run/router';
import AccountSettings from '../module/account/account-settings/AccountSettings.component';
import AccountProfileDetails from '../module/account/profile-details/ProfileDetails.component';
import BillinPreference from '../module/account/billing-preference-setting/BillingPreferences.component';
import Lti13Handler from '../module/ltiHandlers/lti13/index.component';
import Transcript from '../module/transcript/index.component';
import { useEffect } from 'react';
import Feature from '../models/Feature.model';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import ReactGA from 'react-ga4';

const AppRoutes = () => {
  const location: Location = useLocation();

  const featuresFlags: Array<Feature> = useSelector(
    (state: RootState) => state.app.masterData?.features ?? [],
  );

  const transcriptFeeFeature = featuresFlags?.find(
    (feature) => feature.name === features.transcriptFee,
  );

  useEffect(() => {
    const completePath = location.pathname;
    switch (true) {
      case completePath.startsWith('/help-center'):
        document.title = getPageTitle(routePathConstants.helpCenter);
        break;
      case completePath === RoutesConstants.accountSettings:
        document.title = getPageTitle(routePathConstants.accountSettings);
        break;
      case completePath === RoutesConstants.profileDetails:
        document.title = getPageTitle(routePathConstants.profileDetails);
        break;
      case completePath === RoutesConstants.billinPreference:
        document.title = getPageTitle(routePathConstants.billingPreference);
        break;
      case completePath === RoutesConstants.transcriptHistory:
        document.title = getPageTitle(routePathConstants.transcriptHistory);
        break;
      case completePath === RoutesConstants.transcript:
        document.title = getPageTitle(routePathConstants.transcript);
        break;
      default:
        document.title = getPageTitle(routePathConstants.dashboard);
        break;
    }
    if (ReactGA._currentMeasurementId) {
      ReactGA.send(location.pathname);
    }
  }, [location.pathname]);

  const screenLaptop = useMediaQuery(`(max-width:${screenBreakpoints.laptop})`);

  const routes = (
    <Routes>
      <Route
        path={RoutesConstants.transcriptHistory}
        element={<TranscriptHistory />}
      />
      <Route path={RoutesConstants.helpCenter} element={<HelpCenterHome />} />

      <Route
        path={RoutesConstants.helpCenter}
        element={<HelpCenterNavigation />}
      >
        <Route
          path={RoutesConstants.orientationTutorial}
          element={<OrientationAndTutorials />}
        />
        <Route
          path={RoutesConstants.supportRequest}
          element={<SupportRequests />}
        />

        <Route
          path={RoutesConstants.academicsCourses}
          element={<Academics data={coursesData} />}
        />
        <Route
          path={RoutesConstants.academicsGrading}
          element={<Academics data={gradingData} />}
        />
        <Route
          path={RoutesConstants.academicsExams}
          element={<Academics data={examsData} />}
        />
        <Route
          path={RoutesConstants.academicsWrittenAssignments}
          element={<Academics data={writtenAssignmentsData} />}
        />
        <Route
          path={RoutesConstants.academicsLabs}
          element={<Academics data={labsData} />}
        />
        <Route
          path={RoutesConstants.academicsCourseRetakes}
          element={<Academics data={courseRetakesData} />}
        />
        <Route
          path={RoutesConstants.academicsMTATrainingCourses}
          element={<Academics data={mtaTrainigCoursesData} />}
        />
        <Route
          path={RoutesConstants.academicsComptiaTrainingCourses}
          element={<Academics data={comptiaTrainingCoursesData} />}
        />
        <Route
          path={RoutesConstants.popUpBlockers}
          element={<BillingAndAccountManagement data={PopUpBlockers} />}
        />
        <Route
          path={RoutesConstants.billing}
          element={<BillingAndAccountManagement data={Billing} />}
        />
        <Route
          path={RoutesConstants.accountManagement}
          element={<BillingAndAccountManagement data={AccountManagement} />}
        />
        <Route
          path={RoutesConstants.creditTransfer}
          element={
            <CreditTransfer
              data={getCreditTransferData(
                transcriptFeeFeature?.enabled ?? false,
              )}
            />
          }
        />
        <Route
          path={RoutesConstants.creditTransferaceTranscripts}
          element={<CreditTransfer data={aceTranscriptsData} />}
        />
        <Route
          path={RoutesConstants.etextbooks}
          element={<Etextbook data={ETextBooks} />}
        />
        <Route
          path={RoutesConstants.proctoring}
          element={<Proctoring data={ProctoringData} />}
        />
        <Route
          path={RoutesConstants.studentHandbookStudentCodeOfConduct}
          element={<StudentHandbook data={StudentCodeOfConduct} />}
        />
        <Route
          path={RoutesConstants.studentHandbookTermsOfUse}
          element={<StudentHandbook data={TermsOfUse} />}
        />
        <Route
          path={RoutesConstants.studentHandbookAccessibility}
          element={<StudentHandbook data={Accessibility} />}
        />
        <Route
          path={RoutesConstants.studentHandbookRefunds}
          element={<StudentHandbook data={Refunds} />}
        />
        <Route
          path={RoutesConstants.studentHandbookPrivacyPolicy}
          element={<StudentHandbook data={PrivacyPolicy} />}
        />
        <Route
          path={RoutesConstants.studentHandbookPoliciesAndGuidlines}
          element={<StudentHandbook data={AssessmentPoliciesAndGuidlines} />}
        />
        <Route
          path={RoutesConstants.studentHandbookCourseDesign}
          element={<StudentHandbook data={CourseDesign} />}
        />
        <Route path={RoutesConstants.systemCheck} element={<SystemCheck />} />
      </Route>
      <Route path={RoutesConstants.account} element={<Account />}>
        <Route
          path={RoutesConstants.accountSettings}
          element={<AccountSettings />}
        />
        <Route
          path={RoutesConstants.profileDetails}
          element={<AccountProfileDetails />}
        />
        <Route
          path={RoutesConstants.billinPreference}
          element={<BillinPreference />}
        />
      </Route>
      <Route path={RoutesConstants.home} element={<Dashboard />} />
      <Route path={RoutesConstants.LTI13Handler} element={<Lti13Handler />} />
      <Route path={RoutesConstants.transcript} element={<Transcript />} />
    </Routes>
  );
  return (
    <>
      {location.pathname.toLowerCase().includes(RoutesConstants.LTI13Handler) ? (
        <Grid container sx={Styles.rootGridContainer}>
          <Grid item md={12} sx={Styles.routeContainerGrid}>
            {routes}
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={Styles.rootGridContainer}>
          {screenLaptop ? (
            <>
              <Grid item md={12} sx={Styles.routeContainerGrid}>
                {routes}
              </Grid>
              <NavBar />
            </>
          ) : (
            <>
              <Grid item md={1} sm={1} xs={0} p={0} sx={Styles.gridNavbar}>
                <NavBar />
              </Grid>
              <Grid item md={11} sm={11} xs={12} sx={Styles.routeContainerGrid}>
                {routes}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default AppRoutes;
