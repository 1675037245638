import { spacing } from '../../../themes/theme';
import { themeColors, hexToRGB } from '../../../themes/colors';

export default {
  root: {
    bgcolor: hexToRGB(themeColors.lightBlue, 0.6),
    borderRadius: `${spacing * 1}px`,
    p: `${spacing * 2}px`,
    width: `${spacing * 32}px`,
    '@media (max-width: 1300px)': {
      width: `${spacing * 28}px`,
      p: `${spacing * 1}px`,
    },
    '@media (max-width: 1200px)': {
      width: `${spacing * 32}px`,
      p: `${spacing * 2}px`,
    },
  },

  textHeader: {
    fontSize: `${spacing * 1.5}px`,
    textTransform: 'uppercase',
    color: themeColors.primary,
    fontWeight: 700,
    mt: `${spacing * 0.5}px`,
  },
  boxLink: { my: `${spacing * 2}px`, px: `${spacing * 1}px` },
  row: {
    display: 'flex',
    alignItems: 'start',
  },
  boxLinkText: {
    cursor: 'pointer',
    '&:hover': {
      color: themeColors.darkBlue,
      '& .MuiTypography-root': {
        color: themeColors.darkBlue,
      },
    },
  },
  boxSubText: {
    pl: `${spacing * 2}px`,
    mt: `${spacing * 1}px`,
  },
  textLink: {
    px: `${spacing * 1}px`,
    color: themeColors.blackLight,
  },
  subText: {
    color: themeColors.tertiaryBlack,
    fontSize: `${spacing * 1.75}px`,
    px: `${spacing * 0.5}px`,
  },
  iconClock: {
    fontSize: `${spacing * 1.5}px`,
  },
  iconLink: { mt: `${spacing * 0.5}px` },
};
