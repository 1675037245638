import { themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

const Styles = {
  label: {
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
  },
  parentTextfield: {
    width: `${spacing * 44}px`,
    border: 'none',
    mt: `${spacing * 2}px`,
    '& .MuiOutlinedInput-root': {
      height: `${spacing * 5}px`,
      width: `${spacing * 44}px`,
      '@media (max-width: 1024px)': { width: '100%' },
      backgroundColor: themeColors.white,
    },
    '@media (max-width: 1024px)': { width: '50%' },
    '@media (max-width: 768px)': { width: '95%' },
  },
  textfield: {
    display: 'block',
    width: `${spacing * 44}px`,
    border: 'none',
    mt: `${spacing * 2}px`,
    '& .MuiOutlinedInput-root': {
      height: `${spacing * 5}px`,
      width: `${spacing * 44}px`,
      '@media (max-width: 1024px)': { width: '60%' },
      '@media (max-width: 768px)': { width: '90%' },
      backgroundColor: `${themeColors.white}`,
    },
    '& .MuiInputLabel-root': {
      marginTop: `-${spacing}px`,
    },
    '@media (max-width: 1024px)': { width: '60%' },
    '@media (max-width: 480px)': { width: '100%' },
  },
  boxBtnNext: {
    mt: `${spacing * 5}px`,
    mb: `${spacing * 5}px`,
    textAlign: 'left',
    display: 'flex',
  },
  updateBtnContainer: {
    paddingRight: '20px',
  },
  btnNext: {
    bgcolor: themeColors.primary,
    color: themeColors.white,
    height: `${spacing * 5}px`,
    width: `${spacing * 25}px`,
    borderRadius: `${spacing * 25}px`,
    '&:hover': {
      bgcolor: themeColors.primary,
      color: themeColors.grey,
    },
    letterSpacing: '2px',
    fontSize: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': {
      width: '100%',
    },
  },
  btnCancel: {
    border: '1px solid black',
    bgcolor: themeColors.white,
    color: themeColors.black,
    height: `${spacing * 5}px`,
    width: `${spacing * 12}px`,
    borderRadius: `${spacing * 25}px`,
    fontWeight: '700',
    '&:hover': {
      bgcolor: themeColors.midGrey,
    },
    letterSpacing: '2px',
    fontSize: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': {
      width: '100%',
    },
  },
  passwordContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    pl: `${spacing * 3}px`,
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      pl: 0,
    },
  },
  changeBtn: {
    fontWeight: 700,
    letterSpacing: '1px',
    color: themeColors.blendedBlue,
    marginLeft: `${spacing * 4}px`,
    marginTop: `${spacing * 1.75}px`,
    paddingLeft: `${spacing * 2}px`,
    paddingRight: `${spacing * 2}px`,
    '&:hover': {
      backgroundColor: themeColors.lightBlue,
      borderRadius: `${spacing * 2.25}px`,
    },
    '@media (max-width: 1024px)': { p: 0, ml: `${spacing * 2}px` },
    '@media (max-width: 768px)': {
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
  eyeIcon: {
    fontSize: `${spacing * 2}px`,
  },
  validationTitle: {
    fontSize: `${spacing * 2}px`,
    fontWeight: 500,
    mt: `${spacing * 3}px`,
    mb: `${spacing * 2}px`,
  },
  validationPoints: {
    fontSize: `${spacing * 1.75}px`,
    paddingBottom: `${spacing * 0.2}px`,
    ml: `${spacing * 0.5}px`,
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  required: {
    fontWeight: 400,
    color: themeColors.warningDark,
    ml: `${spacing * 0.5}px`,
    mt: `${spacing * 0.5}px`,
  },
};

export default Styles;
