import { themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

export default {
  root: {
    width: '100%',
    mt: `${spacing * 8}px`
  },
  container: {
    position: 'relative'
  },
  boxHeader: {
    mt: `${spacing * 8}px`,
  },
  textPageHeader: {
    mt: `${spacing * 2}px`,
    fontSize: `${spacing * 7}px`,
    fontWeight: 700,
    mb: `${spacing * 4}px`,
    fontFamily: `Atkinson Hyperlegible`,
    '@media (max-width: 768px)': { fontSize: `${spacing * 5}px` },
  },
  textHeaderCollege: {
    fontSize: `${spacing * 3.5}px`,
    fontWeight: 700,
    mb: `${spacing * 4}px`,
  },
  btnCancel: {
    fontSize: `${spacing * 1.5}px`,
    border: `1px solid ${themeColors.grey}`,
    borderRadius: `${spacing * 25}px`,
    height: `${spacing * 5}px`,
    letterSpacing: `${spacing * 0.25}px`,
    mr: `${spacing * 1.25}px`,
  },
  boxBtnNext: {
    mt: `${spacing * 5}px`,
    mb: `${spacing * 5}px`,
    textAlign: 'right',
  },
};
