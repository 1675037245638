import { Avatar, Box, Typography, useMediaQuery } from '@mui/material';
import footerImage from '../../../assets/images/footer-help-center.png';
import { VariantTypography } from '../../../themes/properties';
import Styles from './styles';
import { footerHelpCenter, screenBreakpoints } from '../../../constants';

const Footer = () => {
  const screenLaptopLarge = useMediaQuery(
    `(max-width:${screenBreakpoints.laptopLarge})`,
  );
  return (
    <Box sx={Styles.root}>
      <Box sx={Styles.container}>
        <Typography variant={VariantTypography.h5} sx={Styles.textHeader}>
          {footerHelpCenter.header}
        </Typography>
        <Box sx={Styles.boxInfo}>
          <Typography
            sx={Styles.textContactInfo}
            variant={VariantTypography.body1}
            dangerouslySetInnerHTML={{
              __html: footerHelpCenter.contactRequest,
            }}
          />
          <Typography sx={Styles.textTimings} variant={VariantTypography.body1}>
            {footerHelpCenter.timings}
          </Typography>
        </Box>
      </Box>
      {!screenLaptopLarge ? (
        <Avatar src={footerImage} sx={Styles.avatar} />
      ) : null}
    </Box>
  );
};

export default Footer;
