import { Box, Button, Typography } from '@mui/material';
import { VariantTypography } from '../../themes/properties';
import Styles from './Styles';
import { TutorialStepCardType } from '../../types/global';
import { tutorialsStepsCardConstants } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints } from '@fortawesome/pro-solid-svg-icons';

interface PropsType {
  data: TutorialStepCardType;
  onStartTutorial: () => void;
}

const TutorialsStepCard = ({ data, onStartTutorial }: PropsType) => {
  return (
    <Box sx={Styles.root}>
      <Box sx={Styles.boxHeader}>
        <Box sx={Styles.iconShoeBox}>
          <Box sx={Styles.iconShoe}>
            <FontAwesomeIcon icon={faShoePrints} />
          </Box>
        </Box>
        <Typography sx={Styles.modalTitle}>{data.title}</Typography>
      </Box>
      <img src={data.bannerImage} style={Styles.img} />
      <Box sx={Styles.boxFooter}>
        <Typography variant={VariantTypography.body2}>
          {data.modalData.length} {tutorialsStepsCardConstants.steps}
        </Typography>
        <Button sx={Styles.btnStartTutorial} onClick={onStartTutorial}>
          {tutorialsStepsCardConstants.startTutorial}
        </Button>
      </Box>
    </Box>
  );
};

export default TutorialsStepCard;
