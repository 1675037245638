import { spacing } from '../../themes/theme';
import { themeColors } from '../../themes/colors';

export default {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
  },
  root: {
    boxShadow: 24,
    outline: 'none',
    maxWidth: 900,
    minWidth: 900,
    position: 'relative',
    bgcolor: themeColors.white,
    borderRadius: `${spacing * 1}px`,
    '@media (max-width: 1200px)': {
      maxWidth: 700,
      minWidth: 700,
    },
    '@media (max-width: 768px)': {
      maxWidth: 450,
      minWidth: 450,
    },
    '@media (max-width: 480px)': {
      maxWidth: 300,
      minWidth: 300,
    },
  },
  boxHeader: {
    bgcolor: themeColors.primary,
    height: `${spacing * 6}px`,
    display: 'flex',
    alignItems: 'center',
    pl: `${spacing * 4}px`,
    pr: `${spacing * 2}px`,
    '@media (max-width: 768px)': {
      pl: `${spacing * 2}px`,
      pr: `${spacing * 1}px`,
    },
    '@media (max-width: 480px)': {
      pl: `${spacing * 1}px`,
      pr: `${spacing * 0.5}px`,
    },
  },
  iconsShpePrints: {
    color: themeColors.white,
    mr: `${spacing * 3}px`,
    '@media (max-width: 768px)': {
      mr: `${spacing * 1.5}px`,
    },
  },
  iconClose: {
    color: themeColors.white,
    ml: 'auto',
    cursor: 'pointer',
    p: `${spacing * 1}px`,
  },
  txtTutorialHeader: {
    color: themeColors.white,
    mr: `${spacing * 1}px`,
    textTransform: 'uppercase',
    fontSize: `${spacing * 2}px`,
    letterSpacing: `${spacing * 0.15}px`,
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: `${spacing * 1.75}px`,
    },
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 1.5}px`,
    },
  },
  txtTutorialHeaderValue: {
    color: themeColors.white,
    '@media (max-width: 768px)': {
      fontSize: `${spacing * 1.75}px`,
    },
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 1.5}px`,
    },
  },
  boxFooterRoot: {
    height: `${spacing * 6}px`,
  },
  footerBtn: {
    fontSize: `${spacing * 1.5}px`,
    bgcolor: themeColors.primary,
    color: themeColors.white,
    borderRadius: `${spacing * 3}px`,
    border: `1px solid ${themeColors.primary}`,
    px: `${spacing * 2}px`,
    '&:hover': {
      bgcolor: themeColors.primary,
    },
    '&:disabled': {
      border: `1px solid ${themeColors.primary}`,
      color: themeColors.primary,
      bgcolor: themeColors.white,
      fontSize: `${spacing * 1.5}px`,
    },
  },
  imageContainer: {
    width: '100%',
    height: '100%',
  },
  imageBox: {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '40vw',
  },
  textStep: {
    '&.MuiTypography-root': {
      fontSize: `${spacing * 2.75}px`,
    },
    mb: `${spacing * 0.5}px`,
  },
  textStepDots: {
    '& .MuiMobileStepper-dots': { display: 'none' },
  },
};
