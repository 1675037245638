import { Button } from '@mui/material';
import Styles from './UpdateButton.style';
import { spacing } from '../../themes/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { AccountSettingsConstants, dataTestIds } from '../../constants';
import { themeColors } from '../../themes/colors';

interface PropsType {
  isLoading: boolean;
  isUpdated: boolean;
  isValidForm: boolean;
  handleUpdateSettings: () => void;
}

const UpdateButton = ({
  isLoading,
  isUpdated,
  isValidForm,
  handleUpdateSettings,
}: PropsType) => {
  const updateButtonText = isLoading ? (
    <FontAwesomeIcon icon={faSpinner} spin style={Styles.buttonIcons} />
  ) : isUpdated ? (
    <FontAwesomeIcon icon={faCheck} style={Styles.buttonIcons} />
  ) : (
    AccountSettingsConstants.updateBtn
  );

  const updateBtnBackground = isLoading
    ? themeColors.tertiaryBlack
    : isUpdated
    ? themeColors.primary
    : !isValidForm
    ? themeColors.white
    : themeColors.primary;

  const updateBtntextColor = isLoading
    ? themeColors.primary
    : !isValidForm
    ? themeColors.grey
    : themeColors.white;
  return (
    <Button
      sx={{
        ...Styles.btnNext,
        width:
          isLoading || isUpdated ? `${spacing * 5}px` : `${spacing * 25}px`,
        backgroundColor: updateBtnBackground,
        color: updateBtntextColor,
      }}
      onClick={handleUpdateSettings}
      disabled={!isValidForm}
      data-testid={dataTestIds.updateSettingsButton.btnUpdateSettings}
    >
      {updateButtonText}
    </Button>
  );
};

export default UpdateButton;
