import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  APIs,
  EMPTY_STRING,
  features,
  requestTranscipt,
  US_COUNTRY_ID,
} from '../../../constants';
import axiosInstance from '../../../http-requests/httpClient';
import Course from '../../../models/Course.model';
import Institute, { InstituteAddress } from '../../../models/Institute.model';
import { Address } from '../../../models/Profile.model';
import Transcript from '../../../models/Transcript.model';
import { AppDispatch, RootState } from '../../../store';
import { showToast } from '../../../store/app.slice';
import { SLICE_TRANSCRIPT } from '../../../store/slices';
import { SnackbarAnchorSeverity } from '../../../themes/properties';
import {
  ErrorResponse,
  SubmitTranscriptResponse,
} from '../../../types/contract';
import { setTranscriptData as setTranscript } from './transcriptSlice';
import Country from '../../../models/Country.model';
import State from '../../../models/State.model';
import { isTranscriptEligibleCourse, openPage } from '../../../utils/utils';
import { getInstitutes, updateMasterData } from '../../../store/appAsync.actions';
import { MasterDataEntity } from '../../../types/global';
import Feature from '../../../models/Feature.model';
import { isTranscriptFeeFeatureEnable } from '../transcriptHelper';

export const setTranscriptData =
  () => (dispatch: AppDispatch, getState: any) => {
    let { profile, app }: RootState = getState();
    const profileData = profile.profile;
    const address = new Address(
      profileData?.address?.address ?? EMPTY_STRING,
      profileData?.address?.city ?? EMPTY_STRING,
      profileData?.address?.state ?? EMPTY_STRING,
      profileData?.address?.zip ?? EMPTY_STRING,
      profileData?.address?.country ?? US_COUNTRY_ID,
    );

    const mailAddress = new Address(
      EMPTY_STRING,
      EMPTY_STRING,
      EMPTY_STRING,
      EMPTY_STRING,
      US_COUNTRY_ID,
    );

    const featuresFlags: Array<Feature> = app.masterData?.features ?? [];

    const transcriptFeature = featuresFlags?.find(
      (feature) => feature.name === features.transcriptFee,
    );

    const transcript = new Transcript(
      profileData?.firstName ?? EMPTY_STRING,
      profileData?.lastName ?? EMPTY_STRING,
      profileData?.email ?? EMPTY_STRING,
      profileData?.dob ?? EMPTY_STRING,
      EMPTY_STRING,
      null,
      false,
      false,
      true,
      address,
      mailAddress,
      transcriptFeature?.enabled ?? false,
    );

    dispatch(setTranscript(transcript));
  };

export const sendTranscript = createAsyncThunk(
  `${SLICE_TRANSCRIPT + APIs.transcript}`,
  async (_, thunkAPI) => {
    let { transcript, app, dashboard, profile }: RootState =
      thunkAPI.getState() as RootState;
    const transcriptData = transcript.transcriptData;

    const learnerCountry = app.masterData?.countries.find(
      (country: Country) => country.id === transcriptData?.address?.country,
    );
    const learnerStateData = learnerCountry?.states.find(
      (state: State) => state.id === transcriptData?.address?.state,
    );
    const collegeCountry = app.masterData?.countries.find(
      (country: Country) => country.id === transcriptData?.mailAddress?.country,
    );
    const collegeStateData = collegeCountry?.states.find(
      (state: State) => state.id === transcriptData?.mailAddress?.state,
    );

    const college: Institute | null =
      app.institutes.find(
        (institute: Institute) => institute.id === transcriptData?.college?.id
      ) ?? null;

      const collegeDetails: any = {
      name: college?.name ?? (transcriptData?.college?.isCustom ? transcriptData?.college?.name : requestTranscipt.unofficial),
      isCustom: !!transcriptData?.college?.isCustom,
      sendToAce: false,
      sendUnofficialCopy: transcriptData?.emailUnoffcialTranscript,
      mailTranscript: transcriptData?.mailTranscript,
      emailTranscript: transcriptData?.emailTranscript,
    };

    if(collegeDetails.isCustom && college?.id) {
        collegeDetails['id'] = college?.id; // Add ID property, to ensure updating existing custom destination
    }

    if (college?.name === 'American Council on Education (ACE)') {
      collegeDetails['sendToAce'] = true;
    }

    if (transcriptData?.emailTranscript) {
      collegeDetails['email'] =
        transcriptData?.emailDestination ?? EMPTY_STRING;
    }

    if (transcriptData?.mailTranscript) {
      collegeDetails['address'] = {
        address1: transcriptData?.mailAddress?.address,
        address2: transcriptData?.mailAddress?.addressLine2,
        city: transcriptData?.mailAddress?.city,
        state: collegeStateData?.code,
        zip: transcriptData?.mailAddress?.zip,
        country: collegeCountry?.code,
      };
    }

    if (college?.isPartner) {
      collegeDetails.emailTranscript = college.emailTranscript;
      collegeDetails.mailTranscript = college.mailTranscript;

      if (collegeDetails.emailTranscript) {
        collegeDetails['email'] = college.email;
      }

      if (collegeDetails.mailTranscript) {
        const address: InstituteAddress | null =
          college.addresses.find(
            (address: InstituteAddress) => address.type === 1,
          ) ?? null;

        if (address) {
          collegeDetails['address'] = {
            address1: address.address,
            address2: address.address2,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country: address.country,
          };
        }
      }
    }

    const courseDetails: Array<any> = [];

    dashboard.courses.forEach((course: Course) => {
      if (isTranscriptEligibleCourse(course)) {
        courseDetails.push({
          id: course.id,
          identifier: course.courseIdentifier,
          name: course.name,
          score: course?.gradeValue,
          completionDate: course?.CompletionDate,
          isRetake: course?.isRetake ?? false,
          sku: course.courseSKU,
        });
      }
    });

    if (!courseDetails.length) {
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: requestTranscipt.noTranscript,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
      return false;
    }

    const requestData: any = {
      transcriptFeeFlag: transcriptData?.isFeeFeatureEnabled ?? false,
      isPartner: college?.isPartner ?? false,
      studentDetails: {
        firstName: transcriptData?.firstName,
        lastName: transcriptData?.lastName,
        email: transcriptData?.email,
        dob: transcriptData?.dob,
        address: {
          address1: transcriptData?.address.address,
          address2: transcriptData?.address.addressLine2 ?? null,
          city: transcriptData?.address.city,
          state: transcriptData?.address.state,
          zip: transcriptData?.address.zip,
          country: transcriptData?.address.country,
        },
      },
      collegeDetails,
      courseDetails,
    };

    // API call
    try {
      const response = await axiosInstance.post(
        APIs.transcript.replace('%s', profile.profile?.id ?? EMPTY_STRING),
        {
          ...requestData,
        },
      );

      if(collegeDetails.isCustom) { // Refresh the list of institutes if custom transcript destination has been added/updated
        await thunkAPI.dispatch(getInstitutes(true));
      }

      const data: SubmitTranscriptResponse = response.data;
      // Feature is enabled.
      if (
        data.transcriptFeatureFlag &&
        isTranscriptFeeFeatureEnable(transcriptData)
      ) {
        openPage(data.transcriptCheckoutUrl, false);
      } else {
        return true;
      }
    } catch (e: any) {
      if (e?.response.status === 409) {
        thunkAPI.dispatch(
          updateMasterData({ dataType: MasterDataEntity.Features }),
        );
      }
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
      return false;
    }
  },
);
