import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  root: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '@media (max-width: 768px)': { overflowX: 'hidden', }, 
  },
  leftContainer: {
    background: themeColors.leftPanelGradient,
  },
  menuContainer: {
    width: `${spacing * 108}px`,
  },
  contentContainer: {
    width: '100%',
    paddingLeft: `${spacing * 3}px`,
  },
};
