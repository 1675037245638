import { ContentType, interfaceType, ListType } from '../types';

export const mtaTrainigCoursesData: ContentType = {
  header: 'MTA Training Courses',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: 'MTA Training Courses',
      sections: [
        `StraighterLine ‘s Microsoft Technology Associate (MTA) training courses are designed to help students prepare for the Networking Fundamentals <a href="#hc_mta_certification_exam" title="MTA Certification Exam">certification examinations</a>. Courses include access to a comprehensive <a href="${window.location.origin}/help-center/etextbooks" title="StraighterLine eTextbooks">eTextbook</a> as well as training videos and lectures. To successfully complete your MTA training course, you must complete all required <a href="/help-center/academics/exams/" title="StraighterLine Exams">exams</a> and upload <a href="#hc_mta_submit_proof" title="Upload Proof">proof</a> that you completed your <a href="#hc_mta_certification_exam" title="Certification Exam">certification exam</a>. To understand how your grade will be calculated, please review your course syllabus. Your course syllabus includes an overview of course topics and an explanation of how each assignment contributes to your grade in the course.`,
      ],
    },
    {
      title: '<a id="hc_mta_certification_exam"></a>MTA Certification Exam',
      sections: [
        `StraighterLine’s MTA training courses prepare you for taking the MTA certification exams, but completing the training course does not mean you are MTA certified. To receive MTA certification, you will need to take one of the MTA certification exams, offered by <a href="http://www.pearsonvue.com/microsoft/" target="_blank" title="Pearson VUE">Pearson VUE</a> or <a href="http://www.certiport.com/locator" target="_blank" title="Certigo">Certigo</a>. MTA exams can either be taken in-person at an approved testing center or at your home or office with an <a href="https://www.microsoft.com/en-us/learning/online-proctored-exams.aspx" target="_blank" title="Microsoft Online Proctored Exams">online proctor</a>.`,

        `Schedule Your MTA Certification Exam: Use the links below to schedule your MTA certification exam.`,

        {
          type: interfaceType.POINT_TYPE,
          title: null,
          listType: ListType.Unordered,
          points: [
            `<a href="https://www.microsoft.com/en-us/learning/exam-98-366.aspx" target="_blank" title="Schedule Your MTA Certification Exam">Networking Fundamentals</a>`,
          ],
        },

        `<a href="https://home.pearsonvue.com/Clients/Microsoft/pvue_ms_scheduling.aspx" target="_blank" title="Tutorial: Scheduling your MTA Certification Exam">Tutorial: Scheduling your MTA Certification Exam</a>`,
      ],
    },
    {
      title:
        '<a id="hc_mta_submit_proof"></a>How to Submit Proof of Your MTA Certification Exam',
      sections: [
        `At the end of your StraighterLine MTA training course, there is an assignment called “Certification Attempt Submission,” where you can upload exam attempt proof.`,

        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `Follow the step-by-step instructions below to submit proof in your MTA course:`,
          points: [
            `Login to your <a href="https://wsr.pearsonvue.com/testtaker/registration/Dashboard/MICROSOFT" target="_blank" title="Pearson VUE account login">Pearson VUE account</a>`,
            `Under the My Account menu, click "View Score Reports"`,
            `Take a screenshot of your report`,
            `Upload your screenshot to the "Certification Attempt Submission"`,
            `Make sure your name is clearly visible on your submitted Score Report`,
          ],
          note: `testing facilities can provide proof of attempt upon request which can also be uploaded to your assignment for acceptable proof`,
        },

        {
          type: interfaceType.IMAGE_TYPE,
          title: null,
          image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academics/mta_cert_proof_example.png`,
        },

        `Within a few minutes of completing your MTA certification exam, you will receive notification of your pass or fail status. In addition, you will receive a printable report that provides your exam score and feedback on performance on the skill areas measured. The exam delivery provider will forward your score to Microsoft within five business days.`,

        `<a href="https://www.microsoft.com/en-us/learning/certification-exam-policies.aspx#scoring" target="_blank" title="Microsoft Exam Policies and FAQ">Microsoft Exam Policies and FAQ</a>`,
      ],
    },
    {
      title:
        'Can I Earn Credit for Completing StraighterLine MTA Training Courses?',
      sections: [
        `StraighterLine’s MTA training courses are not currently recommended by the American Council on Education (ACE) for credit. However, many schools consider MTA certifications for credit transfer. Contact the admissions department at your target college/university for details about whether MTA certifications are transferable for credit.`,
      ],
    },
  ],
};
