import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_STRING } from '../../../constants';
import Transcript from '../../../models/Transcript.model';
import { SLICE_TRANSCRIPT } from '../../../store/slices';
import { sendTranscript } from './transcriptAsync.actions';
import { API_STATE } from '../../../constants';

export interface State {
  transcriptData: Transcript | null;
  apiState: string;
  showTranscriptForm: boolean;
  showNoTranscriptPage: boolean;
}

const initialState: State = {
  transcriptData: null,
  apiState: EMPTY_STRING,
  showTranscriptForm: false,
  showNoTranscriptPage: false,
};

export const transcriptSlice = createSlice({
  name: SLICE_TRANSCRIPT,
  initialState,
  reducers: {
    setTranscriptData: (state, action) => {
      state.transcriptData = { ...action.payload };
    },
    resetApiState: (state) => {
      state.apiState = EMPTY_STRING;
    },
    setPageState: (state, action) => {
      state.showTranscriptForm = action.payload.showTranscriptForm;
      state.showNoTranscriptPage = action.payload.showNoTranscriptPage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendTranscript.pending, (state) => {
      state.apiState = API_STATE.pending;
    });
    builder.addCase(sendTranscript.fulfilled, (state, action) => {
      if (action.payload) {
        state.apiState = API_STATE.success;
      } else {
        state.apiState = API_STATE.error;
      }
    });
    builder.addCase(sendTranscript.rejected, (state) => {
      state.apiState = API_STATE.error;
    });
  },
});

export const { setTranscriptData, resetApiState, setPageState } =
  transcriptSlice.actions;

export default transcriptSlice.reducer;
