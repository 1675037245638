import { createTheme } from '@mui/material';
import { themeColors } from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.secondary,
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
});

export default theme;

export const MAX_SCREEN_WIDTH = 1536;

export const leftPoint =
  window.innerWidth > MAX_SCREEN_WIDTH
    ? (window.innerWidth - MAX_SCREEN_WIDTH) / 2
    : 0;

export const screenWidth =
  window.innerWidth > MAX_SCREEN_WIDTH ? MAX_SCREEN_WIDTH : window.innerWidth;

export const singleGridWidth = screenWidth / 12;

export const spacing: number = 8;

export const dimensions = {
  header: {
    height: `${spacing * 8}px`,
  },
  footer: {
    height: `${spacing * 18}px`,
  },
};
