import { themeColors } from '../../themes/colors';
import { interfaceType, ListType } from './types';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

//browser
var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var majorVersion = parseInt(navigator.appVersion, 10);
var nameOffset, verOffset, ix;

const getOperaingSystem = () => {
  var os = '-';
  var clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Linux', r: /(Linux|X11)/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  return os;
};

const checkOS = () => {
  const os = getOperaingSystem();

  if (/Windows/.test(os)) {
    const osVersion = /Windows (.*)/.exec(os)?.[1] ?? '-';
  }

  if (os === 'Mac OS X') {
    const osVersion: string | null =
      /Mac OS X (10[\.\_\d]+)|(11[\.\_\d]+)|(12[\.\_\d]+)|(13[\.\_\d]+)/.exec(
        nAgt,
      )?.[1] ?? null;

    if (osVersion) {
      const osVersions = osVersion.split('_');
      if (osVersions[0] === '10' && parseInt(osVersions[1]) < 11) {
        return false;
      }
      return true;
    }
  }
  return true;
};

const getBrowser = () => {
  let browser = 'Unkown';
  let version = '';
  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browser = 'Chrome';
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browser = 'Safari';
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browser = 'Firefox';
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf('Trident/') != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(nAgt.indexOf('rv:') + 3);
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() == browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }

  // trim the version string
  if ((ix = version?.indexOf(';')) != -1) version = version?.substring(0, ix);
  if ((ix = version?.indexOf(' ')) != -1) version = version?.substring(0, ix);
  if ((ix = version?.indexOf(')')) != -1) version = version?.substring(0, ix);

  majorVersion = parseInt('' + version, 10);
  if (isNaN(majorVersion)) {
    version = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  return { browser, version };
};

const checkBrowser = () => {
  const { browser } = getBrowser();
  const preferredBrowsers: Array<string> = [
    'Chrome',
    'Firefox',
    'Microsoft Internet Explorer',
    'Safari',
  ];
  return preferredBrowsers.includes(browser || '');
};

const checkCookie = () => {
  var cookieEnabled = navigator.cookieEnabled ? true : false;

  if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') != -1 ? true : false;
  }
  return cookieEnabled;
};

const getFlash = () => {
  const plugins: any = navigator.plugins;
  const mimeTypes: any = navigator.mimeTypes;
  let flashVersion = 'no check';
  let d;
  let fv: any = [];
  if (
    typeof plugins !== 'undefined' &&
    typeof plugins['Shockwave Flash'] === 'object'
  ) {
    d = plugins['Shockwave Flash'].description;
    if (
      d &&
      !(
        typeof mimeTypes !== 'undefined' &&
        mimeTypes['application/x-shockwave-flash'] &&
        !mimeTypes['application/x-shockwave-flash'].enabledPlugin
      )
    ) {
      // navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin indicates whether plug-ins are enabled or disabled in Safari 3+
      d = d.replace(/^.*\s+(\S+\s+\S+$)/, '$1');
      fv[0] = parseInt(d.replace(/^(.*)\..*$/, '$1'), 10);
      fv[1] = parseInt(d.replace(/^.*\.(.*)\s.*$/, '$1'), 10);
      fv[2] = /[a-zA-Z]/.test(d)
        ? parseInt(d.replace(/^.*[a-zA-Z]+(.*)$/, '$1'), 10)
        : 0;
    }
  }
  if (fv.length) {
    flashVersion = fv[0] + '.' + fv[1];
    return flashVersion;
  } else {
    return null;
  }
};

const checkFlash = () => {
  const flashVersion = getFlash();
  if (flashVersion) {
    const versions = flashVersion.split('.');
    if (parseInt(versions[0]) < 11) {
      return false;
    }
    if (parseInt(versions[0]) === 11 && parseInt(versions[1]) < 6) {
      return false;
    }
    return true;
  }
  return false;
};

const browser = getBrowser();

export const systemCheck = {
  header: 'System Check',
  subHeader: 'Check for Recommended Settings.',
  title:
    'To ensure that you have the best StraighterLine experience, we recommend that your computer has the following necessary resources.',
  tableColumns: [`CATEGORY`, `RECOMMENDED`, `DETECTED`, `INFORMATION`],
  tableData: [
    {
      category: `Operating System`,
      recommended: `Windows XP or later, Mac OS X 10.11.X or later`,
      detected: getOperaingSystem(),
      information: checkOS(),
    },
    {
      category: `Web Browser`,
      recommended: `Latest Chrome (Preferred), Firefox, Internet Explorer or Safari browser`,
      detected: `${browser.browser} ${browser.version}`,
      information: checkBrowser(),
    },
    {
      category: `Cookies`,
      recommended: `Enabled`,
      detected: checkCookie() ? 'Enabled' : 'Disabled',
      information: checkCookie(),
    },
    {
      category: `Adobe Flash Player`,
      recommended: `Version 11.6 or later`,
      detected: getFlash() || 'Not Found',
      information: checkFlash() || {
        icon: faTriangleExclamation,
        color: themeColors.warning,
        text: 'Not Optimal',
        linkText: 'Download Flash',
        link: 'http://get.adobe.com/flashplayer',
      },
    },
  ],

  sections: [
    {
      title: 'Online Proctoring Technical Requirements and System Check',
      sections: [
        `The final exams for all StraighterLine courses except for English Composition I & II require online proctoring by ProctorU. <span class="ub-bld">A web-camera is required for online proctoring.</span> To ensure that you can be successfully proctored by ProctorU, please check the technical requirement and test your computer at this link: <a href="http://www.proctoru.com/testitout/" target="_blank">http://www.proctoru.com/testitout/</a>`,

        `If you still experience any issues, please <a href="${window.location.origin}/help-center/support-request">contact us</a>. We're happy to help!`,
      ],
    },
    {
      title: 'General Suggestions',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `If you experience technical issues, it is helpful to try the following:`,
          points: [
            `<strong>Update your internet browser`,
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.Unordered,
              //   title: ``,
              points: [
                `<a href="https://www.google.com/intl/en/chrome/browser/"
              title="Download Google Chrome" target="_blank">Google Chrome</a>
              <span class="font-green">(preferred)</span>`,
                `<a href="">OS X</a>`,

                `<a href="http://www.mozilla.org/en-US/firefox/new/"
                title="Download Mozilla Firefox" target="_blank">Mozilla Firefox</a>`,

                `<a href="http://windows.microsoft.com/en-us/internet-explorer/ie-11-worldwide-languages" title="Download Internet Explorer" target="_blank">Internet Explorer</a>`,

                `<a href="http://www.apple.com/safari/"
                title="Learn about Safari" target="_blank">Safari</a>`,
              ],
            },
            `Ensure your pop-up blocker is turned off`,

            `Enable Javascript`,

            `Clear your cookies and cache`,

            `If using Firefox, <a href="https://www.mozilla.org/en-US/plugincheck/" target="_blank">visit here</a> to check that your Plug-ins are active and up to date.`,
          ],
        },

        `For instructions on how to turn off your pop-up blocker, enable Javascript and clear your cache and cookies in Firefox, <a href="../../media/systemcheck/how-to-guide-systemcheck.pdf" target="_blank">please view this PDF document</a>.`,
      ],
    },
  ],
  constants: {
    passed: 'Passed',
  },
};

export interface SuggestionType {
  icon: IconProp;
  color: string;
  text: string;
  linkText: string;
  link: string;
}

export interface TableRowType {
  category: string;
  recommended: string;
  detected: string;
  information: SuggestionType | boolean;
}
