import React, { ReactNode, useMemo } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Divider,
  Collapse,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import Course from '../../models/Course.model';
import Styles from './CourseCardSkeleton.style';
import { dataTestIds } from '../../constants';
import { CourseStatus } from '../../types/global';

interface Props {
  course: Course;
  bannerHandler: () => void;
  statusText: string;
  menuIcons: ReactNode;
  contentSlot: ReactNode;
  actionSlot: ReactNode;
}

const CourseCardSkeleton = ({
  course,
  bannerHandler,
  statusText,
  contentSlot,
  actionSlot,
  menuIcons,
}: Props) => {
  const [expanded, setExpanded] = React.useState(true);

  const isCourseNotStated = useMemo(
    () =>
      course.status.toLocaleLowerCase() ===
      CourseStatus.NotStarted.toLocaleLowerCase(),
    [course.status],
  );

  const handleExpandClick = (event: React.MouseEvent<HTMLElement>) => {
    setExpanded(true);
    event.stopPropagation();
  };

  return (
    <Card
      sx={{
        ...Styles.root,
        '&.MuiPaper-root': {
          boxShadow: expanded ? Styles.cardBoxShadow : 'none',
        },
      }}
      data-testid={dataTestIds.courseCard.cardContainer}
    >
      <CardMedia
        sx={Styles.banner}
        image={course.banner}
        title={course.name}
        onClick={bannerHandler}
        data-testid={dataTestIds.courseCard.cardMedia}
      >
        <Box sx={Styles.boxRoot}>
          {(!expanded || isCourseNotStated) && (
            <Box sx={Styles.blurBanner}></Box>
          )}
          {!expanded && (
            <Typography
              sx={Styles.textCourseNameHeader}
              data-testid={dataTestIds.courseCard.textCourseName}
            >
              {course.name}
            </Typography>
          )}
          <Typography
            sx={Styles.textCourseStatusHeader}
            data-testid={dataTestIds.courseCard.textHeaderStatusValue}
          >
            {statusText}
          </Typography>

          {!expanded && (
            <Box
              onClick={handleExpandClick}
              sx={Styles.iconCollapse}
              data-testid={dataTestIds.courseCard.downArrowDiv}
            >
              <FontAwesomeIcon
                icon={faCaretDown}
                data-testid={dataTestIds.courseCard.downArrowIcon}
              />
            </Box>
          )}
        </Box>
      </CardMedia>
      <Collapse in={expanded}>
        <CardActions data-testid={dataTestIds.courseCard.cardAction}>
          <Box
            sx={Styles.boxCourseHeader}
            data-testid={dataTestIds.courseCard.divHeader}
          >
            <Typography
              sx={Styles.textCourseName}
              data-testid={dataTestIds.courseCard.textCourseValue}
            >
              {course.name}
            </Typography>
            {menuIcons}
          </Box>
        </CardActions>
        <CardContent
          sx={{ ...Styles.cardContent }}
          data-testid={dataTestIds.courseCard.cardContent}
        >
          {contentSlot}

          {actionSlot}

          <Divider sx={Styles.dividerBottom} />

          <Box
            sx={Styles.upArrow}
            onClick={() => setExpanded(false)}
            data-testid={dataTestIds.courseCard.upArrowDiv}
          >
            <FontAwesomeIcon
              icon={faCaretUp}
              data-testid={dataTestIds.courseCard.upArrowIcon}
            />
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CourseCardSkeleton;
