import { ProctorProvider } from '../types/global';

export default class Course {
  constructor(
    public id: number,
    public name: string,
    public banner: string,
    public code: string,
    public courseIdentifier: string,
    public progress: number,
    public grade: string | null,
    public upNext: CourseSection | null,
    public status: string,
    public enrollmentStatus: string,
    public gradeValue: number | null,
    public isPlagiarismViolation: boolean,
    public protorUrl: string | null,
    public courseSKU: string,
    public startDate: string,
    public resourceLinkId: string,
    public syllabusUri: string | null,
    public isSurveyTaken: boolean,
    public CompletionDate: string,
    public isRetake: boolean,
    public sections: Array<CourseSection>,
    public proctorProviderCode: ProctorProvider,
    public cwpCode: ProctorProvider,
    public courseUrl: string,
    public resumeUrl: string,
    public providerCourseId: string,
    public providerCourseName: string | null,
    public isActive: boolean,
    public expirationDate: string | null,
  ) {}
}

export class CourseSection {
  constructor(
    public name: string,
    public url: string,
    public resourceLinkId: string,
    public cwpCode: string,
  ) {}
}
