import {
  Avatar,
  Button, Checkbox,
  Dialog, DialogActions, DialogContent,
  DialogTitle, FormControlLabel, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeColors } from '../../themes/colors';
import Styles from './AddTrialCoursesDialog.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/system';
import React, { useMemo, useState} from "react";
import { VariantButton } from "../../themes/properties";
import { showFreeTrialAddCoursesModal } from "../../module/dashboard/redux/dashboardSlice";
import {
  addTrialCoursesDialogConstants,
  dataTestIds,
  FreeTrialCourses,
} from "../../constants";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store";
import Course from "../../models/Course.model";
import { addTrialCourses } from "../../module/dashboard/redux/dashboardAsync.actions";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: themeColors.primary,
  color: themeColors.white,
  display: 'flex',
  alignItems: 'center',
  '& .MuiIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

interface PropsType {
  enrolledCourses: Course[];
}

const AddTrialCoursesDialog = ({ enrolledCourses }: PropsType) => {
  const dispatch = useDispatch<AppDispatch>();

  const isCourseEnrolledInto = (courseIdentifier: string) => {
    return enrolledCourses.some(course => course.courseIdentifier === courseIdentifier);
  }

  const [coursesList, setCoursesList] = useState(
    FreeTrialCourses.map(freeTrialCourse => {
      return {
        ...freeTrialCourse,
        isActive: !isCourseEnrolledInto(freeTrialCourse.id),
        isChecked: isCourseEnrolledInto(freeTrialCourse.id)
      }
    })
  );

  const handleClickSubmit = () => {
    const coursesToEnrollPayload = coursesList
      .filter(course => course.isActive && course.isChecked)
      .map(course => {
        return { courseId: course.id}
      })
    dispatch(addTrialCourses(coursesToEnrollPayload));
  }

  const isValidForm: boolean = useMemo(
    () => coursesList.some(course => course.isActive && course.isChecked),
    [coursesList],
  );

  const onChangeCheck = (courseId: string, isChecked: boolean) => {
    const newCoursesList = [...coursesList]
    newCoursesList[newCoursesList.findIndex(item => item.id === courseId)].isChecked = isChecked
    setCoursesList([...newCoursesList])
  }

  const closeModal = () => {
    dispatch(showFreeTrialAddCoursesModal(false));
  };

  return (
    <div>
      <Dialog
        open={true}
        maxWidth="md"
        fullWidth
        sx={Styles.dialog}
        PaperProps={{
          sx: Styles.root,
        }}
      >
        <StyledDialogTitle sx={Styles.title}>
          <Box sx={Styles.titleText}>
            {addTrialCoursesDialogConstants.title}
          </Box>
          <Box sx={Styles.iconClose} onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark} />
          </Box>
        </StyledDialogTitle>

        <DialogContent sx={Styles.content} dividers>
          <Typography sx={Styles.subtitle}>
            {addTrialCoursesDialogConstants.subtitle}
          </Typography>

          <Box
            sx={Styles.boxCheckbox}
            data-testid={dataTestIds.addTrialCoursesDialog.coursesContainer}
          >
            {coursesList.map((course) => {
              return (
                <FormControlLabel
                  sx={Styles.checkboxController}
                  key={course.id}
                  control={
                    <Checkbox
                      checkedIcon={
                        <Box
                          sx={{
                            ...Styles.checkboxIcon,
                            ...Styles.checkedboxIcon,
                          }}
                        />
                      }
                      icon={<Box sx={Styles.iconCheckBox} />}
                      onChange={e => onChangeCheck(course.id, e.target.checked)}
                      name="trialCourse"
                      checked={course.isChecked}
                      disabled={!course.isActive}
                      data-testid={dataTestIds.addTrialCoursesDialog.courseCheckbox}
                    />
                  }
                  label={
                    <Box sx={Styles.checkboxLabelContainer}>
                      <Avatar
                        src={course.image}
                        sx={Styles.courseImage}
                        data-testid={dataTestIds.addTrialCoursesDialog.courseImage}
                      />
                      <Typography>
                        <strong>{course.name}</strong>
                      </Typography>
                    </Box>
                  }
                  data-testid={dataTestIds.addTrialCoursesDialog.course}
                />
              );
            })}
          </Box>
        </DialogContent>

        <DialogActions sx={Styles.dialogActions}>
          <Button
            autoFocus
            onClick={handleClickSubmit}
            disabled={!isValidForm}
            variant={VariantButton.contained}
            sx={Styles.button}
          >
            {addTrialCoursesDialogConstants.buttonAddCourses}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddTrialCoursesDialog;