import { dimensions } from './themes/theme';
const Styles = {
  container: { padding: '0 !important', maxWidth: '100% !important' },
  rootGridContainer: {
    mt: dimensions.header.height,
  },
  routeContainerGrid: {
    padding: 1,
    paddingTop: 0,
    width: '100%',
  },
  gridNavbar: { float: 'left' },
};

export default Styles;
