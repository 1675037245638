import { themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

export default {
  container: {
    width: `90%`,
    paddingLeft: `${spacing * 2}px`,
  },
  textHeader: {
    fontWeight: 700,
    fontSize: `${spacing * 7}px`,
    marginTop: `${spacing * 5}px`,
  },
  textSubHeader: {
    fontWeight: 400,
    fontSize: `${spacing * 2.75}px`,
    margin: `${spacing * 3}px 0 ${spacing * 4}px`,
  },
  divider: {
    marginTop: `${spacing * 5.5}px`,
    border: `1px solid ${themeColors.midGrey}`,
  },
};
