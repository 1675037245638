import {
  AccountSettingsConstants,
  BC_API_URL,
  BC_URL,
} from './../constants';
import { Auth } from 'aws-amplify';
import axiosInstance from '../http-requests/httpClient';
import { APIs, localStorageConstants } from '../constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_APP } from '../store/slices';
import {
  MasterDataType as MasterDataTypeStore,
  showLoader,
  showToast,
} from './app.slice';
import {
  MasterDataResponse,
  MasterDataResponseType,
  MasterDataCountryType,
  MasterDataStateType,
  MasterDataType,
  ErrorResponse,
  InstituteResponse,
  InstituteResponseType,
  InstituteAddressType,
  MasterDataFeatureType,
} from '../types/contract';
import Country from '../models/Country.model';
import State from '../models/State.model';
import CourseProgram from '../models/CourseProgram.model';
import HighestEducation from '../models/HighestEducation.model';
import ReferralType from '../models/ReferralType.model';
import Institute, { InstituteAddress } from '../models/Institute.model';
import { isFirstInDay } from '../utils/dateUtils';
import {
  getMasterData as getMasterDataLocatalStorage,
  storeMasterData,
  getInstitutesData,
  storeInstitutesData,
} from '../utils/auth.util';
import { SnackbarAnchorSeverity } from '../themes/properties';
import { MasterDataEntity } from '../types/global';
import Feature from '../models/Feature.model';
import { RootState } from "./index";

export const getMasterData = createAsyncThunk(
  `${SLICE_APP + APIs.getMasterData}`,
  async (_, thunkAPI) => {
    try {
      const masterDataLocatalStorage = getMasterDataLocatalStorage();
      if (
        isFirstInDay(localStorageConstants.firstInDayMasterData) ||
        !masterDataLocatalStorage
      ) {
        const response = await axiosInstance.get(APIs.getMasterData, {
          params: { include: MasterDataEntity.All },
        });
        const responseData: MasterDataResponse = response;
        const parsedData: MasterDataTypeStore = prepareMasterData(
          responseData.data,
        );
        storeMasterData(parsedData);
        return parsedData;
      } else {
        return masterDataLocatalStorage;
      }
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const updateMasterData = createAsyncThunk(
  `${SLICE_APP + 'Update Master Data'}`,
  async ({ dataType }: { dataType: string }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(APIs.getMasterData, {
        params: { include: dataType },
      });
      const responseData: MasterDataResponse = response;
      let parsedData: MasterDataTypeStore = prepareMasterData(
        responseData.data,
      );

      for (var key in parsedData) {
        const data = parsedData[key as keyof MasterDataTypeStore];
        if (data.length === 0) {
          delete parsedData[key as keyof MasterDataTypeStore];
        }
      }

      const masterDataLocatalStorage: any = getMasterDataLocatalStorage();

      if (masterDataLocatalStorage) {
        for (var key in parsedData) {
          if ((key as keyof MasterDataTypeStore) in masterDataLocatalStorage) {
            masterDataLocatalStorage[key as keyof MasterDataTypeStore] =
              parsedData[key as keyof MasterDataTypeStore];
          }
        }
      }

      storeMasterData(masterDataLocatalStorage);
      return masterDataLocatalStorage;
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const getInstitutes = createAsyncThunk(
  `${SLICE_APP + APIs.getInstitutes}`,
  async (isForced: boolean|void = false, thunkAPI) => {
    try {
      const institutesDataLocatalStorage = getInstitutesData();
      if (
        isForced ||
        isFirstInDay(localStorageConstants.firstInDayInstitutes) ||
        !institutesDataLocatalStorage
      ) {
        const { profile }: RootState = thunkAPI.getState() as RootState;
        const responseData: InstituteResponse = await axiosInstance.get(APIs.getInstitutes,
          { params: { learnerId: profile.profile?.id } }
        );
        const parsedData: Array<Institute> = prepareInstituteData(
          responseData.data,
        );
        storeInstitutesData(parsedData);
        return parsedData;
      }
      return institutesDataLocatalStorage;
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const getTargetInstitute = createAsyncThunk(
  `${SLICE_APP + APIs.getTargetInstitute}`,
  async ({ targetCollege }: { targetCollege: string }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        APIs.getTargetInstitute.replace('%s', targetCollege),
      );
      const responseData: InstituteResponse = response;
      const parsedData: Institute | null = prepareTargetInstituteData(
        responseData.data[0],
      );
      return parsedData;
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const signOut = createAsyncThunk('signOut', async (_, thunkAPI) => {
  thunkAPI.dispatch(showLoader(true));
  try {
    const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    localStorage.clear();
    removeCognitoCookies();
    await Auth.signOut({ global: true });
    localStorage.clear();
    removeCognitoCookies();
    if (window.location.hostname !== 'localhost') {
      const bcLogoutUrl: any = await getBigCommerceLoginUrl(
        user.attributes['custom:bc_customerId'],
        idToken,
        '/login.php?action=logout',
      );

      if (bcLogoutUrl !== '') window.location.href = bcLogoutUrl;
      else {
        window.location.href = BC_URL ?? '';
      }
    }
    thunkAPI.dispatch(showLoader(false));
  } catch (e: any) {
    const error: ErrorResponse = e?.response?.data as ErrorResponse;
    thunkAPI.dispatch(
      showToast({
        show: true,
        message: error?.message,
        severity: SnackbarAnchorSeverity.error,
      }),
    );
    thunkAPI.dispatch(showLoader(false));
  }
});

export const removeCognitoCookies = () => {
  const cookieNames = document.cookie.split(/=[^;]*(?:;\s*|$)/);
  for (let cookie of cookieNames) {
    if (cookie.includes('Cognito')) {
      document.cookie =
        cookie + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        cookie +
        '=; Path=/; domain = .straighterline.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }
};

export const getBigCommerceLoginUrl = (
  customerId: string,
  idToken: string,
  redirectUrl: string,
) => {
  return new Promise(async (resolve, reject) => {
    fetch(`${BC_API_URL}/bc/login-url`, {
      headers: {
        Accept: 'application/json',
        Authorization: idToken,
      },
      method: 'POST',
      body: JSON.stringify({
        customer_id: parseInt(customerId),
        redirect_url: redirectUrl,
      }),
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.status === 200) {
            resolve(json.data.loginUrl);
          } else {
            console.error(json.message);
            reject(null);
          }
        });
      })
      .catch((error) => {
        console.error(error);
        reject(null);
      });
  });
};

export const changePassword = createAsyncThunk(
  'changePassword',
  async (
    { oldPassword, newPassword }: { oldPassword: string; newPassword: string },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(showLoader(true));
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      thunkAPI.dispatch(showLoader(false));
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: AccountSettingsConstants.passwordMessage.success,
          severity: SnackbarAnchorSeverity.success,
        }),
      );
    } catch (error: any) {
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error?.message,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
      thunkAPI.dispatch(showLoader(false));
    }
  },
);

const prepareMasterData = (
  data: Array<MasterDataResponseType>,
): MasterDataTypeStore => {
  let countries: Array<Country> = [];
  let courseProgramms: Array<CourseProgram> = [];
  let highestEducations: Array<HighestEducation> = [];
  let referralTypes: Array<ReferralType> = [];
  let features: Array<Feature> = [];

  data?.forEach((data: MasterDataResponseType) => {
    if (data.entityType === MasterDataEntity.Country) {
      countries = parseMasteDataObject(
        data.entityType,
        data.data,
      ) as Array<Country>;
    } else if (data.entityType === MasterDataEntity.CourseProgram) {
      courseProgramms = parseMasteDataObject(
        data.entityType,
        data.data,
      ) as Array<CourseProgram>;
    } else if (data.entityType === MasterDataEntity.HighestEducation) {
      highestEducations = parseMasteDataObject(
        data.entityType,
        data.data,
      ) as Array<HighestEducation>;
    } else if (data.entityType === MasterDataEntity.ReferralType) {
      referralTypes = parseMasteDataObject(
        data.entityType,
        data.data,
      ) as Array<ReferralType>;
    } else if (data.entityType === MasterDataEntity.Features) {
      features = parseMasteDataObject(
        data.entityType,
        data.data,
      ) as Array<Feature>;
    }
  });
  const masterData: MasterDataTypeStore = {
    countries,
    courseProgramms,
    highestEducations,
    referralTypes,
    features,
  };
  return masterData;
};

const parseMasteDataObject = (
  dataType: string,
  data: Array<
    | MasterDataCountryType
    | MasterDataType
    | MasterDataFeatureType
  >,
) => {
  if (dataType === MasterDataEntity.Country) {
    return data
      .sort((a: any, b: any) => a.order - b.order)
      .map((country: any) => {
        country = country as MasterDataCountryType;
        const states =
          country?.states?.map(
            (state: MasterDataStateType) =>
              new State(
                state.id,
                state.name,
                state.code,
                state.isActive,
                state.order,
              ),
          ) ?? [];

        return new Country(
          country.id,
          country.name,
          country.code,
          country.isActive,
          states,
        );
      });
  } else if (dataType === MasterDataEntity.CourseProgram) {
    return data
      .sort((a: any, b: any) => a.order - b.order)
      .map((courseProgram: any) => {
        courseProgram = courseProgram as MasterDataType;
        return new CourseProgram(
          courseProgram.id,
          courseProgram.name,
          courseProgram.isActive,
        );
      });
  } else if (dataType === MasterDataEntity.HighestEducation) {
    return data
      .sort((a: any, b: any) => a.order - b.order)
      .map((highestEducation: any) => {
        highestEducation = highestEducation as MasterDataType;
        return new HighestEducation(
          highestEducation.id,
          highestEducation.name,
          highestEducation.isActive,
          highestEducation.order,
        );
      });
  } else if (dataType === MasterDataEntity.ReferralType) {
    return data
      .sort((a: any, b: any) => a.order - b.order)
      .map((referralType: any) => {
        referralType = referralType as MasterDataType;
        return new ReferralType(
          referralType.id,
          referralType.name,
          referralType.isActive,
          referralType.order,
        );
      });
  } else if (dataType === MasterDataEntity.Features) {
    return data.map((feature: any) => {
      feature = feature as MasterDataFeatureType;
      return new Feature(feature.name, feature.value);
    });
  }
};

const prepareInstituteData = (
  data: Array<InstituteResponseType>,
): Array<Institute> => {
  const institutes: Array<Institute> = [];
  data
    // No need to sort here, because the array is sorted by back-end already (custom sorting may be applied there)
    .forEach((item: InstituteResponseType) => {
      if (!item.isDeleted) {
        const addresses: Array<InstituteAddress> = [];
        item.Addresses.forEach((address: any) => {
          addresses.push(
            new InstituteAddress(
              address.AddressId,
              address.Address,
              address.AddressTwo,
              address.City,
              address.State,
              address.Country,
              address.Zip ?? address.ZipCode,
              address.Email,
              address.Type,
            ),
          );
        });
        institutes.push(
          new Institute(
            item.id,
            item.name,
            item.uri,
            item.email,
            item.isPartner,
            item.isCustom,
            item.mailTranscript,
            item.emailTranscript,
            addresses,
          ),
        );
      }
    });
  return institutes;
};

const prepareTargetInstituteData = (
  data: InstituteResponseType,
): Institute | null => {
  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  const addresses: Array<InstituteAddress> = [];

  data.Addresses.forEach((address: InstituteAddressType) => {
    addresses.push(
      new InstituteAddress(
        address.AddressId,
        address.Address,
        address.AddressTwo,
        address.City,
        address.State,
        address.Country,
        address.Zip,
        address.Email,
        address.Type,
      ),
    );
  });

  return new Institute(
    data.id,
    data.name,
    data.uri,
    data.email,
    data.isPartner,
    data.isCustom,
    data.mailTranscript,
    data.emailTranscript,
    addresses,
  );
};
