export default {
  straighterLineLogo: 'StraighterLine Logo',
  profilePic: 'Profile Picture',
  emailDesitnation: 'emailDesitnation',
  schoolListView: 'schoolListView',
  rightPanel: {
    closeIcon: 'rightPanel_icon_close',
    expiredMembership: 'rightPanel_text_expiredMembership',
    myTargetSchool: 'rightPanel_textLabel_myTargetSchool',
    myTargetSchoolValue: 'rightPanel_textValue_myTargetSchool',
    myTargetSchoolMedia: 'rightPanel_image_myTargetSchool',
    undecidedCollege: 'rightPanel_text_undecidedCollege',
    helpfulLinks: {
      linkIcon: 'helpfulLinks_icon_link',
      linkName: 'helpfulLinks_link_name',
      subText: 'helpfulLinks_text',
      clockIcon: 'helpfulLinks_icon_clock',
    },
    purchaseCourseToday: {
      divParent: 'rightPanel_purchaseCourseToday_div_parent',
      header: 'rightPanel_purchaseCourseToday_header',
      content: 'rightPanel_purchaseCourseToday_content',
      actionContainer: 'rightPanel_purchaseCourseToday_action_container',
    },
    recommendedCourses: {
      header: 'recommendedCourses_heading',
      courseName: 'recommendedCourses_heading_courseName',
      courseDescription: 'recommendedCourses_body_courseDescription',
      learnButton: 'recommendedCourses_button_learnmore',
      nextButton: 'recommendedCourses_button_next',
      backButton: 'recommendedCourses_button_back',
    },
  },
  courseCard: {
    upArrowIcon: 'courseCard_icon_upArrow',
    upArrowDiv: 'courseCard_div_upArrow',
    actionButton: 'courseCard_button_action',
    textGradeValue: 'courseCard_text_GradeValue',
    divGradeValue: 'courseCard_div_GradeValue',
    labelGrade: 'courseCard_label_Grade',
    divGrade: 'courseCard_div_Grade',
    divUpNext: 'courseCard_div_upNext',
    labelUpNext: 'courseCard_label_upNext',
    textUpNextValue: 'courseCard_text_upNextValue',
    borderLineProgress: 'courseCard_progress_borderLine',
    containerStatus: 'courseCard_container_status',
    divStatus: 'courseCard_div_status',
    textStatusValue: 'courseCard_text_statusValue',
    textExpired: 'courseCard_text_expired',
    cardContainer: 'courseCard_card_container',
    cardContent: 'courseCard_card_content',
    cardAction: 'courseCard_card_action',
    cardMedia: 'courseCard_card_media',
    textCourseValue: 'courseCard_text_courseValue',
    divHeader: 'courseCard_div_courseHeader',
    downArrowDiv: 'courseCard_div_downArrow',
    downArrowIcon: 'courseCard_icon_downArrow',
    textHeaderStatusValue: 'courseCard_text_headerStatusValue',
    textCourseName: 'courseCard_text_courseName',
    menu: {
      iconCalendar: 'courseCard_menu_icon_calendar',
      iconPDFFile: 'courseCard_menu_icon_pdfFile',
      iconListCheck: 'courseCard_menu_icon_listCheck',
      divCalendar: 'courseCard_menu_div_calendar',
      divPDFFile: 'courseCard_menu_div_pdfFile',
      divListCheck: 'courseCard_menu_div_listCheck',
      buttonCalendar: 'courseCard_menu_button_calendar',
      buttonPDFFile: 'courseCard_menu_button_pdfFile',
      buttonListCheck: 'courseCard_menu_button_listCheck',
    },
  },
  profileHeader: {
    divParent: 'profileHeader_div_parent',
    divContainer: 'profileHeader_div_container',
    divAvatar: 'profileHeader_div_avatar',
    divName: 'profileHeader_div_name',
    textFirstName: 'profileHeader_text_fname',
    textLastName: 'profileHeader_text_lname',
    divCouseInfo: 'profileHeader_div_courseInfo',
    divCouseInnner1: 'profileHeader_div_courseInner1',
    divIconUser: 'profileHeader_div_icon_user',
    iconUser: 'profileHeader_icon_user',
    textCourseName: 'profileHeader_text_courseName',
    avatarValue: 'profileHeader_avatar_value',
    divIconPen: 'profileHeader_div_iconPen',
    iconPen: 'profileHeader_icon_pen',
  },
  courseStatus: {
    divContainer: 'courseStatus_div_container',
    divFilterContainer: 'courseStatus_div_filterContainer',
    buttonAllCourses: 'courseStatus_button_allCourses',
    buttonInProgress: 'courseStatus_button_inProgress',
    buttonInCompleted: 'courseStatus_button_completed',
    divActionButtons: 'courseStatus_div_actionButtons',
    buttonSendTranscript: 'courseStatus_button_sendTranscript',
    buttonAddCourse: 'courseStatus_button_addCourse',
    textSendTranscript: 'courseStatus_text_sendTranscript',
    textAddCourse: 'courseStatus_text_addCourse',
    divIconSendTranscript: 'courseStatus_div_iconSendTranscript',
    divIconAddCourse: 'courseStatus_div_iconAddCourse',
    iconSendTranscript: 'courseStatus_icon_sendTranscript',
    iconAddCourse: 'courseStatus_icon_addCourse',
  },
  leftNavbar: {
    drawerContainer: 'leftNavbar_drawer_container',
    profilePicContainer: 'leftNavbar_div_profilePicContainer',
    profileDivider: 'leftNavbar_divider_profileDivider',
    menuContainer: 'leftNavbar_div_menuContainer',
    listDivider: 'leftNavbar_divider_listDivider',
    menuIconContainer: 'leftNavbar_icon_menuIconContainer',
    listItem: 'leftNavbar_list_listItem',
    myCoursesName: 'leftNavbar_list_myCoursesName',
    myCoursesIcon: 'leftNavbar_icon_myCoursesIcon',
    transcriptName: 'leftNavbar_list_transcriptName',
    transcriptIcon: 'leftNavbar_icon_transcriptIcon',
    helpCenterName: 'leftNavbar_list_helpcenterName',
    helpCenterIcon: 'leftNavbar_icon_helpcenterIcon',
    accountsName: 'leftNavbar_list_accountsName',
    accountIcon: 'leftNavbar_icon_accountIcon',
    supportName: 'leftNavbar_list_supportName',
    supportIcon: 'leftNavbar_icon_supportIcon',
  },
  dashboard: {
    divContainer: 'dashboard_div_container',
    divIconPlay: 'dashboard_div_iconPlay',
    iconPlay: 'dashboard_icon_play',
    divRightPanel: 'dashboard_div_rightPanel',
  },
  addTrialCoursesDialog: {
    coursesContainer: 'addTrialCoursesDialog_courses_container',
    course: 'addTrialCoursesDialog_course',
    courseCheckbox: 'addTrialCoursesDialog_course_checkbox',
    courseImage: 'addTrialCoursesDialog_course_image',
  },
  header: {
    divContainer: 'header_div_container',
    logoDiv: 'header_div_logo',
    logoImage: 'header_image_logo',
    closeButton: 'header_button_close',
    closeIcon: 'header_icon_close',
    homeIconDiv: 'header_div_homeIcon',
    homeIcon: 'header_icon_home',
    dashboardButtonDiv: 'header_div_dashboardButton',
    dashboardButton: 'header_button_dashboard',
    menuItem: 'header_menu_',
    mainMenuCourse: 'header_menu_mainCourse',
    mainMenuCourseIcon: 'header_icon_mainCourseMenu',
    cartIconDiv: 'header_div_cartIcon',
    cartIcon: 'header_icon_cart',
    logoutIconDiv: 'header_div_logoutIcon',
    logoutIcon: 'header_icon_logout',
    buttonCourseMenu: 'header_btn_courseMenu',
  },
  footer: {
    divContainer: 'footer_div_container',
    linkAboutUsText: 'footer_link_aboutUsText',
    linkCareerText: 'footer_link_careerText',
    linkPrivacyPolicyText: 'footer_link_privacyPolicyText',
    linkContactUsText: 'footer_link_contactUsText',
    facebookIcon: 'footer_icon_facebook',
    instagramIcon: 'footer_icon_instagram',
    twitterIcon: 'footer_icon_twitter',
    youtubeIcon: 'footer_icon_youtube',
    linkedInIcon: 'footer_icon_linkedIn',
    appLogoDiv: 'footer_div_appLogo',
    appLogoImage: 'footer_image_appLogo',
    copyRightDiv: 'footer_div_copyRight',
    copyRightIcon: 'footer_icon_copyRight',
    copyRightText: 'footer_text_copyRight',
    childcareImage: 'footer_image_childcare',
  },
  transcriptHistory: {
    divContainer: 'tsHistory_div_container',
    textHeader: 'tsHistory_text_header',
    divSubHeader: 'tsHistory_div_subHeader',
    textSubHeader: 'tsHistory_text_subHeader',
    btnSendTranscript: 'tsHistory_button_sendTranscript',
    divPlaneeeIcon: 'tsHistory_div_planeIcon',
    iconPlane: 'tsHistory_icon_plane',
    textReqTranscript: 'tsHistory_text_requestTranscript',
    textTableHeaderDateOfRequest: 'tsHistory_text_tableHeaderDateOfRequest',
    textTableHeaderCoursesTransfered:
      'tsHistory_text_tableHeaderCoursesTransfered',
    textTableHeaderDestination: 'tsHistory_text_tableHeaderDestination',
    textTableHeaderStatus: 'tsHistory_text_tableHeaderStatus',
    textTableDateOfRequest: 'tsHistory_text_tableDateOfRequest_',
    textTableCoursesTransfered: 'tsHistory_text_tableCoursesTransfered_',
    textTableDestination: 'tsHistory_text_tableDestination_',
    textTableStatus: 'tsHistory_text_tableStatus_',
  },
  transcriptInstructions: {
    divContainer: 'tsInstructions_div_container',
    textHeader: 'tsInstructions_text_header',
    textPoint: 'tsInstructions_text_point_',
    textNote: 'tsInstructions_text_note',
  },
  transcriptViewCourse: {
    divContainer: 'tsViewCourse_div_container',
    divHeader: 'tsViewCourse_div_header',
    textHeader: 'tsViewCourse_text_header',
    textHeaderDate: 'tsViewCourse_text_headerDate',
    divUpDownIcon: 'tsViewCourse_div_upDownIcon',
    iconUpDown: 'tsViewCourse_icon_upDown',
    divTable: 'tsViewCourse_div_table',
    textTableHeaderCourse: 'tsViewCourse_text_tableHeaderCourse',
    textTableHeaderCompletionDate:
      'tsViewCourse_text_tableHeaderCompletionDate',
    textTableHeaderFinalGrade: 'tsViewCourse_text_tableHeaderFinalGrade',
    textTableCourse: 'tsViewCourse_text_tableCourse_',
    textTableCompletionDate: 'tsViewCourse_text_tableCompletionDate_',
    textTableFinalGrade: 'tsViewCourse_text_tableFinalGrade_',
  },
  transcriptForm: {
    textfeeWarningMessage: 'tsForm_text_feeWarningMessage',
    divBodyContainer: 'tsForm_div_bodyContainer',
    headerContainer: 'tsForm_div_headerContainer',
    headerText: 'tsForm_text_headerText',
    listTextField: 'tsForm_text_list',
    listOptions: 'tsForm_text_listOptions_',
    emailContainer: 'tsForm_div_emailContainer',
    divEmailDestination: 'tsForm_div_divEmailDestination',
    textEmailRequired: 'tsForm_text_emailRequired',
    textEmailError: 'tsForm_text_emailError',
    divMailContainer: 'tsForm_div_mailContainer',
    mailForm: 'tsForm_form_mail',
    mailCheckbox: 'tsForm_checkbox_mail',
    divEmailContainer: 'tsForm_div_emailContainer',
    emailForm: 'tsForm_form_email',
    emailCheckbox: 'tsForm_checkbox_email',
    diveUnofficialmailContainer: 'tsForm_div_unofficialmailContainer',
    unofficialEmailForm: 'tsForm_form_unofficialEmail',
    unofficialEmailCheckbox: 'tsForm_checkbox_unofficialEmail',
    btnContainer: 'tsForm_div_btnContainer',
    submitBtn: 'tsForm_btn_submit',
    cancelBtn: 'tsForm_btn_cancel',
    partnerCollegeHeader: 'tsForm_div_partnerCollegeHeader',
    divPartnerCollege: 'tsForm_div_partnerCollege',
    historyMessageContainer: 'tsForm_div_historyMessageContainer',
    textHistoryMessage: 'tsForm_text_textHistoryMessage',
    personalInfoContainer: 'tsForm_div_personalInfoContainer',
    personalInfoHeader: 'tsForm_div_headerPersonalInfo',
    textSelectDestination: 'tsForm_text_selectDestination',
    textDeliveryDestination: 'tsForm_text_deliveryDestination',
    errorDeliveryDestination: 'tsForm_text_errorDeliveryDestination',
  },
  transcriptComponent: {
    userInfo: {
      divContainer: 'tsFormUserInfo_div_container',
      textNameKey: 'tsFormUserInfo_text_nameKey',
      textNameValue: 'tsFormUserInfo_text_nameValue',
      iconLock: 'tsFormUserInfo_icon_lock',
      textEmailKey: 'tsFormUserInfo_text_emailKey',
      textEmailValue: 'tsFormUserInfo_text_emailValue',
      textDobKey: 'tsFormUserInfo_text_dobKey',
      textDobValue: 'tsFormUserInfo_text_dobValue',
    },
    mailingAddressView: {
      divContainer: 'tsFormMailingAddressView_div_container',
      headerContainer: 'tsFormMailingAddressView_div_headerContainer',
      updateBtn: 'tsFormMailingAddressView_btn_update',
      cancelBtn: 'tsFormMailingAddressView_btn_cancel',
      addressContainer: 'tsFormMailingAddressView_div_addressContainer',
      textAddress: 'tsFormMailingAddressView_text_address',
      textZip: 'tsFormMailingAddressView_text_zip',
      textCountry: 'tsFormMailingAddressView_text_country',
      iconPen: 'tsFormMailingAddressView_icon_pen',
      editAddressContainer: 'tsFormMailingAddressView_div_editAddressContainer',
      textEdit: 'tsFormMailingAddressView_text_edit',
    },
  },
  mailingAddressForm: {
    addressForm: 'mailingAddressForm_form_address',
    countryLabel: 'mailingAddressForm_text_countryLabel',
    countryOptions: 'mailingAddressForm_options_countryOptions_',
    countryName: 'mailingAddressForm_text_countryName',
    textCountryRequired: 'mailingAddressForm_text_countryRequired',
    textAddress: 'mailingAddressForm_text_address',
    // inputAddress: 'mailingAddressForm_input_address',
    textAddressRequired: 'mailingAddressForm_text_addressRequired',
    twoLineAddress: 'mailingAddressForm_text_twoLineAddress',
    textCity: 'mailingAddressForm_text_city',
    textCityRequired: 'mailingAddressForm_text_cityRequired',
    textZip: 'mailingAddressForm_text_zip',
    textZipRequired: 'mailingAddressForm_text_zipRequired',
    textZipError: 'mailingAddressForm_text_zipError',
    stateRegion: 'mailingAddressForm_text_stateRegion',
    stateRegionRequired: 'mailingAddressForm_text_stateRegionRequired',
    stateForm: 'mailingAddressForm_form_stateForm',
    stateLabel: 'mailingAddressForm_text_stateLabel',
    stateOptions: 'mailingAddressForm_text_stateOptions_',
    stateName: 'mailingAddressForm_text_stateName',
    stateRequired: 'mailingAddressForm_text_stateRequired',
  },
  transcriptSuccess: {
    textHeader: 'tsSuccess_text_header',
    btnSubmitAnotherTranscript: 'tsSuccess_btn_submitAnotherTranscript',
    btnViewHistory: 'tsSuccess_btn_viewHistory',
    bodyContainer: 'tsSuccess_div_bodyContainer',
    subHeader: 'tsSuccess_text_subHeader',
    successMessage: 'tsSuccess_text_successMessage',
  },
  noTranscript: {
    textHeader: 'noTs_text_header',
    btnSubmitAnotherTranscript: 'noTs_btn_submitAnotherTranscript',
    btnViewHistory: 'noTs_btn_viewHistory',
    bodyContainer: 'noTs_div_bodyContainer',
    textNoTranscript: 'noTs_text_noTranscript',
    eligibilityMessage: 'noTs_text_eligibilityMessage',
  },
  accountSettings: {
    divPersonalInfo: 'accountSettings_div_personalInfo',
    textHeader: 'accountSettings_text_header',
    textYourPhoto: 'accountSettings_text_yourPhoto',
    textStudentInfo: 'accountSettings_text_studentInfo',
    textStudentInfoSubHeading: 'accountSettings_text_studentInfoSubHeading',
    divMailingAddress: 'accountSettings_div_mailingAddress',
    textMailingAddressHeader: 'accountSettings_text_mailingAddressHeader',
    divBtnNext: 'accountSettings_div_buttonNext',
    textChangePassword: 'accountSettings_text_changePassword',
  },
  uploadProfilePic: {
    avatar: 'uploadProfilePic_avatar',
    inputFile: 'uploadProfilePic_input_file',
    btnUpload: 'uploadProfilePic_btn_upload',
    divUploadIcon: 'uploadProfilePic_div_uploadIcon',
    iconUpload: 'uploadProfilePic_icon_upload',
  },
  profileInfoForm: {
    labelFirstName: 'profileInfoForm_label_fName',
    inputFirstName: 'profileInfoForm_input_fName',
    labelLastName: 'profileInfoForm_label_lName',
    inputLastName: 'profileInfoForm_input_lName',
    labelEmail: 'profileInfoForm_label_email',
    inputEmail: 'profileInfoForm_input_email',
    datePickerDOB: 'profileInfoForm_datePicker_dob',
    textErrorDOB: 'profileInfoForm_text_errorDOB',
    textRequiredDOB: 'profileInfoForm_text_requiredDOB',
    inputPhone: 'profileInfoForm_input_phone',
    textRequiredPhone: 'profileInfoForm_text_requiredPhone',
    textErrorPhone: 'profileInfoForm_text_errorPhone',
  },
  updateSettingsButton: {
    btnUpdateSettings: 'updateSettingsButton_btn_updateSettings',
  },
  changePasswordForm: {
    divContainer: 'changePasswordForm_div_container',
    inputCurrentPwd: 'changePasswordForm_input_currentPassword',
    btnChangePwd: 'changePasswordForm_button_changePassword',
    inputOldPwd: 'changePasswordForm_input_oldPassword',
    textRequiredOldPwd: 'changePasswordForm_text_requiredOldPassword',
    inputNewPwd: 'changePasswordForm_input_newPassword',
    textRequiredNewPwd: 'changePasswordForm_text_requiredNewPassword',
    inputConfirmPwd: 'changePasswordForm_input_confirmPassword',
    textRequiredConfirmPwd: 'changePasswordForm_text_requiredConfirmPassword',
    textRequiredMatch: 'changePasswordForm_text_requiredMatch',
    textHeaderValidation: 'changePasswordForm_text_headerValidation',
    textValidMinChar: 'changePasswordForm_text_validMinChar',
    textValidLowerCase: 'changePasswordForm_text_validLowerCase',
    textValidUpperCase: 'changePasswordForm_text_validUpperCase',
    textValidNumber: 'changePasswordForm_text_validNumber',
    textValidSpecialChar: 'changePasswordForm_text_validSpecialChar',
    btnCancel: 'changePasswordForm_button_cancel',
    iconValid: 'changePasswordForm_icon_valid',
    iconInvalid: 'changePasswordForm_icon_invalid',
  },
  profileDetails: {
    textHeader: 'profileDetails_text_header',
    textHeaderAboutYou: 'profileDetails_text_headerAboutYou',
  },
  collegeCareerForm: {
    labelTargetCollege: 'collegeCareerForm_label_targetCollege',
    selectCollege: 'collegeCareerForm_select_college',
    menuCollege: 'collegeCareerForm_menu_college_',
    checkboxCollege: 'collegeCareerForm_checkbox_college',

    labelAreaStudy: 'collegeCareerForm_label_areaStudy',
    inputAreaStudy: 'collegeCareerForm_input_areaStudy',
    selectAreaStudy: 'collegeCareerForm_select_areaStudy',
    menuAreaStudy: 'collegeCareerForm_menu_areaStudy_',
    textRequiredAreaStudy: 'collegeCareerForm_text_requiredAreaStudy',
  },
  aboutYouForm: {
    labelHighestEducation: 'collegeCareerForm_label_highestEducation',
    inputHighestEducation: 'collegeCareerForm_input_highestEducation',
    selectHighestEducation: 'collegeCareerForm_select_highestEducation',
    menuHighestEducation: 'collegeCareerForm_menu_highestEducation_',
    textRequiredHighestEducation:
      'collegeCareerForm_text_requiredHighestEducation',

    labelReference: 'collegeCareerForm_label_reference',
    inputReference: 'collegeCareerForm_input_reference',
    selectReference: 'collegeCareerForm_select_reference',
    menuReference: 'collegeCareerForm_menu_reference_',
    textRequiredReference: 'collegeCareerForm_text_requiredReference',
  },
};
