import axios from 'axios';
import { showLoader, showToast } from '../store/app.slice';
import { storeAccessToken, storeToken } from '../utils/auth.util';
import {
  API_BASE_URL,
  APP_ENV,
  AUTHORIZATION_TOKEN_FOR_PROCTORU,
  BC_API_URL,
  BC_URL,
  PROCTOR_U_BASE_URL,
} from '../constants';
import { Auth } from 'aws-amplify';
import { SnackbarAnchorSeverity } from '../themes/properties';

let store: any;

export const injectStore = (_store: any) => (store = _store);

let axiosInstance = axios.create({
  headers: {
    'content-type': 'application/json',
  },
  baseURL: API_BASE_URL,
});

export const axiosProctorInstance = axios.create({
  headers: {
    'content-type': 'application/json',
    'Authorization-Token': AUTHORIZATION_TOKEN_FOR_PROCTORU,
  },
  baseURL: PROCTOR_U_BASE_URL,
});

export const axiosBCInstance = axios.create({
  headers: {
    'content-type': 'application/json',
  },
  baseURL: BC_API_URL,
});

let pendingRequest = 0;

// request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    const currentUser = await Auth.currentAuthenticatedUser();
    const idToken = currentUser.signInUserSession.idToken.jwtToken;
    const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
    storeToken(idToken);
    storeAccessToken(accessToken);
    config.headers['Authorization'] = accessToken;
    if (pendingRequest === 0) {
      store.dispatch(showLoader(true));
    }
    pendingRequest++;
    return config;
  },
  (error) => Promise.reject(error),
);

// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    pendingRequest--;
    if (pendingRequest === 0) {
      store.dispatch(showLoader(false));
    }
    if (response) {
      //perform the manipulation here and change the response object
    }
    return response;
  },
  (error) => {
    pendingRequest--;
    if (pendingRequest === 0) {
      store.dispatch(showLoader(false));
    }
    if (error.response.status === 401) {
      store.dispatch(
        showToast({
          show: true,
          message: 'Your session is expired. Please login in again.',
          severity: SnackbarAnchorSeverity.error,
        }),
      );
      if (APP_ENV !== 'development') {
        window.location.href =
          BC_URL + `/login.php?returnUrl=${window.location.href}`;
      } else window.location.href = 'http://localhost:3000';
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
