import { Box } from '@mui/material';
import { useState } from 'react';
import { ModalType } from '../../../assets/help-center-data/types';
import Styles from './styles';
import TutorailDetailModal from '../../../common-components/tutorial-detail-modal/TutorailDetailModal.component';
import TutorialsStepCard from '../../../common-components/tutorials-step-card/TutorialsStepCard.component';

interface PropsType {
  data: ModalType;
}

const Modal = ({ data }: PropsType) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <Box sx={Styles.boxModalCard}>
      <TutorailDetailModal
        title={data.title}
        modalData={data.modalData}
        show={show}
        onClose={() => setShow(false)}
      />
      <TutorialsStepCard data={data} onStartTutorial={() => setShow(true)} />
    </Box>
  );
};

export default Modal;
