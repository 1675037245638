import { themeColors } from '../../themes/colors';
import theme, { spacing } from '../../themes/theme';

const Styles = {
  root: {
    width: `${spacing * 120}px`,
    borderRadius: `${spacing}px`,
    '@media (max-width: 768px)': { height: 'auto' },
  },
  dialog: {
    zIndex: 10, // Making sure loading overlay has higher z-index
  },
  categoryTitleText: {
    fontWeight: 700,
    mb: `${spacing}px`,
    fontFamily: `Atkinson Hyperlegible`,
    '@media (max-width: 768px)': { fontSize: `${spacing * 5}px` },
  },
  mailingAddressTitleContainer: {
    display: 'flex',
    mb: `${spacing}px`,
    alignItems: 'center',
    gap: `${spacing * 3}px`,
  },
  title: {
    fontFamily: theme.typography,
    fontSize: `${spacing * 2}px`,
    fontWeight: 600,
    letterSpacing: `${spacing * 0.2}px`,
    paddingLeft: `${spacing * 5}px`,
  },

  content: {
    overflowY: 'hidden',
    fontSize: `${spacing}px`,
    padding: `${spacing}px ${spacing * 3}px`,
    '@media (max-width: 768px)': { height: 'auto' },
    '@media (max-width: 480px)': { overflowY: 'scroll', },
  },
  contentWelcome: {
    padding: `${spacing * 3}px ${spacing * 4}px`,
  },
  dialogActions: {
    padding: `${spacing * 3}px`,
    overflowX: 'hidden',
    '@media (max-width: 768px)': { justifyContent: 'center' },
  },
  button: {
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 700,
    borderRadius: '50px',
    height: `${spacing * 5}px`,
    letterSpacing: `${spacing * 0.1}px`,
    '@media (max-width: 768px)': { marginRight: 0, width: '100%' },
  },
  buttonNext: {
    backgroundImage: `${themeColors.greenGradient}`,
    '&:hover': {
      backgroundImage: `${themeColors.greenGradientOpposite}`,
    },
  },
  icon: {
    paddingRight: `${spacing * 2}px`,
    fontSize: `${spacing * 2.25}px`,
  },
  paragraph: {
    paddingTop: `${spacing * 2.5}px`,
  },
};

export default Styles;
