import React, { ReactNode, useEffect } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { onboardingDialogConstants } from '../../constants';
import { VariantButton, VariantTypography } from '../../themes/properties';
import { onBoardingConstants } from '../../constants';
import { Box } from '@mui/system';
import Profile from "../../models/Profile.model";
import CollegeCareerForm from "../college-career-form/CollegeCareerForm.component";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { resetProfile, resetProfileUpdateStatus, updateProfileInfo } from "../../module/profileHeader/redux/profileSlice";
import AboutYouForm from "../about-you-form/AboutYouForm.component";
import { getInstitutes } from "../../store/appAsync.actions";
import Styles from './OnboardingDialog.style';
import { updateProfile as updateUserProfile } from "../../module/profileHeader/redux/profileAsync.actions";

interface PropsType {
  goBack: () => void;
  completeStep: () => void;
  stepper: ReactNode;
}

const OnboardingDialogAboutYou = ({ goBack, completeStep, stepper }: PropsType) => {
  const { profileMutable, profileUpdateStatus } = useSelector(
    (state: RootState) => state.profile,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getInstitutes());
  }, []);

  // function to update the user-profile data in redux-store
  const updateProfile = (updatedProfileValue: Profile) => {
    dispatch(updateProfileInfo(updatedProfileValue));
  };

  const handleClickBack = () => {
    dispatch(resetProfile());
    goBack();
  };

  useEffect(() => {
    if (
      profileUpdateStatus === onBoardingConstants.profileUpdationState.success
    ) {
      dispatch(resetProfileUpdateStatus());
      completeStep();
    }
  }, [profileUpdateStatus]);

  const handleClickNext = () => {
    dispatch(updateUserProfile());
  };

  const isValidForm = () => {
    if (!profileMutable) {
      return false;
    }
    if (
      (!profileMutable.targetCollege && !profileMutable.isCollegeUndecided) ||
      !profileMutable.courseName ||
      !profileMutable.highestEducation ||
      !profileMutable.referredBy
    ) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <DialogContent sx={Styles.content} dividers>
        {stepper}

        <Box mt={1}>
          <Typography sx={Styles.categoryTitleText} variant={VariantTypography.h5}>
            {onBoardingConstants.aboutYourself.header}
          </Typography>

          <CollegeCareerForm
            updateProfile={updateProfile}
            profile={profileMutable}
          />
          <AboutYouForm
            updateProfile={updateProfile}
            profile={profileMutable}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={Styles.dialogActions}>
        <Button
          autoFocus
          onClick={handleClickBack}
          variant={VariantButton.outlined}
          sx={Styles.button}
        >
          {onboardingDialogConstants.buttonBack}
        </Button>

        <Button
          autoFocus
          onClick={handleClickNext}
          disabled={!isValidForm()}
          variant={VariantButton.contained}
          sx={{...Styles.button, ...Styles.buttonNext}}
        >
          {onboardingDialogConstants.buttonNext}
        </Button>
      </DialogActions>
    </div>
  );
};

export default OnboardingDialogAboutYou;