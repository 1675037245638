import { createSlice } from '@reduxjs/toolkit';
import { SLICE_AUTH } from '../../../store/slices';
import { getToken, parseToken } from '../../../utils/auth.util';

const token = getToken();
const jwt: any = token ? parseToken(token) : null;
export interface State {
  token: any;
}

const initialState: State = {
  token: jwt,
};

export const authSlice = createSlice({
  name: SLICE_AUTH,
  initialState,
  reducers: {},
});

export default authSlice.reducer;
