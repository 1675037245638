import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Styles from './Footer.style';
import {
  FooterCenterPanel,
  FooterRightPanel,
  FooterLeftPanelList,
  Urls,
  screenBreakpoints,
  dataTestIds,
} from '../../constants';
import { Avatar, Box, Link, useMediaQuery } from '@mui/material';
import logo from '../../assets/images/logo.svg';
import childCareLogo from '../../assets/images/childCareLogo.svg';
import { socialMediaIcons } from '../../themes/icons';
import { VariantTypography } from '../../themes/properties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { openPage } from '../../utils/utils';

const Footer = () => {
  const [isChildCareHovered, setIsChildCareHovered] = useState(false);
  const screenLaptop = useMediaQuery(`(max-width:${screenBreakpoints.laptop})`);
  const screenIPad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);
  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);

  const SlLogo = (
    <>
      <Box
        sx={Styles.logoContainer}
        data-testid={dataTestIds.footer.appLogoDiv}
      >
        <Avatar
          src={logo}
          sx={Styles.logo}
          alt={dataTestIds.straighterLineLogo}
          data-testid={dataTestIds.footer.appLogoImage}
        />
      </Box>
      <Box sx={Styles.copyright} data-testid={dataTestIds.footer.copyRightDiv}>
        <FontAwesomeIcon
          icon={faCopyright}
          data-testid={dataTestIds.footer.copyRightIcon}
        />
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.textYear}
          data-testid={dataTestIds.footer.copyRightText}
        >
          {FooterCenterPanel.COPYRIGHT_YEAR}
        </Typography>
      </Box>
    </>
  );

  const InfoLinksLeft = FooterLeftPanelList.map((item, index) => (
    <Link
      key={index}
      sx={Styles.leftPanelOptions}
      underline="none"
      href={item.href}
      data-testid={item.dataTestId}
    >
      {item.label}
    </Link>
  ));

  const InfoLinksRight = FooterRightPanel.map((item, index) => (
    <Link
      key={index}
      sx={Styles.leftPanelOptions}
      underline="none"
      href={item.href}
      data-testid={item.dataTestId}
    >
      {item.label}
    </Link>
  ));

  const logoChildCare = (
    <Link href={Urls.CCEI}>
      <img
        onMouseOver={() => setIsChildCareHovered(true)}
        onMouseOut={() => setIsChildCareHovered(false)}
        src={childCareLogo}
        style={{
          filter: isChildCareHovered ? 'none' : 'brightness(0)',
        }}
        data-testid={dataTestIds.footer.childcareImage}
      />
    </Link>
  );

  const socialMediaIconsView = socialMediaIcons.map((icon, index) => (
    <Box key={index}>
      <Box onClick={() => openPage(icon.href, true)}>
        <Box sx={Styles.socialMediaIcons}>
          <FontAwesomeIcon
            icon={icon.label as IconProp}
            data-testid={icon.dataTestId}
          />
        </Box>
      </Box>
    </Box>
  ));

  if (screenPhone) {
    return (
      <Box sx={Styles.root}>
        <Box sx={Styles.boxSlLogo}>{SlLogo}</Box>
        <Box sx={{ display: 'block' }}>
          <Box sx={Styles.boxInfoLinks}>{InfoLinksLeft}</Box>
          <Box sx={Styles.boxInfoLinks}>{InfoLinksRight}</Box>
        </Box>
        <Box sx={Styles.boxChildCare}>{logoChildCare}</Box>
        <Box sx={Styles.boxSocialMedia}>{socialMediaIconsView}</Box>
      </Box>
    );
  }

  if (screenIPad) {
    return (
      <Box sx={Styles.root}>
        <Box sx={Styles.boxSlLogo}>{SlLogo}</Box>
        <Box sx={Styles.boxInfoLinks}>
          {InfoLinksLeft}
          {InfoLinksRight}
        </Box>
        <Box sx={Styles.boxChildCare}>{logoChildCare}</Box>
        <Box sx={Styles.boxSocialMedia}>{socialMediaIconsView}</Box>
      </Box>
    );
  }

  if (screenLaptop) {
    return (
      <Box sx={Styles.root}>
        <Box sx={Styles.boxSlLogo}>{SlLogo}</Box>
        <Box sx={Styles.boxInfoLinks}>
          {InfoLinksLeft}
          {InfoLinksRight}
        </Box>
        <Box sx={Styles.socialMediaIconsAndChidCare}>
          <Box sx={Styles.boxChildCare}>{logoChildCare}</Box>
          <Box sx={Styles.boxSocialMedia}>{socialMediaIconsView}</Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={Styles.root} data-testid={dataTestIds.footer.divContainer}>
      <Grid container sx={Styles.footerContainer}>
        <Grid
          item
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={Styles.leftPanel}>{InfoLinksLeft}</Box>
          <Box sx={Styles.leftPanelLogo}>{logoChildCare}</Box>
        </Grid>
        <Grid item md={4}>
          {SlLogo}
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={Styles.contactSupport}>
            {FooterRightPanel.map((item, index) => (
              <Link
                key={index}
                sx={Styles.leftPanelOptions}
                underline="none"
                href={item.href}
                data-testid={item.dataTestId}
              >
                {item.label}
              </Link>
            ))}
          </Box>
          <Box sx={Styles.socialMediaIconsContainer}>
            {socialMediaIconsView}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
