import axiosInstance from '../../../http-requests/httpClient';
import {
  APIs, acrobatiqAccessURL,
} from '../../../constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_DASHBOARD } from '../../../store/slices';
import {
  ErrorResponse,
  ProvidersJWTTokenParams,
  AcrobatiqAccessParams,
} from '../../../types/contract';
import { showToast } from '../../../store/app.slice';
import { SnackbarAnchorSeverity } from '../../../themes/properties';
import { acrobatiqAccessFormHtml } from '../../../utils/acrobatiqAccess.util';

export const acrobatiqCourseAccess = (
  providerData: AcrobatiqAccessParams,
) => {
  console.log("acrobatiqCourseAccess", JSON.stringify(providerData));
  providerData.URL = acrobatiqAccessURL;
  const formHtml = acrobatiqAccessFormHtml(providerData);
  const popupWindow = window.open('', '_self');
  popupWindow?.document.write(formHtml);
};

export const getProvidersJWTToken = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.getProvidersJWTToken}`,
  async (
    jwtTokenParams: any,
    thunkAPI,
  ) => {
    try {
      const jwtTokenResponse = await axiosInstance.post(
        APIs.getProvidersJWTToken,
        {
          "type": jwtTokenParams.type,
          "name": jwtTokenParams.name,
          "model": {
            "nonce": jwtTokenParams.model.nonce,
            "ltiMessageHint": jwtTokenParams.model.ltiMessageHint,
            "learnerId": jwtTokenParams.model.Id,
            "email": jwtTokenParams.model.Email,
            "givenName": jwtTokenParams.model.GivenName,
            "familyName": jwtTokenParams.model.FamilyName,
            "courseId": jwtTokenParams.model.CourseId,
            "source": jwtTokenParams.model.source,
            "state": jwtTokenParams.model.state,
            "scope": jwtTokenParams.model.scope,
          }
        },
      );
      console.log("response", JSON.stringify(jwtTokenResponse));
      await acrobatiqCourseAccess({ state: jwtTokenResponse.data.state, token: jwtTokenResponse.data.id_token, URL: null });
    } catch (e: any) {
      console.log(e);
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);