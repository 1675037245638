import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';
const Styles = {
  root: {
    position: 'sticky',
    top: 0,
    height: `calc(140vh)`,
    px: 2,
    mt: `${spacing * 2}px`,
    width: `${spacing * 36}px`,
    overflowY: 'auto',
    '@media (max-width: 1200px)': {
      height: `90vh`,
      mb: `${spacing * 3}px`,
    },
    '@media (max-width: 768px)': {
      width: `${spacing * 60}px`,
    },
    '@media (max-width: 480px)': {
      width: `${spacing * 60}px`,
    },
    '&::-webkit-scrollbar': {
      width: 0,
    },
    mb: `${spacing * 30}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${spacing * 3}px`,
  },
  txtMembershipName: {
    width: '90%',
    '@media (max-width: 768px)': {
      width: '60%',
    },
    fontSize: 14,
    color: themeColors.grey,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
  partnerLogo: {
    mt: 3,
    ml: 1,
    width: '225px',
    height: 'auto',
    objectFit: 'contain',
  },
  textMySchool: {
    fontSize: `${spacing * 1.5}px`,
    color: themeColors.tertiaryBlack,
    fontWeight: 700,
  },
  textCollege: {
    fontSize: `${spacing * 1.75}px`,
    color: themeColors.tertiaryBlack,
    fontWeight: 600,
    mt: `${spacing * 1.5}px`,
    ml: `${spacing * 3}px`,
  },
  boxCloseIcon: {
    position: 'fixed',
    right: `${spacing * 3}px`,
    top: `${spacing * 10}px`,
  },
};

export default Styles;
