import React from 'react';
import Styles from './styles';
import { supportRequests } from '../../constants';
const SupportAndBillingForm = () => {
  return (
    <div style={Styles.root}>
      <form action={supportRequests.action} method="POST" style={Styles.form}>
        <input type="hidden" name="orgid" value={supportRequests.orgId} />
        <input
          type="hidden"
          name="retURL"
          value={supportRequests.redirectUrl}
        />
        {/* <!--  ----------------------------------------------------------------------  -->
<!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
<!--  these lines if you wish to test in debug mode.                          -->
<!--  <input type="hidden" name="debug" value=1>                              -->
<!--  <input type="hidden" name="debugEmail"                                  -->
<!--  value="straighterline@fastslowmotion.com">                              -->
<!--  ----------------------------------------------------------------------  --> */}
        <label htmlFor="name" style={Styles.label}>
          First Name
        </label>
        <input
          id="name"
          maxLength={80}
          name="name"
          size={20}
          type="text"
          style={Styles.input}
        />
        <br />
        <label htmlFor={supportRequests.lastNameId} style={Styles.label}>
          Last Name
        </label>
        <input
          id={supportRequests.lastNameId}
          maxLength={80}
          name={supportRequests.lastNameId}
          size={20}
          type="text"
          style={Styles.input}
        />
        <br />
        <label htmlFor="email" style={Styles.label}>
          Email
        </label>
        <input
          id="email"
          maxLength={80}
          name="email"
          size={20}
          type="text"
          style={Styles.input}
        />
        <br />
        <label htmlFor="reason" style={Styles.label}>
          Case Reason
        </label>
        <select id="reason" name="reason" style={Styles.select}>
          <option value="">--None--</option>
          <option value="Account Updates">Account Updates</option>
          <option value="Assignment Resubmissions">
            Assignment Resubmissions
          </option>
          <option value="Billing &amp; Membership Services">
            Billing &amp; Membership Services
          </option>
          <option value="Course Related">Course Related</option>
          <option value="Credit Transfer">Credit Transfer</option>
          <option value="Exam Related">Exam Related</option>
          <option value="General Inquiries">General Inquiries</option>
          <option value="New Prospect Inquiry">New Prospect Inquiry</option>
          <option value="New Student Inquiry">New Student Inquiry</option>
          <option value="Problem">Problem</option>
          <option value="Proctoring">Proctoring</option>
          <option value="Question">Question</option>
          <option value="Technical Difficulty">Technical Difficulty</option>
          <option value="Tutoring">Tutoring</option>
        </select>
        <br />
        <label htmlFor="description" style={Styles.label}>
          Description
        </label>
        <textarea name="description" style={Styles.textArea}></textarea>
        <br />
        <input
          type="hidden"
          name="recordType"
          id="recordType"
          value={supportRequests.recordType}
        ></input>
        <input type="submit" name="submit" style={Styles.button} />
      </form>
    </div>
  );
};
export default SupportAndBillingForm;
