import { themeColors } from '../../themes/colors';

const Styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 1,
  },
  boxCourseStatus: { height: 100 },
  courseStatus: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 700,
    mb: 0.5,
    ml: 0.25,
  },
  textExpired: {
    fontWeight: 'bold',
    fontSize: 11,
    color: themeColors.warning,
    mt: 0.5,
    ml: 0.25,
  },
  boxUpNext: { mt: 2, pl: 0.25, display: 'none' },
  upNext: {
    textTransform: 'uppercase',
    color: themeColors.blueLight,
    fontSize: 11,
    fontWeight: 700,
  },
  textNext: {
    ml: 1.75,
    mt: 0.5,
    fontSize: 14,
    fontWeight: 700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& a': {
      textDecoration: 'none',
      color: themeColors.blackLight,
    },
  },
  boxGrade: {
    height: 100,
    width: 80,
    bgcolor: 'rgba(26, 37, 65, 0.05)',
    p: 1,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: 1,
  },
  textGrade: {
    width: 64,
    fontSize: 12,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 700,
    lineHeight: 1.25,
  },
  boxGradeValue: {
    width: '95%',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(26, 37, 65, 0.1)',
    borderRadius: 1,
    py: 0.75,
  },
  textGradeValue: { fontSize: 15, fontWeight: 700 },
};

export default Styles;
