import Institute from './Institute.model';
import { Address } from './Profile.model';

export default class Transcript {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public dob: string,
    public emailDestination: string,
    public college: Institute | null,
    public mailTranscript: boolean,
    public emailTranscript: boolean,
    public emailUnoffcialTranscript: boolean,
    public address: Address,
    public mailAddress: Address | null,
    public isFeeFeatureEnabled: boolean,
  ) {}
}
