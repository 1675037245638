import { Urls, dataTestIds } from '../constants';
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

export const socialMediaIcons = [
  {
    label: faFacebook,
    href: `${Urls.FACEBOOK}`,
    dataTestId: `${dataTestIds.footer.facebookIcon}`,
  },
  {
    label: faInstagram,
    href: `${Urls.INSTAGRAM}`,
    dataTestId: `${dataTestIds.footer.instagramIcon}`,
  },
  {
    label: faTwitter,
    href: `${Urls.TWITTER}`,
    dataTestId: `${dataTestIds.footer.twitterIcon}`,
  },
  {
    label: faYoutube,
    href: `${Urls.YOUTUBE}`,
    dataTestId: `${dataTestIds.footer.youtubeIcon}`,
  },
  {
    label: faLinkedinIn,
    href: `${Urls.LINKEDIN}`,
    dataTestId: `${dataTestIds.footer.linkedInIcon}`,
  },
];
