import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Course from '../../models/Course.model';
import Styles from './CourseCardProgress.style';
import { Constants, CourseCardConstants, dataTestIds } from '../../constants';
import {
  VariantLinearProgress,
  VariantTypography,
} from '../../themes/properties';
import BorderLinearProgress from '../borderline-progress/BorderLinearProgress';
import { CourseStatus } from '../../types/global';
import { dateFormats, formatData, isDateExpired } from '../../utils/dateUtils';

interface Props {
  course: Course;
}

const DEFAULT_GRADE: string = '####';
const MAX_PROGRESS: number = 100;
const PERCENT_100 = '100%';
const PERCENT_70 = '70%';

const CourseCardProgress = ({ course }: Props) => {
  const isCourseNotStarted = useMemo(
    () =>
      course.status.toLocaleLowerCase() ===
      CourseStatus.NotStarted.toLocaleLowerCase(),
    [course.status],
  );

  const expiredText = useMemo(() => {
    const courseExpirationDate = course.expirationDate ?? '';
    if (isDateExpired(courseExpirationDate) && courseExpirationDate) {
      return CourseCardConstants.expiredText.replace(
        '%s',
        formatData(courseExpirationDate, dateFormats.MMDDYYYY_SLASH),
      );
    }
    return '';
  }, [course.expirationDate]);

  return (
    <Box
      sx={Styles.container}
      data-testid={dataTestIds.courseCard.containerStatus}
    >
      <Box
        sx={{
          ...Styles.boxCourseStatus,
          width: isCourseNotStarted ? PERCENT_100 : PERCENT_70,
        }}
        data-testid={dataTestIds.courseCard.divStatus}
      >
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.courseStatus}
          data-testid={dataTestIds.courseCard.textStatusValue}
        >
          {course.progress === MAX_PROGRESS
            ? Constants.COURSE_COMPLETE
            : Constants.COURSE_PROGRESS}
        </Typography>
        <BorderLinearProgress
          variant={VariantLinearProgress.determinate}
          value={course.progress}
          data-testid={dataTestIds.courseCard.borderLineProgress}
        />
        {expiredText && (
          <Typography
            sx={Styles.textExpired}
            data-testid={dataTestIds.courseCard.textExpired}
          >
            {expiredText}
          </Typography>
        )}
        <Box
          sx={Styles.boxUpNext}
          data-testid={dataTestIds.courseCard.divUpNext}
        >
          <Typography
            sx={Styles.upNext}
            data-testid={dataTestIds.courseCard.labelUpNext}
          >
            {CourseCardConstants.upNext}
          </Typography>
          <Typography
            sx={Styles.textNext}
            data-testid={dataTestIds.courseCard.textUpNextValue}
          >
            <a href={course.upNext?.url}>{course.upNext?.name ?? ''}</a>
          </Typography>
        </Box>
      </Box>

      {!isCourseNotStarted && (
        <Box sx={Styles.boxGrade} data-testid={dataTestIds.courseCard.divGrade}>
          <Typography
            sx={Styles.textGrade}
            data-testid={dataTestIds.courseCard.labelGrade}
          >
            {course.grade}
          </Typography>
          <Box
            sx={Styles.boxGradeValue}
            data-testid={dataTestIds.courseCard.divGradeValue}
          >
            <Typography
              sx={Styles.textGradeValue}
              data-testid={dataTestIds.courseCard.textGradeValue}
            >
              {course.gradeValue
                ? `${Math.round(course?.gradeValue * 10) / 10}%`
                : DEFAULT_GRADE}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CourseCardProgress;
