import { themeColors } from '../../themes/colors';
import { singleGridWidth, spacing } from '../../themes/theme';

const Styles = {
  root: {
    top: `64px`,
    position: 'absolute',
    bgcolor: `${themeColors.white}`,
    zIndex: 2,
    mb: 1,
    pl: 1,
    py: 2,
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    '@media (max-width: 1200px)': {
      width: '98%',
    },
    '@media (max-width: 768px)': {
      display: 'block',
    },
  },
  courseStatusContainer: {
    backgroundColor: `${themeColors.lightGrey}`,
    borderRadius: `${spacing * 0.5}px`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: `${spacing}px`,
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'space-evenly',
      py: `${spacing * 0.75}px`,
    },
    height: `${spacing * 4.5}px`,
    '& .MuiButtonBase-root': {
      fontWeight: 700,
    },
  },
  selectedCourseStatus: {
    backgroundColor: `${themeColors.paperWhite}`,
    '&:hover': {
      backgroundColor: `${themeColors.paperWhite}`,
    },
    px: `${spacing * 2}px`,
    py: 0,
    fontSize: `${spacing * 1.75}px`,
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
      width: '33%',
      py: 0,
      fontSize: `${spacing * 2}px`,
    },
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 1.5}px`,
    },
  },
  courseFilter: {
    px: `${spacing * 2}px`,
    py: 0,
    fontSize: `${spacing * 1.75}px`,
    textTransform: 'capitalize',
    color: themeColors.grey,
    '@media (max-width: 768px)': {
      flex: 1,
      py: 0,
      fontSize: `${spacing * 2}px`,
    },
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 1.5}px`,
    },
  },
  rightPanelContainer: {
    textAlign: 'end',
    paddingRight: `${spacing * 2.5}px`,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      justifyContent: 'space-between',
    },
    '@media (max-width: 480px)': {
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
  sendTranscriptBtn: {
    color: `${themeColors.darkBlue}`,
    borderRadius: `${spacing * 6.25}px`,
    padding: `${spacing * 1.25}px`,
    '&:hover': {
      bgcolor: 'rgba(235, 239, 247, 0.6)',
    },
  },
  sendTranscripts: {
    display: 'inline',
    paddingLeft: `${spacing * 1.25}px`,
    fontSize: `${spacing * 1.5}px`,
    textTransform: 'uppercase',
    fontWeight: 900,
  },
  addCourse: {
    display: 'inline',
    paddingLeft: `${spacing * 1.25}px`,
    fontSize: `${spacing * 1.5}px`,
    textTransform: 'uppercase',
    fontWeight: 900,
  },
};

export default Styles;
