export interface TableHeader {
  columns: Array<string>;
}
export interface TableRowType {
  data: Array<string>;
}

export interface ModalDataType {
  image: string;
  description: string;
  note?: string;
}

export interface ModalType {
  type: string;
  title: string;
  bannerImage: string;
  modalData: Array<ModalDataType>;
}

export interface PointType {
  type: string;
  title: string;
  points: Array<any>;
  note: string;
  listType: ListType;
}

export interface ImageType {
  type: string;
  title: string;
  image: string;
}

export interface TableType {
  type: string;
  note: string;
  title: string;
  tableHeader: TableHeader;
  tableData: Array<TableRowType>;
}

export interface SectionType {
  title: string;
  sections: Array<any>;
  header?: string;
}

export interface ContentType {
  header: string;
  subHeader: string;
  sections: Array<SectionType>;
}

export enum ListType {
  Ordered = 'Ordered',
  Unordered = 'Unordered',
  UpperAlpha = 'UpperAlpha',
  LowerAlpha = 'LowerAlpha',
}

export const interfaceType = {
  TABLE_TYPE: 'TABLE_TYPE',
  POINT_TYPE: 'POINT_TYPE',
  SUB_LIST_TYPE: 'SUB_LIST_TYPE',
  IMAGE_TYPE: 'IMAGE_TYPE',
  MODAL_TYPE: 'MODAL_TYPE',
};
