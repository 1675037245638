import { useMemo } from 'react';
import { Modal, Box, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import Styles from './Styles';
import Footer from './Footer.component';
import Header from './Header.component';
import { TutorialStepModelType } from '../../types/global';
import { useState } from 'react';
import { emptyString } from '../../constants';
import Grid from '@mui/material/Grid';
import { VariantTypography } from '../../themes/properties';

interface PropsType {
  title?: string;
  modalData?: Array<TutorialStepModelType>;
  show: boolean;
  onClose: () => void;
}

const TutorailDetailModal = ({
  title,
  modalData,
  show,
  onClose,
}: PropsType) => {
  const theme = useTheme();
  const [step, setStep] = useState(0);
  const maxSteps = useMemo(() => modalData?.length ?? 0, [modalData]);

  const handleClose = () => {
    setStep(0);
    onClose();
  };

  return (
    <Modal open={show} sx={Styles.modal} onClose={handleClose}>
      <Box sx={Styles.root}>
        <Header title={title ?? emptyString} onClose={handleClose} />

        <Box>
          <SwipeableViews
            index={step}
            onChangeIndex={(step: number) => setStep(step)}
            enableMouseEvents
          >
            {modalData?.map((data: TutorialStepModelType, index: number) => (
              <Box key={data.image}>
                {Math.abs(0 - index) <= maxSteps - 1 ? (
                  <Grid container padding={2} rowSpacing={1} alignItems="flex-start">
                    <Grid item sm={6} xs={12} sx={Styles.imageContainer}>
                      <img
                        style={Styles.imageBox}
                        src={data.image}
                        alt={data.description}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{ px: 2 }}>
                      <Typography
                        variant={VariantTypography.h5}
                        sx={Styles.textStep}
                      >
                        Step {step + 1} of {maxSteps}
                      </Typography>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                      {data.note && (
                        <>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: data.note,
                            }}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            ))}
          </SwipeableViews>
        </Box>

        <Divider />
        <Footer
          totalSteps={maxSteps}
          currentStep={step}
          backHanlder={() => setStep(step - 1)}
          nextHanlder={() => setStep(step + 1)}
        />
      </Box>
    </Modal>
  );
};

export default TutorailDetailModal;
