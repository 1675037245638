import theme, { spacing } from '../../../themes/theme';
import { themeColors } from '../../../themes/colors';

const Styles = {
  root: { width: '100%', position: 'relative', marginLeft: `${spacing * 2}px` },
  progressBar: {
    width: '100%',
    height: `${spacing * 2}px`,
    '&.MuiLinearProgress-colorPrimary': {
      bgcolor: themeColors.blackLight,
    },
  },
  progressAfterImage: {
    height: `${spacing * 2}px`,
    bgcolor: themeColors.lime,
    width: `${spacing * 1}px`,
    position: 'absolute',
    top: 0,
    left: `${spacing * 6 + spacing * 2.5}px`,
  },
  avatar: {
    position: 'absolute',
    height: `${spacing * 6}px`,
    width: `${spacing * 6}px`,
    top: `-${spacing * 2}px`,
    left: `${spacing * 1.25 + spacing * 1.5}px`,
  },
  textFinal: {
    color: themeColors.lime,
    position: 'absolute',
    right: `${spacing * 2.25}px`,
    top: 0,
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: 2,
    opacity: 0.5,
  },
  boxFinal: {
    borderRadius: '50%',
    bgcolor: themeColors.lightGrey,
    height: `${spacing * 5}px`,
    width: `${spacing * 5}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: `-${spacing * 1.5}px`,
    right: `-${spacing * 2.5}px`,
  },
  boxFinalInner: {
    borderRadius: '50%',
    bgcolor: themeColors.lime,
    height: `${spacing * 2.25}px`,
    width: `${spacing * 2.25}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default Styles;
