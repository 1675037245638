import { ContentType, ListType, interfaceType } from '../types';
export const AssessmentPoliciesAndGuidlines: ContentType = {
  header: 'Assessment Policies and Guidelines',
  subHeader: '',
  sections: [
    {
      title: '<strong>Grading Policy*.</strong>',
      sections: [
        `As a general rule, assessments vary by course, but typically include a mixture of exams, quizzes, activities, and writing assessments. It's also common for courses to include a series of ungraded, formative assignments. While some assessments have only one attempt, others may have more than one. In cases where a student resubmits work already graded, where applicable, the highest grade will always be recorded. We return all graded assignments in 3-5 business days from the date of submission.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: '',
          points: [
            `Students have <strong>multiple attempts</strong> to complete their <strong>first graded quantitative quiz</strong> or exam, unless otherwise noted in the course or on the course syllabus. The highest grade will be applied to the overall course grade. See “First Assignment Retake Policy.`,
            `Students are permitted to take their <strong>final exam once</strong>, unless otherwise noted in the course or on the course syllabus.`,
            `Students have <strong>2 opportunities to submit written assignments</strong> (e.g. essays, lab worksheets, videos, oral presentations, etc.), unless otherwise noted in the course or on the course syllabus.`,
            `Students have <strong>3 attempts to complete quantitative quizzes or exams</strong> , unless otherwise noted in the course or on the course syllabus (Exceptions: There is 1 attempt on a final exam and multiple attempts on the first quantitative assessment within each course). The highest grade will be applied to the overall course grade.`,
            `On a case-by-case basis, and as determined by our Faculty, staff and/or administration, if/when a technical error has impeded the successful submission and/or completion of an assignment, the assignment in question may be reattempted and resubmitted.`,
            `<strong>All required course assignments</strong> must be submitted and graded for a course to be considered complete. See “Course Completion Policy.”`,
            `Failure to comply with the Academic Integrity Policy may result in a failure and/or zero on the attempted assignment/examination.  See “Academic Integrity Policy.”`,
          ],
          note: `*At StraighterLine, we recognize that an equitable and consistent grading policy is important to our students and to our partners. We also recognize that conducting regular reviews of our grading policies is important to ensure that grades accurately reflect students’ knowledge and skills.   If changes in our Grading Policy occur, students will be notified of that change in the course itself, and be given ample time to prepare, as needed, to either finish a course(s), prior to the impending policy change, or migrate into the new section where the revised policy has been adopted. StraighterLine reserves the right to make amendments to the Grading Policy as needed. `,
        },
      ],
    },
    {
      title: 'Course Completion Policy',
      sections: [
        `In order for a course to be considered complete, <strong>all required coursework must be attempted, submitted, and graded</strong>. Required coursework consists of graded assignments. Any Academic Integrity Policy violations may prevent a course from being considered complete.`,
      ],
    },
    {
      title: 'Course Retake Policy',
      sections: [
        `A student may retake any course. If, upon completion of a course, a student has not achieved a satisfactory score (69.45% and above, as per the American Council on Education–ACE CREDIT), a student may retake a course by repurchasing and reattempting it in full. Students are required to wait a minimum of 3 business days between attempts. The highest final score will be recorded as the final course grade.`,
      ],
    },
    {
      title: 'First Assessment Retake Policy',
      sections: [
        `Unless otherwise indicated in the course (e.g. Lab or writing assignments), students may take advantage of multiple attempts on their first quantitative assessment (e.g. quiz or exam) in an attempt to earn a higher grade. As a general rule, qualitative assessments, such as essays, lab work, and oral presentations have two attempts–even as a designated “first” assignment.  In most cases, first assignments are considered assessments that align directly with course outcomes.`,
      ],
    },
    {
      title: 'Grading Scale',
      sections: [
        `StraighterLine provides a letter and number grade for each course. Colleges have their own policies with respect to how they accept and post grades for accepting transfer credit. Students should check with the college they plan to attend to determine its specific grade-posting policies. Transcripts will reflect the percentage score and corresponding grade* as set forth below:`,
        {
          type: interfaceType.TABLE_TYPE,
          title: ``,
          note: `*A grade of at least 69.45% is required for ACE CREDIT recommendation or credit recognition at most StraighterLine partner colleges. ACE transcripts reflect P/F (Pass/Fail) only.`,
          tableHeader: {
            columns: [`SCORE`, `GRADE`],
          },
          tableData: [
            {
              data: [`90-100`, `A`],
            },
            {
              data: [`80-89`, `B`],
            },
            {
              data: [`70-79`, `C`],
            },
            {
              data: [`60-69`, `D`],
            },
            {
              data: [`Below 60`, `F`],
            },
          ],
        },
      ],
    },
    {
      title: 'Examination Policy',
      sections: [
        `Examinations are an integral part of many courses; these assessments are designed to accurately assess student knowledge and competence; thus, we uphold the highest of academic honesty standards throughout all testing processes. Violations of academic integrity during an examination include, but are not limited to, plagiarism, cheating, fabrication and academic misconduct.  Failure to comply with the Academic Integrity Policy may result in a failure and/or zero on the attempted examination, a removal from the course, disqualification to enroll in future courses, and/or revocation of an academic transcript.</br></br>`,
        `Many of our courses have traditional, or quantitative, summative and formative means of assessment. These assessments include: quizzes (in all forms), midterm examinations and final examinations.  Materials that are permitted for each testing scenario differ by course; however, and as a general rule, course quizzes and midterm examinations permit some materials, while final exams may impose a stricter use of materials wherein the textbook and all course reading materials and notes may be limited and/or restricted. Students should carefully read and review the course specific examination requirements prior to taking an examination.</br></br>`,
        `All final exams are administered using some form of Identity Verification. This includes, but is not limited to, proctoring services, out-of-band processes (calling/texting a number and verifying student information), embedded question verification (answering questions based on publicly available information about the student), biometric verification, passive activity monitoring, analysis of voluntarily-provided electronic device data, and account comparison against established publicly available networks (such as social networks) to our authentication system. When a student submits answers on a graded exam, they are confirming that the work is their own, and that the exam has been taken in accordance with the Student Code of Conduct and the Academic Integrity Policy herein.</br></br>`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'Other examination policies include, but are not limited to, the following:',
          points: [
            `Students are permitted to take a final exam once.`,
            `Unless otherwise noted, upon completion of a non-proctored exam, a student may review examination questions and answers.`,
            `Unless otherwise noted, a student may not redo or resubmit a final examination once the exam is submitted.`,
            `If technical issues arise, a student may be permitted to retake the exam.`,
            `Students are asked to report all questions that may appear to have an error. After a careful review, the student may receive credit for the question. In these cases, there is no negative impact on the student’s exam grade.`,
            `Unless otherwise noted, assessments will open in the same window (e.g. there are no pop-ups).`,
            `Some graded assessments are timed. Time limits for exams and graded assessments vary by course. Students must complete the timed exam within the reserved block of time.`,
            `Exams may contain a portion that must be graded by the Faculty or by hand. This additional component will be graded within 3-5 business days. Once this grading is complete, a final grade for the exam/assessment will be posted.`,
          ],
        },
      ],
    },
    {
      title: 'Assignment Resubmission Policy',
      sections: [
        `Assignment resubmission availability is determined by course, and ultimately, by assignment. Please review the specific assignment’s guidelines to determine if you are able to resubmit work.`,
      ],
    },
    {
      title: 'Attendance and Due-Date Policy',
      sections: [
        `StraighterLine’s courses are all self-directed and self-paced. There is no attendance required. Students are in control of their journey through StraighterLine’s learning experiences.  There are no assignment due dates; thus, there is no “late” work.`,
      ],
    },
  ],
};
