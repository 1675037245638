import { Snackbar, Alert, Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import {
  AlertVariant,
  ButtonSize,
  Origin,
  SnackbarAnchorPositon,
  SnackbarAnchorSeverity,
} from '../../themes/properties';
import { showToast as showToastAction } from '../../store/app.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimes,
  faWarning,
} from '@fortawesome/pro-solid-svg-icons';
import Styles from './Snackbar.style';
import { themeColors } from '../../themes/colors';

const AUTO_HIDE_DURATION = 5000;

const AppSnackbar = () => {
  const { toastMessage } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch<AppDispatch>();

  const onCloseAlert = () => dispatch(showToastAction({ show: false }));

  let toastBgColor;
  let toastIcon;

  switch (toastMessage.severity) {
    case SnackbarAnchorSeverity.success:
      toastBgColor = themeColors.secondary;
      toastIcon = faCheckCircle;
      break;
    case SnackbarAnchorSeverity.error:
      toastBgColor = themeColors.yellow;
      toastIcon = faWarning;
      break;
  }

  return (
    <Snackbar
      open={toastMessage.showToast}
      anchorOrigin={{ vertical: Origin.top, horizontal: Origin.right }}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={onCloseAlert}
      sx={{ mt: 6 }}
    >
      <Alert
        variant={AlertVariant.filled}
        onClose={onCloseAlert}
        sx={Styles.alertRoot}
        action={
          <IconButton
            size={ButtonSize.small}
            sx={Styles.closeIcon}
            onClick={onCloseAlert}
            disableRipple
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        }
        icon={
          <Box
            sx={{
              ...Styles.icon,
              backgroundColor: toastBgColor,
            }}
          >
            {toastIcon && <FontAwesomeIcon icon={toastIcon} />}
          </Box>
        }
      >
        {toastMessage.message}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
