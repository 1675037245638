import { Box, Typography, List, ListItem } from '@mui/material';
import {
  dataTestIds,
  getTranscriptInstructions,
  requestTranscipt,
} from '../../constants';
import Styles from './styles';

interface Props {
  isTranscriptFeeEnabled: boolean;
}

const TranscriptInstructions = ({ isTranscriptFeeEnabled }: Props) => {
  const instructionPoints: Array<string> = getTranscriptInstructions(
    isTranscriptFeeEnabled,
  );

  return (
    <Box
      sx={Styles.boxList}
      data-testid={dataTestIds.transcriptInstructions.divContainer}
    >
      <Typography data-testid={dataTestIds.transcriptInstructions.textHeader}>
        {requestTranscipt.transcrriptPoints.header}
      </Typography>
      <List sx={Styles.list}>
        {instructionPoints.map((text: string, index: number) => (
          <ListItem sx={Styles.listItem} key={text}>
            <Typography
              dangerouslySetInnerHTML={{ __html: text }}
              data-testid={`${dataTestIds.transcriptInstructions.textPoint}${index}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TranscriptInstructions;
