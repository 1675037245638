import { spacing } from '../../themes/theme';
import { themeColors } from '../../themes/colors';

export default {
  root: {
    border: `1px solid ${themeColors.offWhite}`,
    borderRadius: `${spacing * 1}px`,
    width: '100%',
    marginTop: '32px',
    '&:hover': {
      boxShadow: `1px 1px 3px 2px ${themeColors.midGrey}`,
    },
  },
  boxHeader: {
    display: 'flex',
    alignItems: 'center',
    px: `${spacing * 2}px`,
    py: `${spacing * 1.5}px`,
  },
  iconShoeBox: {
    p: `${spacing * 2}px`,
    mr: `${spacing * 2}px`,
    borderRadius: '50%',
    bgcolor: themeColors.secondary,
    '&:hover': {
      bgcolor: themeColors.secondary,
    },
  },
  iconShoe: {
    color: themeColors.white,
  },
  img: {
    margin: 'auto',
    display: 'block',
    height: '200px',
  },
  boxFooter: {
    p: `${spacing * 2}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  txtFooterDes: {
    color: themeColors.blueLight,
    mt: `${spacing * 1.5}px`,
  },
  btnStartTutorial: {
    fontSize: `${spacing * 1.5}px`,
    width: 'fit-content',
    alignSelf: 'flex-end',
    bgcolor: themeColors.secondary,
    color: themeColors.white,
    borderRadius: `${spacing * 10}px`,
    px: `${spacing * 2}px`,
    mt: `${spacing * 8}px`,
    letterSpacing: '1px',
    '&:hover': {
      bgcolor: themeColors.darkGreen,
    },
  },
  modalTitle: {
    fontSize: `${spacing * 2}px`,
  },
};
