import { spacing } from '../../themes/theme';
import { themeColors, hexToRGB } from '../../themes/colors';
export default {
  boxAvatar: {},
  profileAvatar: {
    height: spacing * 6,
    width: spacing * 6,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover  + .fa-solid': {
      display: 'block',
    },
    '& .MuiAvatar-img': {
      objectFit: 'cover',
    },
  },
  profileEditAvatar: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-28%)',
    borderRadius: '50%',
    p: `${spacing * 1}px`,
    cursor: 'pointer',
    color: 'transparent',
    '&:hover': {
      color: themeColors.white,
      border: `1px solid ${themeColors.white}`,
      backgroundColor: hexToRGB(themeColors.black, 0.5),
    },
  },
};
