import {
  TextField,
  InputAdornment,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Styles from './ChangePassword.style';
import {
  AccountSettingsConstants,
  dataTestIds,
  EMPTY_STRING,
  passwordValidations,
  required,
} from '../../../constants';
import { changePassword } from '../../../store/appAsync.actions';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../store/app.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faLock,
  faEyeSlash,
  faCheckCircle,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  SnackbarAnchorSeverity,
  VariantTypography,
} from '../../../themes/properties';
import { themeColors } from '../../../themes/colors';

const ChangePasswordForm = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isLoading = useSelector((state: RootState) => state.app.showLoader);

  const [changePasswordBtn, setChangePasswordBtn] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState(EMPTY_STRING);
  const [newPassword, setNewPassword] = useState(EMPTY_STRING);
  const [confirmPassword, setConfirmPassword] = useState(EMPTY_STRING);

  useEffect(() => {
    if (changePasswordBtn && !isLoading) {
      setChangePasswordBtn(false);
    }
  }, [isLoading]);

  const handleChangePasswordReset = () => {
    setChangePasswordBtn(true);
    setOldPassword(EMPTY_STRING);
    setNewPassword(EMPTY_STRING);
    setConfirmPassword(EMPTY_STRING);
  };

  const handleChangePassword = () => {
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    if (
      oldPassword !== EMPTY_STRING &&
      newPassword !== EMPTY_STRING &&
      newPassword === confirmPassword
    ) {
      dispatch(
        changePassword({
          oldPassword: oldPassword,
          newPassword: newPassword,
        }),
      );
    }
  };

  const handleCancelChangePassword = () => {
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setChangePasswordBtn(false);
  };

  const isValidMinChar = () =>
    newPassword.length >= passwordValidations.rules.minChar.value;
  const isValidLowerCase = () => newPassword.toUpperCase() !== newPassword;
  const isValidUpperCase = () => newPassword.toLowerCase() !== newPassword;
  const isValidNumber = () =>
    passwordValidations.rules.number.value.test(newPassword);

  const isValidSpecialChar = () =>
    passwordValidations.rules.specialChar.value.test(newPassword);

  const isFormValid = () =>
    isValidMinChar() &&
    isValidLowerCase() &&
    isValidUpperCase() &&
    isValidNumber() &&
    isValidSpecialChar() &&
    newPassword === confirmPassword &&
    !!oldPassword;

  const validIconView = (
    <FontAwesomeIcon
      icon={faCheckCircle}
      color={themeColors.secondary}
      data-testid={dataTestIds.changePasswordForm.iconValid}
    />
  );
  const invalidIconView = (
    <FontAwesomeIcon
      icon={faXmark}
      color={themeColors.warningDark}
      fontSize="20"
      data-testid={dataTestIds.changePasswordForm.iconInvalid}
    />
  );

  const rulesView = (
    <Box>
      <Box sx={Styles.flexBox}>
        {isValidMinChar() ? validIconView : invalidIconView}

        <Typography
          sx={Styles.validationPoints}
          data-testid={dataTestIds.changePasswordForm.textValidMinChar}
        >
          {passwordValidations.rules.minChar.title}
        </Typography>
      </Box>
      <Box sx={Styles.flexBox}>
        {isValidLowerCase() ? validIconView : invalidIconView}
        <Typography
          sx={Styles.validationPoints}
          data-testid={dataTestIds.changePasswordForm.textValidLowerCase}
        >
          {passwordValidations.rules.lowerCase.title}
        </Typography>
      </Box>
      <Box sx={Styles.flexBox}>
        {isValidUpperCase() ? validIconView : invalidIconView}
        <Typography
          sx={Styles.validationPoints}
          data-testid={dataTestIds.changePasswordForm.textValidUpperCase}
        >
          {passwordValidations.rules.upperCase.title}
        </Typography>
      </Box>
      <Box sx={Styles.flexBox}>
        {isValidNumber() ? validIconView : invalidIconView}
        <Typography
          sx={Styles.validationPoints}
          data-testid={dataTestIds.changePasswordForm.textValidNumber}
        >
          {passwordValidations.rules.number.title}
        </Typography>
      </Box>
      <Box sx={Styles.flexBox}>
        {isValidSpecialChar() ? validIconView : invalidIconView}
        <Typography
          sx={Styles.validationPoints}
          data-testid={dataTestIds.changePasswordForm.textValidSpecialChar}
        >
          {passwordValidations.rules.specialChar.title}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {!changePasswordBtn ? (
        <>
          <Box
            sx={Styles.passwordContainer}
            data-testid={dataTestIds.changePasswordForm.divContainer}
          >
            <TextField
              type={AccountSettingsConstants.passwordTypes.password}
              label={AccountSettingsConstants.passwordLabels.current}
              value={AccountSettingsConstants.demoPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faLock} />
                  </InputAdornment>
                ),
              }}
              sx={Styles.parentTextfield}
              data-testid={dataTestIds.changePasswordForm.inputCurrentPwd}
            />
            <Button
              onClick={() => handleChangePasswordReset()}
              sx={Styles.changeBtn}
              data-testid={dataTestIds.changePasswordForm.btnChangePwd}
            >
              {AccountSettingsConstants.changePassword}
            </Button>
          </Box>
        </>
      ) : (
        <Box
          pl="24px"
          data-testid={dataTestIds.changePasswordForm.divContainer}
        >
          <Box>
            <TextField
              autoComplete="new-password"
              label={AccountSettingsConstants.passwordLabels.old}
              type={
                showOldPassword
                  ? AccountSettingsConstants.passwordTypes.text
                  : AccountSettingsConstants.passwordTypes.password
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)} // toggle state on click
                      edge="end"
                      sx={Styles.eyeIcon}
                    >
                      <FontAwesomeIcon
                        icon={showOldPassword ? faEyeSlash : faEye}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={Styles.textfield}
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              data-testid={dataTestIds.changePasswordForm.inputOldPwd}
            />
            {!oldPassword && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.required}
                data-testid={dataTestIds.changePasswordForm.textRequiredOldPwd}
              >
                {required}
              </Typography>
            )}
          </Box>

          <Box>
            <TextField
              label={AccountSettingsConstants.passwordLabels.new}
              type={
                showNewPassword
                  ? AccountSettingsConstants.passwordTypes.text
                  : AccountSettingsConstants.passwordTypes.password
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)} // toggle state on click
                      edge="end"
                      sx={Styles.eyeIcon}
                    >
                      <FontAwesomeIcon
                        icon={showNewPassword ? faEyeSlash : faEye}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={Styles.textfield}
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              data-testid={dataTestIds.changePasswordForm.inputNewPwd}
            />
            {!newPassword && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.required}
                data-testid={dataTestIds.changePasswordForm.textRequiredNewPwd}
              >
                {required}
              </Typography>
            )}
          </Box>

          <Box>
            <TextField
              label={AccountSettingsConstants.passwordLabels.confirm}
              type={
                showConfirmPassword
                  ? AccountSettingsConstants.passwordTypes.text
                  : AccountSettingsConstants.passwordTypes.password
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      } // toggle state on click
                      edge="end"
                      sx={Styles.eyeIcon}
                    >
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={Styles.textfield}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              data-testid={dataTestIds.changePasswordForm.inputConfirmPwd}
            />
            {!confirmPassword && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.required}
                data-testid={
                  dataTestIds.changePasswordForm.textRequiredConfirmPwd
                }
              >
                {required}
              </Typography>
            )}
            {confirmPassword && confirmPassword !== newPassword && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.required}
                data-testid={dataTestIds.changePasswordForm.textRequiredMatch}
              >
                {AccountSettingsConstants.passwordMessage.mustMatch}
              </Typography>
            )}
          </Box>

          <Typography
            sx={Styles.validationTitle}
            data-testid={dataTestIds.changePasswordForm.textHeaderValidation}
          >
            {passwordValidations.title}
          </Typography>
          {rulesView}
          <Box sx={Styles.boxBtnNext}>
            <Box sx={Styles.updateBtnContainer}>
              <Button
                sx={{
                  ...Styles.btnNext,
                  backgroundColor: !isFormValid()
                    ? themeColors.white
                    : themeColors.primary,
                }}
                onClick={() => handleChangePassword()}
                disabled={!isFormValid()}
                data-testid={dataTestIds.changePasswordForm.btnChangePwd}
              >
                {AccountSettingsConstants.changePassword}
              </Button>
            </Box>
            <Box>
              <Button
                sx={Styles.btnCancel}
                onClick={() => handleCancelChangePassword()}
                data-testid={dataTestIds.changePasswordForm.btnCancel}
              >
                {AccountSettingsConstants.cancel}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default ChangePasswordForm;
