import { themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

export default {
  boxContent: {
    mt: `${spacing * 4}px`,
    px: `${spacing * 4}px`,
    overflow: 'auto',
  },
  rootBottom: {
    mt: `${spacing * 10}px`,
    bgcolor: themeColors.white,
  },
  boxHeader: {
    backgroundColor: themeColors.primary,
    color: themeColors.white,
    display: 'flex',
    alignItems: 'baseline',
    px: `${spacing * 4}px`,
    py: `${spacing * 2}px`,
    borderTopLeftRadius: `${spacing * 1}px`,
    borderTopRightRadius: `${spacing * 1}px`,
    '@media (max-height: 800px)': {
      height: `${spacing * 6}px`,
    },
  },
  boxHeaderText: { ml: `${spacing * 3}px`, flexGrow: 1 },
  textHeader: {
    color: themeColors.blackLight,
    fontSize: `${spacing * 7}px`,
    fontWeight: '700',
    lineHeight: '110%',
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 4.5}px`,
      fontWeight: '900',
    },
  },
  iconClose: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  boxContainerBottomView: {
    height: '100%',
    ml: `${spacing * 4}px`,
  },
  button: {
    bgcolor: themeColors.white,
    color: themeColors.black,
    border: `1px solid ${themeColors.black}`,
    borderRadius: '50px',
    mr: `${spacing * 1.25}px`,

    fontSize: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': {
      width: '100%',
      mb: `${spacing * 1.5}px`,
    },
    '&.MuiButtonBase-root': {
      fontWeight: 900,
    },
    '&:hover': {
      bgcolor: themeColors.white,
      color: themeColors.black,
    },
  },
  buttonAnotherTrancript: {
    backgroundImage: `${themeColors.greenGradient}`,
    border: 'none',
    color: themeColors.white,
    '&:hover': {
      bgcolor: themeColors.black,
    },
  },
  textContentHeader: {
    fontSize: `${spacing * 2.75}px`,
    fontWeight: 900,
  },
  textContentSubHeader: {
    fontSize: `${spacing * 2.75}px`,
    fontWeight: 400,
    mt: `${spacing * 3}px`,
  },
  textContentMessage: {
    fontSize: `${spacing * 2}px`,
    fontWeight: 400,
    mt: `${spacing * 3}px`,
    '& a': {
      color: themeColors.darkBlue,
    },
  },
};
