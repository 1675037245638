import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';
export default {
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      height: `${spacing * 2.5}px`,
      padding: `${spacing * 1.25}px ${spacing * 2}px`,
      borderRadius: `${spacing * 0.5}px`,
    },
    '& .Mui-disabled': {
      '& fieldset': {
        border: 'none',
      },
      bgcolor: themeColors.lightBlue,
    },
    '& .MuiInputLabel-shrink': {
      fontWeight: 700,
    },
  },
  inputLabel: {
    color: themeColors.primary,
    fontSize: `${spacing * 1.75}px`,
    fontWeight: 700,
    px: `${spacing * 1.5}px`,
  },
  datePicker: {
    width: '100%',
    '& .MuiFormHelperText-root ': {
      mx: `${spacing * 1}px`,
      fontSize: `${spacing * 1.75}px`,
    },
    '& .MuiInputLabel-shrink': {
      fontWeight: 700,
    },
    '& .MuiInputLabel-root.Mui-error ': {
      color: themeColors.warningDark,
      fontWeight: 400,
    },
  },
  calenderIcon: {
    color: themeColors.blackLight,
    fontSize: `${spacing * 2.5}px`,
  },
  required: {
    fontWeight: 400,
    color: themeColors.warningDark,
    ml: `${spacing * 0.5}px`,
  },
};
