import { Grid, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router';
import Footer from './footer/Footer.component';
import LeftHelpCenterPanel from './LeftHelpCenterPanel.component';
import Styles from './Styles';
import { screenBreakpoints } from '../../constants';

const HelpCenterNavigation = () => {
  const screenLaptopLarge = useMediaQuery(
    `(max-width:${screenBreakpoints.laptopLarge})`,
  );
  let leftHelpCenterPanel = null;
  let mainHelpCenterPanel = null;
  if (!screenLaptopLarge) {
    leftHelpCenterPanel = (
      <Grid item md={3} sx={Styles.leftContainer}>
        <LeftHelpCenterPanel />
      </Grid>
    );
    mainHelpCenterPanel =(
      <Grid item md={9} sx={Styles.contentContainer}>
          <Outlet />
          <Footer />
        </Grid>
    );
  } else {
    mainHelpCenterPanel =(
      <Grid item md={12} sx={Styles.contentContainer}>
          <LeftHelpCenterPanel />
          <Outlet />
          <Footer />
        </Grid>
    );
  }
  return (
    <>
      <Grid container sx={Styles.root}>
        {!screenLaptopLarge && leftHelpCenterPanel}
        {mainHelpCenterPanel}
      </Grid>
    </>
  );
};

export default HelpCenterNavigation;
