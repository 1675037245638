import { Button, MobileStepper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { dashboardRightPanel, dataTestIds } from '../../../constants';
import { RecommendedCourses } from '../../../types/contract';
import {
  Position,
  VariantButton,
  VariantTypography,
} from '../../../themes/properties';
import Styles from './Styles';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { spacing } from '../../../themes/theme';

const CourseRecommendation = () => {
  const [step, setStep] = useState(0);
  const recommendedCourses: RecommendedCourses = useSelector(
    (state: RootState) => state.dashboard.recommendedCourses,
  ) as RecommendedCourses;
  const backHandler = () => {
    setStep(step - 1);
  };

  const nextHandler = () => {
    setStep(step + 1);
  };

  const recommendationHeader = (data: string) => {
    return (
      <>
        <Box>
          <Typography
            variant={VariantTypography.body2}
            sx={Styles.textHeader}
            data-testid={dataTestIds.rightPanel.recommendedCourses.header}
          >
            {dashboardRightPanel.courseRecommendation.header}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={VariantTypography.body2}
            sx={Styles.courseName}
            data-testid={dataTestIds.rightPanel.recommendedCourses.courseName}
          >
            {data}
          </Typography>
        </Box>
      </>
    );
  };

  const recommendationBody = (data: string) => {
    return (
      <Box>
        <Typography
          sx={Styles.courseDescription}
          data-testid={
            dataTestIds.rightPanel.recommendedCourses.courseDescription
          }
        >
          {data}
        </Typography>
      </Box>
    );
  };

  const learnButton = (data: string) => {
    return (
      <Box sx={Styles.dashboardButtonContainer}>
        <Button
          href={data}
          variant={VariantButton.contained}
          sx={Styles.dashboardButton}
          data-testid={dataTestIds.rightPanel.recommendedCourses.learnButton}
        >
          {dashboardRightPanel.courseRecommendation.button}
        </Button>
      </Box>
    );
  };

  const slideButtons = (
    <MobileStepper
      steps={dashboardRightPanel.courseRecommendation.maxSteps}
      position={Position.static}
      activeStep={step}
      sx={Styles.mobileStepper}
      nextButton={
        <Button
          sx={Styles.footerBtn}
          onClick={nextHandler}
          disabled={
            step === dashboardRightPanel.courseRecommendation.maxSteps - 1
          }
          data-testid={dataTestIds.rightPanel.recommendedCourses.nextButton}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </Button>
      }
      backButton={
        <Button
          sx={Styles.footerBtn}
          onClick={backHandler}
          disabled={step === 0}
          data-testid={dataTestIds.rightPanel.recommendedCourses.backButton}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </Button>
      }
    />
  );

  return (
    <>
      <SwipeableViews
        index={step}
        onChangeIndex={
          step === dashboardRightPanel.courseRecommendation.maxSteps - 1
            ? backHandler
            : nextHandler
        }
        enableMouseEvents
        containerStyle={{
          display: 'flex',
          flexDirection: 'row',
          marginRight: `-${spacing * 3}px`,
        }}
        slideStyle={Styles.slideStyle}
      >
        {recommendedCourses?.data?.map((data) => (
          <Box key={data.courseId} sx={Styles.root}>
            {recommendationHeader(data.courseName)}
            {slideButtons}
            {recommendationBody(data.shortDescription)}
            {learnButton(data.url)}
          </Box>
        ))}
      </SwipeableViews>
    </>
  );
};

export default CourseRecommendation;
