import React from 'react';
import Styles from './BillingPreferences.style';
import { billingPreferences } from '../../../constants';
import SupportAndBillingForm from '../../../common-components/support-request-billing-preference-form/SupportAndBillingForm.component';

const BillinPreference = () => {
  return (
    <div style={Styles.container}>
      <h1 style={Styles.textHeader}>{billingPreferences.header}</h1>
      <h2 style={Styles.textSubHeader}>{billingPreferences.subHeader}</h2>
      <p
        style={Styles.pageDescription}
        dangerouslySetInnerHTML={{ __html: billingPreferences.pageDescription }}
      ></p>
      <SupportAndBillingForm />
    </div>
  );
};
export default BillinPreference;
