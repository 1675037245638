import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { VariantTypography } from '../../../../themes/properties';
import Styles from './Styles';
import TutorialsStepCard from '../../../../common-components/tutorials-step-card/TutorialsStepCard.component';
import TutorailDetailModal from '../../../../common-components/tutorial-detail-modal/TutorailDetailModal.component';
import { orientationAndTutorials } from '../../../../assets/help-center-data/orientation-tutorial.data';
import { TutorialStepCardType } from '../../../../types/global';

const OrientationAndTutorials = () => {
  const [tutorial, setTutorial] = useState<TutorialStepCardType | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);

  const proctoringTutorials = orientationAndTutorials.proctoring.map(
    (item: TutorialStepCardType) => (
      <Grid item md={6} xs={12} key={item.title}>
        <TutorialsStepCard
          key={item.title}
          data={item}
          onStartTutorial={() => setTutorial(item)}
        />
      </Grid>
    ),
  );

  const billingTutorials = orientationAndTutorials.billing.map(
    (item: TutorialStepCardType) => (
      <Grid item md={6} xs={12} key={item.title}>
        <TutorialsStepCard
          key={item.title}
          data={item}
          onStartTutorial={() => setTutorial(item)}
        />
      </Grid>
    ),
  );

  const writtenAssignmentsTutorials =
    orientationAndTutorials.writtenAssignments.map(
      (item: TutorialStepCardType) => (
        <Grid item md={6} xs={12} key={item.title}>
          <TutorialsStepCard
            key={item.title}
            data={item}
            onStartTutorial={() => setTutorial(item)}
          />
        </Grid>
      ),
    );

  return (
    <Box sx={Styles.root}>
      <TutorailDetailModal
        title={tutorial?.title}
        modalData={tutorial?.modalData}
        show={!!tutorial}
        onClose={() => setTutorial(null)}
      />
      <Typography variant={VariantTypography.h2} sx={Styles.mainHeading}>
        {orientationAndTutorials.header}
      </Typography>
      <Typography variant={VariantTypography.body1} sx={Styles.subHeading}>
        {orientationAndTutorials.subHeader}
      </Typography>

      <Box sx={Styles.boxTutorial}>
        <Typography
          variant={VariantTypography.h5}
          sx={Styles.tutorialListHeader}
        >
          {orientationAndTutorials.constants.proctoringTutorials}
        </Typography>
        <Grid container spacing={3} rowSpacing={1}>
          {proctoringTutorials}
        </Grid>
      </Box>
      <Box sx={Styles.boxTutorial}>
        <Typography
          variant={VariantTypography.h5}
          sx={Styles.tutorialListHeader}
        >
          {orientationAndTutorials.constants.billingTutorials}
        </Typography>
        <Grid container spacing={5} rowSpacing={5}>
          {billingTutorials}
        </Grid>
      </Box>
      <Box sx={Styles.boxTutorial}>
        <Typography
          variant={VariantTypography.h5}
          sx={Styles.tutorialListHeader}
        >
          {orientationAndTutorials.constants.writtenAssignmentsTutorials}
        </Typography>
        <Grid container spacing={5} rowSpacing={5}>
          {writtenAssignmentsTutorials}
        </Grid>
      </Box>
      <Divider sx={Styles.divider} />
    </Box>
  );
};

export default OrientationAndTutorials;
