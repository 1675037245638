import { hexToRGB, themeColors } from '../../themes/colors';
import theme, { spacing } from '../../themes/theme';

const Styles = {
  root: {
    maxWidth: 640,
    minWidth: 640,
    '@media (max-width: 700px)': {
      maxWidth: '95%',
      minWidth: '95%',
    },
    '@media (max-width: 480px)': {
      height: '85vh',
    },
    borderRadius: `${spacing}px`,
    '@media (max-width: 768px)': { height: 'auto' },
  },
  dialog: {
    zIndex: 10, // Making sure loading overlay has higher z-index
  },
  title: {
    fontFamily: theme.typography,
    fontSize: `${spacing * 2}px`,
    fontWeight: 600,
    letterSpacing: `${spacing * 0.2}px`,
  },
  titleText: {
    textTransform: 'uppercase',
    flexGrow: 1
  },
  subtitle: {
    padding: `${spacing * 2}px ${spacing * 4}px`,
    fontSize: `${spacing * 2}px`,
    background: '#EBEFF799'
  },
  iconClose: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  content: {
    overflowY: 'hidden',
    fontSize: `${spacing}px`,
    padding: 0,
    '@media (max-width: 768px)': { height: 'auto' },
    '@media (max-width: 480px)': { overflowY: 'scroll', },
  },
  dialogActions: {
    padding: `${spacing * 3}px`,
    overflowX: 'hidden',
    '@media (max-width: 768px)': { justifyContent: 'center' },
  },
  button: {
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 700,
    borderRadius: '50px',
    height: `${spacing * 5}px`,
    letterSpacing: `${spacing * 0.1}px`,
    '@media (max-width: 768px)': { marginRight: 0, width: '100%' },
    backgroundImage: `${themeColors.greenGradient}`,
    '&:hover': {
      backgroundImage: `${themeColors.greenGradientOpposite}`,
    },
    '&:disabled': {
      border: `1px solid ${hexToRGB(themeColors.blueLight, 0.5)}`,
      color: hexToRGB(themeColors.blueLight, 0.5),
      background: themeColors.white,
    },
  },
  courseImage: {
    height: spacing * 6,
    width: spacing * 6,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover  + .fa-solid': {
      display: 'block',
    },
    '& .MuiAvatar-img': {
      objectFit: 'cover',
    },
  },
  boxCheckbox: {
    padding: `${spacing * 2}px ${spacing * 4}px`,
    maxHeight: '50vh',
    overflowY: 'auto',

    display: 'flex',
    flexDirection: 'column',

    '& .MuiFormControlLabel-root': {
      mr: 0,
    },
  },
  checkboxController: {
    display: 'flex',
    gap: `${spacing}px`,
    padding: `${spacing}px`,

    '&:hover': {
      bgcolor: themeColors.whiteNavbar,
    },

    '& .Mui-checked:not(.Mui-disabled)': {
      '& div': {
        bgcolor: themeColors.secondary,
      },
    },

    '& .Mui-checked.Mui-disabled': {
      '& div': {
        bgcolor: themeColors.lightBlue,
      },
    }
  },
  checkboxLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: `${spacing}px`,
  },
  checkboxIcon: {
    borderRadius: 1,
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundColor: themeColors.midGrey,
  },
  checkedboxIcon: {
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    backgroundColor: themeColors.darkBlue,
  },
  iconCheckBox: {
    borderRadius: 1,
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundColor: themeColors.midGrey,
  },
};

export default Styles;
