import React, { ReactNode, useEffect } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
} from '@mui/material';
import { onboardingDialogConstants, US_COUNTRY_ID } from '../../constants';
import { VariantButton, VariantTypography } from '../../themes/properties';
import { onBoardingConstants } from '../../constants';
import { Box } from '@mui/system';
import Profile, { Address } from "../../models/Profile.model";
import { updateProfile as updateUserProfile } from "../../module/profileHeader/redux/profileAsync.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import ProfileInfoForm from "../profile-info-form/ProfileInfoForm.component";
import MailingAddressForm from "../mailing-address-form/MailingAddressForm.component";
import { resetProfileUpdateStatus, updateProfileInfo } from "../../module/profileHeader/redux/profileSlice";
import Styles from './OnboardingDialog.style';
import { dateFormats, isDateValid, isUnderAged } from "../../utils/dateUtils";

interface PropsType {
  completeStep: () => void;
  stepper: ReactNode;
}

const OnboardingDialogPersonalInformation = ({ completeStep, stepper }: PropsType) => {
  const { profileMutable, profileUpdateStatus } = useSelector(
    (state: RootState) => state.profile,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (
      profileUpdateStatus === onBoardingConstants.profileUpdationState.success
    ) {
      dispatch(resetProfileUpdateStatus());
      completeStep();
    }
  }, [profileUpdateStatus]);

  const handleClickNext = () => {
    if(profileMutable) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profileMutable, onBoardingStatus: onBoardingConstants.status.personalInfoCompleted }),
      ) as Profile;
      updateProfile(updatedProfile);
    }
    dispatch(updateUserProfile());
  };

  const updateProfile = (updatedProfile: Profile) => {
    dispatch(updateProfileInfo(updatedProfile));
  };

  // function to update the user-profile-address data in redux-store
  const onUpdateAddress = (updatedAddress: Address) => {
    if (profileMutable) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profileMutable, address: updatedAddress }),
      ) as Profile;
      updateProfile(updatedProfile);
    }
  };

  const isValidForm = () => {
    if (!profileMutable || !profileMutable.address) {
      return false;
    }
    if (
      !profileMutable.dob ||
      !isDateValid(profileMutable.dob, dateFormats.YYYYMMDD_HYPHEN) ||
      isUnderAged(profileMutable.dob) ||
      !profileMutable.phone
    ) {
      return false;
    }
    const address = profileMutable.address;
    if (
      !address.address ||
      !address.city ||
      !address.state ||
      !address.zip ||
      (address.country === US_COUNTRY_ID &&
        address.zip.length !==
        onBoardingConstants.personalInfo.mailingAddress.maxLengths.zip) ||
      (address.country !== US_COUNTRY_ID &&
        (address.zip.length <
          onBoardingConstants.personalInfo.mailingAddress.minLengths.zipNonUS ||
          address.zip.length >
          onBoardingConstants.personalInfo.mailingAddress.maxLengths
            .zipNonUS))
    ) {
      return false;
    }

    if (
      profileMutable.phone.length !== onBoardingConstants.personalInfo.maxLengths.phone
    ) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <DialogContent sx={Styles.content} dividers>
        {stepper}

        <Box mt={1}>
          <Typography sx={Styles.categoryTitleText} variant={VariantTypography.h5}>
            {onBoardingConstants.personalInfo.header}
          </Typography>
          <ProfileInfoForm profile={profileMutable} updateProfile={updateProfile} />
        </Box>

        <Box mt={3}>
          <Box sx={Styles.mailingAddressTitleContainer}>
            <Typography sx={Styles.categoryTitleText} variant={VariantTypography.h5}>
              {onBoardingConstants.personalInfo.mailingAddress.header}
            </Typography>
          </Box>

          <MailingAddressForm
            address={profileMutable?.address ?? null}
            onUpdateAddress={onUpdateAddress}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={Styles.dialogActions}>
        <Button
          autoFocus
          onClick={handleClickNext}
          disabled={!isValidForm()}
          variant={VariantButton.contained}
          sx={{...Styles.button, ...Styles.buttonNext}}
        >
          {onboardingDialogConstants.buttonNext}
        </Button>
      </DialogActions>
    </div>
  );
};

export default OnboardingDialogPersonalInformation;