import { Box, Typography } from '@mui/material';
import { ImageType } from '../../../assets/help-center-data/types';
import { VariantTypography } from '../../../themes/properties';
import Styles from './styles';

interface PropsType {
  data: ImageType;
}

const Image = ({ data }: PropsType) => (
  <Box sx={Styles.boxImage}>
    {data.title && (
      <Typography
        dangerouslySetInnerHTML={{ __html: data.title }}
        sx={Styles.textTableTitle}
        variant={VariantTypography.body1}
      />
    )}

    <img src={data.image} style={Styles.image} />
  </Box>
);

export default Image;
