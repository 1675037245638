import { spacing } from '../../themes/theme';

const Styles = {
  contentText: {
    fontSize: `${spacing * 1.5}px`,
    mb: `${spacing * 2}px`,
  },
  actionText: {
    fontSize: `${spacing * 1.5}px`,
    mb: `${spacing * 2}px`,
  },
};

export default Styles;
