import State from './State.model';

export default class Country {
  constructor(
    public id: string,
    public name: string,
    public code: string,
    public isActive: boolean,
    public states: Array<State>,
  ) {}
}
