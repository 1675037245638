import {
  AWS_REGION,
  AWS_USER_POOLID,
  AWS_USER_POOL_WEB_CLIENTID,
  APP_ENV,
} from './constants';

export const awsconfig = {
  Auth: {
    region: AWS_REGION,
    userPoolId: AWS_USER_POOLID,
    userPoolWebClientId: AWS_USER_POOL_WEB_CLIENTID,
    cookieStorage: {
      domain: APP_ENV === 'development' ? 'localhost' : '.straighterline.com',
      path: '/',
      secure: APP_ENV === 'development' ? false : true,
    },
  },
};
