import { ContentType, interfaceType } from '../types';

export const coursesData: ContentType = {
  header: 'Courses',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: '<a id="hc_courses_selfpaced"></a>Self-Paced, No Due Dates',
      sections: [
        'StraighterLine’s courses are all self-directed and self-paced. There is no attendance required. Students are in control of their journey through StraighterLine’s learning experiences. There are no assignment due dates.',
      ],
    },
    {
      title: 'Lessons and Assessments',
      sections: [
        'Each StraighterLine course follows a syllabus, which is located in our catalog. Though the topics and components of our courses vary, each topic may include a reading assignment, a lesson presentation, review activities, homework assignments, and other methods to help you learn the essential concepts. The topics represent the fundamental concepts that you will need to master in order to successfully complete the course.',
        `Graded <a href="/help-center/academics/exams/" title="Complete an assessment">assessments</a> are your key to successfully completing the course. Each assessment, with the exception of the <a href="/help-center/academics/course-retakes/#hc_retakes_firstassignment" title="First Assignment Retake Policy">first exam,</a> can only be taken one time and all graded assessments have a time limit. Once you finish and submit the assessment, submitted answers cannot be changed. You will receive a <a href="/help-center/academics/grading/" title="Assignment final score">final score</a> for the course after all of the assessments and <a href="/help-center/academics/grading/#hc_grading_required_assignments" title="Required assignments">required assignments</a> have been completed. If you choose to take the course for credit at a partner college, you can <a href="/help-center/credit-transfer/credit-transfer/#hc_ct_request_transcript" title="Transfer StraighterLine credit">request a transcript</a> and the final score will be forwarded to the partner college, who will award credit based on their academic criteria.`,
      ],
    },
  ],
};
