import { ContentType, ListType, interfaceType } from '../types';

export const PrivacyPolicy: ContentType = {
  header: 'Privacy Policy',
  subHeader: 'StraighterLine, Inc. Privacy Policy',
  sections: [
    {
      title: '',
      sections: [
        `Last modified: April 28, 2022`,
        `</br>`,
        `For California residents, please <a href="/help-center/student-handbook/privacy-policy/#california-privacy-rights" title="California Privacy Rights">click here</a> to learn more about your privacy rights.`,
        `</br>`,
        `Welcome to StraighterLine.com (together with all other digital properties and applications, communications, or forms that link or refer to this notice, the “Website”), which is operated by StraighterLine, Inc. (“StraighterLine”).  This notice describes our practices for collecting, using, maintaining, protecting, and disclosing the personal data we may collect from you or that you may provide when you visit our Website.`,
        `</br>`,
        `We respect your privacy and commit to protecting it through our compliance with the practices described in this notice. Please read this notice carefully to understand our policies and practices for processing and storing your personal data. By engaging with our Website, you accept and consent to the practices described in this notice. This notice applies to the personal data collected through our Website, regardless of the country where you are located.`,
        `</br>`,
        `This notice may change from time to time (see <a href="/help-center/student-handbook/privacy-policy/#changes-to-our-privacy-policy" title="Changes to Our Privacy Policy">Changes to Our Privacy Policy</a>). Your continued engagement with our Website after any such revisions indicates that you accept and consent to them, so please check the notice periodically for updates.`,
        `</br>`,
        `Additionally, the Website may include links to third-party websites, plug-ins, services, social networks, and applications. Clicking on those links or enabling those connections may allow the third party to collect or share data about you. We do not control these third-party websites, and we encourage you to read the privacy notice of every website you visit.`,
      ],
    },
    {
      title: 'Data We May Collect About You',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'We collect and use different types of data from and about you including (without limitation):',
          points: [
            `Personal data that we could reasonably use to directly or indirectly identify you, such as your name, postal address, email address, telephone number, photograph, educational history, age, credit and/or debit card number, bank account number, username or other similar identifier, and any other identifier we may use to contact you online or offline.`,
            `Personal data related to your use of StraighterLine’s products and services, including (without limitation) class lists for StraighterLine courses, payment information, transaction history, student identification codes, course progress, assignment grades, course grades, and transcripts.`,
            `Non-personal data that does not directly or indirectly reveal your identity or directly relate to an identified individual, such as demographic information, statistics, or aggregated information. Statistical or aggregated data does not directly identify a specific person, but we may derive non-personal statistical or aggregated data from personal data. For example, we may aggregate personal data to calculate the percentage of users completing a specific course or using a specific Website feature.`,
            `Technical and device information, including (without limitation) the Internet protocol (“IP”) address used to connect your computer or device to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, or operating system and platform. The information we collect may depend on the type of device you use and its settings.`,
            `Non-personal details about your Website interactions, including (without limitation) the full Uniform Resource Locators (“URLs”), clickstream to, through, and from our Website (including date and time), products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, interactions with StraighterLine web properties and advertisements, or any phone number used to call our customer service number.`,
          ],
        },
        `If we combine or connect non-personal, technical, or demographic data with personal data so that it directly or indirectly identifies an individual, we treat the combined information as “personal data.”`,
      ],
    },
    {
      title: 'How We Collect Data About You',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'We use different methods to collect data from and about you including (without limitation) through:',
          points: [
            `Direct interactions. You may give us information about you by filling in forms or by corresponding with us by phone, e-mail, chat or otherwise. This includes information you provide when you create an account, search for a course, enroll in a course, participate in discussion with a StraighterLine enrollment counselor or other chat functions on our Website, and when you report a problem with our Website.`,
            `Automated technologies or interactions. As you interact with our Website, we may automatically collect technical data about your equipment, browsing actions and patterns as specified above. We collect this information by using cookies, server logs, and other similar technologies (see <a href="/help-center/student-handbook/privacy-policy/#cookies" title="Cookies and Automatic Data Collection Technologies">Cookies and Automatic Data Collection Technologies</a>).`,
            `Third parties or publicly available sources. We may receive information about you from third parties including, for example, university partners and advertising networks.  `,
            `Student Submissions. If you enroll in one of our courses, you may include information about yourself as part of assignments and other submissions that you make as part of the course.`,
          ],
        },
      ],
    },
    {
      title:
        '<a id="cookies"></a>Cookies and Automatic Data Collection Technologies',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'We will collect anonymous non-personally identifiable information about your website visit through pixel tags, sometimes known as cookies. These tags help us track information about your visit and may be used to deliver banner advertisements to you on the Internet. This helps us deliver a better and more personalized service when you browse our Website. It also allows us to improve our Website by enabling us to:',
          points: [
            `Store your preferences so we may customize our Website according to your individual interests.`,
            `Recognize you when you return to our Website.`,
            `Estimate our audience size and usage patterns.`,
          ],
        },
        `You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting, certain parts of our Website may become inaccessible and certain features may not work correctly. Unless you adjust your browser settings to refuse cookies, our system will issue them.`,
      ],
    },
    {
      title: 'How We Use Your Personal Data',
      sections: [
        `We do not and will not sell your personal data.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'We use your personal data to provide you with products, offer you services, communicate with you, deliver advertising and marketing, and to conduct other business operations, such as using data to improve and personalize your experiences. Examples of how we may use the personal data we collect include to:',
          points: [
            `Fulfill the purposes for which you provided the data or that were described when it was collected.`,
            `Provide you with the information, products, services, and support that you request from us.`,
            `Meet our obligations and enforce our rights arising from any contracts with you, including (without limitation) for billing or collections, or to comply with legal requirements.`,
            `Respond to your inquiries, comments, feedback, or questions.`,
            `Monitor and maintain course enrollment information as required by our students’ target or current university and other educational partners.`,
            `Provide, support, personalize, and develop our Website, products, and services.`,
            `Notify you about changes to our Website, products, or services.`,
            `Ensure that we present our Website content in the most effective manner for you and for your computer or device.`,
            `Administer our Website and conduct internal operations, including (without limitation) for troubleshooting and debugging, data analysis, testing, research, statistical, development, and maintenance purposes.`,
            `Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, and prosecute those responsible for that activity.`,
            `Improve our Website, products or services, marketing, and experiences.`,
            `Protect our Website, employees, or operations.`,
            `Measure or understand the effectiveness of the advertising we serve to you and others, deliver relevant advertising to you, and audit current interactions with you.`,
          ],
        },
        `Depending on how you access our services, we may process your personal data on behalf of and at the direction of third parties, including a benefits provider or college or university partners with whom you are enrolled or otherwise affiliated. If we are processing your personal data at the direction of a third party, that third party’s privacy policy may govern your rights with respect to your personal data. You should review the privacy policy of your benefits provider or educational institution with whom you are enrolled or otherwise affiliated, and you may contact them with any questions about the collection, retention, and use of your personal data. `,
        `We may also aggregate personal data and use the aggregated information to analyze the effectiveness of our products and services, to improve and add features to our Website, and for other similar purposes.`,
      ],
    },
    {
      title: 'Disclosure of Your Personal Data',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: 'We may share your personal data:',
          points: [
            `To fulfill the purpose for which you provide it.`,
            `With your consent.`,
            `With university and other business partners, suppliers, service providers, sub-contractors, and other third parties we use to support our business (such as analytics and search engine providers that assist us with Website improvement and optimization). We contractually require these third parties to keep that personal data confidential and use it only for the contracted purposes.`,
            `With advertisers and advertising networks that require the data to select and serve relevant ads to you and others. `,
            `With advertisers and advertising networks to suppress ads for our services after you have visited our Website.`,
            `For any other purposes that we disclose in writing when you provide the data.`,
          ],
        },
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: 'We may also disclose your personal data to third parties:',
          points: [
            `To enforce or apply our terms of use.`,
            `To comply with any court order, law, or legal process, including (without limitation) responding to any government or regulatory request or complying with mandatory reporting requirements.`,
            `To protect the rights, property, or safety of our business, our employees, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of cybersecurity, fraud protection, and credit risk reduction.`,
            `To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, where one of the transferred assets is the personal data we hold.`,
          ],
        },
      ],
    },
    {
      title: 'Consent to Personal Data Transfer',
      sections: [
        `We are based primarily in the United States and have personnel located around the world. We may process, store, and transfer the personal data we collect, in and to a country outside your own, with different privacy laws that may or may not be as comprehensive as your own. As of the date of this notice, our processing activities take place in the United States and in the United Kingdom.`,
        `By submitting your personal data or engaging with our Website, you consent to this transfer, storing, or processing.`,
      ],
    },
    {
      title: 'Your Personal Data Use Choices',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'We strive to provide you with choices regarding certain personal data uses. We have established the following personal data control mechanisms:',
          points: [
            `Promotional Offers from StraighterLine. If you do not want us to use your contact information to promote our own products and services, you can opt-out by sending us an email with your request to <a href="mailto:unsubscribe@straighterline.com" title="unsubscribe@straighterline.com">unsubscribe@straighterline.com</a>. You may also opt-out of further marketing communications by replying to any promotional email we have sent you or following the opt-out links on that message. This opt out does not apply to information provided to StraighterLine as a result of a course registration or other transactions.`,
            `Advertising. If you do not want us to share your personal data with unaffiliated or non-agent third parties for advertising purposes, you can opt-out by sending us an email stating your request to <a href="mailto:unsubscribe@straighterline.com" title="unsubscribe@straighterline.com">unsubscribe@straighterline.com.</a> `,
            `Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this Website may become inaccessible or not function properly. For more information about tracking technologies, please see <a href="/help-center/student-handbook/privacy-policy/#cookies" title="Cookies and Automatic Data Collection Technologies">Cookies and Automatic Data Collection Technologies.</a>`,
          ],
        },
      ],
    },
    {
      title: 'Accessing and Correcting Your Personal Data',
      sections: [
        `You can access, review, and change your personal data by logging into the Website and visiting your account profile page or by contacting us at <a href="mailto:privacy@straighterline.com" title="privacy@straighterline.com">privacy@straighterline.com.</a>`,
        `You may also contact us to request access to, correct, delete,  receive a copy, or restrict our processing of personal data that you have provided to us. We may not accommodate a request to change or delete information if we believe the change or deletion would violate any law or legal requirement or negatively impact the information’s accuracy.`,
        `At any time, you may also contact us to withdraw your consent to our processing of your personal data. If you withdraw your consent, this will only affect whether and how we process your personal data after you inform us that you no longer consent to the processing.`,
      ],
    },
    {
      title: 'Our Retention of Personal Data',
      sections: [
        `StraighterLine retains personal data for as long as necessary to provide the products and services and fulfill the transactions you have requested, or for other legitimate purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different types of personal data, the context of our interactions with you or your use of products, actual retention periods can vary significantly.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'Other criteria used to determine the retention periods include:',
          points: [
            `Whether you provided the data with the expectation that it will remain until you affirmatively remove it. Examples include the email address you provided to establish an account with us or the payment information you saved in your account to purchase online courses.`,
            `Whether you provided consent for a longer retention period. If so, we will retain data in accordance with your consent.`,
            `Is the personal data of a sensitive type. If so, a shortened retention time will generally be adopted.`,
          ],
        },
      ],
    },
    {
      title: `<a id="california-privacy-rights"></a>California Privacy Rights`,
      sections: [
        `<table style="border-collapse: collapse;">
        <thead>
          <tr style="border: 1px solid black;">
            <th style="border: 1px solid black; padding: 5px;">CATEGORY</th>
            <th style="border: 1px solid black; padding: 5px;">COLLECTED</th>
            <th style="border: 1px solid black; padding: 5px;">TYPES OF INFORMATION COLLECTED</th>
          </tr>
        </thead>
        <tbody>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">A. Identifiers.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">Name, postal address, unique personal identifier, online identifier, IP address, email address, and account name.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">B. Personal information categories listed in the California Customer Records statute.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">Name, address, telephone number, education, bank account number, credit card number, and debit card number.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">C. Protected classification characteristics under California or federal law.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">Age</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">D. Commercial information.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">Records of products and services purchased from StraighterLine.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">E. Biometric information.</td>
            <td style="border: 1px solid black; padding: 5px;">NO</td>
            <td style="border: 1px solid black; padding: 5px;">We do not collect this information.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">F. Internet or other similar network activity.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">Interactions with the Website and StraighterLine advertisements.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">G. Geolocation data.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">We do not collect this information.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">H. Sensory data.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">We do not collect this information.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;"I. Professional or employment-related information.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">We do not collect this information.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">J. Non-public education information.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">Grades, transcripts, class lists for StraighterLine courses, student identification codes, student financial and payment information, and student transaction history.</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td style="border: 1px solid black; padding: 5px;">K. Inferences drawn from other personal information.</td>
            <td style="border: 1px solid black; padding: 5px;">YES</td>
            <td style="border: 1px solid black; padding: 5px;">We do not collect this information.</td>
          </tr>
          `,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'To the extent provided for by law and subject to applicable exceptions, California residents have the following privacy rights in relation to the personal data we collect:',
          points: [
            `The right to know what personal data we have collected and how we have used and disclosed that personal data;`,
            `The right to request deletion of your personal data; and`,
            `The right to be free from discrimination relating to the exercise of any of your privacy rights.`,
          ],
        },
        `California residents can exercise the above privacy rights by emailing us at: <a href="mailto:privacy@straighterline.com" title="privacy@straighterline.com">privacy@straighterline.com.</a>`,
        `In order to protect your personal data from unauthorized access or deletion, we may require you to verify your identity before we respond to a request to know or delete personal data. If you do not have an account with us, or if we suspect fraudulent or malicious activity, we may ask you to provide additional personal data for verification. If we cannot verify your identity, we will not provide or delete your personal data.`,
        `You may submit a request to know or a request to delete your personal data through an authorized agent. If you do so, the agent must present signed written permission to act on your behalf, and you may also be required to independently verify your identity.`,
      ],
    },
    {
      title: 'Data Security',
      sections: [
        `The security of your personal data is very important to us. We implement commercially reasonable technical, administrative, and organizational measures to protect personal data both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. `,
        `The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.  `,
        `Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal data, we cannot guarantee the security of your personal data transmitted to our Website. Any transmission of personal data is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures contained on the Website.`,
      ],
    },
    {
      title: 'Children’s Online Privacy',
      sections: [
        `We do not direct our Website to minors and we do not knowingly collect personal data from children under 16. If we learn we have mistakenly or unintentionally collected or received personal data from a child without appropriate consent, we will delete it. If you believe we have mistakenly or unintentionally collected any information from or about a child, please contact us at <a href="mailto:privacy@straighterline.com" title="privacy@straighterline.com">privacy@straighterline.com.</a>`,
      ],
    },
    {
      title:
        '<a id="changes-to-our-privacy-policy"></a>Changes to Our Privacy Policy',
      sections: [
        `We will post any changes we may make to our privacy policy on this page. Please check back frequently to see any updates or changes to our privacy policy. If the changes materially alter how we use or treat your personal data we will notify you by email to the primary email address specified in your account.`,
      ],
    },
    {
      title: 'Contact Information',
      sections: [
        `Please address questions, comments, and requests regarding this privacy policy and our privacy practices to <a href="mailto:privacy@straighterline.com" title="privacy@straighterline.com">privacy@straighterline.com.</a>`,
        `If you live in the European Union (“EU”), you may also contact our EU representative at: <a href="mailto:privacy+eu@straighterline.com" title="privacy+eu@straighterline.com">privacy+eu@straighterline.com.</a>  If you feel that StraighterLine has not addressed your concern in a satisfactory manner or should you wish to report a complaint, you may also contact the <a href="https://edps.europa.eu/about-edps/contact_en" title="European Data Protection Supervisor">European Data Protection Supervisor</a>.`,
        ``,
        `StraighterLine's corporate headquarters are located at:`,
        `1201 S. Sharp Street`,
        `Suite 110`,
        `Baltimore, MD 21230`,
      ],
    },
  ],
};
