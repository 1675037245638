import { Typography, Box, Button } from '@mui/material';
import { dataTestIds, requestTranscipt, Routes } from '../../../constants';
import Styles from './styles';
import { VariantButton, VariantTypography } from '../../../themes/properties';
import { useNavigate } from 'react-router-dom';

const NoTranscript = () => {
  const navigate = useNavigate();

  const viewHistoryHandler = () => {
    navigate(Routes.transcriptHistory);
  };

  const header = (
    <Box sx={Styles.boxHeaderText}>
      <Typography
        sx={Styles.textHeader}
        data-testid={dataTestIds.noTranscript.textHeader}
      >
        {requestTranscipt.headers.sendTranscript}
      </Typography>
    </Box>
  );

  const bottomView = (
    <Box sx={Styles.rootBottom}>
      <Box sx={Styles.boxContainerBottomView}>
        <Button
          variant={VariantButton.contained}
          sx={Styles.button}
          disabled
          data-testid={dataTestIds.noTranscript.btnSubmitAnotherTranscript}
        >
          {requestTranscipt.buttons.submitRequest}
        </Button>
        <Button
          variant={VariantButton.contained}
          sx={Styles.button}
          onClick={viewHistoryHandler}
          data-testid={dataTestIds.noTranscript.btnViewHistory}
        >
          {requestTranscipt.buttons.viewHistory}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <>{header}</>

      <Box
        sx={Styles.boxContent}
        data-testid={dataTestIds.noTranscript.bodyContainer}
      >
        <Typography
          variant={VariantTypography.h6}
          sx={Styles.textNoTranscript}
          data-testid={dataTestIds.noTranscript.textNoTranscript}
        >
          {requestTranscipt.noTranscript}
        </Typography>
        <Typography
          variant={VariantTypography.h6}
          sx={Styles.textEligibility}
          data-testid={dataTestIds.noTranscript.eligibilityMessage}
        >
          {requestTranscipt.eligibilityMessage}
        </Typography>
      </Box>
      <>{bottomView}</>
    </Box>
  );
};

export default NoTranscript;
