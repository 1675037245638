import { helpCenterImagesUrl } from '../../../constants';
import { ContentType, interfaceType, ListType } from '../types';

export const writtenAssignmentsData: ContentType = {
  header: 'Written Assignments',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: 'Written Assignments: What to Expect',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `All written assignments, with the exception of <a href="/help-center/academics/labs/" title="Lab Experiments">Lab Experiments,</a> are formatted consistently throughout our courses. Written assignments share the following characteristics:`,
          points: [
            `<strong>Location:</strong> Assignments are located within your course topics. Some assignments (<a href="/help-center/academics/written-assignments/#hc_written_drafts" title="Draft Assignments">like most drafts</a>) may be indicated as optional, while others are required.`,
            `<strong>Submissions:</strong> Unless otherwise noted, you have one (1) <a href="/help-center/academics/written-assignments/#hc_written_submitting" title="Submitting an Assignment">submission</a> only for written assignments.`,
            `<strong>Format of submission:</strong> Instructions for <a href="/help-center/academics/written-assignments/#hc_written_fileformats" title="Accepted File Formats">file type,</a> file naming and any specific details for the assignment are located in the assignment instructions.`,
            `<strong>Grading:</strong> Assignments are graded individually in the order in which they are received.  <a href="/help-center/academics/written-assignments/#hc_written_turnaroundtimes" title="Grading Turnaround Times">Grades</a> are posted to your assignment along with any specific  <a href="/help-center/academics/written-assignments/#hc_written_reviewfeedback" title="Reviewing Assignment Feedback">feedback</a> through <a href="/help-center/academics/written-assignments/#hc_written_turnitin" title="TurnItIn and Plagiarism Detection">TurnItIn.</a>`,
          ],
        },
      ],
    },
    {
      title: '<a id="hc_written_submitting"></a>Submitting an Assignment',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `To submit your assignment for grading, follow the steps outlined below. For more detailed instructions, you can follow this step-by-step tutorial.`,
          points: [
            `Locate the assignment in your course`,
            `Click on the Click “My Submissions” tab`,
            `Follow the instructions to upload your assignment`,
            `Complete the <a href="/help-center/academics/written-assignments/#hc_written_uploadchecklist" title="Assignment Upload Checklist">Upload Checklist</a> to ensure that all files and information are included in your assignment`,
          ],
        },
        {
          type: interfaceType.MODAL_TYPE,
          title: `Submitting an Assignment`,
          bannerImage: helpCenterImagesUrl.Course_Assignments_Submit,
          modalData: [
            {
              image: helpCenterImagesUrl.Course_Resume,
              description: `From your student dashboard, re-enter your course by selecting the "Resume Course" button or click on the course card to view a list of course topics.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_02.png`,
              description: `Navigate to and open the assignment in your course that you would like to submit.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_03.png`,
              description: `Click the assignment that you want to submit.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_04.png`,
              description: `Provide a title for your submission, upload the file, accept the terms, and click “Add Submission”`,
              note: `Once a submission is uploaded, you will not be able to change or edit the assignment. Please be sure to verify that all work is complete before upload.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_05.png`,
              description: `Your assignment has successfully been uploaded to Turn It In for grading.`,
            },
          ],
        },
      ],
    },
    {
      title: `<a class="anchor" id="hc_written_uploadchecklist"></a>Assignment Upload Checklist`,
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `Before submitting your assignment, complete the checklist below:`,
          points: [
            `Clearly state your First and Last Name on your assignment. Your name must be listed <strong>exactly</strong> the way it is documented in your <a href="/help-center/account/account-management/#hc_am_acct_info" title="StraighterLine Account Information">StraighterLine account</a>? (e.g., if your middle initial is in the system, is it on your paper?)`,
            `Confirm that you properly cited external sources. Plagiarized submissions will be flagged by the grader and you will need to resubmit a revised version to receive a grade.`,
            `Save your assignment in an <a href="/help-center/academics/written-assignments/#hc_written_fileformats" title="Accepted File Formats">acceptable file format</a>`,
            `Check to make sure you are uploading the correct assignment (this might sound like a no-brainer, but it happens all the time!)`,
          ],
        },
      ],
    },
    {
      title: '<a  id="hc_written_fileformats"></a>Accepted File Formats',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `StraighterLine accepts the following file types for all assignments that are uploaded to <a href="/help-center/academics/written-assignments/#hc_written_turnitin" title="TurnItIn and Plagiarism Detection">TurnItIn:</a>`,
          points: [
            `Microsoft Word® (.doc/.docx) - Recommended`,
            `Google Docs via Google Drive™`,
            `Adobe® PDF`,
            `Microsoft PowerPoint® (.pptx, .ppt, .ppsx, and .pps)`,
            `Microsoft Excel® (.xls and .xlsx)`,
            `OpenOffice Text (.odt)`,
            `WordPerfect®  (.wpd)`,
            `PostScript (.ps/.eps)`,
            `HTML`,
            `Hangul Word Processor file (.hwp)`,
            `Rich text format (.rtf)`,
            `Plain text (.txt)`,
          ],
        },
      ],
    },
    {
      title: '<a id="hc_written_drafts"></a>Draft Assignments',
      sections: [
        `Good writing requires revision, so take advantage of the early feedback opportunities we offer! Many assignments offer you the opportunity to submit a draft, so we encourage you do so whenever possible. It’s always good to get multiple readers to review your writing before you officially submit it for grading. Make sure to leave <a href="#hc_written_turnaroundtimes" title="Grading Turnaround Times">time</a> to receive your draft <a href="/help-center/academics/written-assignments/#hc_written_reviewfeedback" title="Reviewing Assignment Feedback">feedback</a> so that you can apply your grader’s comments to your final version.`,
      ],
    },
    {
      title: '<a id="hc_written_turnaroundtimes"></a>Grading Turnaround Times',
      sections: [
        `In general, you should plan on a 3-5 business day turnaround for your writing assignment to be graded. Grades will be posted to your assignment along with any specific <a href="/help-center/academics/written-assignments/#hc_written_reviewfeedback" title="Reviewing Assignment Feedback">feedback</a> through <a href="/help-center/academics/written-assignments/#hc_written_turnitin" title="TurnItIn and Plagiarism Detection">TurnItIn.</a> If you have been waiting for a grade for more than 5 business days, please contact a Student Advisor to inquire about the delay.`,
      ],
    },
    {
      title:
        '<a id="hc_written_gradedassignment"></a>How To Open A Graded Assignment',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `To open a graded assignment, follow the steps below. For more detailed instructions, you can follow this step-by-step tutorial.`,
          points: [
            `Open your course in Moodle`,
            `Look for the “Administration” block on the left-hand side of the page and click on the blue “Turnitin Assignments” link`,
            `Click the assignment that you want to view`,
            `Click the tab at the top of the screen titled “My Submissions”`,
            `Once you are in your submission inbox, click the blue pencil next to your score`,
            `This will open your assignment in TurnItIn in a new window (Check to make sure your Pop-Up blocker is turned off if nothing happens when you click the pencil)`,
          ],
        },

        {
          type: interfaceType.MODAL_TYPE,
          title: `Open Your Graded Assignment`,
          bannerImage:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_04.png',
          modalData: [
            {
              image: helpCenterImagesUrl.Course_Resume,
              description: `From your student dashboard, re-enter your course by selecting the "Resume Course" button or click on the course card to view a list of course topics.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_02.png`,
              description: `Navigate to and open the assignment in your course that you would like to review.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_03.png`,
              description: `Click the tab titled “My Submissions”`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_04.png`,
              description: `This brings you to your submission inbox for the assignment. Click the blue pencil next to your score to access the assignment in Turn It In.”`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_05.png`,
              description: `Your assignment will open in Turn It In.`,
            },
          ],
        },
      ],
    },
    {
      title:
        '<a id="hc_written_reviewfeedback"></a>Reviewing Assignment Feedback',
      sections: [
        `To get the most out of your feedback, we recommend that you read your grader’s comments carefully and do your best to apply what you learn to your next writing assignment.`,

        `All StraighterLine graders have at least a master’s degree or a Ph.D in their field of study and are highly qualified, trained professionals who have plenty of college-level teaching or tutoring experience. They are here to help you succeed!`,

        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `There are two locations in your assignment that you can review feedback:`,
          points: [
            `Throughout the text: Hover over any of the blue boxes in your text to view a comment about a specific area`,
            `Feedback Summary: General feedback can be found on the right of your screen`,
          ],
          note: `Note: If you are using TurnItIn Classic, follow the instructions in this guide`,
        },
      ],
    },
    {
      title: 'Your Originality Score',
      sections: [
        `As part of your assignment feedback, you will receive an Originality Score. TurnItIn can be a great tool for helping you learn how to cite material appropriately.`,

        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `Follow the steps below to access and interpret your score:`,
          points: [
            `<a href="#hc_written_gradedassignment" title="How To Open Your Graded Assignment">Open your assignment in TurnItIn</a>`,
            `Click on the “Originality” tab at the top left of the page`,
            `The percentage at the top right hand corner tells you how much of your material matches other sources. <strong>There is no generally acceptable match percentage - If anything more than 0% registers in the report, your paper may be marked for additional review.</strong>`,
            `Different colors and numbers highlighted within the page on the left correspond to the sources denoted on the right.`,
            `If your paper is flagged for plagiarism, you will need to appropriately cite the sources highlighted by TurnItIn. When you have made revisions, you may <a href="#hc_written_resubmit" title="Resubmit an Assignment">re-submit your assignment.</a>`,
            `Sometimes, TurnItIn will highlight portions of text that you have already cited. In this case, take a look at the similarity percentage. <strong>As a rule of thumb, no more than 10% of your text should be composed of direct quotes, even if they are cited correctly.</strong> After all, it’s YOUR ideas we’re interested in! If you find that your similarity percentage is above 10%, even if you have been citing correctly, you may want to consider paraphrasing your direct quotations. (Keep in mind that paraphrases need to be cited, too!)`,
          ],
          note: `Note: If you are using TurnItIn Classic, follow the instructions in this guide.`,
        },
      ],
    },
    {
      title:
        '<a id="hc_written_zeroassignment"></a>Why Do I Have a Zero on my Assignment?',
      sections: [
        `Don’t worry! If you see a zero on your assignment, it usually just  means that we’d like you to resubmit it for a chance at a better grade. Our graders assign a zero when your paper has an issue we’d like you to fix. For instance, maybe you missed something in the assignment instructions, or maybe you didn’t cite your sources correctly. If you open your submission, you’ll be able to <a href="/help-center/academics/written-assignments/#hc_written_reviewfeedback" title="Reviewing Assignment Feedback">see comments from your grader</a> indicating what the issue is and what you can do to resolve it. As always, if you have questions about what you need to do to <a href="#hc_written_resubmit" title="Resubmit an Assignment">resubmit</a>, please contact one of our Student Advisors.`,
      ],
    },
    {
      title:
        '<a id="hc_written_turnitin"></a>TurnItIn and Plagiarism Detection',
      sections: [
        `All written StraighterLine assignments are submitted through a service called <a href="http://turnitin.com/" title="TurnItIn" target="_blank">TurnItIn.</a> TurnItIn scans your assignment for plagiarism, which we know is often unintentional, especially when students are learning how to research and cite appropriately. If the similarity report generated by your submission is high (meaning your submission matches something found on the Internet or in TurnItIn’s database very closely), you may be assigned a temporary zero and asked to make any necessary changes. Sometimes this means you’ll simply need to provide a citation for your source, or perhaps you’ll need to rephrase things in your own words. Think of TurnItIn as just one tool in a whole kit designed to help you learn about the acceptable use of intellectual property. Keep in mind that the similarity report TurnItIn generates is just a number--the number only means something when it’s interpreted by a person. That’s part of the reason that there there is no generally acceptable similarity report score--every similarity report is interpreted individually and contextually.`,

        `Unfortunately, sometimes plagiarism is intentional. In cases where it appears that students have intentionally plagiarized, they are generally given an opportunity to resubmit. However, please note that plagiarism is a violation of our <a href="${window.location.origin}/help-center/student-handbook/privacy-policy" title="Academic Honesty Policy">Academic Honesty Policy</a>, and students who plagiarize repeatedly may be subject to sanctions such as a grade reduction or transcript denial. Remember, your courses are what you make of them--don’t cheat yourself out of a learning experience (and transferable college credit!) because you’re worried your own work isn’t good enough. We want to help you succeed, so if you’re feeling unsure about how to tackle a written assignment, please let us know how we can help.`,
      ],
    },
    {
      title: 'Grading Notifications',
      sections: [
        `At this time we do not alert students when an assignment is graded or returned. Please check your course regularly to see when your grade is available.`,
      ],
    },
    {
      title: 'TurnItIn Due Dates',
      sections: [
        `StraighterLine does not set <a href="/help-center/academics/courses/#hc_courses_selfpaced" title="Self-Paced, No Due Dates">due dates</a> for assignments or assessments. Please ignore any due dates in your <a href="/help-center/academics/written-assignments/#hc_written_turnitin" title="TurnItIn and Plagiarism Detection">TurnItIn</a> course assignments. Once you are ready to submit an assignment, you may do so—take as much time as you need, or move as quickly as you wish.`,
      ],
    },
    {
      title: '<a id="hc_written_resubmit"></a>Resubmit an Assignment',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `TIn general, assignments cannot be resubmitted once they have been graded. If you do need to resubmit an assignment, please initiate the process based on the following criteria:`,
          points: [
            `<strong>Assignment has not been graded:</strong> As long as your assignment has not been graded, you can contact a Student Advisor to clear your original submission. Grading turn around times can take as little as 24 hours, so if you want to resubmit an assignment that has not yet been graded,  we recommend that you call or use our Live Chat service to get in touch with a Student Advisor as soon as possible.`,
            `<strong>Assignment has been identified for resubmission by a grader:</strong> If you received a <a href="#hc_written_zeroassignment" title="Why Do I Have a Zero on my Assignment?">temporary zero</a> on your graded assignment due to an issue like low word count or a high TurnItIn similarity score, you can resubmit the assignment with the changes requested in your assignment feedback. Please create a <a href="${window.location.origin}/help-center/support-request" target="_blank" title="Create a Support Request">Support Request</a> with the course name and assignment name and a Student Advisor will clear the assignment for you.`,
          ],
        },
      ],
    },
  ],
};
