import React, { useMemo, useState } from 'react';
import {
  Grid,
  Box,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import Styles from './styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { VariantTypography } from '../../themes/properties';
import {
  dataTestIds,
  minAge,
  onBoardingConstants,
  required,
  US_PHONE_REFORMAT_REGEX,
} from '../../constants';
import Profile from '../../models/Profile.model';
import {
  formatData,
  dateFormats,
  isUnderAged,
  isDateValid,
} from '../../utils/dateUtils';
import dayjs from 'dayjs';
import { US_PHONE_FORMAT_REGEX, EMPTY_STRING } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const CalenderIcon = () => (
  <Box sx={Styles.calenderIcon}>
    {' '}
    <FontAwesomeIcon icon={faCalendar} />
  </Box>
);

interface PropsType {
  profile: Profile | null;
  updateProfile: (profile: Profile) => void;
}

const ProfileInfoForm = ({ profile, updateProfile }: PropsType) => {
  const { showLoader } = useSelector((state: RootState) => state.app);
  const showError: boolean = !showLoader;

  const phoneNumber = useMemo(
    () => profile?.phone?.replace(US_PHONE_FORMAT_REGEX, '($1) $2-$3'),
    [profile?.phone],
  );
  const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
  const [invalidPhoneNumberError, setInvalidPhoneNumberError] =
    useState(EMPTY_STRING);

  // callback function to update the phone number in redux-store
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidPhoneNumberError(EMPTY_STRING);
    let phone = e.target.value;
    phone = phone.replace(US_PHONE_REFORMAT_REGEX, EMPTY_STRING);

    if (phone.length > onBoardingConstants.personalInfo.maxLengths.phone) {
      return;
    }

    if (phone.length !== onBoardingConstants.personalInfo.maxLengths.phone) {
      setInvalidPhoneNumberError(onBoardingConstants.errors.invalidPhoneNumber);
    }

    if (profile) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profile, phone: phone }),
      ) as Profile;
      updateProfile(updatedProfile);
    }
  };

  // callback function to update the date in redux-store
  const handleChangeDate = (value: any) => {
    const updatedDate: string = dayjs(value, dateFormats.MMDDYYYY_SLASH).format(
      dateFormats.YYYYMMDD_HYPHEN,
    );
    if (profile) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profile, dob: updatedDate }),
      ) as Profile;
      updateProfile(updatedProfile);
    }

    !isDateValid(updatedDate, dateFormats.YYYYMMDD_HYPHEN)
      ? setErrorMessage(onBoardingConstants.errors.invalidDate)
      : isUnderAged(updatedDate)
      ? setErrorMessage(onBoardingConstants.errors.invalidDob)
      : setErrorMessage(EMPTY_STRING);
  };

  return (
    <Box>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={{ xs: 3, md: 4 }}
      >
        <Grid item xs={12} md={3}>
          <InputLabel
            sx={Styles.inputLabel}
            data-testid={dataTestIds.profileInfoForm.labelFirstName}
          >
            {onBoardingConstants.personalInfo.studentFistName}
          </InputLabel>
          <TextField
            disabled
            sx={Styles.inputField}
            value={profile?.firstName ?? EMPTY_STRING}
            data-testid={dataTestIds.profileInfoForm.inputFirstName}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputLabel
            sx={Styles.inputLabel}
            data-testid={dataTestIds.profileInfoForm.labelLastName}
          >
            {onBoardingConstants.personalInfo.studentLastName}
          </InputLabel>
          <TextField
            disabled
            sx={Styles.inputField}
            value={profile?.lastName ?? EMPTY_STRING}
            data-testid={dataTestIds.profileInfoForm.inputLastName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel
            sx={Styles.inputLabel}
            data-testid={dataTestIds.profileInfoForm.labelEmail}
          >
            {onBoardingConstants.personalInfo.emailAddress}
          </InputLabel>
          <TextField
            disabled
            sx={Styles.inputField}
            value={profile?.email ?? EMPTY_STRING}
            data-testid={dataTestIds.profileInfoForm.inputEmail}
          />
        </Grid>

        <Grid item xs={12} md={3} data-testid={dataTestIds.profileInfoForm.datePickerDOB}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={onBoardingConstants.personalInfo.dob}
              slots={{
                openPickerIcon: CalenderIcon,
              }}
              slotProps={{
                textField: {
                  helperText: !errorMessage && dateFormats.MMDDYYYY_SLASH,
                  size: 'small',
                  error: showError && !!errorMessage,
                },
              }}
              sx={Styles.datePicker}
              value={
                profile?.dob
                  ? dayjs(
                    formatData(
                      profile?.dob ?? dayjs().toString(),
                      dateFormats.MMDDYYYY_SLASH,
                    ),
                  )
                  : EMPTY_STRING
              }
              maxDate={dayjs().subtract(minAge, 'years')}
              onChange={handleChangeDate}
              // data-testid={dataTestIds.profileInfoForm.datePickerDOB}
            />
            {errorMessage && showError && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.required}
                data-testid={dataTestIds.profileInfoForm.textErrorDOB}
              >
                {errorMessage}
              </Typography>
            )}
          </LocalizationProvider>
          {!profile?.dob && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.profileInfoForm.textRequiredDOB}
              id={dataTestIds.profileInfoForm.textRequiredDOB}
            >
              {required}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            sx={Styles.inputField}
            label={onBoardingConstants.personalInfo.phoneNumber}
            InputLabelProps={{ size: 'small' }}
            value={phoneNumber ?? EMPTY_STRING}
            onChange={handlePhoneChange}
            data-testid={dataTestIds.profileInfoForm.inputPhone}
          />
          {!phoneNumber && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.profileInfoForm.textRequiredPhone}
            >
              {required}
            </Typography>
          )}
          {phoneNumber && invalidPhoneNumberError && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.profileInfoForm.textErrorPhone}
            >
              {invalidPhoneNumberError}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileInfoForm;
