import { Avatar, Box } from '@mui/material';
import Styles from './Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Routes, dataTestIds } from '../../constants';

interface PropsType {
  imageUrl: string;
}

const ProfilePic = ({ imageUrl }: PropsType) => {
  const navigate = useNavigate();
  return (
    <Box sx={Styles.boxAvatar}>
      <Avatar
        src={imageUrl}
        sx={Styles.profileAvatar}
        alt={dataTestIds.profilePic}
        data-testid={dataTestIds.profileHeader.avatarValue}
      />

      <Box
        sx={Styles.profileEditAvatar}
        onClick={() => navigate(Routes.accountSettings)}
        data-testid={dataTestIds.profileHeader.divIconPen}
      >
        <FontAwesomeIcon
          icon={faPen}
          data-testid={dataTestIds.profileHeader.iconPen}
        />
      </Box>
    </Box>
  );
};

export default ProfilePic;
