import { themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

export default {
  rootTableHeader: {
    bgcolor: themeColors.primary,

    '& .MuiTableCell-root': {
      color: themeColors.white,
      fontWeight: 700,
      fontSize: `${spacing * 2}px`,
    },
    width: '100vw',
  },
  cell: {
    '& .MuiTableCell-root': {
      p: `${spacing * 1.5}px ${spacing * 2}px`,
    },
    '@media (max-width: 1200px)': {
      '&.MuiTableCell-root': {
        p: `${spacing * 1.5}px ${spacing * 1}px`,
      },
    },
    '@media (max-width: 1024px)': {
      '&.MuiTableCell-root': {
        p: `${spacing * 1.5}px ${spacing * 0.5}px`,
      },
    },
  },
  rowStatus: {
    textTransform: 'uppercase',
    width: '80%',
    textAlign: 'center',
    py: `${spacing * 0.5}px`,
    fontSize: `${spacing * 1.5}px`,
    borderRadius: `${spacing * 1}px`,
    fontWeight: 600,
    '@media (max-width: 1440px)': { width: '100%' },
  },
  boxFlex: {
    display: 'flex',
  },
  textCourseId: { fontWeight: 700, mr: `${spacing * 1.5}px` },
};
