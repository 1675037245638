import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  dashboardRightPanel,
  dataTestIds,
  HelpfulLinkType,
} from '../../../constants';
import { VariantTypography } from '../../../themes/properties';
import Styles from './Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { faClock } from '@fortawesome/pro-light-svg-icons';

const HelpfulLinks = () => {
  const navigate = useNavigate();

  const handleNavigate = (
    link: string,
    isExternalLink: boolean,
    isBigCommerceLink: boolean,
  ) => {
    if (isExternalLink) {
      window.open(link, '_newtab');
    } else {
      if (isBigCommerceLink) window.open(link, '_self');
      else navigate(link);
    }
  };

  const linkRows = dashboardRightPanel.hepfulLinks.linkData.map(
    (data: HelpfulLinkType) => (
      <Box sx={Styles.boxLink} key={data.text}>
        <Box
          sx={Styles.boxLinkText}
          onClick={() =>
            handleNavigate(
              data.link,
              data.isExternalLink,
              data.isBigCommerceLink,
            )
          }
        >
          <Box sx={Styles.row}>
            <Box
              sx={Styles.iconLink}
              data-testid={dataTestIds.rightPanel.helpfulLinks.linkIcon}
            >
              <FontAwesomeIcon icon={faLink} />
            </Box>
            <Typography
              variant={VariantTypography.body1}
              data-testid={dataTestIds.rightPanel.helpfulLinks.linkName}
              sx={Styles.textLink}
            >
              {data.text}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ...Styles.row, ...Styles.boxSubText }}>
          <Box
            sx={Styles.iconClock}
            data-testid={dataTestIds.rightPanel.helpfulLinks.clockIcon}
          >
            <FontAwesomeIcon icon={faClock} />
          </Box>
          <Typography
            variant={VariantTypography.body1}
            sx={Styles.subText}
            data-testid={dataTestIds.rightPanel.helpfulLinks.subText}
          >
            {data.subText}
          </Typography>
        </Box>
      </Box>
    ),
  );

  return (
    <Box sx={Styles.root}>
      <Box>
        <Typography variant={VariantTypography.body2} sx={Styles.textHeader}>
          {dashboardRightPanel.hepfulLinks.header}
        </Typography>
      </Box>
      {linkRows}
    </Box>
  );
};

export default HelpfulLinks;
