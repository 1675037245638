import { Box, Button, Typography } from '@mui/material';
import {
  dataTestIds,
  EMPTY_STRING,
  requestTranscipt,
  US_COUNTRY_ID,
} from '../../../../constants';
import { Address } from '../../../../models/Profile.model';
import {
  VariantTypography,
  VariantButton,
} from '../../../../themes/properties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import Styles from './styles';
import State from '../../../../models/State.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Country from '../../../../models/Country.model';
import { MasterDataType } from '../../../../store/app.slice';
import { useEffect, useMemo, useState } from 'react';
import MailingAddressForm from '../../../../common-components/mailing-address-form/MailingAddressForm.component';

interface Props {
  address: Address | null;
  onUpdateMailAddress: (address: Address) => void;
}

const MailingAddressView = ({ address, onUpdateMailAddress }: Props) => {
  const masterData: MasterDataType | null = useSelector(
    (state: RootState) => state.app.masterData,
  );

  const [editAddress, setEditAddress] = useState(false);

  const [mailAddress, setMailAddress] = useState<Address>(
    JSON.parse(JSON.stringify(address)) as Address,
  );

  useEffect(() => {
    setMailAddress(JSON.parse(JSON.stringify(address)) as Address);
  }, [address]);

  const state: string | null = useMemo(() => {
    const countries: Array<Country> = masterData?.countries ?? [];
    const selectedCountry: Country | null =
      countries.find((country: Country) => country.id === address?.country) ??
      null;

    const state: State | null =
      selectedCountry?.states.find(
        (state: State) => state.id === address?.state,
      ) ?? null;
    return state?.name ?? address?.state ?? null;
  }, [address?.state, masterData]);

  const country: Country | null = useMemo(() => {
    const countries: Array<Country> = masterData?.countries ?? [];
    const selectedCountry: Country | null =
      countries.find((country: Country) => country.id === address?.country) ??
      null;

    return selectedCountry;
  }, [address?.country, masterData]);

  const editAddressButtonView = (
    <Box
      sx={{ ...Styles.flexBox, ...Styles.boxEditAddress }}
      onClick={() => setEditAddress(true)}
      data-testid={
        dataTestIds.transcriptComponent.mailingAddressView.editAddressContainer
      }
    >
      <Box
        sx={Styles.iconEdit}
        data-testid={dataTestIds.transcriptComponent.mailingAddressView.iconPen}
      >
        <FontAwesomeIcon icon={faPen} />
      </Box>
      <Typography
        variant={VariantTypography.body2}
        sx={Styles.textEdit}
        data-testid={dataTestIds.transcriptComponent.mailingAddressView.textEdit}
      >
        {requestTranscipt.edit}
      </Typography>
    </Box>
  );

  const onCancelAddress = () => {
    setMailAddress(JSON.parse(JSON.stringify(address)) as Address);
    setEditAddress(false);
  };

  const updateAddress = () => {
    onUpdateMailAddress(JSON.parse(JSON.stringify(mailAddress)) as Address);
    setEditAddress(false);
  };

  const isValidMailForm = (): boolean => {
    if (
      mailAddress &&
      (!mailAddress?.address ||
        !mailAddress?.city ||
        !mailAddress?.state ||
        !mailAddress.zip ||
        (mailAddress.country === US_COUNTRY_ID &&
          mailAddress.zip.length !==
            requestTranscipt.mailingAddress.maxLengths.zip) ||
        (mailAddress.country !== US_COUNTRY_ID &&
          (mailAddress.zip.length <
            requestTranscipt.mailingAddress.minLengths.zipNonUS ||
            mailAddress.zip.length >
              requestTranscipt.mailingAddress.maxLengths.zipNonUS)))
    ) {
      return false;
    }
    return true;
  };

  const buttonsView = (
    <Box>
      <Box>
        <Button
          variant={VariantButton.contained}
          sx={Styles.button}
          onClick={updateAddress}
          disabled={!isValidMailForm()}
          data-testid={dataTestIds.transcriptComponent.mailingAddressView.updateBtn}
        >
          {requestTranscipt.buttons.update}
        </Button>
        <Button
          variant={VariantButton.contained}
          sx={{ ...Styles.button, ...Styles.buttonCancel }}
          onClick={onCancelAddress}
          data-testid={dataTestIds.transcriptComponent.mailingAddressView.cancelBtn}
        >
          {requestTranscipt.buttons.cancel}
        </Button>
      </Box>
    </Box>
  );

  const addressView = (
    <Box sx={Styles.boxAddressValue}>
      <Typography
        data-testid={dataTestIds.transcriptComponent.mailingAddressView.textAddress}
      >{`${address?.address ?? EMPTY_STRING} ${
        address?.addressLine2 ?? EMPTY_STRING
      }`}</Typography>

      <Typography
        data-testid={dataTestIds.transcriptComponent.mailingAddressView.textZip}
      >{`${address?.city ?? EMPTY_STRING}, ${state}, ${
        address?.zip ?? EMPTY_STRING
      }`}</Typography>

      <Typography
        data-testid={dataTestIds.transcriptComponent.mailingAddressView.textCountry}
      >{`${country?.name ?? EMPTY_STRING}`}</Typography>
    </Box>
  );

  const onUpdateAddress = (address: Address) => {
    setMailAddress(address);
  };

  const editAddressView = (
    <MailingAddressForm
      address={mailAddress}
      onUpdateAddress={onUpdateAddress}
      showAddressLineTwo={true}
    />
  );

  return (
    <Box
      sx={Styles.root}
      data-testid={dataTestIds.transcriptComponent.mailingAddressView.divContainer}
    >
      <Box
        sx={{ ...Styles.flexBox, ...Styles.boxMailingAddress }}
        data-testid={
          dataTestIds.transcriptComponent.mailingAddressView.headerContainer
        }
      >
        <Typography variant={VariantTypography.body1} sx={Styles.textKey}>
          {requestTranscipt.mailingAddress.header}
        </Typography>

        {editAddress ? buttonsView : editAddressButtonView}
      </Box>
      <Box sx={{ mt: 2 }}>{editAddress ? editAddressView : addressView}</Box>
    </Box>
  );
};

export default MailingAddressView;
