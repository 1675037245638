import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { themeColors } from '../../themes/colors';
import { useNavigate } from 'react-router';
import { cards } from './HelpCenter.types';
import Styles from './HelpCenter.style';
import { Constants } from '../../constants';
import { VariantButton, VariantTypography } from '../../themes/properties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HelpCenterHome = () => {
  const navigate = useNavigate();

  const navigateToService = (route: string) => {
    navigate(route);
  };

  const helpCenterCards = cards.map((item, index) => {
    return (
      <Grid item key={index} sx={Styles.cardGrid}>
        <Card
          onClick={() => navigateToService(item.route)}
          sx={Styles.cardContainer}
          variant={VariantButton.outlined}
        >
          <CardHeader
            avatar={
              <Box sx={Styles.serviceIcon}>
                <FontAwesomeIcon icon={item.icon} />
              </Box>
            }
            title={item.title}
            titleTypographyProps={Styles.serviceTitle}
            sx={
              item.title === Constants.BILLING_AND_ACCOUNT_MANAGEMENT
                ? Styles.accountManagementHeader
                : Styles.cardHeader
            }
          />

          <CardContent
            sx={
              item.title === Constants.BILLING_AND_ACCOUNT_MANAGEMENT
                ? Styles.accountManagementContent
                : Styles.cardContent
            }
          >
            {item.body}
          </CardContent>
        </Card>
      </Grid>
    );
  });
  return (
    <>
      <Box sx={Styles.boxHeader}>
        <Typography sx={Styles.helpCenterTitle}>
          {Constants.HELP_CENTER_TITLE}
        </Typography>
        <Typography
          variant={VariantTypography.h5}
          sx={Styles.helpCenterSubTitle}
        >
          {Constants.HELP_CENTER_SUB_TITLE}
        </Typography>
      </Box>
      <Grid container rowSpacing={2} sx={Styles.helpCenterCardContainer}>
        {helpCenterCards}
      </Grid>
    </>
  );
};

export default HelpCenterHome;
