import { createSlice } from '@reduxjs/toolkit';
import Course from '../../../models/Course.model';
import Topic from '../../../models/Topic.model';
import {
  getCourseList,
  getAssignmentList,
  addTrialCourses,
  courseRecommendation,
} from './dashboardAsync.actions';
import { SLICE_DASHBOARD } from '../../../store/slices';
import { FilterCourse } from '../../../types/global';
import { RecommendedCourses } from '../../../types/contract';
import { API_STATE, EMPTY_STRING } from '../../../constants';

interface courseDetailModal {
  showModal: boolean;
  course: Course | null;
  topics: Array<Topic>;
}

export interface State {
  courses: Array<Course>;
  loading: boolean;
  courseDetailModal: courseDetailModal;
  filterCoureCard: string;
  recommendedCourses: RecommendedCourses;
  showFreeTrialAddCoursesModal: boolean;
  showRenewMembershipModal: boolean;
  courseApiState: string;
}

const initialState: State = {
  courses: [],
  loading: false,
  courseDetailModal: {
    showModal: false,
    course: null,
    topics: [],
  },
  filterCoureCard: FilterCourse.AllCourses,
  recommendedCourses: {
    type: '',
    data: [
      {
        courseId: '',
        courseName: '',
        shortDescription: '',
        url: '',
      },
    ],
  },
  showFreeTrialAddCoursesModal: false,
  showRenewMembershipModal: false,
  courseApiState: EMPTY_STRING,
};

export const dashboardSlice = createSlice({
  name: SLICE_DASHBOARD,
  initialState,
  reducers: {
    closeModal: (state) => {
      state.courseDetailModal.showModal = false;
    },

    updateFilterCards: (state, action) => {
      state.filterCoureCard = action.payload;
    },

    updateCourse: (state, action) => {
      const updatedCourse: Course = action.payload;
      state.courses = state.courses.map((course: Course) => {
        if (updatedCourse.id === course.id) {
          return updatedCourse;
        } else {
          return course;
        }
      });
    },
    showFreeTrialAddCoursesModal: (state, action) => {
      state.showFreeTrialAddCoursesModal = action.payload;
    },
    showRenewMembershipModal: (state, action) => {
      state.showRenewMembershipModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCourseList.pending, (state) => {
      state.loading = true;
      state.courseApiState = API_STATE.pending;
    });
    builder.addCase(getCourseList.fulfilled, (state, action) => {
      state.courses = action?.payload ?? [];
      state.courseApiState = API_STATE.success;

      state.showFreeTrialAddCoursesModal = state.courses.length === 0;
    });
    builder.addCase(getCourseList.rejected, (state, action) => {
      console.log(action);
      state.courseApiState = API_STATE.error;
    });


    builder.addCase(addTrialCourses.fulfilled, (state) => {
      state.showFreeTrialAddCoursesModal = false;
    })

    builder.addCase(getAssignmentList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssignmentList.fulfilled, (state, action) => {
      if (action.payload) {
        const { courseIdentifier, topics } = action?.payload ?? {
          courseIdentifier: null,
          topics: [],
        };
        const selectedCourse = state.courses.find(
          (course: Course) => course.courseIdentifier === courseIdentifier,
        );
        if (selectedCourse) {
          state.courseDetailModal.course = selectedCourse;
        }
        state.courseDetailModal.topics = topics;
        state.courseDetailModal.showModal = true;
      }
    });
    builder.addCase(getAssignmentList.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(courseRecommendation.fulfilled, (state, action) => {
      state.recommendedCourses = action.payload; // Update the recommendedCourses state with the payload
    });
  },
});

export const {
  closeModal,
  updateFilterCards,
  updateCourse,
  showFreeTrialAddCoursesModal,
  showRenewMembershipModal,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
