import { Box } from '@mui/system';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Styles from './CourseStatus.style';
import {
  Constants,
  Routes,
  Urls,
  screenBreakpoints,
  dataTestIds,
} from '../../constants';
import { FilterCourse } from '../../types/global';
import { showFreeTrialAddCoursesModal, updateFilterCards } from '../dashboard/redux/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

interface PropsType {
  isFreeTrial: boolean
}

const CourseCardStatus = ({ isFreeTrial }: PropsType) => {
  const screenIPad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);

  const selectedFilter = useSelector(
    (state: RootState) => state.dashboard.filterCoureCard,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const addCourseClickHandler = () => {
    if(isFreeTrial) {
      dispatch(showFreeTrialAddCoursesModal(true));
    } else {
      const url = Urls.ADD_A_COURSE;
      const newLocation = new URL(url);
      window.location.href = newLocation.href;
    }
  };

  const transcriptClickHandler = () => {
    navigate(Routes.transcript);
  };

  const onClickFilter = (filterType: string) => {
    dispatch(updateFilterCards(filterType));
  };

  const filters = (
    <Box
      sx={Styles.courseStatusContainer}
      data-testid={dataTestIds.courseStatus.divFilterContainer}
    >
      <Button
        onClick={() => onClickFilter(FilterCourse.AllCourses)}
        sx={
          selectedFilter === FilterCourse.AllCourses
            ? Styles.selectedCourseStatus
            : Styles.courseFilter
        }
        disableTouchRipple
        data-testid={dataTestIds.courseStatus.buttonAllCourses}
      >
        {FilterCourse.AllCourses}
      </Button>
      <Button
        onClick={() => onClickFilter(FilterCourse.Progress)}
        sx={
          selectedFilter === FilterCourse.Progress
            ? Styles.selectedCourseStatus
            : Styles.courseFilter
        }
        disableTouchRipple
        data-testid={dataTestIds.courseStatus.buttonInProgress}
      >
        {FilterCourse.Progress}
      </Button>
      <Button
        onClick={() => onClickFilter(FilterCourse.Completed)}
        sx={
          selectedFilter === FilterCourse.Completed
            ? Styles.selectedCourseStatus
            : Styles.courseFilter
        }
        disableTouchRipple
        data-testid={dataTestIds.courseStatus.buttonInCompleted}
      >
        {FilterCourse.Completed}
      </Button>
    </Box>
  );

  const actionButtons = (
    <Box
      sx={Styles.rightPanelContainer}
      data-testid={dataTestIds.courseStatus.divActionButtons}
    >
      <Button
        sx={Styles.sendTranscriptBtn}
        onClick={transcriptClickHandler}
        disabled={isFreeTrial}
        data-testid={dataTestIds.courseStatus.buttonSendTranscript}
      >
        <Box data-testid={dataTestIds.courseStatus.divIconSendTranscript}>
          <FontAwesomeIcon
            icon={faPaperPlane}
            data-testid={dataTestIds.courseStatus.iconSendTranscript}
          />
        </Box>
        <Typography
          sx={Styles.sendTranscripts}
          data-testid={dataTestIds.courseStatus.textSendTranscript}
        >
          {Constants.SEND_TRANSCRIPT}
        </Typography>
      </Button>

      <Button
        sx={Styles.sendTranscriptBtn}
        onClick={addCourseClickHandler}
        data-testid={dataTestIds.courseStatus.buttonAddCourse}
      >
        <Box data-testid={dataTestIds.courseStatus.divIconAddCourse}>
          <FontAwesomeIcon
            icon={faPlusCircle}
            data-testid={dataTestIds.courseStatus.iconAddCourse}
          />
        </Box>
        <Typography
          sx={Styles.addCourse}
          data-testid={dataTestIds.courseStatus.textAddCourse}
        >
          {Constants.ADD_A_COURSE}
        </Typography>
      </Button>
    </Box>
  );

  if (screenIPad) {
    return (
      <Box sx={Styles.root}>
        {actionButtons}
        {filters}
      </Box>
    );
  }

  return (
    <Box sx={Styles.root} data-testid={dataTestIds.courseStatus.divContainer}>
      {filters}
      {actionButtons}
    </Box>
  );
};

export default CourseCardStatus;
