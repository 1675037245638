import { themeColors, hexToRGB } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

export default {
  root: {
    bgcolor: hexToRGB(themeColors.lightBlue, 0.6),
    borderRadius: `${spacing * 1}px`,
    p: `${spacing * 1}px ${spacing * 3}px`,
  },
  flexBox: { display: 'flex', my: `${spacing * 1.25}px` },
  textKey: { fontWeight: 900 },
  textValue: {
    ml: `${spacing * 2}px`,
    overflowWrap: 'break-word',
  },
  iconLock: {
    color: themeColors.grey,
    '& .svg-inline--fa': {
      fontSize: `${spacing * 1.75}px`,
    },
    mr: `${spacing * 2.5}px`,
  },
};
