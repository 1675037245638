import { ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import AppRoutes from './routes/Routes';
import Theme from './themes/theme';
import { Container } from '@mui/system';
import Footer from './module/footer/Footer.component';
import Header from './module/header/Header.component';
import Loader from './common-components/loader/Loader.component';
import AppSnackbar from './common-components/snackbar/Snackbar.component';
import { BrowserRouter } from 'react-router-dom';
import Styles from './App.style';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';
import { getProfile } from './module/profileHeader/redux/profileAsync.actions';
import { getMasterData } from './store/appAsync.actions';
import './http-requests/index';
import { Amplify } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { storeAccessToken, storeToken } from './utils/auth.util';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { awsconfig } from './aws-exports';
import {
  BC_URL,
  APP_ENV,
  Routes as RoutesConstants,
  APP_GTM,
  ga4MeasurementId,
} from './constants';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

//import '@aws-amplify/ui-react/styles.css'; todo:for awesome ui but cognito css and las css overlapping.

let byPassAuthentication = false;
configureAmplify();
function shouldByPassAuthentication() {
  byPassAuthentication = false;
  if (location.pathname.toLowerCase().includes(RoutesConstants.LTI13Handler)) {
    byPassAuthentication = true;
  }
  return byPassAuthentication;
}

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (APP_ENV !== 'development') {
      const tageManagerArgs = {
        gtmId: APP_GTM,
      };
      TagManager.initialize(tageManagerArgs);
    }
    async function checkAuthStatus() {
      try {
        const usersession = await Auth.currentSession();
        const currentUser = await Auth.currentAuthenticatedUser();
        const idToken = currentUser.signInUserSession.idToken.jwtToken;
        const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
        storeToken(idToken);
        storeAccessToken(accessToken);
        setUser(currentUser);
        setIsAuthenticated(true);
        dispatch(getProfile());
        dispatch(getMasterData());
      } catch (err) {
        setIsAuthenticated(false);
        if (APP_ENV !== 'development') {
          window.location.href =
            BC_URL + `/login.php?returnUrl=${window.location.href}`;
        }
      }
    }
    checkAuthStatus().then((c) =>
      console.log('User Authenticated Successfully'),
    );
    if (ga4MeasurementId) {
      console.log('ga4MeasurementId-', ga4MeasurementId);
      ReactGA.initialize(ga4MeasurementId);
    }
  }, []);

  if (isAuthenticated)
    return (
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <Container sx={Styles.container}>
            <Loader />
            <AppSnackbar />
            <Header />
            <AppRoutes />
            <Footer />
          </Container>
        </BrowserRouter>
      </ThemeProvider>
    );
  else return <></>;
}

function AppWithoutAuthenticator() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Container sx={Styles.container}>
          <Loader />
          <AppSnackbar />
          <AppRoutes />
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
}

function configureAmplify() {
  Amplify.configure(awsconfig);
}

export default shouldByPassAuthentication()
  ? AppWithoutAuthenticator
  : APP_ENV === 'development'
  ? withAuthenticator(App, { hideSignUp: true })
  : App;
