import { Typography, Box, Button } from '@mui/material';
import { dataTestIds, requestTranscipt, Routes } from '../../../constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import Styles from './styles';
import { VariantButton, VariantTypography } from '../../../themes/properties';

import { resetApiState } from '../redux/transcriptSlice';
import { setTranscriptData } from '../redux/transcriptAsync.actions';
import { useNavigate } from 'react-router-dom';

const SuccessTranscript = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const handleAnotherTranscript = () => {
    dispatch(resetApiState());
    dispatch(setTranscriptData());
  };

  const viewHistoryHandler = () => {
    navigate(Routes.transcriptHistory);
  };

  const header = (
    <Box sx={Styles.boxHeaderText}>
      <Typography
        sx={Styles.textHeader}
        data-testid={dataTestIds.transcriptSuccess.textHeader}
      >
        {requestTranscipt.success.header}
      </Typography>
    </Box>
  );

  const bottomView = (
    <Box sx={Styles.rootBottom}>
      <Box sx={Styles.boxContainerBottomView}>
        <Button
          variant={VariantButton.contained}
          sx={{ ...Styles.button, ...Styles.buttonAnotherTrancript }}
          onClick={handleAnotherTranscript}
          data-testid={dataTestIds.transcriptSuccess.btnSubmitAnotherTranscript}
        >
          {requestTranscipt.buttons.submitAnotherRequest}
        </Button>

        <Button
          variant={VariantButton.contained}
          sx={Styles.button}
          onClick={viewHistoryHandler}
          data-testid={dataTestIds.transcriptSuccess.btnViewHistory}
        >
          {requestTranscipt.buttons.viewHistory}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <>{header}</>

      <Box
        sx={Styles.boxContent}
        data-testid={dataTestIds.transcriptSuccess.bodyContainer}
      >
        <Typography
          variant={VariantTypography.h5}
          sx={Styles.textContentSubHeader}
          data-testid={dataTestIds.transcriptSuccess.subHeader}
        >
          {requestTranscipt.success.subHeader}
        </Typography>

        <Typography
          variant={VariantTypography.body1}
          sx={Styles.textContentMessage}
          data-testid={dataTestIds.transcriptSuccess.successMessage}
        >
          {requestTranscipt.success.message}
        </Typography>
      </Box>
      <>{bottomView}</>
    </Box>
  );
};

export default SuccessTranscript;
