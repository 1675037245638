import { hexToRGB, themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

export default {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
  },
  root: {
    boxShadow: 24,
    outline: 'none',
    maxWidth: 600,
    minWidth: 600,
    position: 'relative',
    bgcolor: themeColors.white,
    borderRadius: `${spacing * 1}px`,
    minHeight: 300,
    '@media (max-width: 768px)': {
      maxWidth: 460,
      minWidth: 460,
    },
    '@media (max-width: 480px)': {
      maxWidth: 300,
      minWidth: 300,
    },
  },
  boxSubHeader: {
    pl: `${spacing * 4}px`,
    pt: `${spacing * 4}px`,
  },

  txtSubHeader: {
    fontWeight: 700,
    fontSize: `${spacing * 2.5}px`,
  },
  txtMessage: {
    fontSize: `${spacing * 2}px`,
  },
  boxContent: {
    pt: `${spacing * 2}px`,
    px: `${spacing * 4}px`,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    bgcolor: themeColors.white,
  },
  boxHeader: {
    backgroundColor: themeColors.primary,
    color: themeColors.white,
    display: 'flex',
    alignItems: 'baseline',
    px: `${spacing * 4}px`,
    py: `${spacing * 2}px`,
    borderTopLeftRadius: `${spacing * 1}px`,
    borderTopRightRadius: `${spacing * 1}px`,
  },
  boxHeaderText: { ml: `${spacing * 3}px`, flexGrow: 1 },
  textHeader: {
    fontSize: `${spacing * 2}px`,
    fontWeight: '900',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    color: themeColors.white,
  },
  iconClose: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  boxContainerBottomView: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    px: `${spacing * 2}px`,
    my: `${spacing * 3}px`,
  },
  button: {
    backgroundImage: `${themeColors.greenGradient}`,
    borderRadius: '50px',
    mr: `${spacing * 1.25}px`,
    '&:disabled': {
      border: `1px solid ${hexToRGB(themeColors.blueLight, 0.5)}`,
      color: hexToRGB(themeColors.blueLight, 0.5),
      background: themeColors.white,
    },
    fontSize: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': {
      width: '100%',
    },
    '&.MuiButtonBase-root': {
      fontWeight: 900,
    },
  },
};
