import { Box } from '@mui/material';
import TopicModel from '../../../models/Topic.model';
import Topic from './Topic.component';
import Styles from './topic.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import Course from '../../../models/Course.model';

interface Props {
  course: Course | null;
}

const Topics = ({ course }: Props) => {
  const topics = useSelector((state: RootState) => {
    return state.dashboard.courseDetailModal.topics;
  });
  const topicsView = topics.map((topic: TopicModel) => (
    <Topic key={topic.name} topic={topic} course={course} />
  ));
  return <Box sx={Styles.rootTopics}>{topicsView}</Box>;
};

export default Topics;
