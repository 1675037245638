import { LinearProgress, styled } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { spacing } from '../../themes/theme';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: spacing * 1.25,
  borderRadius: spacing * 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: spacing * 0.5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default BorderLinearProgress;
