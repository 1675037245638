import { Box, Button, Tooltip } from '@mui/material';
import Styles from './CourseCard.menu.style';
import { courseCardIconsText, dataTestIds } from '../../constants';
import { themeColors, hexToRGB } from '../../themes/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faFilePdf,
  faListCheck,
} from '@fortawesome/pro-solid-svg-icons';

interface MenuItemType {
  display: boolean;
  handler?: () => void;
}

interface PropsType {
  hasProctorUrl: boolean;
  checkList?: MenuItemType;
  pdf?: MenuItemType;
  calendar?: MenuItemType;
}

const MenuIcons = ({ hasProctorUrl, checkList, pdf, calendar }: PropsType) => (
  <Box sx={Styles.menuContainer}>
    {/* Check list icon */}
    {checkList && checkList.display && (
      <Tooltip title={courseCardIconsText.topic_details}>
        <span>
          <Button
            disabled={!checkList.handler}
            sx={Styles.btnIcon}
            data-testid={dataTestIds.courseCard.menu.buttonListCheck}
          >
            <Box
              sx={Styles.icon}
              onClick={checkList.handler}
              data-testid={dataTestIds.courseCard.menu.divListCheck}
            >
              <FontAwesomeIcon
                icon={faListCheck}
                data-testid={dataTestIds.courseCard.menu.iconListCheck}
              />
            </Box>
          </Button>
        </span>
      </Tooltip>
    )}

    {/* PDF icon */}
    {pdf && pdf.display && (
      <Tooltip title={courseCardIconsText.download_syllabus}>
        <span>
          <Button
            disabled={!pdf.handler}
            sx={Styles.btnIcon}
            data-testid={dataTestIds.courseCard.menu.buttonPDFFile}
          >
            <Box
              sx={Styles.icon}
              onClick={pdf.handler}
              data-testid={dataTestIds.courseCard.menu.divPDFFile}
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                data-testid={dataTestIds.courseCard.menu.iconPDFFile}
              />
            </Box>
          </Button>
        </span>
      </Tooltip>
    )}

    {/* Calendar icon */}
    {calendar && calendar.display && (
      <Tooltip
        title={hasProctorUrl ? courseCardIconsText.schedule_proctoring : null}
      >
        <span>
          <Button
            disabled={!calendar.handler}
            sx={{
              ...Styles.btnIcon,
              '&:hover': {
                borderRadius: hasProctorUrl ? '50%' : '0',
                bgcolor: hasProctorUrl
                  ? hexToRGB(themeColors.secondary, 0.1)
                  : themeColors.transparent,
              },
            }}
            data-testid={dataTestIds.courseCard.menu.buttonCalendar}
          >
            <Box
              sx={Styles.icon}
              onClick={calendar.handler}
              data-testid={dataTestIds.courseCard.menu.divCalendar}
            >
              <FontAwesomeIcon
                icon={faCalendarDays}
                data-testid={dataTestIds.courseCard.menu.iconCalendar}
              />
            </Box>
          </Button>
        </span>
      </Tooltip>
    )}
  </Box>
);

export default MenuIcons;
