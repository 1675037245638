import { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  FormControl,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Styles from './LeftHelpCenterPanel.style';
import { cards, CardType, CardSubMenuType } from './HelpCenter.types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Constants, Routes, screenBreakpoints } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faAngleLeft, faCheck } from '@fortawesome/pro-solid-svg-icons';

const LeftHelpCenterPanel = () => {
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedSubItem, setSelectedSubItem] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const MenuProps = {
    PaperProps: {
      style: {
        width: '10%',
        maxHeight: '27%',
      },
    },
  };

  const screenLaptopLarge = useMediaQuery(
    `(max-width:${screenBreakpoints.laptopLarge})`,
  );

  const handleItemClick = (item: string, route: string) => {
    navigate(route);
    setSelectedItem(item);
  };

  const handleSubMenuClick = (item: string, route: string) => {
    navigate(route);
    setSelectedSubItem(item);
  };

  const handleMenuChange = (e: any) => {
    const selectedValue = e.target.value as string;
    const selectedItem = cards.find((item) => item.title === selectedValue);
    if (selectedItem) {
      navigate(selectedItem.route);
      setSelectedItem(selectedValue);
    }
  };

  const leftPanel = cards.map((item: CardType) => {
    const isSelected: boolean = item.title === selectedItem;
    const hasSubItems: boolean = !!item?.subItems?.length;
    return (
      <Box key={item.title}>
        <ListItemButton
          onClick={() => handleItemClick(item.title, item.route)}
          sx={Styles.itemContainer}
          disableTouchRipple
        >
          {isSelected && (
            <ListItemIcon sx={Styles.expandIcon}>
              <FontAwesomeIcon icon={faPlay} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={item.title}
            sx={
              selectedItem === item.title
                ? Styles.selectedListItem
                : Styles.listItemText
            }
          />
        </ListItemButton>
        {isSelected && hasSubItems && (
          <List sx={Styles.leftPanelSubList}>
            {item.subItems?.map((subItem: CardSubMenuType, index: number) => {
              const isSubMenuSelected: boolean =
                subItem.title === selectedSubItem;
              return (
                <ListItemButton
                  key={index}
                  sx={Styles.subItemButton}
                  disableTouchRipple
                  onClick={() =>
                    handleSubMenuClick(subItem.title, subItem.route)
                  }
                >
                  <ListItemText
                    primary={subItem.title}
                    sx={
                      isSubMenuSelected
                        ? Styles.selectedSubListItem
                        : Styles.subListItemText
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        )}
      </Box>
    );
  });

  const handleRenderValue = (selectedValue: string) => {
    const selectedItem = cards.find((item) => item.title === selectedValue);
    if (selectedItem && selectedItem.subItems && selectedSubItem) {
      const selectedSubItemObj = selectedItem.subItems.find(
        (subItem) => subItem.title === selectedSubItem,
      );
      if (selectedSubItemObj) {
        return selectedSubItemObj.title;
      }
    }
    return selectedValue;
  };

  const dropDown = (
    <FormControl size="small" sx={Styles.formControl}>
      <Select
        value={selectedItem}
        onChange={(e) => handleMenuChange(e)}
        MenuProps={MenuProps}
        renderValue={(selectedValue) => handleRenderValue(selectedValue)}
        sx={Styles.menuContainer}
      >
        {cards.map((item: CardType) => (
          <MenuItem
            value={item.title}
            key={item.title}
            sx={Styles.menuItem}
            selected={item.title === selectedItem}
          >
            {item.title === selectedItem && !item.subItems?.length && (
              <ListItemIcon sx={Styles.checkIcon}>
                <FontAwesomeIcon icon={faCheck} />
              </ListItemIcon>
            )}
            {item.title}
            <Box>
              {!!item?.subItems?.length &&
                item.subItems &&
                item.subItems.length > 0 &&
                item.subItems.map((subItem) => (
                  <MenuItem
                    value={subItem.title}
                    key={subItem.title}
                    onClick={() =>
                      handleSubMenuClick(subItem.title, subItem.route)
                    }
                    selected={
                      item.title === selectedItem &&
                      subItem.title === selectedSubItem
                    }
                  >
                    {subItem.title === selectedSubItem &&
                      item.title === selectedItem && (
                        <ListItemIcon sx={Styles.checkIcon}>
                          <FontAwesomeIcon icon={faCheck} />
                        </ListItemIcon>
                      )}
                    {subItem.title}
                  </MenuItem>
                ))}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  useEffect(() => {
    // Update the selected item based on the current route
    const url = location.pathname;
    const currentPath = url.split('/');
    const menuPath = currentPath.length > 2 ? currentPath[2] : null;
    const subMenuPath = currentPath.length > 3 ? currentPath[3] : null;
    if (menuPath) {
      for (let index = 0; index < cards.length; index++) {
        const card: CardType = cards[index];
        if (card.route.includes(menuPath)) {
          setSelectedItem(card.title);
          if (subMenuPath && card.subItems) {
            for (
              let subIndex = 0;
              subIndex < card.subItems.length;
              subIndex++
            ) {
              const subMenu: CardSubMenuType = card.subItems[subIndex];
              if (url.includes(subMenu.route)) {
                setSelectedSubItem(subMenu.title);
                break;
              }
            }
          }
          break;
        }
      }
    }
  }, [location.pathname]);

  return (
    <>
      {!screenLaptopLarge ? (
        <>
          <Link href={`${Routes.helpCenter}`} sx={Styles.backIcon}>
            <Box>
              <FontAwesomeIcon icon={faAngleLeft} />
            </Box>
            <Typography sx={Styles.navigateBackText}>
              {Constants.NAVIGATE_TO_HELP_CENTER}
            </Typography>
          </Link>
          <Divider sx={Styles.divider} />
        </>
      ) : null}
      <List sx={Styles.leftPanelList}>
        {!screenLaptopLarge ? leftPanel : dropDown}
      </List>
    </>
  );
};

export default LeftHelpCenterPanel;
