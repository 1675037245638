import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  alertRoot: {
    width: `${spacing * 80}px`,
    height: 'auto',
    minHeight: `${spacing * 7}px`,
    padding: 0,
    backgroundColor: themeColors.white,
    boxShadow: 3,
    color: themeColors.black,
    '& .MuiAlert-message': {
      fontSize: `${spacing * 2}px`,
      p: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiAlert-icon': {
      padding: 0,
    },
    '&.MuiPaper-root': {
      borderRadius: `${spacing * 1}px`,
    },
    '& .MuiButtonBase-root': {
      color: themeColors.black,
    },
  },
  closeIcon: {
    paddingRight: `${spacing * 6.25}px`,
    paddingTop: `${spacing * 1.75}px`,
    '&:hover': {
      backgroundColor: 'transparent', // set background color to transparent on hover
    },
  },
  icon: {
    width: `${spacing * 7}px`,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: themeColors.white,
    fontSize: `${spacing * 2}px`,
    borderTopLeftRadius: `${spacing * 1}px`,
    borderBottomLeftRadius: `${spacing * 1}px`,
  },
};

export default Styles;
