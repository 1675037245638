import { TableCell, TableRow, useMediaQuery } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { dataTestIds, screenBreakpoints } from '../../../constants';
import Styles from './styles';

const Header = () => {
  const screenLaptop = useMediaQuery(`(max-width:${screenBreakpoints.laptop})`);
  const screenIpad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);

  return (
    <TableHead sx={Styles.rootTableHeader}>
      <TableRow>
        <TableCell
          sx={{ minWidth: screenIpad ? '40vw' : screenLaptop ? '18%' : '15%' }}
          data-testid={
            dataTestIds.transcriptHistory.textTableHeaderDateOfRequest
          }
        >
          Date of Request
        </TableCell>
        <TableCell
          sx={{ minWidth: screenIpad ? '60vw' : '30%' }}
          data-testid={
            dataTestIds.transcriptHistory.textTableHeaderCoursesTransfered
          }
        >
          Courses Transfered
        </TableCell>
        <TableCell
          sx={{ minWidth: screenIpad ? '60vw' : screenLaptop ? '40%' : '43%' }}
          data-testid={dataTestIds.transcriptHistory.textTableHeaderDestination}
        >
          Transcript Destination
        </TableCell>
        <TableCell
          sx={{ minWidth: screenIpad ? '20vw' : '12%' }}
          data-testid={dataTestIds.transcriptHistory.textTableHeaderStatus}
        >
          Status
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default Header;
