import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  cardGrid: {
    '&.MuiGrid-item': {
      paddingLeft: `${spacing * 2}px`,
    },
    '@media (max-width: 480px)': {
      '&.MuiGrid-item': {
        paddingLeft: `${spacing * 0.5}px`,
      },
    },
  },
  boxHeader: {
    ml: `${spacing * 2}px`,
  },
  cardContainer: {
    width: '28vw',
    height: `${spacing * 22.5}px`,
    borderRadius: `${spacing * 1.5}px`,
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: themeColors.white,
      cursor: 'pointer',
      '& .MuiCardContent-root': {
        color: themeColors.white,
      },
      '& .MuiBox-root': {
        backgroundColor: themeColors.white,
        color: themeColors.black,
      },
    },
    '@media (max-width: 1440px)': {
      height: `${spacing * 25}px`,
    },
    '@media (max-width: 1200px)': {
      width: '42vw',
      height: `${spacing * 25}px`,
    },
    '@media (max-width: 1024px)': {
      width: '46vw',
      height: `${spacing * 25}px`,
    },
    '@media (max-width: 768px)': {
      width: '92vw',
      height: `${spacing * 25}px`,
    },
    '@media (max-width: 480px)': {
      height: `${spacing * 27.75}px`,
    },
  },
  serviceIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: `${themeColors.secondary}`,
    borderRadius: '50%',
    width: `${spacing * 6.25}px`,
    height: `${spacing * 6.25}px`,
    color: `${themeColors.white}`,
  },
  serviceTitle: {
    fontWeight: 700,
    fontSize: `${spacing * 3.5}px`,
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 2.75}px`,
    },
  },
  helpCenterTitle: {
    fontSize: `${spacing * 7}px`,
    fontWeight: 700,
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 5}px`,
    },
    fontFamily: `Atkinson Hyperlegible`,
  },
  helpCenterSubTitle: {
    fontSize: `${spacing * 2.75}px`,
    fontWeight: 400,
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 2}px`,
    },
  },
  helpCenterCardContainer: {
    mt: `${spacing * 3}px`,
    mb: `${spacing * 23}px`,
  },
  accountManagementHeader: {
    '&.MuiCardHeader-root': {
      paddingTop: `${spacing * 0.75}px`,
      paddingBottom: `${spacing * 0.75}px`,
    },
  },
  cardHeader: {
    '&.MuiCardHeader-root': {
      paddingBottom: `${spacing * 1.5}px`,
    },
  },
  accountManagementContent: {
    color: themeColors.tertiaryBlack,
    '&.MuiCardContent-root': {
      paddingTop: `${spacing * 0.75}px`,
      fontSize: `${spacing * 2}px`,
    },
  },
  cardContent: {
    color: themeColors.tertiaryBlack,
    '&.MuiCardHeader-root': {
      paddingBottom: `${spacing * 1.5}px`,
    },
    '&.MuiCardContent-root': {
      fontSize: `${spacing * 2}px`,
    },
  },
};

export default Styles;
