import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import UserInfo from '../userInfo/UserInfo.component';
import MailingAddressView from '../mailingAddress/view/MailingAddressView.component';
import TranscriptType from '../../../models/Transcript.model';
import {
  dataTestIds,
  EMPTY_STRING,
  requestTranscipt,
  Routes,
  US_COUNTRY_ID,
  screenBreakpoints,
  features,
} from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { sendTranscript } from '../redux/transcriptAsync.actions';
import { setTranscriptData as updateTranscriptData } from '../redux/transcriptSlice';
import Styles from './styles';
import { VariantButton, VariantTypography } from '../../../themes/properties';
import Institute from '../../../models/Institute.model';
import Feature from "../../../models/Feature.model";
import MailingAddressForm from '../../../common-components/mailing-address-form/MailingAddressForm.component';
import { Address } from '../../../models/Profile.model';
import { validateEmail } from '../../../utils/utils';
import { themeColors } from '../../../themes/colors';
import ViewCourses from '../../../common-components/transcriptCourses/ViewCourses.component';
import TranscriptInstructions from '../../../common-components/transcriptInstructions/TranscriptInstructions.component';
import { useNavigate } from 'react-router-dom';
import { getInstitutes } from '../../../store/appAsync.actions';
import Country from '../../../models/Country.model';
import State from '../../../models/State.model';
import { isTranscriptFeeFeatureEnable } from '../transcriptHelper';

const TranscriptForm = () => {
  const transcriptData: TranscriptType | null = useSelector(
    (state: RootState) => state.transcript.transcriptData,
  );

  const institutes: Array<Institute> | [] = useSelector(
    (state: RootState) => state.app.institutes,
  );

  const featuresFlags: Array<Feature> = useSelector(
      (state: RootState) => state.app.masterData?.features ?? [],
  );

  const customInstituteFeature = featuresFlags?.find(
      (feature) => feature.name === features.customInstitute,
  );

  const [isTranscriptDestinationDropdownFocused, setIsTranscriptDestinationDropdownFocused] = useState(false);
  const [isTranscriptDestinationDropdownEmpty, setIsTranscriptDestinationDropdownEmpty] = useState(false);

  const [transcriptDestinationAutocompleteOptions, setTranscriptDestinationAutocompleteOptions] = useState<Institute[]>([]);
  const [transcriptDestinationAutocompleteInputValue, setTranscriptDestinationAutocompleteInputValue] = useState('');

  const [selectedCollege, setSelectedCollege] = useState<Institute|null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);

  useEffect(() => {
    dispatch(getInstitutes());
  }, []);

  useEffect(() => {
    setTranscriptDestinationAutocompleteOptions([...institutes]);
  }, [institutes]);

  const shouldShowFeeMessage = useMemo(() => {
    return isTranscriptFeeFeatureEnable(transcriptData);
  }, [selectedCollege, transcriptData]);

  const countries = useSelector(
    (state: RootState) => state.app.masterData?.countries,
  );

  useEffect(() => {
    const newTrascriptData = {
      ...transcriptData,
      emailDestination: selectedCollege?.email ?? EMPTY_STRING,
      college: selectedCollege,
    };

    if (selectedCollege?.isPartner) {
      newTrascriptData.mailTranscript = false;
      newTrascriptData.emailTranscript = false;
      newTrascriptData.emailDestination = EMPTY_STRING;
    }
    newTrascriptData.mailAddress = new Address(
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        US_COUNTRY_ID,
    );

    const selectedCollegeAddress = selectedCollege?.addresses?.[0];
    if (selectedCollege?.isCustom && selectedCollegeAddress) {
      const countryStates: Array<State>
        = countries?.find((country: Country) => country.id == US_COUNTRY_ID)?.states ?? [];
      newTrascriptData.mailAddress = new Address(
        selectedCollegeAddress.address,
        selectedCollegeAddress.city,
        countryStates.find((state: State) => state.code == selectedCollegeAddress.state)?.id ?? EMPTY_STRING,
        selectedCollegeAddress.zip,
        US_COUNTRY_ID,
        selectedCollegeAddress.address2,
      );
    }

    dispatch(updateTranscriptData(newTrascriptData));
  }, [selectedCollege]);

  const handleFocus = () => {
    setIsTranscriptDestinationDropdownFocused(true);
  };

  const handleBlur = () => {
    setIsTranscriptDestinationDropdownFocused(false);
    setIsTranscriptDestinationDropdownEmpty(false);
    setTranscriptDestinationAutocompleteInputValue('');
  };

  const handleAddValue = () => {
    if(transcriptDestinationAutocompleteInputValue.trim().length === 0) {
      return;
    }

    const newInstitute = new Institute(
        transcriptDestinationAutocompleteInputValue, // it's a temporary front-end value, simply to ensure uniqueness of IDs
        transcriptDestinationAutocompleteInputValue,
        '',
        '',
        false,
        true,
        false,
        false,
        [],
    );

    setTranscriptDestinationAutocompleteOptions([
      newInstitute,
        ...transcriptDestinationAutocompleteOptions
    ]);
    setSelectedCollege(newInstitute);

    setIsTranscriptDestinationDropdownEmpty(false);
    setTranscriptDestinationAutocompleteInputValue(transcriptDestinationAutocompleteInputValue);
  };

  const onChangeCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target.name;
    const isChecked = event.target.checked;

    const newTrascriptData = { ...transcriptData, [name]: isChecked };
    dispatch(updateTranscriptData(newTrascriptData));
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target.name;
    const isChecked = event.target.value;

    const newTrascriptData = { ...transcriptData, [name]: isChecked };
    dispatch(updateTranscriptData(newTrascriptData));
  };

  // funtion to update the address in redux-store
  const onUpdateAddress = (address: Address) => {
    const newTrascriptData = { ...transcriptData, mailAddress: address };
    dispatch(updateTranscriptData(newTrascriptData));
  };

  const onUpdateMailAddress = (address: Address) => {
    const newTrascriptData = { ...transcriptData, address: address };
    dispatch(updateTranscriptData(newTrascriptData));
  };

  const isValidForm = (): boolean => {
    const address: Address | null = transcriptData?.address ?? null;
    const mailingAddress: Address | null = transcriptData?.mailAddress ?? null;

    if (
      ((transcriptData?.mailTranscript || transcriptData?.emailTranscript) &&
        !transcriptData?.college) ||
      (transcriptData?.college &&
        !transcriptData?.college?.isPartner &&
        !transcriptData?.mailTranscript &&
        !transcriptData?.emailTranscript)
    ) {
      return false;
    }

    const isValidRequiredFields =
      !transcriptData?.mailTranscript &&
      !transcriptData?.emailTranscript &&
      !transcriptData?.emailUnoffcialTranscript &&
      !transcriptData?.college?.isPartner;

    const isValidAddress =
      !address?.address ||
      !address?.city ||
      !address?.state ||
      !address.zip ||
      (address.country === US_COUNTRY_ID &&
        address.zip.length !==
          requestTranscipt.mailingAddress.maxLengths.zip) ||
      (address.country !== US_COUNTRY_ID &&
        (address.zip.length <
          requestTranscipt.mailingAddress.minLengths.zipNonUS ||
          address.zip.length >
            requestTranscipt.mailingAddress.maxLengths.zipNonUS));

    const isValidMailingAddress =
      transcriptData?.mailTranscript &&
      (!mailingAddress?.address ||
        !mailingAddress?.city ||
        !mailingAddress?.state ||
        !mailingAddress?.zip ||
        mailingAddress?.zip.length !==
          requestTranscipt.mailingAddress.maxLengths.zip);

    if (
      isValidRequiredFields ||
      isValidAddress ||
      isValidMailingAddress ||
      (transcriptData?.emailTranscript &&
        !validateEmail(transcriptData?.emailDestination))
    ) {
      return false;
    }
    return true;
  };

  const isValidDeliveryDestination = (): boolean => {
    return (
      (transcriptData?.mailTranscript ?? false) ||
      (transcriptData?.emailTranscript ?? false) ||
      (transcriptData?.emailUnoffcialTranscript ?? false) ||
      (transcriptData?.college?.isPartner ?? false)
    );
  };

  const isValidNonPartnerDeliveryDestination = (): boolean => {
    return (
      !transcriptData?.college ||
      transcriptData.college.isPartner ||
      transcriptData?.mailTranscript ||
      transcriptData?.emailTranscript
    );
  };

  const sendTranscriptHandler = () => {
    dispatch(sendTranscript());
  };

  const onCancelTranscript = () => {
    navigate(Routes.transcriptHistory);
  };

  const partnerCollegeView = (
    <Box>
      <Typography
        variant={VariantTypography.body1}
        data-testid={dataTestIds.transcriptForm.partnerCollegeHeader}
      >
        {requestTranscipt.partnerCollege.header}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Typography
          variant={VariantTypography.body1}
          data-testid={dataTestIds.transcriptForm.divPartnerCollege}
        >
          <strong>{selectedCollege?.name} </strong>
          {requestTranscipt.partnerCollege.message}
        </Typography>
      </Box>
    </Box>
  );

  const header = (
    <Box
      sx={Styles.boxHeaderText}
      data-testid={dataTestIds.transcriptForm.headerContainer}
    >
      <Typography
        sx={Styles.textHeader}
        data-testid={dataTestIds.transcriptForm.headerText}
      >
        {requestTranscipt.headers.sendTranscript}
      </Typography>
    </Box>
  );

  const schoolListView = (
    <Autocomplete
      sx={{
        ...Styles.schoolField,
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${
            (transcriptData?.mailTranscript ||
              transcriptData?.emailTranscript) &&
            !transcriptData?.college
              ? themeColors.warningDark
              : themeColors.grey
          }`,
        },
      }}
      size="small"
      options={transcriptDestinationAutocompleteOptions}
      getOptionLabel={(institute: Institute) => institute.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          key={option.id}
          sx={{ fontWeight: option.isPartner ? 700 : 400 }}
          data-testid={`${dataTestIds.transcriptForm.listOptions}${option.name}`}
        >
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            screenPhone
              ? requestTranscipt.transcriptDestinationMobile
              : requestTranscipt.transcriptDestination
          }
          inputProps={{
            ...params.inputProps,
            maxLength: 150
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name="school"
          data-testid={dataTestIds.schoolListView}
        />
      )}
      value={selectedCollege}
      onChange={(_, v) => setSelectedCollege(v)}
      filterOptions={(options, state) => {
        const inputValue = state.inputValue;
        const filteredOptions = options.filter(
          ({ name }) =>
            name != null &&
            name.toLowerCase().includes(inputValue.toLowerCase()),
        );

        setTranscriptDestinationAutocompleteInputValue(inputValue);
        setIsTranscriptDestinationDropdownEmpty(filteredOptions.length === 0);
        return filteredOptions;
      }}
      popupIcon={
        !isTranscriptDestinationDropdownFocused ? (
          <FontAwesomeIcon icon={faCaretDown} />
        ) : isTranscriptDestinationDropdownEmpty && customInstituteFeature?.enabled ? (
            <FontAwesomeIcon icon={faAdd} onClick={handleAddValue} title="Add a new transcript destination" />
        ) : (
            <FontAwesomeIcon icon={faCaretUp} />
        )
      }
      data-testid={dataTestIds.transcriptForm.listTextField}
    />
  );

  const emailAddressView = (
    <Box
      sx={{ ...Styles.boxAddress, mt: 2 }}
      data-testid={dataTestIds.transcriptForm.emailContainer}
    >
      <TextField
        sx={Styles.inputField}
        label={requestTranscipt.destinationEmailAddress}
        InputLabelProps={{ size: 'small' }}
        inputProps={{
          maxLength: requestTranscipt.mailingAddress.maxLengths.address,
        }}
        value={transcriptData?.emailDestination ?? EMPTY_STRING}
        name={requestTranscipt.fields.emailDestination}
        onChange={onChangeText}
        data-testid={dataTestIds.emailDesitnation}
      />
      {!transcriptData?.emailDestination && (
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.required}
          data-testid={dataTestIds.transcriptForm.textEmailRequired}
        >
          {requestTranscipt.required}
        </Typography>
      )}
      {transcriptData?.emailDestination &&
        !validateEmail(transcriptData.emailDestination) && (
          <Typography
            variant={VariantTypography.body2}
            sx={Styles.required}
            data-testid={dataTestIds.transcriptForm.textEmailError}
          >
            {requestTranscipt.errors.invalidEmail}
          </Typography>
        )}
    </Box>
  );

  const checkBoxMailTranscript = (
    <Box
      sx={Styles.boxCheckbox}
      data-testid={dataTestIds.transcriptForm.divMailContainer}
    >
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={
              <Box
                sx={{
                  ...Styles.checkboxIcon,
                  ...Styles.checkedboxIcon,
                }}
              />
            }
            icon={<Box sx={Styles.iconCheckBox} />}
            onChange={onChangeCheck}
            name={requestTranscipt.fields.mailTranscript}
            checked={transcriptData?.mailTranscript}
            data-testid={dataTestIds.transcriptForm.mailCheckbox}
            disabled={!transcriptData?.college}
          />
        }
        label={requestTranscipt.mailTranscript}
        data-testid={dataTestIds.transcriptForm.mailForm}
      />
    </Box>
  );

  const checkBoxEmailTranscript = (
    <Box
      sx={Styles.boxCheckbox}
      data-testid={dataTestIds.transcriptForm.divEmailContainer}
    >
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={
              <Box
                sx={{
                  ...Styles.checkboxIcon,
                  ...Styles.checkedboxIcon,
                }}
              />
            }
            icon={<Box sx={Styles.iconCheckBox} />}
            onChange={onChangeCheck}
            name={requestTranscipt.fields.emailTranscript}
            checked={transcriptData?.emailTranscript}
            data-testid={dataTestIds.transcriptForm.emailCheckbox}
            disabled={!transcriptData?.college}
          />
        }
        label={requestTranscipt.emailTranscript}
        data-testid={dataTestIds.transcriptForm.emailForm}
      />
    </Box>
  );

  const checkBoxEmailUnofficialCopy = (
    <Box
      sx={Styles.boxCheckbox}
      data-testid={dataTestIds.transcriptForm.diveUnofficialmailContainer}
    >
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={
              <Box
                sx={{
                  ...Styles.checkboxIcon,
                  ...Styles.checkedboxIcon,
                }}
              />
            }
            icon={<Box sx={Styles.iconCheckBox} />}
            onChange={onChangeCheck}
            name="emailUnoffcialTranscript"
            checked={transcriptData?.emailUnoffcialTranscript ?? false}
            data-testid={dataTestIds.transcriptForm.unofficialEmailCheckbox}
          />
        }
        label={requestTranscipt.emailUnofficial}
        data-testid={dataTestIds.transcriptForm.unofficialEmailForm}
      />
    </Box>
  );

  const bottomView = (
    <Box
      sx={Styles.boxContainerBottomView}
      data-testid={dataTestIds.transcriptForm.btnContainer}
    >
      <Button
        variant={VariantButton.contained}
        sx={{ ...Styles.button, ...Styles.buttonSubmit }}
        disabled={!isValidForm()}
        onClick={sendTranscriptHandler}
        data-testid={dataTestIds.transcriptForm.submitBtn}
      >
        {requestTranscipt.buttons.submitRequest}
      </Button>

      <Button
        variant={VariantButton.contained}
        sx={Styles.button}
        onClick={onCancelTranscript}
        data-testid={dataTestIds.transcriptForm.cancelBtn}
      >
        {requestTranscipt.buttons.cancel}
      </Button>
    </Box>
  );

  const transcriptFeeMessage = (
    <Box>
      <Typography
        sx={Styles.transcriptFee}
        data-testid={dataTestIds.transcriptForm.textfeeWarningMessage}
      >
        {requestTranscipt.transcriptFeeMessage}
      </Typography>
    </Box>
  );

  return (
    <Box>
      {/* Header */}
      <>{header}</>
      <Box
        sx={Styles.bodyContainer}
        data-testid={dataTestIds.transcriptForm.divBodyContainer}
      >
        <Box>
          <TranscriptInstructions
            isTranscriptFeeEnabled={
              transcriptData?.isFeeFeatureEnabled ?? false
            }
          />
        </Box>

        <Box sx={{ pl: 2 }}>
          <ViewCourses />
        </Box>
        <Box
          sx={Styles.viewHistoryMessage}
          data-testid={dataTestIds.transcriptForm.historyMessageContainer}
        >
          <Typography
            dangerouslySetInnerHTML={{
              __html: requestTranscipt.viewHistoryMessage,
            }}
            data-testid={dataTestIds.transcriptForm.textHistoryMessage}
          />
        </Box>

        <Box
          sx={Styles.boxContent}
          data-testid={dataTestIds.transcriptForm.personalInfoContainer}
        >
          {/* 1. Confirm your personal information for transcript  */}
          <Box>
            <Box sx={{ ...Styles.boxFlex, ...Styles.boxContentHeader }}>
              <Typography variant={VariantTypography.body1}>1.</Typography>
              <Typography
                variant={VariantTypography.body1}
                sx={Styles.textContentHeader}
                data-testid={dataTestIds.transcriptForm.personalInfoHeader}
              >
                {requestTranscipt.headers.personalInfo}
              </Typography>
            </Box>

            <UserInfo transcript={transcriptData} />
            <Box sx={{ mt: 1.5 }}>
              <MailingAddressView
                address={transcriptData?.address ?? null}
                onUpdateMailAddress={onUpdateMailAddress}
              />
            </Box>
          </Box>

          {/* 2. Select a transcript destination */}
          <Box>
            <Box sx={{ ...Styles.boxFlex, ...Styles.boxContentHeader }}>
              <Typography variant={VariantTypography.body1}>2.</Typography>
              <Typography
                variant={VariantTypography.body1}
                sx={Styles.textContentHeader}
                data-testid={dataTestIds.transcriptForm.textSelectDestination}
              >
                {requestTranscipt.headers.selectDestination}
              </Typography>
            </Box>
            {schoolListView}
          </Box>

          {/* 3. Provide Delivery Details for Destination */}
          <Box>
            <Box sx={{ ...Styles.boxFlex, ...Styles.boxContentHeader }}>
              <Typography variant={VariantTypography.body1}>3.</Typography>
              <Typography
                variant={VariantTypography.body1}
                sx={Styles.textContentHeader}
                data-testid={dataTestIds.transcriptForm.textDeliveryDestination}
              >
                {requestTranscipt.headers.deliveryDestination}
              </Typography>
            </Box>

            {!isValidDeliveryDestination() && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.error}
                data-testid={
                  dataTestIds.transcriptForm.errorDeliveryDestination
                }
              >
                {requestTranscipt.errors.deliveryDestination}
              </Typography>
            )}

            {!isValidNonPartnerDeliveryDestination() && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.error}
                data-testid={
                  dataTestIds.transcriptForm.errorDeliveryDestination
                }
              >
                {selectedCollege?.isCustom ? requestTranscipt.errors.newDeliveryDestination : requestTranscipt.errors.nonPartnerDeliveryDestination}
              </Typography>
            )}

            {selectedCollege?.isPartner && partnerCollegeView}

            {!selectedCollege?.isPartner && (
              <>
                <Box>
                  {checkBoxMailTranscript}
                  {transcriptData?.mailTranscript && (
                    <MailingAddressForm
                      address={transcriptData?.mailAddress ?? null}
                      onUpdateAddress={onUpdateAddress}
                      showAddressLineTwo={true}
                      hideCountry={true}
                    />
                  )}
                </Box>
                <Box>
                  {checkBoxEmailTranscript}
                  {transcriptData?.emailTranscript && emailAddressView}
                </Box>
              </>
            )}
            <Box>{checkBoxEmailUnofficialCopy}</Box>
          </Box>
        </Box>
      </Box>
      {shouldShowFeeMessage && transcriptFeeMessage}
      <>{bottomView}</>
    </Box>
  );
};

export default TranscriptForm;
