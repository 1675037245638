import { ContentType, interfaceType } from '../types';

export const aceTranscriptsData: ContentType = {
  header: 'ACE TRANSCRIPTS',
  subHeader: '',
  sections: [
    {
      title: 'Requesting an ACE Transcript',
      sections: [
        `<strong>New in 2020:</strong> To request your ACE transcript simply request a transcript to be sent, by email only, to “American Council on Education (ACE)” as the target college in our transcript request form.`,
        'Once you have selected “American Council on Education (ACE)” as your target college, choose any/all applicable courses to be transferred and then click to submit your transcript request for approval.',
        'By clicking choosing to send a transcript to the American Council on Education (ACE) you consent to StraighterLine sending the required account information to ACE and its applicable partners (Credly and the YourAcclaim portal) so that digital badges and subsequent ACE transcripts can be transferred.',
        'Please note that it may take 7-14 days for your requested ACE transcript to be approved. Once your courses have been approved you will be notified through the ACE/YourAcclaim digital badge portal and you will be able to send your transcript to your institution(s) at will.',
      ],
    },
    {
      title: 'ACE Transcript Fees',
      sections: [
        `<strong>New in 2020:</strong> There are no longer fees associated with requesting or sending transcripts through the American Council on Education (ACE).`,
      ],
    },
  ],
};
