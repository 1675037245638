import { Box, Typography, List, ListItem } from '@mui/material';
import {
  PointType,
  ListType,
  interfaceType,
  ImageType,
  ModalType,
} from '../../../assets/help-center-data/types';
import { VariantTypography, ListStyleType } from '../../../themes/properties';
import Styles from './styles';
import Image from './Image.component';
import Modal from './Modal.component';
import { useNavigate } from 'react-router-dom';

interface PropsType {
  data: PointType;
}

const Points = ({ data }: PropsType) => {
  const navigate = useNavigate();

  const getListStyle = (listType: string, tagType: string) => {
    switch (listType) {
      case ListType.Unordered:
        if (tagType === interfaceType.SUB_LIST_TYPE) {
          return ListStyleType.Circle;
        } else {
          return ListStyleType.Disc;
        }
      case ListType.UpperAlpha:
        return ListStyleType.UpperAlpha;
      case ListType.LowerAlpha:
        return ListStyleType.LowerAlpha;
      default:
        return null;
    }
  };

  //TODO- Prevent Page refresh
  // const contentClickHandler = (e: any) => {
  //   const targetLink = e.target.closest('a');
  //   if (!targetLink) return;
  //   e.preventDefault();
  //   navigate(targetLink.href.split('http://localhost:3000')[1]);
  //   console.log(targetLink.href); // this.props.history.push(e.target.href)
  // };

  const renderUI = (data: PointType) => {
    let title = null;
    if (data.type === interfaceType.POINT_TYPE) {
      title = (
        <Typography
          dangerouslySetInnerHTML={{ __html: data.title }}
          sx={Styles.textTableTitle}
          variant={VariantTypography.body1}
          // onClick={contentClickHandler}
        />
      );
    } else {
      title = (
        <List
          sx={{
            ...Styles.subList,
            listStyleType: getListStyle(data.listType, data.type),
          }}
        >
          <ListItem>
            <Typography
              dangerouslySetInnerHTML={{ __html: data.title }}
              sx={{ ...Styles.textTableTitle, mb: 0 }}
              variant={VariantTypography.body1}
            />
          </ListItem>
        </List>
      );
    }
    return (
      <Box>
        {data.title && <>{title}</>}

        <Box sx={{ ...Styles.boxPoint }}>
          <List
            sx={{
              ...Styles.listType,
              listStyleType: getListStyle(data.listType, data.type),
              '&.MuiList-root': {
                pt: data.type === interfaceType.SUB_LIST_TYPE ? 0 : 1,
              },
            }}
          >
            {data?.points?.map(
              (point: string | PointType | ImageType | ModalType) => {
                if (typeof point === 'string') {
                  return (
                    <ListItem key={point}>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: point }}
                        sx={Styles.textPoint}
                        variant={VariantTypography.body1}
                      />
                    </ListItem>
                  );
                } else if (point.type === interfaceType.SUB_LIST_TYPE) {
                  const points = point as PointType;
                  return <>{renderUI(points)}</>;
                } else if (point.type === interfaceType.IMAGE_TYPE) {
                  const pointImage = point as ImageType;
                  return <Image data={pointImage} />;
                } else if (point.type === interfaceType.MODAL_TYPE) {
                  const pointModal = point as ModalType;
                  return <Modal data={pointModal} />;
                }
                return null;
              },
            )}
          </List>
        </Box>
        {data.note && (
          <Typography
            dangerouslySetInnerHTML={{ __html: data.note }}
            sx={Styles.textPointNote}
            variant={VariantTypography.body1}
          />
        )}
      </Box>
    );
  };
  return <>{renderUI(data)}</>;
};

export default Points;
