import { requestTranscipt } from '../constants';
import Course from '../models/Course.model';
import { CourseStatus } from '../types/global';

export const openPage = (url: string, inNewTab: boolean) => {
  window.open(url, inNewTab ? '_blank' : '_self');
};

export const validateEmail = (email: string): boolean => {
  let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email);
};

const isCourseEligible = (course: Course) => {
  if (course.gradeValue) {
    const roundedGradeValue = Math.round(
      parseFloat(course.gradeValue.toFixed(1)) || 0,
    );
    return (
      course.status === CourseStatus.Complete &&
      roundedGradeValue &&
      roundedGradeValue >= requestTranscipt.minGradeValue &&
      !course.isPlagiarismViolation
    );
  }
  return false;
};

export const isTranscriptEligibleCourse = (course: Course) =>
isCourseEligible(course);

export const getTranscriptEligibleCourses = (courses: Array<Course>) =>
  courses?.filter(isCourseEligible);
