export const Properties = {
  visibility: { visible: 'visible', hidden: 'hidden' },
};

export enum Elements {
  span = 'span',
}

export enum Variant {
  permanent = 'permanent',
  persistent = 'persistent',
  temporary = 'temporary',
}

export enum VariantLinearProgress {
  determinate = 'determinate',
  indeterminate = 'indeterminate',
  buffer = 'buffer',
  query = 'query',
}

export enum VariantTypography {
  body1 = 'body1',
  body2 = 'body2',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

export enum VariantDivider {
  middle = 'middle',
}

export enum VariantButton {
  text = 'text',
  contained = 'contained',
  outlined = 'outlined',
}

export enum Orientation {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export enum Position {
  static = 'static',
}

export enum Origin {
  bottom = 'bottom',
  left = 'left',
  top = 'top',
  right = 'right',
}

export enum ListStyleType {
  Disc = 'disc',
  Circle = 'circle',
  UpperAlpha = 'upper-alpha',
  LowerAlpha = 'lower-alpha',
}

export enum SnackbarAnchorPositon {
  top = 'top',
  bottom = 'bottom',
  center = 'center',
}

export enum SnackbarAnchorSeverity {
  error = 'error',
  success = 'success',
}

export enum InputType {
  text = 'text',
  number = 'number',
}

export enum AlertVariant {
  filled = 'filled',
}

export enum ButtonSize {
  small = 'small',
}

export enum VariantMobileStepper {
  dots = 'dots',
}
