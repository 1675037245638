import { themeColors } from '../../themes/colors';
import { dimensions, screenWidth, spacing } from '../../themes/theme';
const Styles = {
  root: {
    backgroundColor: `${themeColors.white}`,
    color: `${themeColors.black}`,
    borderTop: `1px solid ${themeColors.grey}`,
    p: `${spacing * 4}px`,
    height: `${spacing * 20}px`,
    '@media (max-width: 1024px)': {
      py: 0,
      height: `${spacing * 36}px`,
    },
    '@media (max-width: 768px)': {
      py: 0,
      px: `${spacing * 2}px`,
      height: `${spacing * 43}px`,
    },
    '@media (max-width: 480px)': {
      py: 0,
      height: `${spacing * 48}px`,
    },
  },
  footerContainer: {
    backgroundColor: `${themeColors.white}`,
    zIndex: 3,
    color: `${themeColors.black}`,
    width: '100%',
    height: `100%`,
    mx: 'auto',
  },
  leftPanel: {
    display: 'flex',
    flex: 1,
  },
  leftPanelLogo: {
    display: 'flex',
    flex: 1,
  },
  leftPanelOptions: {
    textAlign: 'center',
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 'bold',
    mr: `${spacing * 3}px`,
    '&:last-child': {
      mr: 0,
    },
    '&:hover': {
      color: themeColors.secondary,
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '240px',
  },
  childCareLogo: {
    width: '50%',
    height: '50%',
  },
  copyright: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: `${spacing * 1.5}px`,
    mt: `${spacing}px`,
  },
  textYear: {
    fontSize: `${spacing * 1.75}px`,
    ml: `${spacing * 0.5}px`,
  },
  contactSupport: {
    display: 'flex',
    justifyContent: 'end',
    flex: 1,
  },
  socialMediaIconsContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flex: 1,
  },
  socialMediaIcons: {
    textAlign: 'right',
    fontSize: '18px',
    cursor: 'pointer',
    ml: `${spacing * 4}px`,
    '@media (max-width: 768px)': {
      m: 0,
    },
  },
  boxSlLogo: {
    my: `${spacing * 2}px`,
  },
  boxInfoLinks: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& .MuiTypography-root': {
      mb: `${spacing * 3}px`,
    },
  },
  boxChildCare: {
    textAlign: 'center',
    '@media (max-width: 768px)': {
      mb: `${spacing * 4}px`,
    },
  },
  boxSocialMedia: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '@media (max-width: 768px)': {
      mb: `${spacing * 10}px`,
    },
    '@media (max-width: 480px)': {
      paddingX: `${spacing * 0}px`,
    },
  },
  socialMediaIconsAndChidCare: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: `${spacing * 8}px`,
    '@media (max-width: 768px)': {
      p: 0,
    },
  },
};

export default Styles;
