import { TableContainer, Table as TableUI, TableBody } from '@mui/material';
import { useSelector } from 'react-redux';
import Course from '../../../models/Course.model';
import { RootState } from '../../../store';
import Header from './Header.component';
import Row from './Row.component';
import { getTranscriptEligibleCourses } from '../../../utils/utils';

const Table = () => {
  const courses: Array<Course> = useSelector(
    (state: RootState) => state.dashboard.courses,
  );

  const courseList: Array<Course> = getTranscriptEligibleCourses(courses);

  const dataRows = courseList?.map((course: Course) => <Row data={course} />);
  return (
    <TableContainer>
      <TableUI
        sx={{ minWidth: 650, mt: 1, bgcolor: 'white' }}
        aria-label="simple table"
      >
        <Header />
        <TableBody>{dataRows}</TableBody>
      </TableUI>
    </TableContainer>
  );
};

export default Table;
