import { Box, Typography, useMediaQuery } from '@mui/material';
import Transcript from '../../../models/Transcript.model';
import {
  EMPTY_STRING,
  dataTestIds,
  requestTranscipt,
  screenBreakpoints,
} from '../../../constants';
import { VariantTypography } from '../../../themes/properties';
import Styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { formatData, dateFormats } from '../../../utils/dateUtils';

interface Props {
  transcript: Transcript | null;
}

const UserInfo = ({ transcript }: Props) => {
  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);
  return (
    <Box
      sx={Styles.root}
      data-testid={dataTestIds.transcriptComponent.userInfo.divContainer}
    >
      <Box
        sx={{
          ...Styles.flexBox,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ ...Styles.flexBox, my: 0 }}>
          <Typography
            variant={VariantTypography.body1}
            sx={Styles.textKey}
            data-testid={dataTestIds.transcriptComponent.userInfo.textNameKey}
          >
            {requestTranscipt.userInfo.name}
          </Typography>
          <Typography
            variant={VariantTypography.body1}
            sx={Styles.textValue}
            data-testid={dataTestIds.transcriptComponent.userInfo.textNameValue}
          >
            {`${transcript?.firstName ?? EMPTY_STRING} ${
              transcript?.lastName ?? EMPTY_STRING
            }`}
          </Typography>
        </Box>
        <Box
          sx={Styles.iconLock}
          data-testid={dataTestIds.transcriptComponent.userInfo.iconLock}
        >
          <FontAwesomeIcon icon={faLock} />
        </Box>
      </Box>
      <Box sx={{ ...Styles.flexBox, display: screenPhone ? 'block' : 'flex' }}>
        <Typography
          variant={VariantTypography.body1}
          sx={Styles.textKey}
          data-testid={dataTestIds.transcriptComponent.userInfo.textEmailKey}
        >
          {requestTranscipt.userInfo.email}
        </Typography>
        <Typography
          variant={VariantTypography.body1}
          sx={{
            ...Styles.textValue,
            ml: screenPhone ? 0 : Styles.textValue.ml,
          }}
          data-testid={dataTestIds.transcriptComponent.userInfo.textEmailValue}
        >
          {transcript?.email}
        </Typography>
      </Box>
      <Box sx={Styles.flexBox}>
        <Typography
          variant={VariantTypography.body1}
          sx={Styles.textKey}
          data-testid={dataTestIds.transcriptComponent.userInfo.textDobKey}
        >
          {requestTranscipt.userInfo.dob}
        </Typography>
        <Typography
          variant={VariantTypography.body1}
          sx={Styles.textValue}
          data-testid={dataTestIds.transcriptComponent.userInfo.textDobValue}
        >
          {transcript?.dob
            ? formatData(transcript?.dob, dateFormats.MMDDYYYY_SLASH)
            : EMPTY_STRING}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserInfo;
