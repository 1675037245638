import {Box, Button, Typography} from '@mui/material';
import {dashboardRightPanel, dataTestIds} from '../../../constants';
import {VariantButton, VariantTypography} from '../../../themes/properties';
import Styles from './Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faPersonChalkboard,
  faCalendarDays,
  faChartLineUp,
  faFileImport
} from '@fortawesome/pro-regular-svg-icons';
import React from "react";

const PurchaseCourseToday = () => {
  const redirectTo = (link: string) => {
    window.open(link, '_self');
  };

  const contentItems = [
    { text: 'eTextbooks', icon: faBook },
    { text: 'On-demand tutoring', icon: faPersonChalkboard },
    { text: 'Resubmit quizzes & essays', icon: faChartLineUp },
    { text: 'Transfer courses for college credit', icon: faFileImport },
    { text: 'Student support 7 days a week', icon: faCalendarDays }
  ]

  const rows = contentItems.map(
    (contentItem) => (
      <Box sx={Styles.row} key={contentItem.text}>
        <Box
          sx={Styles.contentItemIcon}
        >
          <FontAwesomeIcon icon={contentItem.icon} />
        </Box>
        <Typography
          variant={VariantTypography.body1}
          sx={Styles.contentItemText}
        >
          {contentItem.text}
        </Typography>
      </Box>
    )
  );

  return (
    <Box
      sx={Styles.root}
      data-testid={dataTestIds.rightPanel.purchaseCourseToday.divParent}
    >
      <Box
        sx={Styles.header}
        data-testid={dataTestIds.rightPanel.purchaseCourseToday.header}
      >
        <Typography variant={VariantTypography.body2} sx={Styles.textHeader}>
          {dashboardRightPanel.purchaseCourseToday.header}
        </Typography>
      </Box>
      <Box
        sx={Styles.contentItems}
        data-testid={dataTestIds.rightPanel.purchaseCourseToday.content}
      >
        {rows}
      </Box>
      <Box
        sx={Styles.actionContainer}
        data-testid={dataTestIds.rightPanel.purchaseCourseToday.actionContainer}
      >
        <Button
          variant={VariantButton.contained}
          sx={Styles.button}
          onClick={() => redirectTo(dashboardRightPanel.purchaseCourseToday.button.link)}
        >
          {dashboardRightPanel.purchaseCourseToday.button.text}
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseCourseToday;
