import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  root: {
    position: 'absolute',
    top: 0,
    bgcolor: themeColors.white,
    zIndex: 2,
    mb: 1,
    pl: 1,
    width: '75%',
    '@media (max-width: 1200px)': {
      width: '98%',
    },
  },
  containerBox: { display: 'flex' },
  boxAvatar: {
    pt: 2,
    position: 'relative',
    height: spacing * 6,
    width: spacing * 6,
    mr: `${spacing * 2}px`,
  },
  namBoxContainer: { py: 2 },
  nameText: { fontSize: 14, fontWeight: 700, textTransform: 'uppercase' },
  divider: {
    borderStyle: 'dashed',
    height: 32,
    my: 'auto',
    ml: 3,
    '@media (max-width: 400px)': { ml: 1 },
  },
  courseBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 400px)': { ml: 1 },
  },
  courseInnerBox: { display: 'flex', alignItems: 'center' },
  courseIcon: { color: themeColors.secondary },
  courseNameText: {
    ml: 1,
    fontSize: 16,
    fontWeight: 700,
    color: themeColors.secondary,
  },
};

export default Styles;
