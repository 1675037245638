import { Box, Typography } from '@mui/material';
import Styles from './Styles';
import { VariantTypography } from '../../themes/properties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faShoePrints } from '@fortawesome/pro-solid-svg-icons';

interface PropsType {
  title: string;
  onClose: () => void;
}

const Header = ({ title, onClose }: PropsType) => (
  <Box sx={Styles.boxHeader}>
    <Box sx={Styles.iconsShpePrints}>
      <FontAwesomeIcon icon={faShoePrints} />
    </Box>
    <Typography variant={VariantTypography.body1} sx={Styles.txtTutorialHeader}>
      Tutorial:
    </Typography>
    <Typography
      variant={VariantTypography.body2}
      sx={Styles.txtTutorialHeaderValue}
    >
      {title}
    </Typography>
    <Box sx={Styles.iconClose} onClick={onClose}>
      <FontAwesomeIcon icon={faXmark} />
    </Box>
  </Box>
);

export default Header;
