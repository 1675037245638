import { helpCenterImagesUrl } from "../../constants";

export const orientationAndTutorials = {
  header: 'Orientation & Tutorials',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  proctoring: [
    {
      title: 'Scheduling Your Proctoring',
      bannerImage: helpCenterImagesUrl.BackOfCourseCard_Proctoring_Schedule,
      description:
        'How to schedule your proctoring sessions from your student dashboard.',
      modalData: [
        {
          image: helpCenterImagesUrl.CourseCard_Proctoring_Schedule,
          description:
            'Login to your Dashboard and click the center of the course card for the exam you wish to schedule. As an alternative, you may click on the calendar icon.'
        },
        {
          image: helpCenterImagesUrl.BackOfCourseCard_Proctoring_Schedule,
          description:
            'Click "Schedule Proctoring" on the back of the course card.',
          note: '<strong>Note: This page will open in a new window. Make sure your Pop-Up blocker is turned off</strong>',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_tbd.png',
          description: `Read and accept ProctorU's Terms of Service and Privacy Policy.`,
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_03.png',
          description:
            'Navigate back to "Home" and Click the "Schedule" button next to the course you wish to schedule proctoring for.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_04.png',
          description:
            'Select your preferred date and time for your exam and click “Find Available Times."',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_05.png',
          description:
            'ProctorU will provide available times to select from. Click “SELECT” for the time slot you would like to schedule.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_06.png',
          description:
            'Click “SCHEDULE” to confirm, or select a different time.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_07.png',
          description:
            'The session will add to your cart (there is no fee for sessions scheduled 72 hours in advance). ProctorU charges a small fee for expedited scheduling. Click “Process Order” to proceed, entering in your credit card information if needed.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_08.png',
          description:
            'You will get a confirmation screen once the session is scheduled. You have successfully completed the scheduling of your exam and will receive an email notification with your registration information.',
        },
      ],
    },
    {
      title: 'Starting Your Proctoring Session on Exam Day',
      bannerImage: helpCenterImagesUrl.Proctoring_Start,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum.',
      modalData: [
        {
          image: helpCenterImagesUrl.CourseCard_Proctoring_Schedule,
          description:
            'Login to your Dashboard and click the center of the course card for the exam you wish to schedule. As an alternative, you may click on the calendar icon.',
        },
        {
          image: helpCenterImagesUrl.BackOfCourseCard_Proctoring_Schedule,
          description:
            'Click on the proctoring link, ensuring your pop-up blocker is turned off.',
        },
        {
          image: helpCenterImagesUrl.Proctoring_Start,
          description:
            'Click the “Start Session” button. If you are a little early, you will see a countdown to the exact time of your session. You will now be launched into your proctoring session and your proctor will Walk you through the steps needed to start your exam. You will need to open your course content and navigate to your exam in a new tab in the proctoring browser.',
        },
      ],
    },
    {
      title: 'Enabling Accessibility Settings for Proctoring',
      bannerImage:
        'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_tbd2.png',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum.',
      modalData: [
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_tbd2.png',
          description:
            'Navigate to the Account Settings page by Clicking your Name at the top of the page.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/proctoru_3.png',
          description:
            'On the Account Settings page, scroll down and check the box under “Accessibility” and click “Update Account” at the bottom of the page.',
        },
      ],
    },
  ],
  billing: [
    {
      title: 'Cancel Your StraighterLine Membership',
      bannerImage: helpCenterImagesUrl.Membership_Billing_Preferences,
      description:
        'How to schedule your proctoring sessions from your student dashboard.',
      modalData: [
        {
          image: helpCenterImagesUrl.Membership_Billing_Preferences,
          description:
            'From your Dashboard, select Account Settings, and then select Billing Preferences from the Account Settings Menu.',
        },
        {
          image: helpCenterImagesUrl.Membership_Cancel,
          description:
            'You can fill out the Billing Settings form to cancel your membership. Select "Billing and Membership Services" as the Case Reason, and note that you want to cancel your membership in the "Description" field.',
        },
        {
          image: helpCenterImagesUrl.Membership_Confirmation,
          description:
            'You will receive an email from StraighterLine within 24-48 hours, confirming your membership cancellation.',
        },
      ],
    },
    // {
    //   title: 'Renew Your StraighterLine Membership',
    //   bannerImage:
    //     'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_renew_membership_02.png',
    //   description:
    //     'How to schedule your proctoring sessions from your student dashboard.',
    //   modalData: [
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_renew_membership_01.png',
    //       description:
    //         'Login to your MyLine Dashboard and click Billing Preferences from the User Menu.',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_renew_membership_02.png',
    //       description: 'Click “Renew Membership".',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_renew_membership_03.png',
    //       description: `A $99 Monthly Membership will be added to your cart. Click “CHECKOUT NOW” to continue with the Membership Renewal.`,
    //       note: '<strong>Note: If your previous Monthly Membership had not expired yet, this step is not required and you will receive a confirmation message. Your Membership will not expire and will continue to rebill on your original billing cycle and payment method.</strong>',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_renew_membership_04.png',
    //       description:
    //         'Enter your desired payment method and complete the checkout process by clicking “CONTINUE TO CHECKOUT REVIEW”. This payment method will also be used for future membership payments each month.',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_renew_membership_05.png',
    //       description:
    //         'Review and confirm your payment, agree to the StraighterLine Terms and Conditions and click “PLACE ORDER”.',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_renew_membership_06.png',
    //       description:
    //         'Once your payment is successfully processed, a confirmation message will appear and you will receive an email with your order invoice. Please note that future membership payments will be processed on the payment method used on your next billing date.',
    //     },
    //   ],
    // },
    // {
    //   title: 'Update Your Credit Card',
    //   bannerImage:
    //     'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_update_creditcard_03.png',
    //   description:
    //     'How to schedule your proctoring sessions from your student dashboard.',
    //   modalData: [
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_update_creditcard_01.png',
    //       description:
    //         'Login to your MyLine Dashboard and click Billing Preferences from the User Menu.',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_update_creditcard_02.png',
    //       description:
    //         'Under Address & Payment Information, click “UPDATE CARD”. This will open a new window with the credit card form.',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_update_creditcard_03.png',
    //       description:
    //         'Enter in your new payment information and click “UPDATE CARD INFO”. This payment method will be used for future membership payments each month and will not be charged until your Next Billing Date.',
    //     },
    //     {
    //       image:
    //         'https://s3.amazonaws.com/straighterline-web/images/help-center/billingTutorials/tutorial_update_creditcard_04.png',
    //       description:
    //         'Once your credit card is updated, a confirmation message will appear.',
    //     },
    //   ],
    // },
  ],
  writtenAssignments: [
    {
      title: 'Submit an Assignment',
      bannerImage: helpCenterImagesUrl.Course_Assignments_Submit,
      description:
        'How to schedule your proctoring sessions from your student dashboard.',
      modalData: [
        {
          image: helpCenterImagesUrl.Course_Resume,
          description:
            'From your student dashboard, re-enter your course by selecting the "Resume Course" button or click on the course card to view a list of course topics.',
        },
        {
          image: 'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_02.png',
          description:
            'Navigate to and open the assignment in your course that you would like to submit.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_03.png',
          description: 'Click the assignment that you want to submit.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_04.png',
          description:
            'Provide a title for your submission, upload the file, accept the terms, and click “Add Submission”',
          note: '<strong>Note: Once a submission is uploaded, you will not be able to change or edit the assignment. Please be sure to verify that all work is complete before upload.</strong>',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_submit_assignment_05.png',
          description:
            'Your assignment has successfully been uploaded to Turn It In for grading.',
        },
      ],
    },
    {
      title: 'Open Your Graded Assignment',
      bannerImage:
        'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_04.png',
      description:
        'How to schedule your proctoring sessions from your student dashboard.',
      modalData: [
        {
          image: helpCenterImagesUrl.Course_Resume,
          description:
            'From your student dashboard, re-enter your course by selecting the "Resume Course" button or click on the course card to view a list of course topics.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_02.png',
          description:
            'Navigate to and open the assignment in your course that you would like to review.',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_03.png',
          description: 'Click the tab titled “My Submissions”',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_04.png',
          description:
            'This brings you to your submission inbox for the assignment. Click the blue pencil next to your score to access the assignment in Turn It In.',
          note: '<strong>Note: This link will open in a new window. Please turn off any pop-up blockers in your browser.</strong>',
        },
        {
          image:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/academicsTutorials/tutorial_graded_assignments_05.png',
          description: 'Your assignment will open in Turn It In.',
        },
      ],
    },
  ],
  constants: {
    proctoringTutorials: 'Proctoring Tutorials',
    billingTutorials: ' Billing Tutorials',
    writtenAssignmentsTutorials: 'Written Assignments Tutorials',
  },
};
