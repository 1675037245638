import { Routes, transcriptFeeAmount } from '../../../constants';
import { ContentType, ListType, interfaceType } from '../types';

const getRequestTranscriptPoints = (isFeeFeatureEnabled: boolean) => {
  const points = [
    `Go to the <a href="${window.location.origin}${Routes.transcript}" title="Transcripts">Transcripts</a> page in your <a href="${window.location.origin}" title="MyLine Dashboard">Dashboard</a>`,
    `Click the button to create a new transcript request`,
    `Follow the instructions in the form and click Submit`,
    `If a fee is required, you will be taken to the payment screen to enter and confirm your payment details`,
  ];
  return points;
};

const getTranscriptFeeTimeMessage = (isFeeFeatureEnabled: boolean) => {
  const paid = `StraighterLine does not charge for transcripts sent to participating partner schools or via ACE (American Council on Education) or unofficial transcripts sent to the student's email address. There is a ${transcriptFeeAmount} fee per transcript request for any other destination.`;

  const free = `StraighterLine does not charge for transcripts.`;
  const message =
    (isFeeFeatureEnabled ? paid : free) +
    ` All transcripts are processed in the order they are received. Please note, transcript requests may take up to 7 business days for processing. Electronic Transcripts, which are sent via email, are transmitted upon approval. This processing time does not include transit time if a physical transcript is being sent via USPS.`;

  return message;
};

export const getCreditTransferData = (
  isFeeFeatureEnabled: boolean,
): ContentType => {
  return {
    header: 'Credit Transfer',
    subHeader: '',
    sections: [
      {
        title: '<a id="hc_ct_eligibility"></a>Credit Transfer Eligibility',
        sections: [
          {
            type: interfaceType.POINT_TYPE,
            listType: ListType.Unordered,
            title:
              'In order for your course to be eligible for transfer, it must meet the following conditions:',
            points: [
              `Your course must be marked as “Complete” in our systems. Even if you have completed all your required assignments, some courses require a grader to review the final assignments to mark the course as complete.`,
              `Your final grade must be greater than a 70%`,
              `There cannot be an <a href="/help-center/credit-transfer/credit-transfer#hc_ct_academic_holds" title="Academic Holds">academic hold</a> on the course`,
            ],
          },

          `If your school accepts credit for a StraighterLine course with a grade below our 70% required average, please contact a Student Advisor for assistance with your transcript. StraighterLine can manually process a transcript on your behalf if your school can provide documentation that it will accept a grade below a 70% for transfer. To initiate this process, please submit a <a href="${window.location.origin}/help-center/support-request" title="Support Request">Support Request</a> and include the documentation provided by your school and a Student Advisor will follow-up to your inquiry.`,
        ],
      },
      {
        title: '<a id="hc_ct_request_transcript"></a>Request a Transcript',
        sections: [
          {
            type: interfaceType.POINT_TYPE,
            listType: ListType.Unordered,
            title: `To request a transcript for your <a href="/help-center/credit-transfer/credit-transfer/#hc_ct_eligibility" title="Credit Transfer Eligibility">eligible course(s):`,
            points: getRequestTranscriptPoints(isFeeFeatureEnabled),
          },

          `Please note, transcript requests may take up to 7 business days for processing. Electronic Transcripts, which are sent via email, are transmitted upon approval. This processing time does not include transit time if a physical transcript is being sent via USPS.`,
        ],
      },
      {
        title: 'Transcript Status',
        sections: [
          `You can check the status of a Transcript at any time from your <a href="${window.location.origin}${Routes.transcriptHistory}" title="Transcript Request History">Transcript Request History</a> page in your <a href="${window.location.origin}" title="MyLine Dashboard">MyLine Dashboard.</a>`,
        ],
      },
      {
        title: 'Transcript Fees and Processing Time',
        sections: [getTranscriptFeeTimeMessage(isFeeFeatureEnabled)],
      },
      {
        title: '<a id="hc_ct_academic_holds"></a>Academic Holds',
        sections: [
          `Students with unresolved <a href="${window.location.origin}/help-center/student-handbook/privacy-policy" title="Academic Honesty Policy">Academic Honesty Policy</a> (AHP) issues are ineligible for transcripts until all AHP holds are cleared and StraighterLine has deemed them to be in good standing. If you have an academic hold on a course, please contact a Student Advisor for more information.`,
        ],
      },
      {
        title: 'Transferability of Credit',
        sections: [
          'StraighterLine does not award academic credit itself. All credits awarded are transferable through your college or university. Students wishing to confirm transferability of course credit should contact the institution that will receive the transferred credit; they should confirm that the chosen institution will accept credit from the StraighterLine for the course(s) they intend to complete. StraighterLine, along with colleges and universities, cannot guarantee the transferability of credit between regionally accredited institutions.',
        ],
      },
      {
        title: 'American Council on Education (ACE)',
        sections: [
          `The American Council on Education's College Credit Recommendation Service (CREDIT) has evaluated and recommended all of StraighterLine's courses. More than 2,000 colleges and universities consider ACE CREDIT recommendations in determining the applicability to their course and degree programs. Our students also report that they've connected with more than 400 colleges outside of our partner network that have recognized StraighterLine courses.`,
          `If you plan on transferring StraighterLine courses outside of our partner college network, follow the steps outlined in our ACE Credit Transfer Checklist. We strongly recommend that you understand the school’s credit-transfer process before purchasing a StraighterLine course to make sure you won't have any problems.`,
          `Ready to transfer your StraighterLine courses? Learn how to <a href="/help-center/credit-transfer/ace/" title="Request an ACE Transcript.">Request an ACE Transcript.</a>`,
        ],
      },
      {
        title: 'CLEP, AP, and Other Standardized Tests for Credit',
        sections: [
          {
            type: interfaceType.POINT_TYPE,
            listType: ListType.Unordered,
            title: `Many individuals have successfully earned college credit by taking a standardized test, which is accepted for credit at many regionally accredited universities. These tests usually require additional fees and are usually administered at a testing center. Although StraighterLine does not offer these tests, courses taken through StraighterLine will prepare students seeking a test-for-credit option. As with transferability, StraighterLine cannot guarantee individual scores or credit acceptance. For more information about these tests, please visit:`,
            points: [
              `CLEP - <a href="http://www.collegeboard.com/student/testing/clep/about.html" title="http://www.collegeboard.com/student/testing/clep/about.html" target="_blank">http://www.collegeboard.com/student/testing/clep/about.html</a>`,
              `AP - <a href="http://www.collegeboard.com/student/testing/ap/about.html" title="http://www.collegeboard.com/student/testing/ap/about.html" target="_blank">http://www.collegeboard.com/student/testing/ap/about.html</a>`,
            ],
          },
        ],
      },
      {
        title: 'Legacy Courses',
        sections: [
          `If you completed a StraighterLine course prior to 12/12/2012 and would like to request a transcript or need assistance in obtaining an ACE transcript, please contact a Student Advisor for assistance. To initiate this process, submit a <a href="${window.location.origin}/help-center/support-request" title="Support Request">Support Request</a> and include your course name, the address of the recipient (or your ACE account number) and a Student Advisor will follow-up to your inquiry.`,
        ],
      },
    ],
  };
};

// export const creditTransferData: ContentType = {
//   header: 'Credit Transfer',
//   subHeader: '',
//   sections: [
//     {
//       title: '<a id="hc_ct_eligibility"></a>Credit Transfer Eligibility',
//       sections: [
//         {
//           type: interfaceType.POINT_TYPE,
//           listType: ListType.Unordered,
//           title:
//             'In order for your course to be eligible for transfer, it must meet the following conditions:',
//           points: [
//             `Your course must be marked as “Complete” in our systems. Even if you have completed all your required assignments, some courses require a grader to review the final assignments to mark the course as complete.`,
//             `Your final grade must be greater than a 70%`,
//             `There cannot be an <a href="/help-center/credit-transfer/credit-transfer#hc_ct_academic_holds" title="Academic Holds">academic hold</a> on the course`,
//           ],
//         },

//         `If your school accepts credit for a StraighterLine course with a grade below our 70% required average, please contact a Student Advisor for assistance with your transcript. StraighterLine can manually process a transcript on your behalf if your school can provide documentation that it will accept a grade below a 70% for transfer. To initiate this process, please submit a <a href="${window.location.origin}/help-center/support-request" title="Support Request">Support Request</a> and include the documentation provided by your school and a Student Advisor will follow-up to your inquiry.`,
//       ],
//     },
//     {
//       title: '<a id="hc_ct_request_transcript"></a>Request a Transcript',
//       sections: [
//         {
//           type: interfaceType.POINT_TYPE,
//           listType: ListType.Unordered,
//           title: `To request a transcript for your <a href="/help-center/credit-transfer/credit-transfer/#hc_ct_eligibility" title="Credit Transfer Eligibility">eligible course(s):`,
//           points: [
//             `Go to the <a href="${window.location.origin}${Routes.transcript}" title="Transcripts">Transcripts</a> page in your <a href="${window.location.origin}" title="MyLine Dashboard">Dashboard</a>`,
//             `Click the button to create a new transcript request`,
//             `Follow the instructions in the form and click Submit`,
//             `If a fee is required, you will be taken to the payment screen to enter and confirm your payment details`,
//           ],
//         },

//         `Please note, transcript requests may take up to 7 business days for processing. Electronic Transcripts, which are sent via email, are transmitted upon approval. This processing time does not include transit time if a physical transcript is being sent via USPS.`,
//       ],
//     },
//     {
//       title: 'Transcript Status',
//       sections: [
//         `You can check the status of a Transcript at any time from your <a href="${window.location.origin}${Routes.transcriptHistory}" title="Transcript Request History">Transcript Request History</a> page in your <a href="${window.location.origin}" title="MyLine Dashboard">MyLine Dashboard.</a>`,
//       ],
//     },
//     {
//       title: 'Transcript Fees and Processing Time',
//       sections: [
//         `StraighterLine does not charge for transcripts sent to participating partner schools or via ACE (American Council on Education) or unofficial transcripts sent to the student's email address. There is a ${transcriptFeeAmount} fee per transcript request for any other destination. All transcripts are processed in the order they are received. Please note, transcript requests may take up to 7 business days for processing. Electronic Transcripts, which are sent via email, are transmitted upon approval. This processing time does not include transit time if a physical transcript is being sent via USPS.`,
//       ],
//     },
//     {
//       title: '<a id="hc_ct_academic_holds"></a>Academic Holds',
//       sections: [
//         `Students with unresolved <a href="${window.location.origin}/help-center/student-handbook/privacy-policy" title="Academic Honesty Policy">Academic Honesty Policy</a> (AHP) issues are ineligible for transcripts until all AHP holds are cleared and StraighterLine has deemed them to be in good standing. If you have an academic hold on a course, please contact a Student Advisor for more information.`,
//       ],
//     },
//     {
//       title: 'Transferability of Credit',
//       sections: [
//         'StraighterLine does not award academic credit itself. All credits awarded are transferable through your college or university. Students wishing to confirm transferability of course credit should contact the institution that will receive the transferred credit; they should confirm that the chosen institution will accept credit from the StraighterLine for the course(s) they intend to complete. StraighterLine, along with colleges and universities, cannot guarantee the transferability of credit between regionally accredited institutions.',
//       ],
//     },
//     {
//       title: 'American Council on Education (ACE)',
//       sections: [
//         `The American Council on Education's College Credit Recommendation Service (CREDIT) has evaluated and recommended all of StraighterLine's courses. More than 2,000 colleges and universities consider ACE CREDIT recommendations in determining the applicability to their course and degree programs. Our students also report that they've connected with more than 400 colleges outside of our partner network that have recognized StraighterLine courses.`,
//         `If you plan on transferring StraighterLine courses outside of our partner college network, follow the steps outlined in our ACE Credit Transfer Checklist. We strongly recommend that you understand the school’s credit-transfer process before purchasing a StraighterLine course to make sure you won't have any problems.`,
//         `Ready to transfer your StraighterLine courses? Learn how to <a href="/help-center/credit-transfer/ace/" title="Request an ACE Transcript.">Request an ACE Transcript.</a>`,
//       ],
//     },
//     {
//       title: 'CLEP, AP, and Other Standardized Tests for Credit',
//       sections: [
//         {
//           type: interfaceType.POINT_TYPE,
//           listType: ListType.Unordered,
//           title: `Many individuals have successfully earned college credit by taking a standardized test, which is accepted for credit at many regionally accredited universities. These tests usually require additional fees and are usually administered at a testing center. Although StraighterLine does not offer these tests, courses taken through StraighterLine will prepare students seeking a test-for-credit option. As with transferability, StraighterLine cannot guarantee individual scores or credit acceptance. For more information about these tests, please visit:`,
//           points: [
//             `CLEP - <a href="http://www.collegeboard.com/student/testing/clep/about.html" title="http://www.collegeboard.com/student/testing/clep/about.html" target="_blank">http://www.collegeboard.com/student/testing/clep/about.html</a>`,
//             `AP - <a href="http://www.collegeboard.com/student/testing/ap/about.html" title="http://www.collegeboard.com/student/testing/ap/about.html" target="_blank">http://www.collegeboard.com/student/testing/ap/about.html</a>`,
//           ],
//         },
//       ],
//     },
//     {
//       title: 'Legacy Courses',
//       sections: [
//         `If you completed a StraighterLine course prior to 12/12/2012 and would like to request a transcript or need assistance in obtaining an ACE transcript, please contact a Student Advisor for assistance. To initiate this process, submit a <a href="${window.location.origin}/help-center/support-request" title="Support Request">Support Request</a> and include your course name, the address of the recipient (or your ACE account number) and a Student Advisor will follow-up to your inquiry.`,
//       ],
//     },
//   ],
// };
