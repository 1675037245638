import { hexToRGB, themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

export default {
  boxContent: {
    px: `${spacing * 3}px`,
    mt: `${spacing * 4}px`,
  },
  rootBottom: { mt: `${spacing * 8}px` },
  textNoTranscript: { fontWeight: 400, fontSize: `${spacing * 2.75}px` },
  textEligibility: {
    fontWeight: 400,
    fontSize: `${spacing * 2}px`,
    mt: `${spacing * 3}px`,
  },
  boxHeaderText: { ml: `${spacing * 3}px`, flexGrow: 1 },
  textHeader: {
    color: themeColors.blackLight,
    fontSize: `${spacing * 7}px`,
    fontWeight: '700',
    lineHeight: '110%',
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 4.5}px`,
      fontWeight: '900',
    },
  },
  boxContainerBottomView: {
    px: `${spacing * 2}px`,
    my: `${spacing * 3}px`,
  },
  button: {
    bgcolor: themeColors.white,
    border: `1px solid ${themeColors.black}`,
    color: themeColors.black,
    borderRadius: '50px',
    mr: `${spacing * 1.25}px`,
    '&:disabled': {
      border: `1px solid ${hexToRGB(themeColors.blueLight, 0.5)}`,
      color: hexToRGB(themeColors.blueLight, 0.5),
      background: themeColors.white,
    },
    '&:hover': {
      bgcolor: themeColors.white,
    },
    fontSize: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': {
      width: '100%',
      mb: `${spacing * 1.5}px`,
    },
    '&.MuiButtonBase-root': {
      fontWeight: 900,
    },
  },
};
