import { spacing } from '../../../themes/theme';

export default {
  rootTableHeader: {
    width: '100%',
    '& .MuiTableCell-root': {
      fontWeight: 700,
      fontSize: `${spacing * 2}px`,
    },
  },
  rowStatus: {
    textTransform: 'uppercase',
    width: '60%',
    textAlign: 'center',
    py: `${spacing * 0.5}px`,
    fontSize: `${spacing * 1.5}px`,
    borderRadius: `${spacing * 1}px`,
    fontWeight: 600,
  },
  boxFlex: {
    display: 'flex',
  },
  textCourseId: { fontWeight: 700, mr: `${spacing * 1.5}px` },
  textDate: { textAlign: 'center', mr: 3 },
  textGrade: { textAlign: 'right', mr: 1.5 },
  headerCourse: {
    '&.MuiTableCell-root': {
      width: '65%',
      '@media (max-width: 1440px)': { width: '65%' },
      '@media (max-width: 1200px)': { width: '60%' },
      '@media (max-width: 1024px)': { width: '55%' },
      '@media (max-width: 768px)': { width: '60vw' },
      '@media (max-width: 480px)': { width: '80vw' },
    },
  },
  headerCompletionDate: {
    '&.MuiTableCell-root': {
      width: '20%',
      '@media (max-width: 1440px)': { width: '20%' },
      '@media (max-width: 1200px)': { width: '22.5%' },
      '@media (max-width: 1024px)': { width: '25%' },
      '@media (max-width: 768px)': { width: '30vw' },
      '@media (max-width: 480px)': { width: '40vw' },
      '@media (max-width: 400px)': { width: '50vw' },
    },
  },
  headerGrade: {
    '&.MuiTableCell-root': {
      width: '15%',
      '@media (max-width: 1200px)': { width: '17.5%' },
      '@media (max-width: 1024px)': { width: '20%' },
      '@media (max-width: 768px)': { width: '30vw' },
      '@media (max-width: 480px)': { width: '40vw' },
    },
  },
};
