import {
  Paper,
  TableContainer,
  Table as TableUI,
  TableBody,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { GetTranscriptResponse } from '../../../types/contract';
import Header from './Header.component';
import Row from './Row.component';

const Table = () => {
  const transcriptData: Array<GetTranscriptResponse> = useSelector(
    (state: RootState) => state.transcriptHistory.transcriptData,
  );

  const dataRows = transcriptData?.map((data: GetTranscriptResponse) => (
    <Row data={data} />
  ));
  return (
    <TableContainer component={Paper}>
      <TableUI sx={{ minWidth: 650, mt: 1 }} aria-label="simple table">
        <Header />
        <TableBody>{dataRows}</TableBody>
      </TableUI>
    </TableContainer>
  );
};

export default Table;
