export enum CourseStatus {
  Complete = 'Complete',
  Progress = 'In Progress',
  NotStarted = 'Not Started',
}

export enum FilterCourse {
  AllCourses = 'All Courses',
  Progress = 'In Progress',
  Completed = 'Completed',
}

export interface TutorialStepModelType {
  image: string;
  description: string;
  note?: string;
}

export interface TutorialStepCardType {
  title: string;
  bannerImage: string;
  modalData: Array<TutorialStepModelType>;
}

export enum ProctorProvider {
  Moodle = 'moo',
  Acrobatiq = 'acq',
}

export enum LTIProviders {
  Acrobatiq = 'acrobatiq',
}

export enum Providers {
  lti = 'lti',
}

export enum MasterDataEntity {
  All = 'all',
  Country = 'country',
  CourseProgram = 'courseProgram',
  HighestEducation = 'highestEducation',
  ReferralType = 'referralType',
  Features = 'features',
}

export enum LearnerType {
  FREE_TRIAL = 'freeTrial',
  LEARNER = 'learner'
}
