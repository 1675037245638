import { Routes, dataTestIds } from '../../constants';
import {
  faHouse,
  faFileCertificate,
  faCircleQuestion,
  faGear,
  faBell,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface MenuType {
  name: string | null;
  icon: IconProp;
  route: string;
  nameTestId: string;
  iconTestId: string;
}

export const menus: Array<MenuType> = [
  {
    name: 'My Courses',
    icon: faHouse,
    route: Routes.home,
    nameTestId: dataTestIds.leftNavbar.myCoursesName,
    iconTestId: dataTestIds.leftNavbar.myCoursesIcon,
  },
  {
    name: 'Transcript',
    icon: faFileCertificate,
    route: Routes.transcriptHistory,
    nameTestId: dataTestIds.leftNavbar.transcriptName,
    iconTestId: dataTestIds.leftNavbar.transcriptIcon,
  },
  {
    name: 'Help Center',
    icon: faCircleQuestion,
    route: Routes.helpCenter,
    nameTestId: dataTestIds.leftNavbar.helpCenterName,
    iconTestId: dataTestIds.leftNavbar.helpCenterIcon,
  },
  {
    name: 'Account',
    icon: faGear,
    route: Routes.accountSettings,
    nameTestId: dataTestIds.leftNavbar.accountsName,
    iconTestId: dataTestIds.leftNavbar.accountIcon,
  },
  {
    name: 'Support Requests',
    icon: faBell,
    route: `${Routes.helpCenter}/${Routes.supportRequest}?support`,
    nameTestId: dataTestIds.leftNavbar.supportName,
    iconTestId: dataTestIds.leftNavbar.supportIcon,
  },
];
