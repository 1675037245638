import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';
const Styles = {
  root: {
    '@media (max-width: 400px)': {
      ml: 0,
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      border: 'none',
      overflow: 'clip',
      height: 'fit-content',
      position: 'static',
      float: 'left',
      '@media (min-width: 1200px)': {
        pl: `${spacing * 2}px`,
      },
      '@media (max-width: 1200px)': {
        pl: `${spacing * 1}px`,
      },
    },
  },
  boxAvatar: {
    pt: 2,
    position: 'relative',
    height: spacing * 6,
    width: spacing * 6,
    mx: 'auto',
    mb: 4,
  },

  divider: {
    width: '70%',
    mx: 'auto',
    mb: 2.5,
    borderStyle: 'dashed',
  },
  boxMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 1,
  },
  boxMenuIcon: {
    fontSize: spacing * 2.25,
    height: spacing * 2.5,
    width: spacing * 2.5,
    textAlign: 'center',
    padding: `${spacing * 1.5}px`,
    borderRadius: '50%',
    mt: 1,
    cursor: 'pointer',
    '&:hover': { bgcolor: themeColors.secondary, color: themeColors.white },
    '&:hover + .MuiListItemText-root': {
      visibility: 'visible',
    },
    transition: '0.75s',
    '& .svg-inline--fa': {
      height: spacing * 2.5,
      width: spacing * 2.5,
    },
  },
  menuText: {
    '& .MuiTypography-root': {
      textAlign: 'center',
      fontSize: spacing * 1.5,
      fontWeight: 700,
      '@media (max-width: 950px)': {
        fontSize: spacing * 1.25,
      },
      '@media (max-width: 820px)': {
        fontSize: spacing * 1,
      },
    },
  },
  menuListItem: {
    flexDirection: 'column',
  },
  bellIcon: {
    fontSize: spacing * 2.25,
    height: spacing * 6,
    width: spacing * 6,
    textAlign: 'center',
    padding: `${spacing * 1.25}px`,
    borderRadius: '50%',
    mt: 3,
    cursor: 'pointer',
    '&:hover': {
      color: themeColors.secondary,
    },
    '&:hover + .MuiListItemText-root': {
      visibility: 'visible',
    },
    transition: '0.75s',
    bgcolor: themeColors.white,
    color: themeColors.black,
  },
  boxMenuList: {
    '@media (max-width: 1024px)': {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 1,
      dispay: 'flex',
      bgcolor: themeColors.whiteNavbar,
    },
  },
  menuList: {
    '@media (max-width: 1024px)': {
      display: 'flex',
      '& .MuiTypography-root': {
        display: 'none',
      },
    },
  },
};

export default Styles;
