import { useEffect, useMemo, useState } from 'react';
import { Grid, useMediaQuery, Box } from '@mui/material';
import CourseCard from '../../common-components/cource-card/CourseCard.component';
import Course from '../../models/Course.model';
import ProfileHeader from '../profileHeader/ProfileHeader.component';
import CourseCardStatus from '../courseStatus/CourseStatus.component';
import CourseModal from '../courseModal/CourseModal.component';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Style from './Dashboard.style';
import Profile from '../../models/Profile.model';
import { CourseStatus, FilterCourse, LearnerType } from '../../types/global';
import RightPanel from '../../common-components/rightPanel/RightPanel.component';
import {
  onBoardingConstants,
  screenBreakpoints,
  dataTestIds,
} from '../../constants';
import Styles from './Dashboard.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { spacing } from '../../themes/theme';
import RenewMembership from './renew-membership/RenewMembership.component';
import { getTargetInstitute } from '../../store/appAsync.actions';
import AddTrialCoursesDialog from '../../common-components/free-trial/AddTrialCoursesDialog.component';
import OnboardingDialog from '../../common-components/welcome-onboarding-dialog/OnboardingDialog.component';
import CourseCardFreeTrial from '../../common-components/cource-card/CourseCardFreeTrial.component';
import CourseCardPending from '../../common-components/cource-card/CourseCardPending.component';

const Dashboard = () => {
  const screenLaptopLarge = useMediaQuery(
    `(max-width:${screenBreakpoints.laptopLarge})`,
  );

  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);

  let widthRightPanel = `${spacing * 40}px`;
  if (screenPhone) {
    widthRightPanel = '100%';
  }

  const [showDrawer, setShowDrawer] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const selectedFilter = useSelector(
    (state: RootState) => state.dashboard.filterCoureCard,
  );
  const courses = useSelector((state: RootState) => state.dashboard.courses);

  const showRenewMembershipModal = useSelector(
    (state: RootState) => state.dashboard.showRenewMembershipModal,
  );

  const profile: Profile | null = useSelector(
    (state: RootState) => state.profile.profile,
  );

  const isUserFreeTrial: boolean = useMemo(
    () => profile?.learnerType === LearnerType.FREE_TRIAL,
    [profile?.learnerType],
  );

  const showTrialCoursesDialog: boolean = useSelector(
    (state: RootState) => state.dashboard.showFreeTrialAddCoursesModal,
  );

  const onBoardingStatus = useSelector(
    (state: RootState) => state.profile.profile?.onBoardingStatus,
  );

  useEffect(() => {
    profile?.targetCollege &&
      !profile.isCollegeUndecided &&
      dispatch(
        getTargetInstitute({
          targetCollege: profile?.targetCollege,
        }),
      );
  }, [profile]);

  // const courseProviderHandlerForModal = (course: Course | null) => {
  //   if (!course) {
  //     return;
  //   }
  //   if (isAcrobatiqCourse(course.cwpCode)) {
  //     acrobatiqProviderHandler(course, true);
  //   } else {
  //     moodleProviderHandler(
  //       course.courseIdentifier,
  //       course.resourceLinkId,
  //       true,
  //     );
  //   }
  // };

  // const courseProviderHandler = (course: Course) => {
  //   return isAcrobatiqCourse(course.cwpCode)
  //     ? acrobatiqProviderHandler(course, false)
  //     : moodleProviderHandler(
  //         course.courseIdentifier,
  //         course.resourceLinkId,
  //         true,
  //       );
  // };

  let filteredCourses: Array<Course> = courses;
  if (selectedFilter === FilterCourse.Progress) {
    filteredCourses = filteredCourses.filter(
      (course: Course) => course.status === CourseStatus.Progress,
    );
  } else if (selectedFilter === FilterCourse.Completed) {
    filteredCourses = filteredCourses.filter(
      (course: Course) => course.status === CourseStatus.Complete,
    );
  }

  const courseList = filteredCourses.map((course: Course) => (
    <Grid item xl={4} md={6} lg={6} sm={6} xs={12} key={course.id}>
      {isUserFreeTrial ? (
        <CourseCardFreeTrial course={course} />
      ) : course.enrollmentStatus === 'Pending' && !course.isActive ? (
        <CourseCardPending course={course} />
      ) : (
        <CourseCard profile={profile} course={course} />
      )}
    </Grid>
  ));

  let rightPanel = null;
  if (!screenLaptopLarge) {
    rightPanel = (
      <Grid item lg={3} xs={0}>
        <RightPanel isUserFreeTrial={isUserFreeTrial} />
      </Grid>
    );
  } else {
    rightPanel = (
      <>
        <Box
          sx={{
            ...Styles.rightPanelAnchor,
            right: showDrawer ? (screenPhone ? 0 : `${spacing * 39}px`) : 0,
            zIndex: screenPhone && showDrawer ? 0 : 5,
          }}
          onClick={() => setShowDrawer(!showDrawer)}
          data-testid={dataTestIds.dashboard.divIconPlay}
        >
          <FontAwesomeIcon
            icon={faPlay}
            color="green"
            transform={{ rotate: showDrawer ? 0 : 180 }}
            fontSize="10px"
            data-testid={dataTestIds.dashboard.iconPlay}
          />
        </Box>
        <Box
          sx={{
            ...Styles.boxRightPanel,
            width: showDrawer ? widthRightPanel : 0,
          }}
          data-testid={dataTestIds.dashboard.divRightPanel}
        >
          <RightPanel
            onClose={() => setShowDrawer(false)}
            isOpen={showDrawer}
            isUserFreeTrial={isUserFreeTrial}
          />
        </Box>
      </>
    );
  }

  return (
    <Box position="relative" data-testid={dataTestIds.dashboard.divContainer}>
      {showRenewMembershipModal && <RenewMembership />}
      <ProfileHeader profile={profile} isUserFreeTrial={isUserFreeTrial} />
      <CourseCardStatus isFreeTrial={isUserFreeTrial} />
      <Grid container sx={Styles.gridContainer}>
        <CourseModal isUserFreeTrial={isUserFreeTrial} />
        <Grid
          item
          lg={9}
          xs={12}
          container
          spacing={2}
          rowSpacing={4}
          sx={Style.root}
        >
          {courseList}
        </Grid>

        {rightPanel}
      </Grid>
      {(!isUserFreeTrial &&
        onBoardingStatus &&
        onBoardingStatus !== onBoardingConstants.status.completed && (
          <OnboardingDialog status={onBoardingStatus} profile={profile} />
        )) ||
        (isUserFreeTrial && showTrialCoursesDialog && (
          <AddTrialCoursesDialog enrolledCourses={courses} />
        ))}
    </Box>
  );
};

export default Dashboard;
