import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  textLabel: {
    fontWeight: 700,
  },
  formContainer: {
    mt: `${spacing * 2}px`,
  },
  formControl: {
    width: '70%',
    mt: `${spacing * 1.5}px`,
    '& .Mui-disabled': {
      '& fieldset': {
        border: 'none',
      },
      bgcolor: themeColors.lightBlue,
    },
    '& .MuiPaper-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      whiteSpace: 'initial',
    },
    '@media (max-width: 1200px)': { width: '72%' },
    '@media (max-width: 1024px)': { width: '90%' },
    '@media (max-width: 768px)': { width: '100%' },
  },
  required: {
    fontWeight: 400,
    color: themeColors.warningDark,
    ml: `${spacing * 0.5}px`,
  },
  boxUndecidedCollege: {
    mt: `${spacing * 1.5}px`,
  },
  checkUndecidedIcon: {
    borderRadius: 1,
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundColor: themeColors.midGrey,
  },
  checkedUndecidedIcon: {
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    backgroundColor: themeColors.darkBlue,
  },
  styleInputLabel:{
    fontWeight: 700
  }
};
