import { themeColors, hexToRGB } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';
export default {
  root: {
    position: 'relative',
    paddingTop: `${spacing * 10}px`,
    width: '75%',
    '@media (max-width: 1200px)': {
      width: '80%',
    },
    '@media (max-width: 1024px)': {
      width: '95%',
    },
    '@media (max-width: 480px)': {
      paddingTop: `${spacing * 4}px`,
    },
  },
  boxFlex: {
    display: 'flex',
  },
  boxHeaderText: {
    ml: `${spacing * 3}px`,
    flexGrow: 1,
    '@media (max-width: 480px)': {
      ml: `${spacing * 1}px`,
    },
  },
  textHeader: {
    color: themeColors.blackLight,
    fontSize: `${spacing * 7}px`,
    fontWeight: '700',
    lineHeight: '110%',
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 4.5}px`,
      fontWeight: '900',
    },
  },

  iconClose: {
    alignSelf: 'center',
    cursor: 'pointer',
  },

  boxContent: {
    pt: `${spacing * 2}px`,
    pl: `${spacing * 4}px`,
    '@media (max-width: 480px)': { pl: 1 },
  },
  boxContentHeader: {
    '& .MuiTypography-root': {
      fontWeight: 900,
    },
    my: `${spacing * 3}px`,
    pl: `${spacing * 0.5}px`,
  },

  textContentHeader: {
    ml: `${spacing * 1.25}px`,
  },

  schoolField: {
    width: '100%',
    mt: `${spacing * 3}px`,
  },

  boxButtonsBottomView: {
    width: 'fit-content',
    '@media (max-width: 480px)': {
      width: '100%',
    },
  },
  button: {
    bgcolor: themeColors.white,
    color: themeColors.black,
    border: `1px solid ${themeColors.black}`,
    borderRadius: '50px',
    mr: `${spacing * 1.25}px`,

    fontSize: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': {
      marginBottom: `${spacing * 1.5}px`,
      width: '100%',
    },
    '&.MuiButtonBase-root': {
      fontWeight: 900,
    },
  },
  buttonSubmit: {
    backgroundImage: `${themeColors.greenGradient}`,
    border: 'none',
    color: themeColors.white,
    '&:disabled': {
      border: `1px solid ${hexToRGB(themeColors.blueLight, 0.5)}`,
      color: hexToRGB(themeColors.blueLight, 0.5),
      background: themeColors.white,
    },
  },

  boxCheckbox: {
    pl: `${spacing * 0.5}px`,
    mt: `${spacing * 1.5}px`,
    '& .MuiFormControlLabel-root': {
      mr: 0,
    },
    mb: `${spacing * 2}px`,
  },
  checkboxIcon: {
    borderRadius: 1,
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundColor: themeColors.midGrey,
  },
  checkedboxIcon: {
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    backgroundColor: themeColors.darkBlue,
  },
  iconCheckBox: {
    borderRadius: 1,
    width: `${spacing * 2}px`,
    height: `${spacing * 2}px`,
    backgroundColor: themeColors.midGrey,
  },
  boxAddress: {
    width: '100%',
    '@media (max-width: 1024px)': { width: '60%' },
    '@media (max-width: 768px)': { width: '100%' },
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      height: `${spacing * 2.5}px`,
      padding: `${spacing * 1.25}px ${spacing * 2}px`,
      borderRadius: `${spacing * 0.5}px`,
    },
    '& .Mui-disabled': {
      '& fieldset': {
        border: 'none',
      },
      bgcolor: themeColors.lightBlue,
    },
    '& .MuiInputLabel-shrink': {
      fontWeight: 700,
    },
  },
  boxContainerBottomView: {
    height: '100%',
    pl: `${spacing * 7}px`,
    marginTop: `${spacing * 1}px`,
    marginBottom: `${spacing * 8}px`,
    '@media (max-width: 480px)': { pl: 1 },
  },
  required: {
    fontWeight: 400,
    color: themeColors.warningDark,
    ml: `${spacing * 0.5}px`,
  },
  error: {
    fontWeight: 400,
    color: themeColors.warningDark,
    ml: `${spacing * 3.25}px`,
  },
  bodyContainer: {
    paddingLeft: 3,
    mt: 4,
    '@media (max-width: 480px)': { pl: 1 },
  },
  viewHistoryMessage: {
    maxWidth: '100%',
    pl: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: `${spacing * 1.75}px`,
    fontWeight: 400,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
  transcriptFee: {
    color: themeColors.warning,
    paddingLeft: 8,
    fontSize: '14px',
    pt: 8,
  },
};
