import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  boxList: { my: `${spacing * 2}px`, pl: `${spacing * 0.5}px` },
  list: { listStyle: 'disc', pl: `${spacing * 3}px`, py: 0 },
  listItem: {
    maxWidth: '90%',
    display: 'list-item',
    p: 0,
    fontSize: `${spacing * 1.75}px`,
    fontWeight: 400,
    '@media (max-width: 1440px)': { maxWidth: '80%' },
    '@media (max-width: 1200px)': { maxWidth: '100%' },
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
};
