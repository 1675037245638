import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  root: {
    display: 'flex',
  },
  form: {
    width: `100%`,
  },
  label: {
    display: 'block',
    marginTop: `${spacing * 1.5}px`,
    marginBottom: `${spacing * 0.25}px`,
  },
  input: {
    width: '30%',
    height: `${spacing * 3}px`,
  },
  select: {
    width: '30.5%',
    height: `${spacing * 4}px`,
  },
  textArea: {
    width: '30%',
    height: `${spacing * 10}px`,
  },
  button: {
    width: '31%',
    height: `${spacing * 4}px`,
    marginTop: `${spacing * 1.5}px`,
    cursor: 'pointer',
    backgroundColor: themeColors.primary,
    color: themeColors.white,
    border: 'none',
    borderRadius: `${spacing * 0.5}px`,
    fontSize: `${spacing * 2}px`,
  },
};
