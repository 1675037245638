import { Typography, useMediaQuery } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import { themeColors } from '../../../themes/colors';
import { VariantTypography } from '../../../themes/properties';
import { spacing } from '../../../themes/theme';
import {
  EMPTY_STRING,
  onBoardingConstants,
  screenBreakpoints,
} from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const AppStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: themeColors.grey,
    display: 'flex',
    height: spacing * 5,
    alignItems: 'center',
    ...(ownerState.active && {
      color: themeColors.primary,
    }),
    '& .filled': {
      width: spacing * 1.5,
      height: spacing * 1.5,
      borderRadius: '50%',
      backgroundColor: themeColors.primary,
      padding: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .filledCompleted': {
      width: spacing * 1.5,
      height: spacing * 1.5,
      borderRadius: '50%',
      backgroundColor: themeColors.secondary,
      padding: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .circle': {
      width: spacing * 1.5,
      height: spacing * 1.5,
      borderRadius: '50%',
      border: `1px solid ${themeColors.primary}`,
      padding: 1,
    },
    '& .title': {
      color: 'black',
      marginLeft: spacing,
      fontSize: `${spacing * 1.5}px`,
      fontWeight: 900,
    },
  }),
);
const AppStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props;
  const screenIPad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);

  const stepNumber = icon as number;

  const activeView = <div className="filled"></div>;

  const completedView = (
    <div className="filledCompleted">
      <FontAwesomeIcon
        icon={faCheck}
        style={{ color: 'white', fontSize: 10 }}
      />
    </div>
  );

  const pendingView = <div className="circle" />;

  const showLabel = !screenIPad
    ? onBoardingConstants.steps[stepNumber - 1]
    : active
    ? onBoardingConstants.steps[stepNumber - 1]
    : EMPTY_STRING;

  return (
    <>
      <AppStepIconRoot ownerState={{ active }} className={className}>
        {active ? activeView : completed ? completedView : pendingView}
        <Typography className="title" variant={VariantTypography.body1}>
          {showLabel}
        </Typography>
      </AppStepIconRoot>
    </>
  );
};

export default AppStepIcon;
