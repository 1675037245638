import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';
const Styles = {
  root: {
    mt: 13,
    p: 1.5,
    display: 'flex',
    '& .MuiGrid-root': {
      display: 'flex',
    },
    '@media (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiGrid-root': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '@media (max-width: 900px)': {
      mt: 18,
    },
    bg: 'white',
  },
  gridContainer: {
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: 0,
    },
    alignItems: 'start',
  },
  rightPanelAnchor: {
    position: 'fixed',
    top: `${spacing * 11}px`,
    bgcolor: 'white',
    zIndex: 5,
    borderTopLeftRadius: `${spacing * 1}px`,
    borderBottomLeftRadius: `${spacing * 1}px`,
    px: `${spacing * 1}px`,
    py: `${spacing * 2}px`,
    transition: 'all 0.5s ease-out',
    boxShadow:
      '0 -5px 5px -8px #333, 0 5px 5px -8px #333, -5px 0 5px -5px #333',
    display: 'flex',
    height: `${spacing * 3}px`,
    alignItems: 'center',
  },
  boxRightPanel: {
    position: 'fixed',
    top: `${spacing * 8 + 1}px`,
    right: 0,
    bgcolor: themeColors.white,
    zIndex: 4,
    transition: 'all 0.5s ease-out',
    boxShadow:
      '-2px 0px 8px 3px rgba(0, 0, 0, 0.05), -1px 0px 3px rgba(0, 0, 0, 0.15)',
  },
};

export default Styles;
