import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Profile from '../../models/Profile.model';
import { AppDispatch, RootState } from '../../store';
import { getTranscriptEligibleCourses } from '../../utils/utils';
import { setTranscriptData } from './redux/transcriptAsync.actions';
import { resetApiState, setPageState } from './redux/transcriptSlice';
import TranscriptForm from './transcriptForm/TranscriptForm.component';
import NoTranscript from './noTranscript/NoTranscript.component';
import SuccessTranscript from './successTranscript/SuccessTranscript.component';
import Styles from './styles';
import { Box } from '@mui/system';
import { API_STATE } from '../../constants';

const Transcript = () => {
  const profile: Profile | null = useSelector(
    (state: RootState) => state.profile.profile,
  );

  const { courses, courseApiState } = useSelector(
    (state: RootState) => state.dashboard,
  );

  const { showTranscriptForm, showNoTranscriptPage, apiState } = useSelector(
    (state: RootState) => state.transcript,
  );

  const { masterData } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (courses && profile) {
      const eligibleCourses = getTranscriptEligibleCourses(courses);
      if (eligibleCourses.length > 0) {
        dispatch(
          setPageState({
            showTranscriptForm: true,
            showNoTranscriptPage: false,
          }),
        );
        dispatch(setTranscriptData());
      } else {
        dispatch(
          setPageState({
            showTranscriptForm: false,
            showNoTranscriptPage: true,
          }),
        );
      }
    }
    dispatch(resetApiState());
  }, [profile, courses, masterData]);

  return (
    <Box sx={Styles.root}>
      {(showTranscriptForm || courseApiState === API_STATE.pending) &&
        apiState !== API_STATE.success && <TranscriptForm />}
      {showNoTranscriptPage && <NoTranscript />}
      {apiState === API_STATE.success && <SuccessTranscript />}
    </Box>
  );
};

export default Transcript;
