import { spacing } from '../../themes/theme';

const Styles = {
  container: {
    display: 'flex',
    gap: `${spacing * 1.25}px`,
  },
};

export default Styles;
