import { ContentType, ListType, interfaceType } from '../types';
export const CourseDesign: ContentType = {
  header: 'Course Design and Instructional Approach',
  subHeader: '',
  sections: [
    {
      title: 'Role of Faculty',
      sections: [
        `StraighterLine Faculty are duly credentialed experts, having earned a master’s degree or higher in their field of study. This expertise allows them to serve as subject matter experts (SMEs), helping StraighterLine to create innovative courses–using the most up-to-date pedagogical practices and technologies related to online, self-directed learning. Faculty are not assigned to instruct within the course, as courses are designed to be asynchronous and self-paced. Instead, they serve as graders in courses, interacting with students through tailored assessment feedback promoting critical thinking, high expectations, and scaffolded success. Finally, Faculty help StraighterLine to uphold the highest standards for academic integrity and honesty, guiding students in the creation of authentic and original assignments.`,
      ],
    },
    {
      title: 'Course Design and Development',
      sections: [
        `Our approach to instructional design is as follows:`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: '',
          points: [
            `We provide equitable, inclusive, accessible, self-paced learning environments for all students.`,
            `We provide learning experiences that are well-organized and thoughtfully designed.`,
            `We rely on evidence-based instructional principles and strategies to align learning outcomes with learning assessments, activities, and content.`,
            `We combine online technologies with learning outcomes to amplify the effectiveness of student learning experiences–for all students. `,
          ],
        },
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: '</br>We value the following pedagogical tenets:',
          points: [
            `A dynamic syllabus with clear and critical learning outcomes, concrete expectations and clear assessments.`,
            `Essential instructional outcomes.`,
            `A clear and navigable course structure.`,
            `Clear explanations of the purpose of each course activity and how it connects to essential learning outcomes.`,
            `Intentionally designed asynchronous educational activities and learning tools.`,
            `Meaningful, personalized feedback for qualitative assessments driven by robustly qualified and trained faculty.`,
            `Tested quantitative assessments designed to carefully measure course outcomes: low-stakes, open book and high-stakes, proctored exams.`,
            `A culture of learning and engagement that includes:`,
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.LowerAlpha,
              title: ``,
              points: [
                `Interactive course materials`,
                `Multiple modes of instruction`,
                `Opportunities for 1:1 formative feedback (Tutoring, Writing Center, Faculty asynchronous feedback)`,
                `In-house tools: scholarly source database, course roadmap and pacing guide, professor-led videos, clear rubrics and assignment exemplars`,
              ],
            },
            `Scaffolded assignments with anticipatory activities, reflections, thought-provoking questions, etc. all designed to promote critical thinking and rigor.`,
            `Multiple opportunities to succeed.`,
            `A Support Services team to help and guide learners' ability to navigate their learning journey.`,
          ],
        },
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            '</br>Our courses, while all different and driven by their respective disciplines, follow a general instructional format: we embrace Gagné’s Nine Events of Instruction following this format: ',
          points: [
            `Gain Attention of the Learners`,
            `Inform Learners of the Course Outcomes and Learning Objectives per Lesson Topic`,
            `Stimulate a Prior Connection with Content`,
            `Present the Content`,
            `Provide Learners with Instructional Support `,
            `Encourage Practice`,
            `Provide Feedback`,
            `Assess Performance`,
            `Opportunity to Further Connect Knowledge`,
          ],
        },
      ],
    },
    {
      title: 'Course Sunsetting and Migration',
      sections: [
        `To keep course offerings current, StraighterLine’s courses are reviewed and revised on a 1-3 year cycle. Review and revision decisions are based on numerous factors: discipline or educational trends, best pedagogical practices, faculty guidance/recommendations, content renewal, quality-checks, etc. StraighterLine courses are self-paced and self-directed; thus, there is no official “end” to a course.</br></br>`,

        `When a new version of a course is released, students in the prior version will have 120 days to complete their course. If, at the end of 120 days, a student has not completed their course, they will be offered the opportunity to upgrade to the newest version at no additional cost. However, once the 120 day sunset window has passed, no additional progress can be made on the retired version and students will need to start over on the new version.
        `,
      ],
    },
    {
      title: 'Course Sunsetting and Migration Definitions',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: '',
          points: [
            `<strong>Course Version or Section:</strong> The terms version and section are used interchangeably.`,
            `<strong>Course Sunsetting or Retirement:</strong> A process occurring when one section of a course closes and another opens.`,
            `<strong>Course Migration:</strong> A process involving moving students from a closing version or section of a course to the new version or section of a course.`,
          ],
        },
      ],
    },
  ],
};
