import { localStorageConstants } from '../constants';
import jwt_decode from 'jwt-decode';
import Profile from '../models/Profile.model';
import { MasterDataType } from '../store/app.slice';
import Institute from '../models/Institute.model';

export const storeToken = (token: string) =>
  localStorage.setItem(localStorageConstants.token, token);

export const storeAccessToken = (accessToken: string) =>
  localStorage.setItem(localStorageConstants.accessToken, accessToken);

export const getToken = () => localStorage.getItem(localStorageConstants.token);

export const getAccessToken = () =>
  localStorage.getItem(localStorageConstants.accessToken);

export const parseToken = (token: string): string => jwt_decode(token);
export const parseAccessToken = (accessToken: string): string =>
  jwt_decode(accessToken);

export const storeProfile = (profile: Profile) =>
  localStorage.setItem(localStorageConstants.profile, JSON.stringify(profile));

export const getProfile = (): Profile | null => {
  const profileValue: string | null = localStorage.getItem(
    localStorageConstants.profile,
  );
  if (profileValue) {
    const profile: Profile = JSON.parse(profileValue) as Profile;
    return profile;
  } else {
    return null;
  }
};

export const storeMasterData = (data: MasterDataType) =>
  localStorage.setItem(localStorageConstants.masterData, JSON.stringify(data));

export const getMasterData = (): MasterDataType | null => {
  const masterDataValue: string | null = localStorage.getItem(
    localStorageConstants.masterData,
  );
  if (masterDataValue) {
    const masterData: MasterDataType = JSON.parse(
      masterDataValue,
    ) as MasterDataType;
    return masterData;
  } else {
    return null;
  }
};

export const storeInstitutesData = (data: Array<Institute>) =>
  localStorage.setItem(localStorageConstants.institutes, JSON.stringify(data));

export const getInstitutesData = (): Array<Institute> | null => {
  const institutesDataValue: string | null = localStorage.getItem(
    localStorageConstants.institutes,
  );
  if (institutesDataValue) {
    const institutes: Array<Institute> = JSON.parse(
      institutesDataValue,
    ) as Array<Institute>;
    return institutes;
  } else {
    return null;
  }
};
