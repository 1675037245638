import React from 'react';
import Styles from './PurchaseCourseBanner.style';
import { Box } from '@mui/system';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { VariantButton } from '../../themes/properties';
import { Urls } from '../../constants';

const PurchaseCourseBanner = () => {
  const handleButtonClick = () => {
    window.open(Urls.ALL_COURSES, '_self');
  };

  return (
    <Box sx={Styles.root}>
      <Box sx={Styles.iconContainer}>
        <FontAwesomeIcon icon={faLock} />
      </Box>
      <Box sx={Styles.contentContainer}>
        <Box>
          <Typography>
            <strong>Preview of locked section</strong>
          </Typography>
          <Typography>
            Purchase a course to request or update information
          </Typography>
        </Box>
        <Button
          variant={VariantButton.contained}
          sx={Styles.button}
          onClick={handleButtonClick}
        >
          Purchase a course
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseCourseBanner;
