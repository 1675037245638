import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  SelectChangeEvent,
  Autocomplete,
  TextField,
} from '@mui/material';
import { VariantTypography } from '../../themes/properties';
import {
  onBoardingConstants,
  required,
  EMPTY_STRING,
  dataTestIds,
} from '../../constants';
import Styles from './styles';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CourseProgram from '../../models/CourseProgram.model';
import Institute from '../../models/Institute.model';
import Profile from '../../models/Profile.model';

interface PropsType {
  profile: Profile | null;
  updateProfile: (profile: Profile) => void;
}

const MenuProps = {
  PaperProps: {
    style: {
      width: '20%',
      maxHeight: '30%',
    },
  },
};
const CollegeCareerForm = ({ profile, updateProfile }: PropsType) => {
  const courseProgramms: Array<CourseProgram> | null = useSelector(
    (state: RootState) => state.app.masterData?.courseProgramms ?? null,
  );

  const institutes: Array<Institute> | [] = useSelector((state: RootState) =>
    state.app.institutes?.filter((c) => !c.isCustom),
  );

  const { showLoader } = useSelector((state: RootState) => state.app);
  const showError: boolean = !showLoader;

  // function to hnalde state change event
  const handleChange = (e: SelectChangeEvent) => {
    if (profile) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profile, [e.target.name]: e.target.value }),
      ) as Profile;
      updateProfile(updatedProfile);
    }
  };

  const onSelectCollege = (event: any, newValue: Institute | null) => {
    if (profile) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profile, targetCollege: newValue?.id }),
      ) as Profile;
      updateProfile(updatedProfile);
    }
  };

  const handleisCollegeUndecidedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.checked;
    if (profile) {
      const updatedProfile = JSON.parse(
        JSON.stringify({
          ...profile,
          isCollegeUndecided: event.target.checked,
        }),
      ) as Profile;
      if (value) {
        updatedProfile.targetCollege = EMPTY_STRING;
      }
      updateProfile(updatedProfile);
    }
  };

  const selectedCollege: Institute | null = useMemo(
    () =>
      institutes?.find(
        (institute: Institute) =>
          parseInt(institute.id) === parseInt(profile?.targetCollege ?? '0'),
      ) ?? null,
    [profile?.targetCollege, institutes],
  );

  return (
    <Box>
      <Box sx={Styles.formContainer}>
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.textLabel}
          data-testid={dataTestIds.collegeCareerForm.labelTargetCollege}
        >
          {onBoardingConstants.profileDetails.targetCollegeLabel}
        </Typography>

        <FormControl size="small" sx={Styles.formControl}>
          <Autocomplete
            disabled={profile?.isCollegeUndecided}
            size="small"
            options={institutes}
            getOptionLabel={(institute: Institute) => institute.name}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                key={option.id}
                sx={{ fontWeight: option.isPartner ? 700 : 400 }}
                data-testid={`${dataTestIds.collegeCareerForm.menuCollege}${option.name}`}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={onBoardingConstants.profileDetails.targetCollege}
              />
            )}
            value={selectedCollege}
            onChange={onSelectCollege}
            data-testid={dataTestIds.collegeCareerForm.selectCollege}
          />
        </FormControl>

        <Box sx={Styles.boxUndecidedCollege}>
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={
                  <Box
                    sx={{
                      ...Styles.checkUndecidedIcon,
                      ...Styles.checkedUndecidedIcon,
                    }}
                  />
                }
                icon={<Box sx={Styles.checkUndecidedIcon} />}
                onChange={handleisCollegeUndecidedChange}
                checked={profile?.isCollegeUndecided ?? false}
                data-testid={dataTestIds.collegeCareerForm.checkboxCollege}
              />
            }
            label={onBoardingConstants.profileDetails.undecidedCollege}
          />
        </Box>
      </Box>

      <Box sx={Styles.formContainer}>
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.textLabel}
          data-testid={dataTestIds.collegeCareerForm.labelAreaStudy}
        >
          {onBoardingConstants.profileDetails.areaStudyLabel}
        </Typography>

        <FormControl size="small" sx={Styles.formControl}>
          <InputLabel
            sx={Styles.styleInputLabel}
            data-testid={dataTestIds.collegeCareerForm.inputAreaStudy}
          >
            {onBoardingConstants.profileDetails.areaStudy}
          </InputLabel>
          <Select
            label={onBoardingConstants.profileDetails.areaStudy}
            value={profile?.courseName ?? EMPTY_STRING}
            name={onBoardingConstants.profileDetails.fields.courseName}
            onChange={handleChange}
            MenuProps={MenuProps}
            data-testid={dataTestIds.collegeCareerForm.selectAreaStudy}
          >
            {courseProgramms?.map((program: CourseProgram) => (
              <MenuItem
                value={program.id}
                key={program.id}
                data-testid={`${dataTestIds.collegeCareerForm.menuAreaStudy}${program?.name}`}
              >
                {program?.name}
              </MenuItem>
            ))}
          </Select>
          {!profile?.courseName && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.collegeCareerForm.textRequiredAreaStudy}
            >
              {required}
            </Typography>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export default CollegeCareerForm;
