import axiosInstance from '../../../http-requests/httpClient';
import {
  APIs,
  EMPTY_STRING,
  US_COUNTRY_ID,
  TARGET_COLLEGE_UNDECIDED,
  toastMessages,
} from '../../../constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_PROFILE } from '../../../store/slices';
import Profile, {
  Address,
  ProfileBuilder,
} from '../../../models/Profile.model';
import { getCourseList } from '../../dashboard/redux/dashboardAsync.actions';
import { showToast } from '../../../store/app.slice';

import { GetProfileResponse, ErrorResponse } from '../../../types/contract';
import { RootState } from '../../../store';
import {
  storeProfile,
  getProfile as LocalStorageProfile,
  parseToken,
} from '../../../utils/auth.util';
import { onBoardingConstants } from '../../../constants';
import { SnackbarAnchorSeverity } from '../../../themes/properties';
import { LearnerType } from "../../../types/global";

const ACTION_GET_PROFILE = 'ACTION_GET_PROFILE';
const ACTION_UPDATE_PROFILE = 'ACTION_UPDATE_PROFILE';
const ACTION_UPLOAD_PROFILE_PIC = 'ACTION_UPLOAD_PROFILE_PIC';

export const getProfile = createAsyncThunk(
  `${SLICE_PROFILE + APIs.getCourses + ACTION_GET_PROFILE}`,
  async (_, thunkAPI) => {
    let { auth }: RootState = thunkAPI.getState() as RootState;
    try {
      const jwt: any = localStorage.token
        ? parseToken(localStorage.token)
        : null;
      const response = await axiosInstance.get(
        APIs.getProfile.replace('%s', jwt.email),
      );
      const responseData: GetProfileResponse = response?.data ?? {};

      const data: Profile = prepareProfile(responseData);
      storeProfile(data);
      initializePendoForUser(data);
      thunkAPI.dispatch(getCourseList());
      return data;
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const initializePendoForUser = (profile: Profile) => {
  if ((window as any).pendo) {
    (window as any).pendo.initialize({
      visitor: {
        id: profile.id,
        email: profile.email,
        full_name: `${profile.firstName} ${profile.lastName}`,
        customer_id: profile.customerId,
      },
      account: {
        id: window.location.hostname,
      },
    });
  }
};

export const updateProfile = createAsyncThunk(
  `${SLICE_PROFILE + APIs.getCourses + ACTION_UPDATE_PROFILE}`,
  async (_, thunkAPI) => {
    let { profile }: RootState = thunkAPI.getState() as RootState;
    const profileData: Profile | null = profile.profileMutable;

    let targetCollege: string | null = profileData?.targetCollege ?? null;
    if(profileData?.isCollegeUndecided) {
      targetCollege = TARGET_COLLEGE_UNDECIDED;
    } else if (targetCollege === EMPTY_STRING) {
      targetCollege = null;
    } else {
      targetCollege = targetCollege?.toString() ?? null;
    }

    const address: Address | null = profileData?.address ?? null;
    const profileRequestData = {
      mailingAddress: {
        streetAddress1: address?.address,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        zip: (address?.zip?.length ?? 0) > 0 ? address?.zip : null,
      },
      dob: profileData?.dob,
      primaryPhoneNumber: profileData?.phone,
      profilePic: profileData?.avatar,
      onBoardingStatus: profileData?.onBoardingStatus,
      courseProgram: profileData?.courseName,
      targetCollege: targetCollege,
      highestEducation: profileData?.highestEducation,
      referredBy: profileData?.referredBy,
    };
    try {
      const response = await axiosInstance.put(
        APIs.getProfile.replace('%s', profile.profile?.id ?? ''),
        { ...profileRequestData },
      );
      const responseData: GetProfileResponse = response?.data ?? {};

      const data: Profile = prepareProfile(responseData);
      storeProfile(data);
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: toastMessages.accountSettings.success,
          severity: SnackbarAnchorSeverity.success,
        }),
      );
      return data;
    } catch (e: any) {
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: toastMessages.accountSettings.error,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
      throw e;
    }
  },
);

const prepareProfile = (data: GetProfileResponse): Profile => {
  const profile = new ProfileBuilder()
    .setId(data.learnerId)
    .setCustomerId(data.customerId)
    .setFirstName(data.firstName)
    .setLastName(data.lastName)
    .setAvatar(data.profilePic)
    .setCourseName(data.courseProgram)
    .setMembershipExpire(data.membershipExpiryDate)
    .setTargetCollege(data.targetCollege)
    .setEmail(data.email)
    .setDob(data.dob)
    .setPhone(data.primaryPhoneNumber)
    .setOnBoardingStatus(data.onBoardingStatus)
    .setHighestEducation(data.highestEducation)
    .setReferredBy(data.referredBy)
    .setIsCollegeUndecided(data.targetCollege)
    .setAddress(
      new Address(
        data?.mailingAddress?.streetAddress1 ?? EMPTY_STRING,
        data?.mailingAddress?.city ?? EMPTY_STRING,
        data?.mailingAddress?.state ?? EMPTY_STRING,
        data?.mailingAddress?.zip ?? EMPTY_STRING,
        data?.mailingAddress?.country ?? US_COUNTRY_ID,
      ),
    )
    .setLearnerType(data.learnerType ? data.learnerType : LearnerType.LEARNER)
    .build();
  return profile;
};

export const uploadProfileImage = createAsyncThunk(
  `${SLICE_PROFILE + APIs.uploadImage + ACTION_UPLOAD_PROFILE_PIC}`,
  async (
    { file, id }: { file: string | ArrayBuffer; id: string },
    thunkAPI,
  ) => {
    try {
      const response = await axiosInstance.post(
        APIs.uploadImage.replace('%s', id),
        {
          fileName: file.toString(),
        },
      );
      const relativeUrl = response.data.url + `?t=${new Date().getTime()}`;
      return relativeUrl;
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);
