import {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from './table/Table.component';
import { getTranscripts } from './redux/transcriptHistoryAsync.actions';
import { AppDispatch, RootState } from '../../store';
import Profile from '../../models/Profile.model';
import { Box } from '@mui/system';
import { Button, Typography, useMediaQuery } from '@mui/material';
import {
  Routes,
  requestTranscipt,
  screenBreakpoints,
  dataTestIds,
  features,
} from '../../constants';
import Styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import ViewCourses from '../../common-components/transcriptCourses/ViewCourses.component';
import TranscriptInstructions from '../../common-components/transcriptInstructions/TranscriptInstructions.component';
import { resetData } from './redux/transcriptHistorySlice';
import Feature from '../../models/Feature.model';
import PurchaseCourseBanner from "../../common-components/free-trial/PurchaseCourseBanner.component";
import Overlay from "../../common-components/overlay/Overlay.component";
import {LearnerType} from "../../types/global";

const TranscriptHistory = () => {
  const screenIPad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);

  const disptach = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const profile: Profile | null = useSelector(
    (state: RootState) => state.profile.profile,
  );

  const featuresFlags: Array<Feature> = useSelector(
    (state: RootState) => state.app.masterData?.features ?? [],
  );

  const transcriptFeeFeature = featuresFlags?.find(
    (feature) => feature.name === features.transcriptFee,
  );

  useEffect(() => {
    disptach(resetData());
  }, []);

  useEffect(() => {
    if (profile && !isUserFreeTrial) {
      disptach(getTranscripts());
    }
  }, [profile]);

  const transcriptClickHandler = () => {
    navigate(Routes.transcript);
  };

  const isUserFreeTrial: boolean = useMemo(
    () => profile?.learnerType === LearnerType.FREE_TRIAL,
    [profile?.learnerType],
  );

  return (
    <Box
      sx={Styles.root}
      data-testid={dataTestIds.transcriptHistory.divContainer}
    >
      {isUserFreeTrial && <PurchaseCourseBanner />}

      <Box sx={Styles.container}>
        {isUserFreeTrial && <Overlay />}

        <Typography
          sx={Styles.textHeader}
          data-testid={dataTestIds.transcriptHistory.textHeader}
        >
          {requestTranscipt.headers.transcripts}
        </Typography>
        <Box
          sx={{ ...Styles.boxSubheader, display: screenIPad ? 'block' : 'flex' }}
          data-testid={dataTestIds.transcriptHistory.divSubHeader}
        >
          <Typography
            sx={Styles.textHeader2}
            data-testid={dataTestIds.transcriptHistory.textSubHeader}
          >
            {requestTranscipt.headers.transcriptRequests}
          </Typography>
          <Button
            sx={Styles.sendTranscriptBtn}
            size="small"
            onClick={transcriptClickHandler}
            data-testid={dataTestIds.transcriptHistory.btnSendTranscript}
          >
            <Box
              sx={Styles.paperPlaneIcon}
              data-testid={dataTestIds.transcriptHistory.divPlaneeeIcon}
            >
              <FontAwesomeIcon
                icon={faPaperPlane}
                data-testid={dataTestIds.transcriptHistory.iconPlane}
              />
            </Box>
            <Typography
              sx={Styles.sendTranscripts}
              data-testid={dataTestIds.transcriptHistory.textReqTranscript}
            >
              {requestTranscipt.buttons.newTranscript}
            </Typography>
          </Button>
        </Box>
        <TranscriptInstructions
          isTranscriptFeeEnabled={transcriptFeeFeature?.enabled ?? false}
        />
        <ViewCourses />
        <Table />
      </Box>
    </Box>
  );
};

export default TranscriptHistory;
