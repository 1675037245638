import { Grid, Divider, useMediaQuery } from '@mui/material';
import LeftAccountPanel from './LeftAccountPanel.component';
import { Outlet } from 'react-router';
import Footer from '../helpCenter/footer/Footer.component';
import Styles from './Account.styles';
import { screenBreakpoints } from '../../constants';

const Account = () => {
  const screenLaptopLarge = useMediaQuery(
    `(max-width:${screenBreakpoints.laptopLarge})`,
  );
  let leftAccountPanel = null;
  let mainAccountPanel =  null;
  if (!screenLaptopLarge) {
    leftAccountPanel = (
      <Grid item md={3} sx={Styles.leftPanel}>
        <LeftAccountPanel />
      </Grid>
    );
    mainAccountPanel = (
      <Grid item md={9} sx={Styles.main}>
          <Outlet />
          <Divider sx={Styles.divider} />
          <Footer />
        </Grid>
    );
  } else {
    mainAccountPanel = (
      <Grid item md={12} sx={Styles.main}>
          <LeftAccountPanel />
          <Outlet />
          <Divider sx={Styles.divider} />
          <Footer />
      </Grid>
    );
  }

  

  return (
    <>
      <Grid container sx={Styles.root}>
        {!screenLaptopLarge && leftAccountPanel}
        {mainAccountPanel}
      </Grid>
    </>
  );
};

export default Account;
