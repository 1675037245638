import { ContentType, ListType, interfaceType } from '../types';

export const AccountManagement: ContentType = {
  header: `Account Management`,
  subHeader: '',
  sections: [
    {
      title: '<a id="hc_am_acct_info"></a>Account Information',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `When creating your StraighterLine account, we recommend you follow the guidelines listed below:`,
          points: [
            `<strong>First and Last Name:</strong> It’s important that the name you use on your StraighterLine account matches the name you use on your driver’s license or passport. This name will be used on all StraighterLine transcripts and will also be used for identity verification for graded assignments and proctored exams.`,
            `<strong>Email Address:</strong> Your email address will be used as your username for login, for important communications regarding your account and your courses, and also for your transcripts. You may use any email address, but if you already have an ACE account registered under an email address, we recommend using the same email for your StraighterLine account. ACE requires StraighterLine students to have a matching email address in order for an ACE transcript to be requested.`,
            `<strong>Date of Birth:</strong> Your date of birth will be used on StraighterLine transcripts that are sent to your school. Please be sure that this information is accurate to ensure timely processing of StraighterLine transcripts.`,
            `<strong>Target College:</strong> While we don’t require you to tell us your Target College or University, we can help improve your experience at StraighterLine if we know you’d like to transfer your credits. Knowing your Target College also helps our team recognize other schools that are accepting StraighterLine credits outside of our partner network so we can improve the experience for future students like you!`,
          ],
        },
      ],
    },
    {
      title: 'Reset Your Password',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'If you are having trouble signing into your StraighterLine account, you can reset your password following the steps below:',
          points: [
            `Visit <a href="${window.location.origin}/account/account-settings" title="${window.location.origin}/account/account-settings">${window.location.origin}/account/account-settings</a>`,
            `Click “Forgot Your Password” next to the login form`,
            `Enter the email address used for your StraighterLine account`,
            `Open the email and click the link to reset your password (Be sure to check your spam if you don’t see the email)`,
            `Create a new password`,
            `Login with your new password`,
          ],
        },
        `Can’t remember your email address? Contact a Student Advisor for assistance.`,
      ],
    },
    {
      title: 'Email Address or Name Change',
      sections: [
        `Due to the importance of your name and email with our identity verification and transcript processes, the <a href="/help-center/account/account-management/#hc_am_acct_info" title="Account Information">email address</a> and <a href="/help-center/account/account-management/#hc_am_acct_info" title="Account Information">name</a> associated with your StraighterLine account can only be updated by a Student Advisor. If you need to change the email address or name associated with your StraighterLine account, please create a <a href="${window.location.origin}/help-center/support-request" title="Support Request">Support Request</a> and attach a copy of your valid photo id (such as a passport or driver's license) and a Student Advisor will follow-up with your inquiry.`,
      ],
    },
    {
      title: 'Account Settings',
      sections: [
        `Additional account preferences can be set on your <a href="${window.location.origin}/account/account-settings" title="Account Settings">Account Settings</a> page in your <a href="${window.location.origin}" title="MyLine Dashboard">MyLine Dashboard</a> like Date of Birth, Phone Number, and Password.`,
      ],
    },
  ],
};
