import { BC_URL } from './../../../constants';
import { ContentType, interfaceType, ListType } from '../types';

export const courseRetakesData: ContentType = {
  header: 'Course Retakes',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: 'Course Retake Policy',
      sections: [
        `If upon completion of a course a student has not achieved a satisfactory score (70% by default as per ACE), a student may retake their course by repurchasing and reattempting it in full. Students will wait a minimum of three (3) business days between attempts. <strong>The highest final satisfactory score will be used for the transcript.</strong>`,

        `Please note that StraighterLine courses are regularly updated and improved. In some cases, this means that a new course version uses a different textbook. In the case that you are retaking a course and a new version has been released since you originally enrolled, you can decide to move to the new course version or remain in your originally purchased course. All new students are automatically enrolled in the most current course version.`,
      ],
    },
    {
      title: 'Retake a Course',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `To retake a course, follow the steps below:`,
          points: [
            `Find the course you would like to retake in our <a href="${BC_URL}/online-college-courses/most-popular/" title="StraighterLine Course Catalog">catalog</a>`,
            `Add the course to your cart and proceed through checkout`,
            `Once you have paid for the course, you will receive an order invoice and a Support Request will be opened confirming receipt of the retake request`,
            `A Student Advisor will process your retake (Please allow at least 3 business days)`,
            `You will receive an email confirming that your course is ready to begin at which time you can login to your <a href="${window.location.origin}" title="MyLine Dashboard">MyLine Dashboard</a> and restart your course!`,
          ],
        },
      ],
    },
    {
      title: 'Retake Grading',
      sections: [
        `The highest final satisfactory score across all attempts will be used for your transcript. Students must complete all required exams and assignments in order for the course to be <a href="/help-center/credit-transfer/#hc_ct_eligibility" title="Credit Transfer Eligibility">eligible for completion and credit transfer.</a>`,

        `Please note that our systems currently keep a record of your final exam grade from earlier attempts, but this score will not be used as your final exam score. Only grades from your current attempt will be used towards your final grade. You can <a href="/help-center/academics/grading/#hc_grading_calculate_grade" title="StraighterLine Grading">calculate your grade</a> based on the course grading scale provided in your syllabus.`,
      ],
    },
    {
      title:
        '<a id="hc_retakes_firstassignment"></a>First Assignment Retake Policy',
      sections: [
        `Let’s face it, online and self-paced can be difficult, especially if it’s your first course with StraighterLine. To help students get a successful start to their course, we allow students to retake their first assignment for a higher grade. To retake the assignment, simply visit the assessment in your course where we provide instructions. Be sure to set aside enough time to study, have a quiet, distraction free area to take your exam, and be well rested before you take any assessment in the future.`,

        `The first assignment retake policy does not apply to Lab Courses.`,
      ],
    },
  ],
};
