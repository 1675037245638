import { ContentType, ListType, interfaceType } from '../types';

export const PopUpBlockers: ContentType = {
  header: 'Turn Off Pop-Up Blockers',
  subHeader:
    'Certain content or materials for your course may require content to be launched in a pop-up window. As such, we recommend you turn off the pop-up blocker in your browser. Please follow the instructions below for the browser you use to access your courses:',
  sections: [
    {
      title: 'Chrome',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'Follow the instructions below to turn off pop-up blockers in your Chrome browser.',
          points: [
            `In the top-right corner of Chrome, click the Menu, followed by “Settings.”`,
            `At the bottom of the page, click “Show Advanced Settings.”`,
            `In the "Privacy" section, click the “Content Settings” button.`,
            `In the "Pop-ups" section, select “Allow.”`,
          ],
        },
        `Chrome also provides instructions in their <a href="https://support.google.com/chrome/answer/95472?hl=en" title="support resources" target="_blank">support resources</a>.`,
      ],
    },
    {
      title: 'Firefox',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'Follow the instructions below to turn off pop-up blockers in your Firefox browser.',
          points: [
            `In the top-right corner of Firefox, click the Menu, followed by “Options.”`,
            `On the left-hand side of the page, click “Content.”`,
            `Under “Pop-Ups,” uncheck the “Block Pop-Up Windows” button.`,
          ],
        },
        `Firefox also provides instructions in their <a href="https://support.google.com/chrome/answer/95472?hl=en" title="support resources" target="_blank">support resources</a>.`,
      ],
    },
    {
      title: 'Safari',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'Follow the instructions below to turn off pop-up blockers in your Safari browser.',
          points: [
            `In the top-left corner, click “Safari,” followed by “Preferences.”`,
            `At the top of the page, click “Security.”`,
            `Uncheck the “Block Pop-Up Windows” button`,
          ],
        },
      ],
    },
    {
      title: 'Internet Explorer',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'Follow the instructions below to turn off pop-up blockers in your Internet Explorer browser.',
          points: [
            `Open Internet Explorer, select the Tools button , and then select Internet options.`,
            `On the Privacy tab, under Pop-up Blocker, select or clear the Turn on Pop-up Blocker check box, and then select OK.`,
          ],
        },
        `Internet Explorer also provides instructions in their <a href="https://support.google.com/chrome/answer/95472?hl=en" title="support resources" target="_blank">support resources</a>.`,
      ],
    },
    {
      title: 'Edge',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'Follow the instructions below to turn off pop-up blockers in your Edge browser.',
          points: [
            `In the top-right corner of Edge, click the three dots (...) followed by “Settings.”`,
            `Under "Advanced Settings," click "View Advanced Settings" button below.`,
            `Turn off toggle button to unblock the pop-ups.`,
          ],
        },
      ],
    },
    {
      title: 'Other Browsers',
      sections: [
        `Check your browser support online for instructions on how to turn off pop-up blockers.`,
      ],
    },
  ],
};
