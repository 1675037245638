import * as React from 'react';

import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AppStepIcon from './AppStepIcon.component';
import Styles from './styles';

interface Props {
  steps: Array<string>;
  activeStep: number;
}

const AppStepper = ({ steps, activeStep }: Props) => {
  return (
    <Stack sx={Styles.root} spacing={4}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={AppStepIcon} />
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default AppStepper;
