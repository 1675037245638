import { spacing } from '../../../themes/theme';
import { themeColors } from '../../../themes/colors';

export default {
  root: {
    pt: `${spacing * 5}px`,
    pr: `${spacing * 8}px`,
    '& .MuiGrid-root': {
      pl: '8px',
    },
    '@media (max-width: 1024px)': {
      pr: `${spacing * 4}px`,
    },
    '@media (max-width: 768px)': {
      pr: `${spacing * 2}px`,
    },
  },
  mainHeading: {
    fontWeight: 700,
    fontSize: `${spacing * 7}px`,
    fontFamily: `Atkinson Hyperlegible`,
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 6}px`,
    },
  },
  subHeading: {
    fontSize: `${spacing * 2.75}px`,
    mt: `${spacing * 3}px`,
  },
  boxSection: {
    mt: `${spacing * 6}px`,
  },
  txtSectionHeader: {
    fontFamily: `Atkinson Hyperlegible`,
    '@media (max-width: 480px)': {
      fontSize: `${spacing * 4}px`,
    },
    mb: `${spacing * 1.5}px`,
    fontSize: `${spacing * 5}px`,
  },
  txtSectionTitle: {
    mb: `${spacing * 1.5}px`,
    fontSize: `${spacing * 3.5}px`,
  },
  textSectionDescription: {
    fontWeight: 400,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
  textTableTitle: {
    fontWeight: 400,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
  textTableNote: {
    mt: `${spacing * 2}px`,
    mb: `${spacing * 3}px`,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
    fontSize: `${spacing * 1.5}px`,
  },
  textPoint: {
    color: themeColors.blackLight,
    fontWeight: 400,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
  textPointNote: {
    mt: `${spacing * 1}px`,
    mb: `${spacing * 3}px`,
    fontWeight: 400,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
    fontSize: `${spacing * 1.75}px`,
  },
  boxPoint: {
    px: `${spacing * 4}px`,
  },
  listType: {
    listStyle: 'ol',
    pl: `${spacing * 2}px`,
    '& .MuiListItem-root': {
      display: 'list-item',
      p: 0,
    },
  },
  subList: {
    listStyleType: 'disc',
    p: 0,
  },
  divder: {
    borderWidth: '1px',
    mb: `${spacing * 4}px`,
    mt: `${spacing * 4}px`,
  },
  boxModalCard: {
    mb: `${spacing * 2}px`,
    width: '50%',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  boxImage: {
    mb: `${spacing * 2}px`,
  },
  image: {
    width: '100%',
  },
};
