import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { onboardingDialogConstants} from '../../constants';
import Styles from './OnboardingDialog.style';
import { VariantButton } from '../../themes/properties';

interface PropsType {
  completeStep: () => void;
}

const OnboardingDialogWelcome = ({ completeStep }: PropsType) => {
  return (
    <div>
      <DialogContent sx={{...Styles.content, ...Styles.contentWelcome}} dividers>
        <Typography>
          {onboardingDialogConstants.welcome.contentOne}
        </Typography>
        <Typography
          sx={Styles.paragraph}
          dangerouslySetInnerHTML={{
            __html: onboardingDialogConstants.welcome.contentTwo,
          }}
        />
      </DialogContent>

      <DialogActions sx={Styles.dialogActions}>
        <Button
          autoFocus
          onClick={completeStep}
          variant={VariantButton.contained}
          sx={{...Styles.button, ...Styles.buttonNext}}
        >
          {onboardingDialogConstants.welcome.button}
        </Button>
      </DialogActions>
    </div>
  );
};

export default OnboardingDialogWelcome;