import React, { useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Typography,
} from '@mui/material';
import Styles from './styles';
import {
  dataTestIds,
  EMPTY_STRING,
  onBoardingConstants,
  required,
  US_COUNTRY_ID,
} from '../../constants';
import { Address } from '../../models/Profile.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Country from '../../models/Country.model';
import State from '../../models/State.model';
import { InputType, VariantTypography } from '../../themes/properties';

interface PropsType {
  address: Address | null;
  onUpdateAddress: (address: Address) => void;
  showAddressLineTwo?: boolean;
  hideCountry?: boolean;
}

const MenuProps = {
  PaperProps: {
    style: {
      width: '10%',
      maxHeight: '27%',
    },
  },
};

const exceptZipSymbols = ['e', 'E', '+', '-', '.'];

const MailingAddressForm = ({
  address,
  onUpdateAddress,
  showAddressLineTwo,
  hideCountry,
}: PropsType) => {
  const countries = useSelector(
    (state: RootState) => state.app.masterData?.countries,
  );

  const { showLoader } = useSelector((state: RootState) => state.app);
  const showError: boolean = !showLoader;
  const [invalidZipError, setInvalidZipError] = useState(EMPTY_STRING);

  // function to get the states based on selected country
  const states: Array<State> = useMemo(() => {
    const country = countries?.find(
      (country: Country) => country.id == address?.country,
    );
    return country?.states ?? [];
  }, [countries, address?.country]);

  const isNonUSCountry: boolean = useMemo(
    () => address?.country !== US_COUNTRY_ID,
    [address?.country],
  );

  // function to hanlde address, city change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidZipError(EMPTY_STRING);
    const name = e.target.name;
    const value = e.target.value;
    if (name === onBoardingConstants.personalInfo.fields.zip) {
      if (isNonUSCountry) {
        if (
          value.length >
          onBoardingConstants.personalInfo.mailingAddress.maxLengths.zipNonUS
        ) {
          return;
        }
      } else {
        if (
          value.length >
          onBoardingConstants.personalInfo.mailingAddress.maxLengths.zip
        ) {
          return;
        }
      }
    }
    if (address) {
      const updatedAddress = JSON.parse(
        JSON.stringify({ ...address, [name]: value }),
      ) as Address;
      onUpdateAddress(updatedAddress);
    }
    if (name === onBoardingConstants.personalInfo.fields.zip) {
      if (isNonUSCountry) {
        if (
          value.length <
            onBoardingConstants.personalInfo.mailingAddress.minLengths
              .zipNonUS ||
          value.length >
            onBoardingConstants.personalInfo.mailingAddress.maxLengths.zipNonUS
        ) {
          setInvalidZipError(onBoardingConstants.errors.invalidZipNonUS);
        }
      } else {
        if (
          value.length !==
          onBoardingConstants.personalInfo.mailingAddress.maxLengths.zip
        ) {
          setInvalidZipError(onBoardingConstants.errors.invalidZip);
        }
      }
    }
  };

  // function to handle country/State change event
  const handleDropdownChange = (e: SelectChangeEvent) => {
    if (address) {
      const updatedAddress = JSON.parse(
        JSON.stringify({ ...address, [e.target.name]: e.target.value }),
      ) as Address;
      if (e.target.name === onBoardingConstants.personalInfo.fields.country) {
        updatedAddress.state = EMPTY_STRING;
        updatedAddress.zip = EMPTY_STRING;
      }
      onUpdateAddress(updatedAddress);
    }
  };

  const stateRegionInputFiled = (
    <Box>
      <TextField
        sx={Styles.inputField}
        label={onBoardingConstants.personalInfo.mailingAddress.stateRegion}
        InputLabelProps={{ size: 'small' }}
        inputProps={{
          maxLength:
            onBoardingConstants.personalInfo.mailingAddress.maxLengths
              .stateRegion,
        }}
        value={address?.state ?? EMPTY_STRING}
        name={onBoardingConstants.personalInfo.fields.state}
        onChange={handleChange}
        data-testid={dataTestIds.mailingAddressForm.stateRegion}
      />
      {!address?.state && showError && (
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.required}
          data-testid={dataTestIds.mailingAddressForm.stateRegionRequired}
        >
          {required}
        </Typography>
      )}
    </Box>
  );

  const stateDropDown = (
    <Box>
      <FormControl
        size="small"
        sx={Styles.formControl}
        data-testid={dataTestIds.mailingAddressForm.stateForm}
      >
        <InputLabel
          sx={Styles.styleStateInputLabel}
          data-testid={dataTestIds.mailingAddressForm.stateLabel}
        >
          {onBoardingConstants.personalInfo.mailingAddress.state}
        </InputLabel>
        <Select
          label={onBoardingConstants.personalInfo.mailingAddress.state}
          value={address?.state ?? EMPTY_STRING}
          name={onBoardingConstants.personalInfo.fields.state}
          onChange={handleDropdownChange}
          MenuProps={MenuProps}
          sx={Styles.selectField}
          data-testid={`${dataTestIds.mailingAddressForm.stateOptions}${address?.state}`}
        >
          {states.map((state: State) => (
            <MenuItem
              value={state.id}
              key={state.id}
              data-testid={dataTestIds.mailingAddressForm.stateName}
            >
              {state.name}
            </MenuItem>
          ))}
        </Select>
        {!address?.state && showError && (
          <Typography
            variant={VariantTypography.body2}
            sx={Styles.required}
            data-testid={dataTestIds.mailingAddressForm.stateRequired}
          >
            {required}
          </Typography>
        )}
      </FormControl>
    </Box>
  );

  const stateField = isNonUSCountry ? stateRegionInputFiled : stateDropDown;

  return (
    <Box>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={{ xs: 3, md: 3 }}
      >
        <Grid
          container item
          columnSpacing={2}
          rowSpacing={{ xs: 3, md: 4 }}
        >
          {!hideCountry && (
            <Grid item xs={12} md={4.5}>
              <FormControl
                size="small"
                sx={Styles.formControl}
                data-testid={dataTestIds.mailingAddressForm.addressForm}
              >
                <InputLabel
                  sx={Styles.styleCountryInputLabel}
                  data-testid={dataTestIds.mailingAddressForm.countryLabel}
                >
                  {onBoardingConstants.personalInfo.mailingAddress.country}
                </InputLabel>
                <Select
                  label={onBoardingConstants.personalInfo.mailingAddress.country}
                  value={address?.country ?? EMPTY_STRING}
                  name={onBoardingConstants.personalInfo.fields.country}
                  onChange={handleDropdownChange}
                  MenuProps={MenuProps}
                  sx={Styles.selectField}
                  data-testid={`${dataTestIds.mailingAddressForm.countryOptions}${address?.country}`}
                >
                  {countries?.map((state: State) => (
                    <MenuItem
                      value={state.id}
                      key={state.id}
                      data-testid={dataTestIds.mailingAddressForm.countryName}
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                {!address?.country && showError && (
                  <Typography
                    variant={VariantTypography.body2}
                    sx={Styles.required}
                    data-testid={dataTestIds.mailingAddressForm.textCountryRequired}
                  >
                    {required}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item xs>
            <TextField
              sx={Styles.inputField}
              label={onBoardingConstants.personalInfo.mailingAddress.address}
              InputLabelProps={{ size: 'small' }}
              inputProps={{
                maxLength:
                onBoardingConstants.personalInfo.mailingAddress.maxLengths
                  .address,
              }}
              value={address?.address ?? EMPTY_STRING}
              name={onBoardingConstants.personalInfo.fields.address}
              onChange={handleChange}
              data-testid={dataTestIds.mailingAddressForm.textAddress}
            />
            {!address?.address && showError && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.required}
                data-testid={dataTestIds.mailingAddressForm.textAddressRequired}
              >
                {required}
              </Typography>
            )}
          </Grid>

          {showAddressLineTwo && (
            <Grid item xs>
              <TextField
                sx={Styles.inputField}
                label={onBoardingConstants.personalInfo.mailingAddress.addressLine2}
                InputLabelProps={{ size: 'small' }}
                inputProps={{
                  maxLength:
                  onBoardingConstants.personalInfo.mailingAddress.maxLengths
                    .address,
                }}
                value={address?.addressLine2 ?? EMPTY_STRING}
                name={onBoardingConstants.personalInfo.fields.addressLine2}
                onChange={handleChange}
                data-testid={dataTestIds.mailingAddressForm.twoLineAddress}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            sx={Styles.inputField}
            label={onBoardingConstants.personalInfo.mailingAddress.city}
            InputLabelProps={{ size: 'small' }}
            inputProps={{
              maxLength:
              onBoardingConstants.personalInfo.mailingAddress.maxLengths.city,
            }}
            value={address?.city ?? EMPTY_STRING}
            name={onBoardingConstants.personalInfo.fields.city}
            onChange={handleChange}
            data-testid={dataTestIds.mailingAddressForm.textCity}
          />
          {!address?.city && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.mailingAddressForm.textCityRequired}
            >
              {required}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {stateField}
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            sx={{ ...Styles.inputField, ...Styles.inputZip }}
            label={
              isNonUSCountry
                ? onBoardingConstants.personalInfo.mailingAddress.postalCode
                : onBoardingConstants.personalInfo.mailingAddress.zipPostalCode
            }
            InputLabelProps={{ size: 'small' }}
            inputProps={{
              maxLength: isNonUSCountry
                ? onBoardingConstants.personalInfo.mailingAddress.maxLengths
                  .zipNonUS
                : onBoardingConstants.personalInfo.mailingAddress.maxLengths
                  .zip,
            }}
            value={address?.zip ?? EMPTY_STRING}
            name={onBoardingConstants.personalInfo.fields.zip}
            onChange={handleChange}
            type={isNonUSCountry ? InputType.text : InputType.number}
            onKeyDown={(e) =>
              exceptZipSymbols.includes(e.key) && e.preventDefault()
            }
            data-testid={dataTestIds.mailingAddressForm.textZip}
          />
          {!address?.zip && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.mailingAddressForm.textZipRequired}
            >
              {required}
            </Typography>
          )}
          {address?.zip && invalidZipError && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.mailingAddressForm.textZipError}
            >
              {invalidZipError}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MailingAddressForm;
