export const themeColors = {
  primary: '#1A2541',
  secondary: '#009274',
  darkGreen: '#00755d',
  white: '#FFFFFF',
  offWhite: '#E9E9E9',
  black: '#000000',
  blackLight: '#1A171E',
  blueLight: '#63708C',
  grey: '#808080',
  greenGradient:
    'linear-gradient(270deg, #B6E745 0%, #8FD859 3%, #6DC768 6%, #52B572 12.5%, #40A277 25%, #3A8F77 50%)',
  tertiaryBlack: '#63708C',
  greenGradientOpposite:
    'linear-gradient(90deg, #B6E745 0%, #8FD859 3%, #6DC768 6%, #52B572 12.5%, #40A277 25%, #3A8F77 50%)',
  lightGrey: 'rgba(226, 230, 240, 0.25)',
  midGrey: '#E3E3E3',
  paperWhite: 'rgba(254, 253, 250, 1)',
  blendedBlue: 'rgba(64, 107, 217, 1)',
  lime: '#A9EB20',
  darkBlue: '#406BD9',
  backgroundGrey: 'rgba(226, 230, 240, 0.1)',
  warning: '#DC3545',
  warningDark: '#AB192D',
  transparent: 'transparent',
  boxShadow: `#FFFFFF 0px 0px 0px 0px, #0000000d 0px 0px 0px 1px, #0000001a 0px 10px 15px -3px, #0000000d 0px 4px 6px -2px`,
  lightBlue: '#EBEFF7',
  leftPanelGradient: `linear-gradient(180deg, rgba(227, 227, 227, 0.3) 0%, rgba(227, 227, 227, 0) 100%, rgba(227, 227, 227, 0) 100%)`,
  yellow: '#FAB000',
  whiteNavbar: '#F3F5FA',
};

export const hexToRGB = (hex: string, alpha: number) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};
