import { TableCell, TableRow, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { formatData, dateFormats } from '../../../utils/dateUtils';

import Styles from './styles';
import Course from '../../../models/Course.model';
import { dataTestIds } from '../../../constants';

interface PropsType {
  data: Course;
}

const Row = ({ data }: PropsType) => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  return (
    <StyledTableRow>
      <TableCell>
        <Box sx={Styles.boxFlex}>
          <Typography
            sx={Styles.textCourseId}
            data-testid={`${dataTestIds.transcriptViewCourse.textTableHeaderCourse}${data.courseIdentifier}`}
          >
            {data.courseIdentifier}
          </Typography>
          <Typography
            data-testid={`${dataTestIds.transcriptViewCourse.textTableHeaderCourse}${data.name}`}
          >
            {data.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography
          sx={Styles.textDate}
          data-testid={`${dataTestIds.transcriptViewCourse.textTableHeaderCourse}${data.CompletionDate}`}
        >
          {formatData(data.CompletionDate, dateFormats.MMDDYYYY_SLASH)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={Styles.textGrade}
          data-testid={`${
            dataTestIds.transcriptViewCourse.textTableHeaderCourse
          }${data?.gradeValue ?? 0}`}
        >
          {Math.round((data?.gradeValue ?? 0) * 10) / 10}%
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};

export default Row;
