import { helpCenterImagesUrl } from '../../constants';
import { ContentType, ListType, interfaceType } from './types';

export const ProctoringData: ContentType = {
  header: 'Proctoring',
  subHeader: '',
  sections: [
    {
      title: '<a id="hc_proctoring_proctor101"></a>Proctoring 101',
      sections: [
        `A proctored exam is an exam that is supervised by an approved, neutral person (a proctor) who ensures the identity of the test taker and the integrity of the test taking environment. StraighterLine requires proctoring on all final exams and uses an online proctoring service called <a href="https://www.proctoru.com/" title="ProctorU" target="_blank">ProctorU</a>. ProctorU connects your computer and webcam to their website while the exam is being proctored. Proctoring sessions are taken in the comfort of the student's home (or preferred location) and require a webcam.`,
      ],
    },
    {
      title: '<a id="hc_proctoring_schedule"></a>Schedule Your Final Exam',
      sections: [
        `StraighterLine final exams are the only assessment in our courses that must be scheduled in advance. You should schedule your proctored final exam at least 72 hours in advance of your desired exam day. Proctoring is available 24 hours a day, 7 days a week. Expedited scheduling is also available for an <a href="/help-center/proctoring/#hc_proctoring_fees" title="Courses That Do Not Have a Final Exam">additional fee</a>.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'To schedule your proctoring session, follow the steps below. For more detailed instructions, you can follow this step-by-step tutorial.',
          points: [
            `Login to your <a href="${window.location.origin}" title="Dashboard">Dashboard</a>.`,
            `Click the center of the course you wish to schedule proctoring for.`,
            `On the back of the course card, click “Schedule Proctoring” (This will open in a new window. Be sure to turn off any <a href="/help-center/account/pop-up-blockers/" title="pop-up blockers">pop-up blockers</a>)`,
            `Follow the guided steps to schedule your proctoring session with ProctorU`,
          ],
        },
        {
          type: interfaceType.MODAL_TYPE,
          title: 'Scheduling Your Proctoring',
          bannerImage: helpCenterImagesUrl.BackOfCourseCard_Proctoring_Schedule,
          modalData: [
            {
              image: helpCenterImagesUrl.CourseCard_Proctoring_Schedule,
              description:
                'Login to your Dashboard and click the center of the course card for the exam you wish to schedule. As an alternative, you may click on the calendar icon.',
            },
            {
              image: helpCenterImagesUrl.BackOfCourseCard_Proctoring_Schedule,
              description:
                'Click "Schedule Proctoring" on the back of the course card.',
              note: 'This page will open in a new window. Make sure your Pop-Up blocker is turned off',
            },
            {
              image:
                'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_tbd.png',
              description: `Read and accept ProctorU's Terms of Service and Privacy Policy.`,
            },
            {
              image:
                'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_03.png',
              description:
                'Navigate back to "Home" and Click the "Schedule" button next to the course you wish to schedule proctoring for.',
            },
            {
              image:
                'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_04.png',
              description:
                'Select your preferred date and time for your exam and click “Find Available Times."',
            },
            {
              image:
                'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_05.png',
              description:
                'ProctorU will provide available times to select from. Click “SELECT” for the time slot you would like to schedule.',
            },
            {
              image:
                'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_06.png',
              description:
                'Click “SCHEDULE” to confirm, or select a different time.',
            },
            {
              image:
                'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_07.png',
              description:
                'The session will add to your cart (there is no fee for sessions scheduled 72 hours in advance). ProctorU charges a small fee for expedited scheduling. Click “Process Order” to proceed, entering in your credit card information if needed.',
            },
            {
              image:
                'https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/tutorial_proctoring_scheduling_08.png',
              description:
                'You will get a confirmation screen once the session is scheduled. You have successfully completed the scheduling of your exam and will receive an email notification with your registration information.',
            },
          ],
        },
      ],
    },
    {
      title: 'I Clicked “Schedule Proctoring” but Nothing Happened',
      sections: [
        `To schedule proctoring, you must have your browser <a href="/help-center/account/pop-up-blockers/" title="pop-up blockers">pop-up blocker disabled</a> for straighterline.com. Check your browser settings and try to click to schedule again.`,
      ],
    },
    {
      title: 'Required Equipment',
      sections: [
        `You will need a working webcam, microphone and speakers to take your proctored exam. Most laptops already have them built-in. If your laptop doesn't, you can borrow a friend or family member's computer for a few hours to take the exam. Otherwise one can be purchased for only a few dollars through sites like Amazon or locally thru Walmart or similar stores. Please reach out to a Student Advisor for further assistance with obtaining a webcam for the exam.`,
      ],
    },
    {
      title: 'Before Your Exam Day',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'Before your exam day, complete the checklist below to ensure you are prepared to take your final exam:',
          points: [
            `<strong>Test Your Webcam and Computer:</strong> Make sure your computer, webcam, microphone, and speakers are installed and working correctly by running <a href="https://go.proctoru.com/testitout" title="ProctorU's System Check" target="_blank">ProctorU's System Check</a> (be sure to close out any other webcam software before you run the test). In addition, make sure you have all of <a href="https://support.proctoru.com/hc/en-us/articles/12821084697741-Online-Proctoring-Test-Taker-Resource-Center" title="ProctorU's Technical Requirements" target="_blank">ProctorU's Technical Requirements</a>`,
            {
              type: interfaceType.IMAGE_TYPE,
              title: `Example exam review:`,
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/proctoringTutorials/proctoring_systemcheck.png`,
            },
            `<strong id="hc_proctoring_securebrowser">Download Secure Browser:</strong>  The exams require the use of a secure delivery browser. The Secure Browser is a small download and is used for the secure delivery of computer based tests.  This software does not transfer any information from your machine, nor is it resident in memory when you are not delivering a test.`,
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.Unordered,
              title: `<strong>Download Secure Browser:</strong> If you do not already have a Secure Browser installed, visit the <a href="https://guardian.meazurelearning.com/?_gl=1*9u1h9b*_ga*NDM2ODg0NzI0LjE2ODM1NzIyMTg.*_ga_5GQD65R2XC*MTY5MjY1MDM0MC4xLjAuMTY5MjY1MDM0MC42MC4wLjA." title="Download" target="_blank">download page</a> on ProctorU’s site.`,
            },
            `<strong>Secure Your Exam Area:</strong> Find a well-lit, quiet and private room to take the exam and make sure that only the appropriate exam materials are present. The lighting of your room must be daylight quality and overhead lighting is preferred. There should not be shadows on your face. You should avoid any locations where other people would be passing thru the testing area. Having other people in the testing area may invalidate your exam.`,
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.Unordered,
              title: `<strong id="hc_proctoring_twoformsofid">Collect Two Forms of ID:</strong> You are required to present two forms of original (no photo copies), valid (unexpired) IDs: one primary ID (a government-issued ID with your name, recent recognizable photo and signature) and one secondary ID (with at least a name and signature, or name and recent recognizable photo). Visit the <a href="https://www.comptia.org/testing/testing-policies-procedures/test-policies/candidate-id-policy" title="CompTIA Candidate ID Policy page" target="_blank">CompTIA Candidate ID Policy page</a> for a complete list of acceptable identification and the full CompTIA ID policy.`,
              points: [
                `U.S. Passport or U.S. Passport Card`,
                `Driver's license or ID card issued by a State provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address`,
                `ID card issued by federal, state or local government agencies or entities, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address`,
                `U.S. Military card`,
                `Foreign passport`,
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'What to Expect During Your Exam',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: 'You can expect the following during your proctoring session:',
          points: [
            `<strong>Start Your Proctoring Session:</strong>  You should open the Guardian Browser, which you previously downloaded to your device. You should log into your account <a href="${window.location.origin}" title="Dashboard">Dashboard</a>.  in that browser. To start your session, click the “Start Proctoring” link on the back of your course card in your <a href="${window.location.origin}" title="Dashboard">Dashboard</a>. This will open up <a href="/help-center/proctoring/#hc_proctoring_proctor101" title="">ProctorU</a>  in a new window. If you login to your session early, a countdown will display until the start of your session.`,
            {
              type: interfaceType.MODAL_TYPE,
              title: 'Starting Your Proctoring Session on Exam Day',
              bannerImage: helpCenterImagesUrl.Proctoring_Start,
              modalData: [
                {
                  image: helpCenterImagesUrl.CourseCard_Proctoring_Schedule,
                  description:
                    'Login to your Dashboard and click the center of the course card for the exam you wish to schedule. As an alternative, you may click on the calendar icon.',
                },
                {
                  image:
                    helpCenterImagesUrl.BackOfCourseCard_Proctoring_Schedule,
                  description:
                    'Click on the proctoring link, ensuring your pop-up blocker is turned off.',
                },
                {
                  image: helpCenterImagesUrl.Proctoring_Start,
                  description:
                    'Click the “Start Session” button. If you are a little early, you will see a countdown to the exact time of your session. You will now be launched into your proctoring session and your proctor will Walk you through the steps needed to start your exam. You will need to open your course content and navigate to your exam in a new tab in the proctoring browser.',
                },
              ],
            },
            `<strong>Request for Access:</strong> Once your proctor connects to the session, they will introduce themselves and ask you to allow remote desktop control.`,
            `<strong>ID Verification:</strong> Your proctor will ask that you hold up your <a href="/help-center/proctoring/#hc_proctoring_twoformsofid" title="Collect Two Forms of ID">two forms of ID</a> for verification`,
            `<strong>Scan of the Room:</strong> Your proctor will ask that you show him or her the room you are sitting in by scanning the room with your webcam. A proctor is required to view all areas of the room, including behind the computer. If you are unable to move the laptop or camera in a way that displays the full test area, the proctor may request that you use a mirror to show the areas that cannot be viewed using the camera. They will instruct you to remove any items in the room that are not permitted materials.`,
            `<strong>Materials:</strong> The materials allowed for each exam are listed within your course on the exam page, as well as within your course syllabus. These are unique per course.`,
            `<strong>Open the Final Exam:</strong> Your Final Exam is typically located in the last Topic of your course. Your proctor will assist you through this process.`,
            `<strong>Password Protected:</strong> All Final Exams are password protected. Your proctor will provide the exam password.`,
            `<strong>Attempts:</strong> Unless otherwise noted, you have one (1) attempt only for the final exam.`,
            `<strong>Timed:</strong> Your final exam is timed. The timer will not start until the exam has been opened by either you or your proctor. You should not close the exam window while your exam is running (unless you're finished and want to submit it). If time expires, the exam will submit automatically. Time limits for final exams vary by course.`,
            `<strong>Question Display:</strong> Only one (1) question will be displayed at a time.`,
            `<strong>End of Exam Information:</strong> At the end of your final exam, once you've submitted your answers, your score will display on the screen. Your exam score will also be available on the back of your course card on your <a href="${window.location.origin}" title="Dashboard">Dashboard</a>. If an exam includes elements that must be manually graded by an evaluator, your score may not appear until this grading is complete.`,
            `<strong>Questions during the exam:</strong> If you need help for any reason during the exam, simply notify the proctor via the chat box.`,
            `<strong>Technical Difficulties during the exam:</strong> If you experience technical difficulties during your exam, your proctor will assist you via the chat function in your session. If you get disconnected for any reason, your proctor will attempt to contact you via phone. If you still experience issues, please contact ProctorU directly at 855-772-8678 or via Chat from the testing screen.`,
          ],
        },
      ],
    },
    {
      title: 'Can the Proctor Access My Personal Files?',
      sections: [
        `<strong>No.</strong> ProctorU cannot access your computer files without your knowledge. With your permission, proctors have the ability to view the screen and utilize the mouse and keyboard as if they were sitting next to you. Once the exam starts, the proctor will monitor everything on the computer screen, but can no longer utilize the your mouse and keyboard. During the entire process, a chat box is running on your computer, and you can see what permissions the proctor currently has at any time. The entire chat session log is saved, including a permanent record of what actions the proctor took while accessing your computer.`,
      ],
    },
    {
      title: `Cancel or Change Your Scheduled Exam`,
      sections: [
        `If you wish to cancel or reschedule your exam, you must do so at least 24 hours prior to your scheduled appointment. To cancel or change your scheduled proctored exam click the “Reschedule/Cancel Proctoring” link from the back of your course card on your <a href="${window.location.origin}" title="Dashboard">Dashboard</a> and follow the instructions with ProctorU. Cancelling an exam less than 24 hours prior to your appointment or missing your exam may result in forfeiting your exam <a href="/help-center/proctoring/#hc_proctoring_fees" title="Proctoring Fees">fees.</a>`,
      ],
    },
    {
      title: '<a id="hc_proctoring_fees"></a>Proctoring Fees',
      sections: [
        `There is no fee for proctoring sessions scheduled 72 hours in advance. For expedited proctoring, ProctorU has a small fee: Exams scheduled within the 72 hour window are subject to a $8 fee, while those scheduled for "Same Day" scheduling (within 75 minutes) are subject to an $12 fee. Fees for proctoring are paid directly to ProctorU through their website during the <a href="/help-center/proctoring/#hc_proctoring_schedule" title="Schedule Your Final Exam">scheduling process</a>.`,
      ],
    },
    {
      title:
        '<a id="hc_proctoring_fees"></a>Courses That Do Not Have a Final Exam',
      sections: [
        `Several courses, such as English Composition I and Lab Sciences, do not have final exams, so there's no need for proctoring. To ensure academic integrity, written work for such courses is subject to review via plagiarism detection software and other protocols.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: 'Proctoring is not applicable in the following courses:',
          points: [
            `Developmental Writing (ENG099)`,
            `English Composition I (ENG101)`,
            `English Composition II (ENG102)`,
            `Anatomy and Physiology I Lab (BIO201L)`,
            `Anatomy and Physiology II Lab (BIO202L)`,
            `General Chemistry I Lab (CHEM101L)`,
            `General Physics I Lab (PHY250L)`,
            `Introduction to Biology Lab (BIO101L)`,
            `Microbiology Lab (BIO250L)`,
            `Biochemistry Lab (CHEM450L)`,
            `Teaching Students with Exceptionalities (ECE103)`,
            `Classroom Management  (ECE104)`,
            `Language and Literacy (ECE105)`,
          ],
        },
      ],
    },
  ],
};
