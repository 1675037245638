import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { dataTestIds, requestTranscipt } from '../../constants';
import { formatData, dateFormats } from '../../utils/dateUtils';
import Styles from './styles';
import Table from './table/Table.component';

const ViewCourses = () => {
  const [showTable, setShowTable] = useState(false);
  return (
    <Box
      sx={Styles.root}
      data-testid={dataTestIds.transcriptViewCourse.divContainer}
    >
      <Box
        sx={{ ...Styles.boxFlex, ...Styles.boxHeader }}
        data-testid={dataTestIds.transcriptViewCourse.divHeader}
      >
        <Box>
          <Typography
            sx={Styles.textHeader}
            data-testid={dataTestIds.transcriptViewCourse.textHeader}
          >
            {requestTranscipt.viewCourses}
          </Typography>
          <Typography
            sx={Styles.textSubHeader}
            data-testid={dataTestIds.transcriptViewCourse.textHeaderDate}
          >{`${requestTranscipt.updated} ${formatData(
            new Date().toDateString(),
            dateFormats.MMDDYYYY_SLASH,
          )}`}</Typography>
        </Box>
        <Box
          sx={Styles.boxIconArrow}
          onClick={() => setShowTable(!showTable)}
          data-testid={dataTestIds.transcriptViewCourse.divUpDownIcon}
        >
          <FontAwesomeIcon
            icon={showTable ? faAngleUp : faAngleDown}
            data-testid={dataTestIds.transcriptViewCourse.iconUpDown}
          />
        </Box>
      </Box>
      <Box
        sx={{
          px: 1,
          display: showTable ? 'block' : 'none',
        }}
        data-testid={dataTestIds.transcriptViewCourse.divTable}
      >
        <Table />
      </Box>
    </Box>
  );
};

export default ViewCourses;
