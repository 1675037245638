import { Box, Divider, Typography } from '@mui/material';
import { VariantTypography } from '../../../../themes/properties';
import {
  ContentType,
  SectionType,
  TableType,
  PointType,
  interfaceType,
  ImageType,
  ModalType,
} from '../../../../assets/help-center-data/types';
import Styles from '../styles';
import Points from '../Points.component';
import Image from '../Image.component';
import TableSection from '../Table.component';
import Modal from '../Modal.component';
import { useEffect } from 'react';

interface PropsType {
  data: ContentType;
}

const BillingAndAccountManagement = ({ data }: PropsType) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  const sections = data.sections.map((sectionData: SectionType) => (
    <Box sx={Styles.boxSection}>
      <Typography
        sx={Styles.txtSectionTitle}
        variant={VariantTypography.h5}
        dangerouslySetInnerHTML={{ __html: sectionData.title }}
      />
      {sectionData.sections.map(
        (section: TableType | PointType | ImageType | ModalType) => {
          let view = null;
          switch (section.type) {
            case interfaceType.TABLE_TYPE:
              const tableData = section as TableType;
              view = <TableSection data={tableData} />;
              break;
            case interfaceType.POINT_TYPE:
              const pointData = section as PointType;
              view = <Points data={pointData} />;
              break;
            case interfaceType.IMAGE_TYPE:
              const imageData = section as ImageType;
              view = <Image data={imageData} />;
              break;
            case interfaceType.MODAL_TYPE:
              const modalData = section as ModalType;
              view = <Modal data={modalData} />;
              break;
            default:
              const text = section.toString();
              view = (
                <Typography
                  dangerouslySetInnerHTML={{ __html: text }}
                  sx={Styles.textSectionDescription}
                  variant={VariantTypography.body1}
                />
              );
              break;
          }
          return <>{view}</>;
        },
      )}

      <Divider sx={Styles.divder} />
    </Box>
  ));
  return (
    <Box sx={Styles.root}>
      <Typography variant={VariantTypography.h2} sx={Styles.mainHeading}>
        {data.header}
      </Typography>
      <Typography variant={VariantTypography.body1} sx={Styles.subHeading}>
        {data.subHeader}
      </Typography>
      {sections}
    </Box>
  );
};

export default BillingAndAccountManagement;
