import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  root: {
    backgroundColor: themeColors.primary,
    borderRadius: `${spacing * 1.5}px`,
    color: themeColors.white,
    display: 'flex',
    gap: `${spacing * 3}px`,
    p: `${spacing * 3}px`,
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      alignItems: 'start',
    },
  },
  iconContainer: {
    fontSize: '20px',
    py: `${spacing}px`,
  },
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: `${spacing * 2}px`,
    flexDirection: 'row',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  button: {
    backgroundImage: `${themeColors.greenGradient}`,
    borderRadius: '50px',
    height: '100%',
    fontSize: `${spacing * 1.5}px`,
    '&:hover': {
      backgroundImage: `${themeColors.greenGradientOpposite}`,
    },
  },
};
