import { helpCenterImagesUrl } from '../../constants';
import { ContentType, ListType, interfaceType } from './types';

export const ETextBooks: ContentType = {
  header: 'eTextBooks',
  subHeader: '',
  sections: [
    {
      title: 'eTextbook Features',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: 'Time-saving study tools built into our eTextbooks, include:',
          points: [
            `Online and Offline reading`,
            `Note management - Highlight important passages and add notes to help you study`,
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.Unordered,
              title: `Sync across all devices:`,
              points: [
                `Pick up exactly where you left off on your smartphone, tablet or computer`,
                `Your important notes, bookmarks, and highlighted passages are transferred across all devices`,
              ],
            },
            `Copy/Paste features - Use to create study guides, note cards, or citations`,
            `Citation tools - Instant formatting of quotes into MLA/APA style`,
          ],
        },
      ],
    },
    {
      title: 'Activate Your eTextbook',
      sections: [
        `For both new and existing StraighterLine eTextbook users, you must activate your eTextbook after purchase by clicking through to the book from the first lesson in your course.`,
        `Access to your eTextbook is unlimited throughout the duration of your course. When you complete your course, you will no longer be able to access your eTextbook.`,
        {
          type: interfaceType.MODAL_TYPE,
          title: `Activating Your eTextbook`,
          bannerImage: helpCenterImagesUrl.Course_Resume,
          modalData: [
            {
              image: helpCenterImagesUrl.Course_Resume,
              description: `Login to your MyLine Dashboard and click the “RESUME” link for the course associated with the eTextbook you would like to access.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_access_etextbook_02.png`,
              description: `Navigate to the main course page and click to open the first topic.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_access_etextbook_03.png`,
              description: `Scroll down the page and click on the first “eTextbook” reading assignment.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_access_etextbook_04.png`,
              description: `Your eTextbook is now added to your account and is available for reading and downloading.`,
            },
          ],
        },
      ],
    },
    {
      title: 'Create a Bookshelf® Account',
      sections: [
        `StraighterLine eTextbooks are provided through Bookshelf® from VitalSource. To access and read your eTextbooks, a Bookshelf® account is required. The first time you navigate to Bookshelf®, you will be prompted to set up your account OR you may login with an existing Bookshelf® account if you have used the service before. Please make sure to save this login for future use as it is separate from your StraighterLine MyLine account.`,
        {
          type: interfaceType.MODAL_TYPE,
          title: `Creating a Bookshelf® Account`,
          bannerImage: helpCenterImagesUrl.Course_Resume,
          modalData: [
            {
              image: helpCenterImagesUrl.Course_Resume,
              description: `Login to your MyLine Dashboard and click the “RESUME” link for the course associated with the eTextbook you would like to access.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_create_etextbook_account_02.png`,
              description: `Navigate to the main course page and click the link for the first Topic or Unit.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_create_etextbook_account_03.png`,
              description: `Scroll down the page and click on the “eTextbook” reading assignment.`,
              note: ` This page will open in a new window. Make sure your Pop-Up blocker is turned off.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_create_etextbook_account_04.png`,
              description: `Enter an email address to set up your Bookshelf® account.</br>This account will be used to login to Bookshelf® from any device you wish to access your eTextbook from. Please make sure to save this login for future use as it is separate from your StraighterLine MyLine account.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_create_etextbook_account_05.png`,
              description: `Complete the form as instructed to complete your Bookshelf® account set-up.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_create_etextbook_account_06.png`,
              description: `Upon successful account creation, you may now start reading your eTextbook.`,
            },
          ],
        },
      ],
    },
    {
      title: `Online Reading`,
      sections: [
        `Your eTextbooks will default to open in Bookshelf® in your web browser. You can easily link between your course text and the corresponding sections in the eTextbook with a working internet connection.`,
        `If you have any difficulty opening your eTextbook in Bookshelf, check your computer to make sure you are using a <a href="https://support.vitalsource.com/hc/en-us/articles/201949723-Bookshelf-Online-Browser-Support-Policy" title="supported browser" target="_blank">supported browser.</a>`,
      ],
    },
    {
      title: `Offline Reading`,
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title:
            'To enable offline access or to read your eTextbook on your mobile or tablet device, follow the instructions below to set-up the Bookshelf® App.',
          points: [
            `Download and install the Bookshelf® App for your device from the <a href="https://support.vitalsource.com/hc/en-us/articles/201344733-Bookshelf-Download-Page" title="VitalSource Download Page." target="_blank">VitalSource Download Page.</a>`,
            `Login to your Bookshelf® account`,
            `Download the eTextbook that you would like to the Bookshelf® App and start reading on your device!`,
          ],
        },
        {
          type: interfaceType.MODAL_TYPE,
          title: `Downloading an eTextbook to Your Device`,
          bannerImage:
            'https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_download_etextbook_01.png',
          modalData: [
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_download_etextbook_01.png`,
              description: `On your desired device, navigate to ${window.location.origin}/help-center/etextbooks and download the Bookshelf® application. This tutorial includes screenshots from the Bookshelf® iOS Application on an iPhone.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_download_etextbook_02.png`,
              description: `After download, follow the instructions to install the application.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_download_etextbook_03.png`,
              description: `Login to Bookshelf® using the account created when you first accessed your eTextbook.`,
              note: `Note: This is not your StraighterLine MyLine account. If you cannot remember your login, follow the instructions to reset your password.`,
            },
            {
              image: `https://s3.amazonaws.com/straighterline-web/images/help-center/eTextbookTutorials/tutorial_download_etextbook_04.png`,
              description: `Download the eTextbook that you would like to enable on this device and begin reading!</br>For assistance in using eTextbook features, utilize the help materials within the Bookshelf® application.`,
            },
          ],
        },
      ],
    },
    {
      title: 'Bookshelf® Orientation Tutorials',
      sections: [
        `This set of tutorials has been designed to help you learn more about Bookshelf® at your own pace. Additionally, you may find help and support materials specific to your device within the Bookshelf® App.`,
        `<button onClick="window.location.href='http://downloads.vitalbook.com/tutorials/publish/index.html?did=straighterline'" data-toggle="modal" title="LAUNCH TUTORIALS" style="font-size: 12px; width: fit-content; align-self: flex-end; background-color: #009274; color: white; border-radius: 80px; padding-left: 20px;padding-right: 20px;padding-top: 12px;padding-bottom: 12px;border: none; border-color: #009274; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; font-weight: 500;letter-spacing: 1px; " onmouseover="this.style.backgroundColor='#00755d'" onmouseout="this.style.backgroundColor='#009274'">LAUNCH TUTORIALS</button>`,
      ],
    },
    {
      title: 'Accessibility Features',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'Our eTextbooks provide <a href="/help-center/student-handbook/accessibility/" title="accessible solutions">accessible solutions</a> to learners with disabilities. Students can take advantage of the features below:',
          points: [
            `<strong>Printing</strong> - To print the entire eTextbook, or just a few pages, use the print tools provided in the Bookshelf® App.`,
            `<strong>Audio</strong> - Text to speech or read aloud features are provided in the Bookshelf® App.`,
          ],
        },
      ],
    },
    {
      title: 'eTextbook Use During Exams',
      sections: [
        `Textbook/eTextbook use is permitted for open book exams only.`,
        `If your final proctored exam is open book, the proctor will allow you to navigate to the eTextbook in your web browser as needed during the proctoring session. The proctor will not permit eTextbook usage on your mobile phone or tablet during your exam.`,
      ],
    },
    {
      title: 'My Course Does Not Have an eTextbook',
      sections: [
        `Not all StraighterLine courses have a required textbook and therefore do not have an eTextbook version included with the course. Please check your course syllabus for information on required textbooks. If you feel that your course is missing an eTextbook in error, contact a Student Advisor for assistance.`,
      ],
    },
    {
      title: 'Device Restrictions',
      sections: [
        `Bookshelf® allows you to have 2 computers and 2 mobile/tablet devices activated at any given time. If you reach your maximum device limit, you must “Deactivate” one of your devices before you can “Activate” another. eTextbook use and access through your web browser (via links in your course) do not count toward your device limits.`,
      ],
    },
    {
      title: 'Prefer the Hard Copy?',
      sections: [
        `No problem! You can purchase a hard copy from any retailer using the ISBN that is listed in your course syllabus. You will still get the eTextbook for free.`,
      ],
    },
  ],
};
