import React from 'react';
import Styles from './Overlay.style';
import { Box } from "@mui/system";


/*
This component fills all the space of its parent.
Important: assign 'position: relative' style to the parent.
 */
const Overlay = () => {
  return (
    <Box sx={Styles.root} />
  );
};

export default Overlay;
