import { Box, Divider, Typography } from '@mui/material';
import { VariantTypography } from '../../../../themes/properties';
import {
  ContentType,
  SectionType,
  TableType,
  PointType,
  interfaceType,
  ImageType,
  ModalType,
} from '../../../../assets/help-center-data/types';
import Styles from '../styles';
import Points from '../Points.component';
import Image from '../Image.component';
import TableSection from '../Table.component';
import Modal from '../Modal.component';
import React, { useEffect } from 'react';
import { spacing } from '../../../../themes/theme';
import { useNavigate } from 'react-router';

interface PropsType {
  data: ContentType;
}

const scrollToHash = () => {
  const id = window.location.hash.substr(1);
  if (id) {
    const anchor = document.getElementById(id);
    if (anchor) {
      let dims = anchor.getBoundingClientRect();
      window.scrollTo(window.scrollX, dims.top - spacing * 8);
    }
  }
};

const Academics = ({ data }: PropsType) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    scrollToHash();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);

  const sections = data.sections.map((sectionData: SectionType) => (
    <Box sx={Styles.boxSection}>
      <Typography
        sx={Styles.txtSectionTitle}
        variant={VariantTypography.h5}
        dangerouslySetInnerHTML={{ __html: sectionData.title }}
      />
      {sectionData.sections.map(
        (section: string | TableType | PointType | ImageType | ModalType) => {
          let view = null;
          if (typeof section === 'string') {
            const text = section.toString();
            view = (
              <Typography
                dangerouslySetInnerHTML={{ __html: text }}
                sx={Styles.textSectionDescription}
                variant={VariantTypography.body1}
              />
            );
          } else if (section.type === interfaceType.TABLE_TYPE) {
            const sectionData = section as TableType;
            view = <TableSection data={sectionData} />;
          } else if (section.type === interfaceType.POINT_TYPE) {
            const sectionData = section as PointType;
            view = <Points data={sectionData} />;
          } else if (section.type === interfaceType.IMAGE_TYPE) {
            const sectionData = section as ImageType;
            view = <Image data={sectionData} />;
          } else if (section.type === interfaceType.MODAL_TYPE) {
            const sectionData = section as ModalType;
            view = <Modal data={sectionData} />;
          }
          return <>{view}</>;
        },
      )}
      <Divider sx={Styles.divder} />
    </Box>
  ));
  return (
    <Box sx={Styles.root}>
      <Typography variant={VariantTypography.h2} sx={Styles.mainHeading}>
        {data.header}
      </Typography>
      <Typography variant={VariantTypography.body1} sx={Styles.subHeading}>
        {data.subHeader}
      </Typography>
      {sections}
    </Box>
  );
};

export default Academics;
