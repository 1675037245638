import moment from 'moment';
moment.suppressDeprecationWarnings = true;
import { localStorageConstants, minAge } from '../constants';
import dayjs from 'dayjs';

export const formatData = (date: string, format: string = 'YYYY-MM-DD') =>
  moment(date).format(format);

export const isDateExpired = (date: string) => moment().diff(date, 'days') > 0;

export const today = (format: string) => moment().format(format);

export const isFirstInDay = (key: string) => {
  var currentDate = new Date().setHours(0, 0, 0, 0);
  var storedDate = window.localStorage.getItem(key);
  if (storedDate == null || currentDate > parseInt(storedDate)) {
    window.localStorage.setItem(key, currentDate.toString());
    return true;
  }
  return false;
};

export const isUnderAged = (date: string) => {
  const maxDate = dayjs().subtract(minAge, 'years');
  const selectedDate = dayjs(date);
  const isUnderAge = selectedDate.isAfter(maxDate);

  return isUnderAge;
};

export const isDateValid = (value: string, format: string) => {
  return moment(value, format, true).isValid();
};

export const dateFormats = {
  MMMMYYYY: 'MMMM YYYY',
  MMMMdYYYY: 'MMMM DD, YYYY',
  MMDDYYYY_SLASH: 'MM/DD/YYYY',
  YYYYMMDD_HYPHEN: 'YYYY-MM-DD',
  YYYYMMMMMDD_HYPHEN: 'YYYY-MMMM-DD',
  MMMMM: 'MMMM',
  YYYY: 'YYYY',
};
