import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  itemContainer: {
    display: 'flex',
    cursor: 'pointer',
    padding: `${spacing * 0.5}px`,
    minWidth: `${spacing * 6.25}px`,
    paddingLeft: `${spacing * 5.5}px`,
  },
  subItemButton: {
    paddingLeft: `${spacing * 3.75}px`,
  },
  expandIcon: {
    color: themeColors.secondary,
    minWidth: `${spacing * 0.5}px`,
    mr: `${spacing * 1}px`,
  },
  checkIcon: {
    color: themeColors.black,
    minWidth: `${spacing}px`,
    marginLeft: `-${spacing * 1}px`,
  },
  leftPanelSubList: {
    paddingLeft: `${spacing * 5}px`,
  },
  backIcon: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: `${spacing * 1.5}px`,
    textTransform: 'capitalized',
    paddingTop: `${spacing * 2.5}px`,
    '&:hover': {
      color: `${themeColors.secondary}`,
      fontSize: `${spacing * 1.625}px`,
    },
    paddingLeft: `${spacing * 5.5}px`,
  },
  navigateBackText: {
    paddingLeft: `${spacing * 1.25}px`,
    textTransform: 'capitalised',
    fontWeight: 400,
    '&:hover': {
      color: themeColors.secondary,
      fontWeight: 700,
    },
  },
  leftPanelList: {
    // paddingTop: `${spacing * 10}px`,
    backgroundColor: 'none',
  },
  listItemText: {
    fontSize: `${spacing * 1.5}px`,
    '&:hover': {
      color: themeColors.secondary,
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
  selectedListItem: {
    fontSize: `${spacing * 1.5}px`,
    color: themeColors.black,
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
  subListItemText: {
    '& .MuiTypography-root': {
      fontSize: `${spacing * 1.75}px`,
    },
    '&:hover': {
      color: themeColors.secondary,
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
  selectedSubListItem: {
    color: themeColors.secondary,
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: `${spacing * 1.75}px`,
    },
  },
  divider: {
    borderStyle: 'dashed',
    mt: `${spacing * 6}px`,
    width: '80%',
    ml: `${spacing * 5}px`,
  },

  formControl: {
    width: '95%',
    paddingTop: '24px',
  },
  styleStateInputLabel: {
    fontWeight: 700,
  },
  menuContainer: {
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
  menuItem: {
    display: 'block',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
};

export default Styles;
