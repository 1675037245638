import { Button, Grid, Avatar, Box } from '@mui/material';
import Styles from './AccountSettings.style';
import { useState, useRef, useEffect } from 'react';
import {
  cloudfrontImageBaseUrl,
  dataTestIds,
  profilePic,
} from '../../../constants';
import { uploadProfileImage } from '../../profileHeader/redux/profileAsync.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  SnackbarAnchorSeverity,
  VariantButton,
} from '../../../themes/properties';
import { AppDispatch, RootState } from '../../../store';
import { ErrorResponse } from '../../../types/contract';
import { showToast } from '../../../store/app.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';

interface PropsType {
  setIsFormEdited: (value: boolean) => void;
}

const UploadProfilePic = ({ setIsFormEdited }: PropsType) => {
  const { profileMutable: profile } = useSelector(
    (state: RootState) => state.profile,
  );
  const profileImageUrl = `${cloudfrontImageBaseUrl}/${profile?.avatar}`;
  const dispatch = useDispatch<AppDispatch>();
  const [selectedFile, setSelectedFile] = useState(profileImageUrl);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setSelectedFile(profileImageUrl);
  }, [profile?.avatar]);

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files && event.target.files[0];
    const isFileValid =
      (file?.type === profilePic.jpgFile ||
        file?.type === profilePic.pngFile) &&
      file?.size / profilePic.fileSizeToKB <= profilePic.fileSize;
    if (isFileValid && file) {
      setSelectedFile(URL.createObjectURL(file));
      var reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64Result: string | ArrayBuffer | null = reader.result;
        if (base64Result && profile?.id) {
          setIsFormEdited(true);
          dispatch(
            uploadProfileImage({
              file: base64Result,
              id: profile?.id,
            }),
          );
        }
      };
    } else {
      const error: ErrorResponse = {
        friendlyMessage: profilePic.invalidFile,
        message: profilePic.invalidFile,
      };
      dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  }

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };
  return (
    <Grid container spacing={2} sx={Styles.imageContainer}>
      <Grid item>
        <Avatar
          src={selectedFile}
          sx={Styles.profileAvatar}
          data-testid={dataTestIds.uploadProfilePic.avatar}
        />
      </Grid>
      <input
        type={profilePic.fileType}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
        data-testid={dataTestIds.uploadProfilePic.inputFile}
      />
      <Grid item>
        <Button
          variant={VariantButton.contained}
          onClick={handleUploadClick}
          sx={Styles.uploadImageBtn}
          data-testid={dataTestIds.uploadProfilePic.btnUpload}
        >
          <Box
            sx={Styles.uploadIcon}
            data-testid={dataTestIds.uploadProfilePic.divUploadIcon}
          >
            <FontAwesomeIcon
              icon={faUpload}
              data-testid={dataTestIds.uploadProfilePic.iconUpload}
            />
          </Box>
          {profilePic.uploadProfilePic}
        </Button>
        <Grid container direction="column">
          <Grid item sx={Styles.imageTypeSpecification}>
            {profilePic.imageTypeSpecification}
          </Grid>
          <Grid item sx={Styles.imageSizeSpecification}>
            {profilePic.imageSizeSpecification}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadProfilePic;
