import { ContentType, interfaceType, ListType } from '../types';

export const examsData: ContentType = {
  header: 'Exams',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: 'Exams: What to Expect',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `All graded exams are formatted consistently across our courses. Graded exams, quizzes, and tests share the following characteristics:`,
          points: [
            `<strong>Location:</strong> Exams are found within your course topics and are indicated if they are required or not`,
            `<strong>Attempts:</strong> Unless otherwise noted, you have one (1) attempt only for graded exam.`,
            `<strong>Materials:</strong> <a href="/help-center/academics/exams/#hc_academics_materials" title="Permitted and non permitted exam materials">Permitted and Non Permitted Exam materials</a>`,
            `<strong>Response Editing:</strong> You can review and change your answers throughout the duration of the exam.`,
            `<strong>Exam Review:</strong> At the end of your exam, you can <a href="/help-center/academics/exams/#hc_academics_review" title="Review questions">review the questions</a> you were asked after your exam answers are submitted. We provide all questions and answers and show you which questions you answered correct or incorrect. (Hint: this tool is also a great study resource for future exams)`,
          ],
        },
      ],
    },
    {
      title: '<a id="hc_academics_materials"></a>Exam Materials',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `For all exams, except the <a href="/help-center/academics/exams/#hc_academics_final" title="final exam">final exam,</a> you may use the following reference materials:`,
          points: [
            `If your course has an eTextbook, or if you have the physical book, you may use it as a resource during your exam`,
            `Other assigned reading materials listed in the course syllabus`,
            `One (1) sheet of 8 ½ X 11 paper (front and back) of prepared notes`,
            `Blank scratch paper`,
            `A pen or pencil`,
            `A calculator (for math and science courses requiring calculations only, please note what type of calculator, graphing vs. scientific, is permitted for your specific exam)`,
          ],
        },
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `Use of the following resources, services, and aids during exams is <strong>strictly prohibited:</strong>`,
          points: [
            `Additional texts or reference materials not listed within the course syllabus`,
            `Search engines (Google.com, Ask.com, Yahoo Answers, etc.)`,
            `Question and answer sites`,
            `Study sites`,
            `Tutoring`,
            `Forums`,
            `Chat applications`,
            `Landline or cellular phones`,
          ],
        },

        `By submitting your answers for a graded exam, you confirm that the work is your own and that you're taking the exam in accordance with the <a href="${window.location.origin}/help-center/student-handbook/privacy-policy" title="StraighterLine Academic Honesty Policy">StraighterLine Academic Honesty Policy.`,
      ],
    },
    {
      title: '<a id="hc_academics_review"></a>Reviewing Your Exam Answers',
      sections: [
        `For all exams, except the <a href="/help-center/academics/exams/#hc_academics_final" title="final exam">final exam,</a> you can review the questions you were asked after your exam answers are submitted. We provide all questions and answers and show you which questions you answered correct or incorrect. Reviewing previous exams is a great way to study!`,

        {
          type: interfaceType.IMAGE_TYPE,
          title: `Example exam review:`,
          image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academics/exam_review_example.png`,
        },
      ],
    },
    {
      title: 'Open-Ended Questions',
      sections: [
        `In the case where the exam or quiz contains an <a href="/help-center/academics/written-assignments/" title="Written assignments">“essay” or “open-ended” question,</a> this portion of the exam will be <a href="/help-center/academics/written-assignments/#hc_written_turnaroundtimes" title="Written assignments"> graded within 3-5 business days.</a> Once this grading is complete, a final grade for the will be posted for the exam.`,
      ],
    },
    {
      title: 'Exam Retake Policy',
      sections: [
        `With the exception of the <a href="/help-center/academics/course-retakes/#hc_retakes_firstassignment" title="First Exam in Your Course"> first exam in your course,</a> you cannot retake quizzes and exams or change your answers once you've submitted the entire graded exam. In limited cases, exams may be reset due to technical issues. If this happens and we confirm the attempt has been cleared, your score is permanently deleted and you must retake the exam in its entirety. If you require assistance due to a technical difficulty, please contact a Student Advisor.`,
      ],
    },
    {
      title: 'Practice Exams',
      sections: [
        `Most courses have practice exams throughout the course to test your knowledge of topics and materials covered. While these exams are not required as part of the <a href="/help-center/academics/grading/#hc_grading_completion_criteria" title="Completion Criteria">completion criteria</a> for your course, we recommend leveraging these resources as a tool to  help you prepare for the required exams that will count towards your grade.`,
      ],
    },
    {
      title: '<a id="hc_academics_final"></a>Final Exams',
      sections: [
        `StraighterLine requires <a href="/help-center/proctoring/" title="Proctoring">proctored final exams</a> for all courses with the exception of English Composition courses, 1-credit lab courses and developmental courses. During proctoring sessions, student identification is authenticated and the student and workplace are monitored during the entirety of the exam.`,
      ],
    },
    {
      title: 'Contest a Question',
      sections: [
        `If you believe a question on your exam was marked incorrectly, please create a <a href="${window.location.origin}/help-center/support-request" title="Create a Support Request">Support Request</a> and include the exam name, question number and a detailed explanation of your reason for contestation. A Subject Matter Expert will review your request and will respond within 1-3 business days.`,
      ],
    },
    {
      title: 'Exam Sharing or Distribution',
      sections: [
        `StraighterLine's test banks are intellectual property and correct answers may not be shared publicly or otherwise, including with any other student. Any sharing of test bank questions is a violation of the StraighterLine Student Handbook and subjects violators to immediate course failure, removal from StraighterLine's learning management system, and the restriction of access to any of StraighterLine's affiliate websites.`,
      ],
    },
  ],
};
