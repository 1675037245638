import {
  acrobatiqIssuer,
  acrobatiqDeploymentId,
  acrobatiqClientId,
  acrobatiqLaunchInitURL,
  acrobatiqHostURL,
} from '../constants';
import { AcrobatiqLoginParams } from '../types/contract';
import { ProctorProvider } from '../types/global';
import { getProfile } from './auth.util';

export const acrobatiqLoginFormHtml = (providerData: AcrobatiqLoginParams) => `
<!doctype html>
<html>
<body class="page-bare  lti-launch-index">
        <div style="display:none;">
            <form id="ltiLaunchForm" name="ltiLaunchForm" method="POST"
                action="${providerData?.launchInitUrl}"
                encType="application/x-www-form-urlencoded">
                <input type="hidden" name="login_hint" value="${providerData?.loginHint}">
                <input type="hidden" name="lti_deployment" value="${providerData?.ltiDeployment}">
                <input type="hidden" name="client_id" value="${providerData?.clientId}">
                <input type="hidden" name="lti_message_hint" value="${providerData?.ltiMessageHint}">
                <input type="hidden" name="target_link_uri" value="${providerData?.targetLinkUri}">
                <input type="hidden" name="iss" value="${providerData?.iss}">
                <button type="submit" name="LTIForm">Click to continue to your course.</button>
            </form>
        </div>
        <script type="text/javascript">
	        document.ltiLaunchForm.submit();
        </script>
</body>
</html>
    `;

export const getAcrobatiqLoginParams = (
  providerCourseName: string | null,
  providerCourseId: string,
  resourceLinkId: string,
  isResume: boolean,
): AcrobatiqLoginParams => {
  const loginHint = getLoginHint();
  if (loginHint) {
    loginHint.CourseId = providerCourseId;
    loginHint.source = 'sl+';
  }
  const targetUrl = getTargetLinkUri(
    providerCourseName,
    providerCourseId,
    resourceLinkId,
    isResume,
  );
  return {
    loginHint: JSON.stringify(loginHint).replaceAll('"', "'"),
    iss: acrobatiqIssuer,
    ltiDeployment: acrobatiqDeploymentId,
    clientId: acrobatiqClientId,
    launchInitUrl: acrobatiqLaunchInitURL,
    targetLinkUri: targetUrl,
    ltiMessageHint: targetUrl,
  };
};

const getTargetLinkUri = (
  providerCourseName: string | null,
  providerCourseId: string,
  resourceLinkId: string,
  isResume: boolean,
) => {
  const targetUrl = `${acrobatiqHostURL}/${providerCourseName}?custom_course_key=${providerCourseId}`;
  const addtionalParams = isResume
    ? `/en-us/courseware/last_location/${providerCourseId}`
    : `/en-us/courseware/${providerCourseId}/${resourceLinkId}`;
  return `${targetUrl}&custom_deep_link=${addtionalParams}`;
};

const getLoginHint = () => {
  const profile = getProfile();
  if (profile) {
    return {
      Id: profile.id,
      Email: profile.email,
      GivenName: profile.firstName,
      FamilyName: profile.lastName,
      CourseId: '',
      source: '',
    };
  }
};

export const isAcrobatiqCourse = (identifier: string): boolean => {
  return identifier.toLowerCase() === ProctorProvider.Acrobatiq;
};

export const acrobatiqProviderHandler = (
  providerCourseName: string | null,
  providerCourseId: string,
  resourceLinkId: string,
  isResume: boolean,
) => {
  const providerLoginParams = getAcrobatiqLoginParams(
    providerCourseName,
    providerCourseId,
    resourceLinkId,
    isResume,
  );
  initiateAcrobatiqLogin(providerLoginParams);
};

export const initiateAcrobatiqLogin = (providerData: AcrobatiqLoginParams) => {
  const formHtml = acrobatiqLoginFormHtml(providerData);
  const popupWindow = window.open('', '_blank');
  popupWindow?.document.write(formHtml);
};
