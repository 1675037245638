import { ContentType, interfaceType, ListType } from '../types';

export const labsData: ContentType = {
  header: 'Labs',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: 'Lab Courses',
      sections: [
        `StraighterLine lab courses are designed as a standalone addition to our science courses. We strongly recommend that students take labs in conjunction with the lecture course. Students will complete at home <a href="#hc_labs_whattoexpect" title="Lab Experiments, What to Expect">laboratory experiments</a>, track and record results and take lab-based <a href="/help-center/academics/exams/" title="StraighterLine Exams">exams</a> to meet the lab requirement. To successfully complete your lab course, you must complete all required assignments.`,

        `To understand how your grade will be calculated for your online lab course, please review your course syllabus. Your course syllabus includes an overview of course topics and how each assignment contributes to your grade in the course.`,
      ],
    },
    {
      title: '<a id="hc_labs_labkits"></a>Lab Kits',
      sections: [
        `As part of the curriculum, students are responsible for purchasing a custom, physical lab science kit. Lab kits can be purchased at <a href="http://www.esciencelabs.com/" target="_blank" title="eScience Labs">eScience Labs</a> using the Kit Code that is located on your course syllabus or on the course detail page in our catalog. Please allow for at <strong>1-2 weeks shipping time.</strong>`,

        `Your lab kit will be delivered to your door and contains the materials that are essential to completing the required <a href="#hc_labs_whattoexpect" title="Lab Experiments, What to Expect">lab experiments</a> in your course; which allows you to complete your lab in the comfort of your home without having to travel or acquire any specialized lab equipment.`,
      ],
    },
    {
      title: 'Additional Materials',
      sections: [
        `Some StraighterLine lab courses require household items to conduct experiments such as a kitchen knife, scissors, or aluminum foil. Most of these items can be found at home, or are easy to purchase at a local store. If your lab course does require household items, you can download the list in your course.`,
      ],
    },
    {
      title: '<a id="hc_labs_whattoexpect"></a>Lab Experiments: What to Expect',
      sections: [
        `When your at-home kit arrives, please follow the step-by-step process for completing each lab experiment using the provided, downloadable <a href="#hc_labs_worksheets" title="Lab Worksheets">lab worksheets</a> in your course.`,

        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `All lab experiments are formatted consistently throughout our courses. Labs share the following characteristics:`,
          points: [
            `<strong>Location:</strong> Lab assignments are located with your course topics and are indicated if they are required or not`,
            `<strong>Lab Worksheets:</strong> Each lab includes a downloadable <a href="#hc_labs_worksheets" title="Lab Worksheets">lab worksheet</a> that you will use complete and submit your lab.`,
            `<strong>Photographs:</strong> Your lab submissions must include photographs of your completed experiment. <a href="#hc_labs_photograph" title="Photographing Your Lab">Photographs</a> must include an index card or sheet of paper with your First Name and Last Name.`,
            `<strong>Submissions:</strong> Unless otherwise noted, you have one (1) <a href="#hc_labs_submitting" title="Submitting Your Lab">submission</a> only for lab assignments.`,
            `<strong>Format of submission:</strong> Instructions for <a href="/help-center/academics/written-assignments/#hc_written_fileformats" title="Accepted File Formats">file type</a>, file naming and any specific details for the assignment are located in the assignment instructions and lab worksheet.`,
            `<strong>Grading:</strong> Labs are graded individually by graders in the order in which they are received. <a href="/help-center/academics/written-assignments/#hc_written_turnaroundtimes" title="Grading Turnaround Times">Grades</a> are posted to your assignment along with any specific <a href="/help-center/academics/written-assignments/#hc_written_reviewfeedback" title="Reviewing Assignment Feedback">feedback</a> through <a href="/help-center/academics/written-assignments/#hc_written_turnitin" title="TurnItIn and Plagiarism Detection">TurnItIn.</a>`,
            `<strong>Save your materials!</strong> While it might be your first instinct to throw away your experiment materials after you photograph your lab, please keep the experiments until you receive a final grade for the assignment. You should also check future lab experiments as they might require use of the same materials.`,
          ],
        },
      ],
    },
    {
      title: '<a id="hc_labs_worksheets"></a>Lab Worksheets',
      sections: [
        `Each lab includes a downloadable lab worksheet that you will use complete and submit your lab. Please complete all questions in the worksheet and be sure to include your First and Last Name (as it appears in your StraighterLine Account) and your custom Lab Kit Code, which can be found on the lid of your purchased <a href="#hc_labs_labkits" title="Lab Kits">Lab Kit.</a>`,

        {
          type: interfaceType.IMAGE_TYPE,
          title: `Below is a sample, Lab Worksheet:`,
          image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academics/lab_worksheet_example.png`,
        },
      ],
    },
    {
      title: '<a id="hc_labs_photograph"></a>Photographing Your Lab',
      sections: [
        `All labs that are submitted for grading must include a photograph, or photographs, of the completed experiment. All photographs must include an index card or sheet of paper that has your First Name and Last Name written or typed legibly (as it appears in your <a href="${window.location.origin}/help-center/account/account-management/#hc_am_acct_info" title="StraighterLine Account Information">StraighterLine Account).</a>`,

        {
          type: interfaceType.IMAGE_TYPE,
          title: `Below is a sample, well formatted photograph:`,
          image: `https://s3.amazonaws.com/straighterline-web/images/help-center/academics/lab_photo_example.JPG`,
        },
      ],
    },
    {
      title: '<a id="hc_labs_submitting"></a>Submitting Your Lab',
      sections: [
        `To submit your experiment, you will upload the completed <a href="#hc_labs_worksheets" title="Lab Worksheets">lab worksheet</a> and <a href="#hc_labs_photograph" title="Photographing Your Lab">photographs</a> of your experiment for grading. All photographs must include an index card or sheet of paper with your First Name and Last Name. Be sure to <strong>save your experiment materials</strong> in case there are questions during grading or they are needed for future experiments.`,

        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `Follow the steps below to submit your lab for grading:`,
          points: [
            `Locate the lab assignment in your course`,
            `Click “My Submissions” tab`,
            `Follow the instructions to upload your lab`,
            `Complete the <a href="#hc_labs_uploadchecklist" title="Lab Upload Checklist">Upload Checklist</a> to ensure that all files and information are included in your lab.`,
          ],
        },
      ],
    },
    {
      title: '<a id="hc_labs_uploadchecklist"></a>Lab Upload Checklist',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `Before submitting your lab, complete the checklist below. This will help ensure there are no delays due to errors with the assignment grading.`,
          points: [
            `Clearly state your First and Last Name on your <a href="#hc_labs_worksheets" title="Lab Worksheets">lab worksheet</a>. Your name must be listed  exactly the way it is documented in your StraighterLine account? (e.g., if your middle initial is in the system, is it on your paper?)`,
            `Clearly state your Lab Kit Code on your lab worksheet (can be found on the underside of the lid to your lab kit)`,
            `Include <a href="#hc_labs_photograph" title="Photographing Your Lab">photographs</a> with your name clearly visible`,
            `Confirm that you properly cited external sources. Plagiarized submissions will be flagged by the grader and you will need to resubmit a revised version to receive a grade.`,
            `Save your assignment in an <a href="/help-center/academics/written-assignments/#hc_written_fileformats" title="Accepted File Formats">acceptable file format</a>`,
            `Check to make sure you are uploading the correct assignment (this might sound like a no-brainer, but it happens all the time!)`,
          ],
        },
      ],
    },
  ],
};
