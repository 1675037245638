import { ContentType, interfaceType, ListType } from '../types';
export const comptiaTrainingCoursesData: ContentType = {
  header: 'CompTIA Training Courses',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: '<a id="hc_comptia_training_courses"></a>CompTIA Training Courses',
      sections: [
        `StraighterLine CompTIA training courses are designed to help students prepare for the CompTIA A+ and CompTIA Network+ <a href="#hc_comptia_certification_exam" title="CompTIA Certification Exam">certification examinations</a>. Courses include access to a comprehensive <a href="${window.location.origin}/help-center/etextbooks" title="StraighterLine eTextbooks">eTextbook</a>, hundreds of supplemental <a href="#hc_comptia_training_videos" title="Training Videos">training videos</a>, and <a href="#hc_comptia_lab_assignments" title="CompTIA Lab Assignments">lab assignments</a>. To successfully complete your CompTIA training course, you must complete all required <a href="${window.location.origin}/help-center/academics/exams" title="StraighterLine Exams">exams</a>. To understand how your grade will be calculated, please review your course syllabus. Your course syllabus includes an overview of course topics and an explanation of how each assignment contributes to your grade in the course.`,
      ],
    },
    {
      title:
        '<a class="anchor" id="hc_comptia_prerequisites"></a>Prerequisites',
      sections: [
        `There are no prerequisites for taking CompTIA training courses. However, CompTIA does note that some familiarity with computers and operating systems is helpful for students interested in becoming CompTIA A+ certified, and CompTIA recommends that students receive their CompTIA A+ certification before attempting their Network+ certification, but it is not considered a prerequisite.`,
      ],
    },
    {
      title:
        '<a class="anchor" id="hc_comptia_lab_assignments"></a>Lab Assignments',
      sections: [
        `All labs in CompTIA training courses are practice exercises designed to help you prepare for the CompTIA certification exams. You can find a comprehensive list of recommended lab materials in the first topic of your course, and each lab also contains a list of required materials within the assignment. For the best study and practice experiences, you should ideally have access to all of the following for each CompTIA course:`,

        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `<strong>CompTIA A+ Equipment List</strong>`,
          points: [
            `A digital multimeter`,
            `An antistatic wrist strap and grounding mat`,
            `A basic PC toolkit including several sizes of non-magnetized Phillips screwdrivers`,
            `A desktop PC that you can take apart`,
            `A laptop PC that you can take apart`,
            `Access to the following Windows versions: Windows 7, Windows 8/8.1`,
            `Access to the following other operating desktop systems: OS X and Linux`,
            `Access to at least one iOS device (iPhone or iPad)`,
            `Access to at least one Android device (tablet or phone)`,
            `At least one printer or multi-function device (printers/scanner/copier/fax)`,
            `At least one 802.11 wireless router`,
          ],
        },
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `<strong>CompTIA Network+ Equipment List</strong>`,
          points: [
            `Access to the modern operating systems: Windows (7 or newer), OS X, and Linux`,
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.Unordered,
              title: `Software (most if not all of these you can find free versions of):`,
              points: [
                `Packet sniffer`,
                `Port scanner`,
                `Wi-Fi Analyzer`,
                `Firewall`,
              ],
            },
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.Unordered,
              title: `Nice to have equipment (not required):`,
              points: [
                `Crimper`,
                `Cable tester`,
                `Punchdown tools`,
                `Wireless router`,
                `Switch (networking)`,
                `Unshielded twisted pair (UTP) cable`,
                `RJ45s`,
              ],
            },
          ],
        },

        `StraighterLine does not have lab materials available for purchase, but any materials for labs you wish to complete can be purchased at the retailer of your choice. Check out sites like <a href="https://www.amazon.com/" target="_blank" title="Amazon">Amazon</a> or <a href="https://www.newegg.com/" target="_blank" title="New Egg">New Egg</a> for new materials, and consider visiting consignment or thrift stores for access to older computers that can used for assignments that require disassembly.`,
      ],
    },
    {
      title:
        '<a class="anchor" id="hc_comptia_training_videos"></a>Training Videos',
      sections: [
        `StraighterLine CompTIA courses come with over 130 video lectures that are meant to serve as an overview of the detailed exam objectives covered in the eTextbook, labs, and <a href="${window.location.origin}/help-center/academics/exams" title="StraighterLine Exams">exams</a>. Exam questions may be covered by the videos, but they are not comprehensive and are considered supplemental material to prepare for both StraighterLine exams and <a href="#hc_comptia_certification_exam" title="CompTIA Certification Exam">CompTIA certification</a>.`,
      ],
    },
    {
      title:
        '<a class="anchor" id="hc_comptia_certification_exam"></a>CompTIA Certification Exam',
      sections: [
        `StraighterLine’s CompTIA training courses prepare you for taking the CompTIA A+ and Network+ certification exams, but completing the training course does not mean you are CompTIA certified. To receive CompTIA certification, you will need to take one of the CompTIA certification exams, offered exclusively by <a href="https://home.pearsonvue.com/" target="_blank" title="Pearson VUE">Pearson VUE</a>. All CompTIA exams are taken in-person at an approved testing center. NOTE: CompTIA A+ has two certification exams while CompTIA Network+ only has one exam.`,

        `<a href="https://certification.comptia.org/testing/schedule-exam" target="_blank" title="Schedule Your CompTIA Certification Exam">Schedule Your CompTIA Certification Exam</a>`,
      ],
    },
    {
      title:
        '<a class="anchor" id="hc_comptia_submit_proof"></a>How to Submit Proof of Your CompTIA Certification Exam',
      sections: [
        `At the end of your StraighterLine CompTIA training course, there is an assignment called “Certification Attempt Submission,” where you can upload exam attempt proof. You can find information about sharing your exam results on the CompTIA <a href="https://certification.comptia.org/testing/test-policies/sharing-your-exam-results" target="_blank" title="CompTIA website">website</a>.`,
      ],
    },
    {
      title:
        '<a class="anchor" id="hc_comptia_earn_credit"></a>Can I Earn Credit for Completing StraighterLine CompTIA Training Courses?',
      sections: [
        `StraighterLine’s CompTIA training courses are not currently recommended by the American Council on Education (ACE) for credit. However, many schools, including Western Governors University, American Public University, and Excelsior College, consider CompTIA certifications for credit transfer. Contact the admissions department at your target college/university for details about whether CompTIA certifications are transferable for credit.`,
      ],
    },
  ],
};
