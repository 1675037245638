import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  root: {
    pt: `${spacing * 10}px`,
    px: `${spacing * 2}px`,
  },
  container: {
    position: 'relative'
  },
  textHeader: {
    fontSize: `${spacing * 7}px`,
    fontWeight: 700,
  },
  textHeader2: {
    fontSize: `${spacing * 3.5}px`,
    fontWeight: 700,
  },
  boxFlex: { display: 'flex' },
  boxSubheader: { mt: 3, justifyContent: 'space-between' },
  sendTranscriptBtn: {
    bgcolor: themeColors.primary,
    borderRadius: `${spacing * 10}px`,
    color: themeColors.white,
    px: `${spacing * 2}px`,
    py: 0,
    '&:hover': {
      bgcolor: themeColors.primary,
    },
    height: `${spacing * 4.5}px`,
    '@media (max-width: 768px)': { width: '100%', mt: `${spacing * 1}px` },
  },
  paperPlaneIcon: {
    color: themeColors.white,
  },
  sendTranscripts: {
    display: 'inline',
    paddingLeft: `${spacing * 1.25}px`,
    fontSize: `${spacing * 1.5}px`,
    color: themeColors.white,
    textTransform: 'uppercase',
    fontWeight: 900,
  },
};
