import { Typography, Box, Divider, Button } from '@mui/material';
import Styles from './AccountSettings.style';
import ProfileInfoForm from '../../../common-components/profile-info-form/ProfileInfoForm.component';
import {
  AccountSettingsConstants,
  Constants,
  dataTestIds,
  onBoardingConstants,
  Routes,
  US_COUNTRY_ID,
} from '../../../constants';
import { VariantButton, VariantDivider, VariantTypography } from '../../../themes/properties';
import { updateProfile as updateUserProfile } from '../../profileHeader/redux/profileAsync.actions';
import Profile, { Address } from '../../../models/Profile.model';
import { RootState, AppDispatch } from '../../../store';
import {
  resetProfile,
  updateProfileInfo,
} from '../../profileHeader/redux/profileSlice';
import { useSelector, useDispatch } from 'react-redux';
import MailingAddressForm from '../../../common-components/mailing-address-form/MailingAddressForm.component';
import ChangePasswordForm from './ChangePasswordForm.component';
import UploadProfilePic from './UploadProfilePic.component';
import {
  dateFormats,
  isDateValid,
  isUnderAged,
} from '../../../utils/dateUtils';
import {useState, useEffect, useMemo} from 'react';
import UpdateButton from '../UpdateButton.component';
import { useLocation } from 'react-router';
import PurchaseCourseBanner from "../../../common-components/free-trial/PurchaseCourseBanner.component";
import Overlay from "../../../common-components/overlay/Overlay.component";
import {LearnerType} from "../../../types/global";

const AccountSettings = () => {
  const { profileMutable: profileMutable } = useSelector(
    (state: RootState) => state.profile,
  );

  const location = useLocation();

  const { profile: profile } = useSelector((state: RootState) => state.profile);

  const isLoading = useSelector((state: RootState) => state.app.showLoader);
  const dispatch = useDispatch<AppDispatch>();
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    setIsFormEdited(JSON.stringify(profileMutable) !== JSON.stringify(profile));
  }, [profileMutable]);

  useEffect(() => {
    if (location.pathname === Routes.accountSettings) {
      dispatch(resetProfile());
    }
  }, [location.pathname]);

  // function to update the user-profile data in redux-store
  const updateProfile = (updatedProfile: Profile) => {
    dispatch(updateProfileInfo(updatedProfile));
  };

  // function to update the user-profile-address data in redux-store
  const onUpdateAddress = (updatedAddress: Address) => {
    if (profileMutable) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profileMutable, address: updatedAddress }),
      ) as Profile;
      dispatch(updateProfileInfo(updatedProfile));
    }
  };

  const isValidForm = () => {
    if (!profileMutable || !profileMutable.address) {
      return false;
    }
    if (
      !profileMutable.dob ||
      !profileMutable.phone
    ) {
      return false;
    }
    const address = profileMutable.address;
    if (
      !address.address ||
      !address.city ||
      !address.state ||
      !address.zip ||
      (address.country === US_COUNTRY_ID &&
        address.zip.length !==
          onBoardingConstants.personalInfo.mailingAddress.maxLengths.zip) ||
      (address.country !== US_COUNTRY_ID &&
        (address.zip.length <
          onBoardingConstants.personalInfo.mailingAddress.minLengths.zipNonUS ||
          address.zip.length >
            onBoardingConstants.personalInfo.mailingAddress.maxLengths
              .zipNonUS))
    ) {
      return false;
    }

    // if (
    //   profileMutable.phone.length !==
    //   onBoardingConstants.personalInfo.maxLengths.phone
    // ) {
    //   return false;
    // }

    if (
      isUnderAged(profileMutable?.dob) ||
      !isDateValid(profileMutable?.dob, dateFormats.YYYYMMDD_HYPHEN)
    ) {
      return false;
    }

    if (!isFormEdited) {
      return false;
    }

    return true;
  };

  const handleCancel = () => {
    const updatedProfile = JSON.parse(
      JSON.stringify({ ...profile }),
    ) as Profile;
    updateProfile(updatedProfile);
  };

  const handleUpdateSettings = () => {
    setIsUpdated(true);
    dispatch(updateUserProfile());
    setIsFormEdited(false);
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isUpdated) {
      timeoutId = setTimeout(() => {
        setIsUpdated(false);
      }, Constants.CHECK_ICON_TIME);
    }
    return () => clearTimeout(timeoutId);
  }, [isUpdated]);

  const isUserFreeTrial: boolean = useMemo(
    () => profile?.learnerType === LearnerType.FREE_TRIAL,
    [profile?.learnerType],
  );

  return (
    <Box sx={Styles.root}>
      {isUserFreeTrial && <PurchaseCourseBanner />}

      <Box sx={Styles.container}>
        {isUserFreeTrial && <Overlay />}

        <Box data-testid={dataTestIds.accountSettings.divPersonalInfo}>
          <Typography
            variant={VariantTypography.h3}
            sx={Styles.headerTextPersonalInfo}
            data-testid={dataTestIds.accountSettings.textHeader}
          >
            {AccountSettingsConstants.accountSettings}
          </Typography>
          <Typography
            variant={VariantTypography.h4}
            sx={Styles.uploadPhotoContainer}
            data-testid={dataTestIds.accountSettings.textYourPhoto}
          >
            {AccountSettingsConstants.yourPhoto}
          </Typography>
          <UploadProfilePic setIsFormEdited={setIsFormEdited} />
          <Typography
            variant={VariantTypography.h4}
            sx={Styles.headerTextStudentInfo}
            data-testid={dataTestIds.accountSettings.textStudentInfo}
          >
            {AccountSettingsConstants.studentInfo}
          </Typography>
          <Typography
            variant={VariantTypography.h4}
            sx={Styles.subHeaderStudentInfo}
            data-testid={dataTestIds.accountSettings.textStudentInfoSubHeading}
          >
            {AccountSettingsConstants.studentInfoSubHeading}
          </Typography>
          <ProfileInfoForm
            profile={profileMutable}
            updateProfile={updateProfile}
          />
        </Box>
        <Box
          sx={Styles.boxMailingAddres}
          data-testid={dataTestIds.accountSettings.divMailingAddress}
        >
          <Typography
            variant={VariantTypography.h4}
            sx={Styles.headerTextMailingAddress}
            data-testid={dataTestIds.accountSettings.textMailingAddressHeader}
          >
            {onBoardingConstants.personalInfo.mailingAddress.header}
          </Typography>
          <MailingAddressForm
            address={profileMutable?.address ?? null}
            onUpdateAddress={onUpdateAddress}
          />
        </Box>
        <Box
          sx={Styles.boxBtnNext}
          data-testid={dataTestIds.accountSettings.divBtnNext}
        >
          <Button
            autoFocus
            onClick={handleCancel}
            variant={VariantButton.outlined}
            disabled={!isValidForm()}
            sx={Styles.btnCancel}
          >
            {AccountSettingsConstants.cancel}
          </Button>
          <UpdateButton
            isLoading={isLoading}
            isUpdated={isUpdated}
            isValidForm={isValidForm()}
            handleUpdateSettings={handleUpdateSettings}
          />
        </Box>
      </Box>
      <Divider sx={Styles.divider} variant={VariantDivider.middle} />
      <Box>
        <Typography
          sx={Styles.changePasswordBtn}
          data-testid={dataTestIds.accountSettings.textChangePassword}
        >
          {AccountSettingsConstants.changePassword}
        </Typography>
        <Box sx={Styles.passwordContainer}>
          <ChangePasswordForm />
        </Box>
      </Box>
    </Box>
  );
};
export default AccountSettings;
