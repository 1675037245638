import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { VariantTypography } from '../../themes/properties';
import {
  onBoardingConstants,
  required,
  EMPTY_STRING,
  dataTestIds,
} from '../../constants';
import Styles from './styles';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Profile from '../../models/Profile.model';
import HighestEducation from '../../models/HighestEducation.model';
import ReferralType from '../../models/ReferralType.model';

interface PropsType {
  profile: Profile | null;
  updateProfile: (profile: Profile) => void;
}

const MenuProps = {
  PaperProps: {
    style: {
      width: '20%',
      maxHeight: '30%',
    },
  },
};

const AboutYouForm = ({ profile, updateProfile }: PropsType) => {
  const highestEducations: Array<HighestEducation> | null = useSelector(
    (state: RootState) => state.app.masterData?.highestEducations ?? null,
  );

  const referralTypes: Array<ReferralType> | null = useSelector(
    (state: RootState) => state.app.masterData?.referralTypes ?? null,
  );

  const { showLoader } = useSelector((state: RootState) => state.app);
  const showError: boolean = !showLoader;

  // function to hnalde state change event
  const handleChange = (e: SelectChangeEvent) => {
    if (profile) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profile, [e.target.name]: e.target.value }),
      ) as Profile;
      updateProfile(updatedProfile);
    }
  };

  return (
    <Box>
      <Box sx={Styles.formContainer}>
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.textLabel}
          data-testid={dataTestIds.aboutYouForm.labelHighestEducation}
        >
          {onBoardingConstants.profileDetails.highestEducationLabel}
        </Typography>

        <FormControl size="small" sx={Styles.formControl}>
          <InputLabel
            sx={Styles.styleInputLabel}
            data-testid={dataTestIds.aboutYouForm.inputHighestEducation}
          >
            {onBoardingConstants.profileDetails.highestEducation}
          </InputLabel>
          <Select
            label={onBoardingConstants.profileDetails.highestEducation}
            value={profile?.highestEducation ?? EMPTY_STRING}
            name={onBoardingConstants.profileDetails.fields.highestEducation}
            onChange={handleChange}
            MenuProps={MenuProps}
            data-testid={dataTestIds.aboutYouForm.selectHighestEducation}
          >
            {highestEducations?.map((highestEducation: HighestEducation) => (
              <MenuItem
                value={highestEducation.id}
                key={highestEducation.id}
                data-testid={`${dataTestIds.aboutYouForm.selectHighestEducation}${highestEducation.name}`}
              >
                {highestEducation.name}
              </MenuItem>
            ))}
          </Select>
          {!profile?.highestEducation &&
            !profile?.isCollegeUndecided &&
            showError && (
              <Typography
                variant={VariantTypography.body2}
                sx={Styles.required}
                data-testid={
                  dataTestIds.aboutYouForm.textRequiredHighestEducation
                }
              >
                {required}
              </Typography>
            )}
        </FormControl>
      </Box>

      <Box sx={Styles.formContainer}>
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.textLabel}
          data-testid={dataTestIds.aboutYouForm.labelReference}
        >
          {onBoardingConstants.profileDetails.referenceLabel}
        </Typography>

        <FormControl size="small" sx={Styles.formControl}>
          <InputLabel
            sx={Styles.styleInputLabel}
            data-testid={dataTestIds.aboutYouForm.inputReference}
          >
            {onBoardingConstants.profileDetails.reference}
          </InputLabel>
          <Select
            label={onBoardingConstants.profileDetails.reference}
            value={profile?.referredBy ?? EMPTY_STRING}
            name={onBoardingConstants.profileDetails.fields.referredBy}
            onChange={handleChange}
            MenuProps={MenuProps}
            data-testid={dataTestIds.aboutYouForm.selectReference}
          >
            {referralTypes?.map((referralType: ReferralType) => (
              <MenuItem
                value={referralType.id}
                key={referralType.id}
                data-testid={`${dataTestIds.aboutYouForm.menuReference}${referralType?.name}`}
              >
                {referralType?.name}
              </MenuItem>
            ))}
          </Select>
          {!profile?.referredBy && showError && (
            <Typography
              variant={VariantTypography.body2}
              sx={Styles.required}
              data-testid={dataTestIds.aboutYouForm.textRequiredReference}
            >
              {required}
            </Typography>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export default AboutYouForm;
