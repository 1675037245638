import { useSearchParams } from 'react-router-dom';
import { AcrobatiqParams, LTIRedirectUrls } from '../../../constants';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch } from 'react-redux';
import {
  AcrobatiqJWTTokenRequest,
  ProvidersJWTTokenParams,
} from '../../../types/contract';
import {
  acrobatiqCourseAccess,
  getProvidersJWTToken,
} from '../redux/lti13Async.actions';
import { LTIProviders, Providers } from '../../../types/global';

let dispatch: any = null;
const ValidateRequestParams = () => {
  dispatch = useDispatch<AppDispatch>();
  let [searchParams] = useSearchParams();
  let loginHintDetails = searchParams.get(AcrobatiqParams.loginHint);
  if (!loginHintDetails) {
    return (window.location.href = '/');
  }

  let loginHint: AcrobatiqJWTTokenRequest = JSON.parse(
    loginHintDetails.replaceAll("'", '"'),
  );
  console.log(`loginHint`, JSON.stringify(loginHint));
  if (!loginHint.source) {
    return (window.location.href =
      LTIRedirectUrls.MagentoRedirectUrl + searchParams.toString());
  } else {
    console.log('acrobatiq for SL+');
    const providerParams: AcrobatiqJWTTokenRequest = {
      ...loginHint,
      ltiMessageHint: searchParams.get(AcrobatiqParams.ltiMessageHint) ?? '',
      nonce: searchParams.get(AcrobatiqParams.nonce) ?? '',
      state: searchParams.get(AcrobatiqParams.state) ?? '',
      scope: searchParams.get(AcrobatiqParams.scope) ?? '',
    };
    console.log(JSON.stringify(providerParams));
    const courseAccessParams: ProvidersJWTTokenParams = {
      name: LTIProviders.Acrobatiq,
      model: providerParams,
      type: Providers.lti,
    };
    acrobatiqAccessHandler(courseAccessParams);
    // return window.location.href = LTIRedirectUrls.LARedirectUrl + searchParams.toString();
  }
};

const acrobatiqAccessHandler = (providerParams: ProvidersJWTTokenParams) => {
  dispatch(getProvidersJWTToken(providerParams));
};

const Lti13Handler = () => {
  ValidateRequestParams();
  return <div></div>;
};

export default Lti13Handler;
