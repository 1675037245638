import { Box, MobileStepper, Button, useMediaQuery } from '@mui/material';
import Styles from './Styles';
import { Position, VariantMobileStepper } from '../../themes/properties';
import { screenBreakpoints } from '../../constants';

interface PropsType {
  totalSteps: number;
  currentStep: number;
  nextHanlder: () => void;
  backHanlder: () => void;
}

const Footer = ({
  totalSteps,
  currentStep,
  nextHanlder,
  backHanlder,
}: PropsType) => {
  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);
  return (
    <Box sx={Styles.boxFooterRoot}>
      <MobileStepper
        steps={totalSteps}
        position={Position.static}
        activeStep={currentStep}
        nextButton={
          <Button
            sx={Styles.footerBtn}
            onClick={nextHanlder}
            disabled={currentStep === totalSteps - 1}
          >
            Next
          </Button>
        }
        backButton={
          <Button
            sx={Styles.footerBtn}
            onClick={backHanlder}
            disabled={currentStep === 0}
          >
            Previous
          </Button>
        }
        sx={screenPhone ? Styles.textStepDots : null}
      />
    </Box>
  );
};

export default Footer;
