import { hexToRGB, themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  root: {
    borderRadius: `${spacing * 0.5}px`,
    border: `2px solid ${hexToRGB(themeColors.midGrey, 0.3)}`,
    mb: `${spacing * 2}px`,
  },
  boxFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxHeader: {
    padding: `${spacing * 1.5}px ${spacing * 3}px`,
    bgcolor: hexToRGB(themeColors.midGrey, 0.3),
  },
  textHeader: {
    fontSize: `${spacing * 2.75}px`,
    fontWeight: 900,
  },
  textSubHeader: {
    fontSize: `${spacing * 1.75}px`,
    fontWeight: 400,
  },
  boxIconArrow: {
    cursor: 'pointer',
    '& :hover': {
      bgcolor: themeColors.midGrey,
      borderRadius: '50%',
    },
    '& svg': {
      padding: `${spacing * 2}px`,
    },
  },
};
