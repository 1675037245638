import { useMemo } from 'react';
import { Box, Typography, Divider, useMediaQuery } from '@mui/material';
import Profile from '../../models/Profile.model';
import Styles from './ProfileHeader.style';
import { VariantDivider, Orientation } from '../../themes/properties';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CourseProgram from '../../models/CourseProgram.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersRectangle } from '@fortawesome/pro-solid-svg-icons';
import ProfilePic from '../../common-components/profile-pic/ProfilePic.component';
import {
  screenBreakpoints,
  cloudfrontImageBaseUrl,
  dataTestIds,
} from '../../constants';

interface PropsType {
  profile: Profile | null;
  isUserFreeTrial: boolean;
}

const ProfileHeader = ({ profile, isUserFreeTrial }: PropsType) => {
  const screenLaptop = useMediaQuery(`(max-width:${screenBreakpoints.laptop})`);
  const screenIPad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);

  const courseProgramms: Array<CourseProgram> | undefined = useSelector(
    (state: RootState) => state.app.masterData?.courseProgramms,
  );

  const course: CourseProgram | null | undefined = useMemo(() => {
    if (courseProgramms && profile) {
      return courseProgramms.find(
        (program: CourseProgram) => program.id === profile.courseName,
      );
    }
    return null;
  }, [courseProgramms, profile]);

  const profileImageUrl = `${cloudfrontImageBaseUrl}/${profile?.avatar}`;

  return (
    <Box sx={Styles.root} data-testid={dataTestIds.profileHeader.divParent}>
      <Box
        sx={Styles.containerBox}
        data-testid={dataTestIds.profileHeader.divContainer}
      >
        {screenLaptop && (
          <Box
            sx={Styles.boxAvatar}
            data-testid={dataTestIds.profileHeader.divAvatar}
          >
            <ProfilePic imageUrl={profileImageUrl} />
          </Box>
        )}
        <Box
          sx={Styles.namBoxContainer}
          data-testid={dataTestIds.profileHeader.divName}
        >
          <Typography
            sx={Styles.nameText}
            data-testid={dataTestIds.profileHeader.textFirstName}
          >
            {profile?.firstName}
          </Typography>
          <Typography
            sx={Styles.nameText}
            data-testid={dataTestIds.profileHeader.textLastName}
          >
            {profile?.lastName}
          </Typography>
        </Box>

        {!screenIPad && (
          <Divider
            orientation={Orientation.vertical}
            variant={VariantDivider.middle}
            flexItem
            sx={Styles.divider}
          />
        )}

        {!screenIPad && !isUserFreeTrial && (
          <Box
            ml={4}
            sx={Styles.courseBoxContainer}
            data-testid={dataTestIds.profileHeader.divCouseInfo}
          >
            <Box
              sx={Styles.courseInnerBox}
              data-testid={dataTestIds.profileHeader.divCouseInnner1}
            >
              <Box
                sx={Styles.courseIcon}
                data-testid={dataTestIds.profileHeader.divIconUser}
              >
                <FontAwesomeIcon
                  icon={faUsersRectangle}
                  data-testid={dataTestIds.profileHeader.iconUser}
                />
              </Box>
              <Typography
                sx={Styles.courseNameText}
                data-testid={dataTestIds.profileHeader.textCourseName}
              >
                {course?.name}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileHeader;
