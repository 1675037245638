import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

export default {
  root: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '@media (max-width: 480px)': { overflowX: 'hidden', }, 
  },
  leftPanel: {
    background:
      'linear-gradient(180deg, rgba(227, 227, 227, 0.3) 0%, rgba(227, 227, 227, 0) 100%, rgba(227, 227, 227, 0) 100%)',
    pl: `${spacing * 5}px`,
  },
  main: {
    width: '100%',
    px: `${spacing * 5}px`,
    '@media (max-width: 1200px)': { px: `${spacing * 4}px` },
    '@media (max-width: 768px)': { px: `${spacing * 2}px` }, 
  },
  divider: {
    marginTop: `${spacing * 5.5}px`,
    border: `1px solid ${themeColors.midGrey}`,
  },
};
