export default class Assignment {
  constructor(
    public id: number,
    public name: string,
    public isComplete: boolean,
    public grade: string,
    public sortOrder: number,
    public url: string,
    public code: string,
  ) {}
}
