import { themeColors } from '../../themes/colors';

const Styles = {
  actionButton: {
    fontSize: 12,
    backgroundColor: 'primary.main',
    color: themeColors.white,
    width: '100%',
    borderRadius: 50,
    mt: 2,
    '&:hover': {
      bgcolor: themeColors.black,
    },
    '&:disabled': {
      backgroundColor: themeColors.midGrey,
      color: themeColors.black,
    },
    fontWeight: 900,
  },
};

export default Styles;
