import { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';

import Styles from './Navbar.style';
import { themeColors } from '../../themes/colors';

import { MenuType, menus } from './Navbar.types';
import { Properties, Variant, VariantDivider } from '../../themes/properties';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Profile from '../../models/Profile.model';
import {
  Constants,
  cloudfrontImageBaseUrl,
  dataTestIds,
  screenBreakpoints,
} from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfilePic from '../../common-components/profile-pic/ProfilePic.component';

const Navbar = () => {
  const location = useLocation();
  const screenLaptop = useMediaQuery(`(max-width:${screenBreakpoints.laptop})`);
  const menuCount = menus.length;

  const [selectedMenu, setMenu] = useState<MenuType>();
  const navigate = useNavigate();

  const profile: Profile | null = useSelector(
    (state: RootState) => state.profile.profile,
  );

  useEffect(() => {
    const menu: MenuType | undefined = [...menus]
      .reverse()
      .find((menu: MenuType) => {
        if (window.location.search.indexOf('support') === 1) {
          return menu.route.indexOf('support');
        } else {
          return (
            location.pathname.startsWith('/' + menu.route.split('/')[1]) &&
            menu.route.indexOf('support') === -1
          );
        }
      });
    if (menu) {
      setMenu(menu);
    }
  }, [location]);

  const handlerMenu = (menu: MenuType) => {
    setMenu(menu);
    navigate(menu.route);
    window.scrollTo(0, 0);
  };
  const profileImageUrl = `${cloudfrontImageBaseUrl}/${profile?.avatar}`;

  const menuList = (
    <Box sx={Styles.boxMenuList}>
      <List
        sx={Styles.menuList}
        data-testid={dataTestIds.leftNavbar.menuContainer}
      >
        {menus.map((menu: MenuType, index: number) => (
          <ListItem
            key={menu.name}
            disablePadding
            sx={{ ...Styles.menuListItem }}
            onClick={() => handlerMenu(menu)}
            data-testid={menu.nameTestId}
          >
            {!screenLaptop && index + 1 === menuCount && (
              <Divider
                sx={{ ...Styles.divider, mt: 0, mb: 3.5 }}
                variant={VariantDivider.middle}
                data-testid={dataTestIds.leftNavbar.listDivider}
              />
            )}
            <Box sx={Styles.boxMenuContainer}>
              <Box
                sx={{
                  ...Styles.boxMenuIcon,
                  bgcolor:
                    selectedMenu?.name === menu.name
                      ? themeColors.secondary
                      : themeColors.whiteNavbar,
                  color:
                    selectedMenu?.name === menu.name
                      ? themeColors.white
                      : themeColors.black,
                  '&:hover':
                    screenLaptop && menu.name === Constants.TRANSCRIPTS
                      ? null
                      : Styles.boxMenuIcon['&:hover'],
                }}
                data-testid={dataTestIds.leftNavbar.menuIconContainer}
              >
                <FontAwesomeIcon
                  icon={menu.icon}
                  data-testid={menu.iconTestId}
                />
              </Box>
              <ListItemText
                primary={menu.name}
                sx={{
                  ...Styles.menuText,
                  visibility:
                    selectedMenu?.name === menu.name
                      ? Properties.visibility.visible
                      : Properties.visibility.hidden,
                }}
                data-testid={dataTestIds.leftNavbar.listItem}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  if (screenLaptop) {
    return <>{menuList}</>;
  }

  return (
    <Drawer
      variant={Variant.permanent}
      sx={{ ...Styles.drawer }}
      data-testid={dataTestIds.leftNavbar.drawerContainer}
    >
      <Box sx={Styles.root}>
        <Box
          sx={Styles.boxAvatar}
          data-testid={dataTestIds.leftNavbar.profilePicContainer}
        >
          <ProfilePic imageUrl={profileImageUrl} />
        </Box>
        <Divider
          sx={Styles.divider}
          variant={VariantDivider.middle}
          data-testid={dataTestIds.leftNavbar.profileDivider}
        />
        {menuList}
      </Box>
    </Drawer>
  );
};

export default Navbar;
