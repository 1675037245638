import { themeColors, hexToRGB } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  menuContainer: {
    display: 'flex',
    gap: `${spacing * 0.5}px`,
  },

  btnIcon: {
    maxWidth: '32px',
    minWidth: '32px',
    maxHeight: '32px',
    minHeight: '32px',
    p: '20px',
    borderRadius: '50%',
    color: `${themeColors.secondary}`,
    '&:hover': {
      bgcolor: hexToRGB(themeColors.secondary, 0.1),
    },
  },
  icon: {
    cursor: 'pointer',
    mx: 'auto',
    fontSize: 16,
  },
};

export default Styles;
