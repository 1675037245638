import { createSlice } from '@reduxjs/toolkit';
import { SLICE_APP } from './slices';
import Country from '../models/Country.model';
import CourseProgram from '../models/CourseProgram.model';
import {
  getMasterData,
  updateMasterData,
  getInstitutes,
  getTargetInstitute,
} from './appAsync.actions';
import Institute from '../models/Institute.model';
import HighestEducation from '../models/HighestEducation.model';
import ReferralType from '../models/ReferralType.model';
import { SnackbarAnchorSeverity } from '../themes/properties';
import Feature from '../models/Feature.model';

export interface MasterDataType {
  countries: Array<Country>;
  courseProgramms: Array<CourseProgram>;
  highestEducations: Array<HighestEducation>;
  referralTypes: Array<ReferralType>;
  features: Array<Feature>;
}

export interface ToastMessage {
  showToast: boolean;
  message: string | null;
  severity: string;
}

export interface State {
  showLoader: boolean;
  masterData: MasterDataType | null;
  institutes: Array<Institute>;
  toastMessage: ToastMessage;
  targetInstitute: Institute | null;
}

const initialState: State = {
  showLoader: false,
  masterData: null,
  institutes: [],
  toastMessage: {
    showToast: false,
    message: null,
    severity: SnackbarAnchorSeverity.success,
  },
  targetInstitute: null,
};

export const appSlice = createSlice({
  name: SLICE_APP,
  initialState,
  reducers: {
    showLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    showToast: (state, action) => {
      state.toastMessage.showToast = action.payload.show;
      state.toastMessage.severity = action.payload.severity;
      if (!state.toastMessage.showToast) {
        state.toastMessage.message = null;
      } else {
        state.toastMessage.message = action.payload.message;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMasterData.fulfilled, (state: State, action: any) => {
      state.masterData = action?.payload ?? null;
    });
    builder.addCase(updateMasterData.fulfilled, (state: State, action: any) => {
      state.masterData = action?.payload ?? null;
    });
    builder.addCase(getInstitutes.fulfilled, (state: State, action: any) => {
      state.institutes = action?.payload ?? [];
    });
    builder.addCase(
      getTargetInstitute.fulfilled,
      (state: State, action: any) => {
        state.targetInstitute = action?.payload ?? null;
      },
    );
  },
});

export const { showLoader, showToast } = appSlice.actions;

export default appSlice.reducer;
