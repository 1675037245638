import { Box } from '@mui/system';
import {
  ProfileDetailsConstants,
  Constants,
  dataTestIds,
  onBoardingConstants,
} from '../../../constants';
import CollegeCareerForm from '../../../common-components/college-career-form/CollegeCareerForm.component';
import AboutYouForm from '../../../common-components/about-you-form/AboutYouForm.component';
import { Button, Typography } from '@mui/material';
import { VariantButton, VariantTypography } from '../../../themes/properties';
import Styles from './ProfileDetails.style';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import {
  resetProfile,
  updateProfileInfo,
} from '../../profileHeader/redux/profileSlice';
import { updateProfile as updateUserProfile } from '../../profileHeader/redux/profileAsync.actions';
import Profile from '../../../models/Profile.model';
import {useEffect, useMemo, useState} from 'react';
import UpdateButton from '../UpdateButton.component';
import { useLocation } from 'react-router';
import { getInstitutes } from '../../../store/appAsync.actions';
import PurchaseCourseBanner from "../../../common-components/free-trial/PurchaseCourseBanner.component";
import Overlay from "../../../common-components/overlay/Overlay.component";
import {LearnerType} from "../../../types/global";

const ProfileDetails = () => {
  const { profileMutable: profileMutable } = useSelector(
    (state: RootState) => state.profile,
  );
  const location = useLocation();
  const { profile: profile } = useSelector((state: RootState) => state.profile);
  const isLoading = useSelector((state: RootState) => state.app.showLoader);
  const dispatch = useDispatch<AppDispatch>();
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    setIsFormEdited(JSON.stringify(profileMutable) !== JSON.stringify(profile));
  }, [profileMutable]);

  useEffect(() => {
    dispatch(resetProfile());
  }, [location.pathname]);

  useEffect(() => {
    if (!isUserFreeTrial) {
      dispatch(getInstitutes());
    }
  }, []);

  // function to update the user-profile data in redux-store
  const updateProfile = (updatedProfileValue: Profile) => {
    setIsFormEdited(profileMutable !== updatedProfileValue);
    dispatch(updateProfileInfo(updatedProfileValue));
  };

  const handleCancel = () => {
    const updatedProfile = JSON.parse(
      JSON.stringify({ ...profile }),
    ) as Profile;
    updateProfile(updatedProfile);
  };

  const handleUpdateSettings = () => {
    setIsUpdated(true);
    dispatch(updateUserProfile());
    setIsFormEdited(false);
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isUpdated) {
      timeoutId = setTimeout(() => {
        setIsUpdated(false);
      }, Constants.CHECK_ICON_TIME);
    }
    return () => clearTimeout(timeoutId);
  }, [isUpdated]);

  const isValidForm = () => {
    if (!profileMutable) {
      return false;
    }
    if (
      (!profileMutable.targetCollege && !profileMutable.isCollegeUndecided) ||
      !profileMutable.courseName ||
      !profileMutable.highestEducation ||
      !profileMutable.referredBy
    ) {
      return false;
    }

    if (!isFormEdited) {
      return false;
    }

    return true;
  };

  const isUserFreeTrial: boolean = useMemo(
    () => profile?.learnerType === LearnerType.FREE_TRIAL,
    [profile?.learnerType],
  );

  return (
    <Box sx={Styles.root}>
      {isUserFreeTrial && <PurchaseCourseBanner />}

      <Box sx={Styles.container}>
        {isUserFreeTrial && <Overlay />}

        <Box>
          <Typography
            variant={VariantTypography.h3}
            sx={Styles.textPageHeader}
            data-testid={dataTestIds.profileDetails.textHeader}
          >
            {onBoardingConstants.profileDetails.header}
          </Typography>
        </Box>

        <Box sx={Styles.boxHeader}>
          <Typography
            variant={VariantTypography.h3}
            sx={Styles.textHeaderCollege}
            data-testid={dataTestIds.profileDetails.textHeaderAboutYou}
          >
            {onBoardingConstants.profileDetails.aboutYou}
          </Typography>
        </Box>
        <CollegeCareerForm
          updateProfile={updateProfile}
          profile={profileMutable}
        />
        <AboutYouForm updateProfile={updateProfile} profile={profileMutable} />
        <Box sx={Styles.boxBtnNext}>
          <Button
            autoFocus
            onClick={handleCancel}
            variant={VariantButton.outlined}
            disabled={!isValidForm()}
            sx={Styles.btnCancel}
          >
            {ProfileDetailsConstants.cancel}
          </Button>
          <UpdateButton
            isLoading={isLoading}
            isUpdated={isUpdated}
            isValidForm={isValidForm()}
            handleUpdateSettings={handleUpdateSettings}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileDetails;
