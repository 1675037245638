import React, { useEffect } from 'react';
import Styles from './SupportRequests.styles';
import { supportRequests } from '../../../constants';
import SupportAndBillingForm from '../../../common-components/support-request-billing-preference-form/SupportAndBillingForm.component'
import { Divider } from '@mui/material';

const SupportRequests = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  return (
    <div style={Styles.container}>
      <h1 style={Styles.textHeader}>{supportRequests.header}</h1>
      <h6 style={Styles.textSubHeader}>{supportRequests.subHeader}</h6>
      <SupportAndBillingForm />
      <Divider sx={Styles.divider} />
    </div>
  );
};

export default SupportRequests;
