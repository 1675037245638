import Assignment from './Assignment.model';

export default class Topic {
  constructor(
    public name: string,
    public link: string,
    public resourceLinkId: string,
    public code: string,
    public assignments: Array<Assignment>,
    public isComplete: boolean,
    public cwpCode: string,
  ) { }
}
