import { spacing } from '../../../themes/theme';
import { themeColors } from '../../../themes/colors';

export default {
  root: {
    bgcolor: `${themeColors.primary}`,
    borderRadius: `${spacing * 1}px`,
    pt: `${spacing * 2}px`,
    pb: `${spacing * 2}px`,
    mb: `${spacing * 8}px`,
    width: '92%',
    minHeight: `${spacing * 32}px`,
    height: 'auto',
    '@media (max-width: 1300px)': {
      width: '78%',
      minHeight: `${spacing * 35}px`,
    },
    '@media (max-width: 1200px)': {
      width: `${spacing * 36}px`,
      minHeight: `${spacing * 32}px`,
    },
    position: 'relative',
  },
  textHeader: {
    fontSize: `${spacing * 1.5}px`,
    textTransform: 'uppercase',
    color: themeColors.white,
    fontWeight: 700,
    mt: `${spacing * 1}px`,
    letterSpacing: '1px',
    p: `0 ${spacing * 2}px`,
  },
  courseName: {
    fontSize: `${spacing * 2.75}px`,
    color: themeColors.white,
    fontWeight: 700,
    mt: `${spacing * 1.5}px`,
    p: `0 ${spacing * 2}px`,
  },
  courseDescription: {
    fontSize: `${spacing * 1.75}px`,
    color: themeColors.white,
    fontWeight: 400,
    p: `0 ${spacing * 2}px`,
    mt: `${spacing * 1.5}px`,
  },
  dashboardButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    p: `0 ${spacing * 2}px`,
    position: 'absolute',
    bottom: `${spacing * 2}px`,
    left: '8%',
    '@media (max-width: 1300px)': {
      left: `${spacing * 0.6}px`,
    },
  },
  dashboardButton: {
    fontSize: `${spacing * 1.5}px`,
    backgroundImage: `${themeColors.greenGradient}`,
    borderRadius: '50px',
    '&:hover': {
      backgroundImage: `${themeColors.greenGradientOpposite}`,
    },
    paddingTop: `${spacing * 1.5}px`,
    paddingBottom: `${spacing * 1.5}px`,
    paddingLeft: `${spacing * 2.5}px`,
    paddingRight: `${spacing * 2.5}px`,
    width: '197px',
    letterSpacing: '1px',
  },
  footerBtn: {
    fontSize: `${spacing * 2}px`,
    color: themeColors.white,
    px: `${spacing * 2}px`,
    '&:disabled': {
      color: themeColors.primary,
      fontSize: `${spacing * 2}px`,
    },
    '&.MuiButton-root': {
      minWidth: 0,
      padding: 0,
    },
  },
  slideStyle: {
    minWidth: '100%',
    overflow: 'hidden',
  },
  mobileStepper: {
    '& .MuiMobileStepper-dot': {
      display: 'none',
    },
    '&.MuiMobileStepper-root': {
      bgcolor: `${themeColors.transparent}`,
      width: '100%',
      padding: 0,
      position: 'absolute',
      top: '50%',
    },
  },
};
