import { TARGET_COLLEGE_UNDECIDED } from "../constants";

export default class Profile {
  constructor(
    public id: string,
    public customerId: string,
    public firstName: string,
    public lastName: string,
    public avatar: string,
    public courseName: string,
    public membershipExpire: string,
    public targetCollege: string,
    public email: string,
    public dob: string,
    public phone: string,
    public onBoardingStatus: string,
    public highestEducation: string,
    public referredBy: string,
    public isCollegeUndecided: boolean,
    public address: Address | null,
    public learnerType: string
  ) {}
}

export class ProfileBuilder {
  public id!: string;
  public customerId!: string;
  public firstName!: string;
  public lastName!: string;
  public avatar!: string;
  public courseName!: string;
  public membershipExpire!: string;
  public targetCollege!: string;
  public email!: string;
  public dob!: string;
  public phone!: string;
  public onBoardingStatus!: string;
  public address!: Address;
  public highestEducation!: string;
  public referredBy!: string;
  public isCollegeUndecided!: boolean;
  public learnerType!: string;

  setId(id: string) {
    this.id = id;
    return this;
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
    return this;
  }

  setFirstName(firstName: string) {
    this.firstName = firstName;
    return this;
  }

  setLastName(lastName: string) {
    this.lastName = lastName;
    return this;
  }

  setAvatar(avatar: string) {
    this.avatar = avatar;
    return this;
  }

  setCourseName(courseName: string) {
    this.courseName = courseName;
    return this;
  }

  setMembershipExpire(membershipExpire: string) {
    this.membershipExpire = membershipExpire;
    return this;
  }

  setTargetCollege(targetCollege: string) {
    this.targetCollege = targetCollege;
    return this;
  }

  setEmail(email: string) {
    this.email = email;
    return this;
  }

  setDob(dob: string) {
    this.dob = dob;
    return this;
  }

  setPhone(phone: string) {
    this.phone = phone;
    return this;
  }

  setOnBoardingStatus(onBoardingStatus: string) {
    this.onBoardingStatus = onBoardingStatus;
    return this;
  }

  setAddress(address: Address) {
    this.address = address;
    return this;
  }

  setHighestEducation(highestEducation: string) {
    this.highestEducation = highestEducation;
    return this;
  }

  setReferredBy(referredBy: string) {
    this.referredBy = referredBy;
    return this;
  }

  setIsCollegeUndecided(targetCollege: string) {
    this.isCollegeUndecided = targetCollege === TARGET_COLLEGE_UNDECIDED
    return this;
  }

  setLearnerType (learnerType: string) {
    this.learnerType = learnerType;
    return this;
  }

  build() {
    return new Profile(
      this.id,
      this.customerId,
      this.firstName,
      this.lastName,
      this.avatar,
      this.courseName,
      this.membershipExpire,
      this.targetCollege,
      this.email,
      this.dob,
      this.phone,
      this.onBoardingStatus,
      this.highestEducation,
      this.referredBy,
      this.isCollegeUndecided,
      this.address,
      this.learnerType
    );
  }
}

export class Address {
  constructor(
    public address: string,
    public city: string,
    public state: string,
    public zip: string,
    public country: string,
    public addressLine2?: string,
  ) {}
}
