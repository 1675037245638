import { spacing } from '../../../themes/theme';
import { themeColors } from '../../../themes/colors';

export default {
  root: {
    borderRadius: `${spacing}px`,
    border: `1px solid ${themeColors.offWhite}`,
    width: `${spacing * 36}px`,
    '@media (max-width: 1300px)': {
      width: `${spacing * 30}px`,
    },
    '@media (max-width: 1200px)': {
      width: `${spacing * 36}px`,
    },
  },
  header: {
    borderTopLeftRadius: `${spacing}px`,
    borderTopRightRadius: `${spacing}px`,
    backgroundColor: themeColors.secondary,
    p: `${spacing * 2}px ${spacing * 3}px`,
  },
  textHeader: {
    fontSize: `${spacing * 1.5}px`,
    color: themeColors.white,
    textTransform: 'uppercase',
    fontWeight: 900,
  },

  contentItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${spacing * 2}px`,
    p: `${spacing * 2}px ${spacing * 3}px`,
    borderBottom: `1px solid ${themeColors.offWhite}`,
  },

  row: {
    display: 'flex',
    alignItems: 'start',
    gap: `${spacing}px`
  },
  contentItemIcon: {
    width: `${spacing * 2.5}px`,
    color: themeColors.secondary,
  },
  contentItemText: {
    color: themeColors.blackLight,
  },

  actionContainer: {
    p: `${spacing * 2}px ${spacing * 3}px`,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    color: themeColors.white,
    backgroundImage: `${themeColors.greenGradient}`,
    borderRadius: '50px',
    '&:hover': {
      backgroundImage: `${themeColors.greenGradientOpposite}`,
    },
  }
};
