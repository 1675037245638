import { themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';

const Styles = {
  root: {
    mt: `${spacing * 8}px`
  },
  container: {
    position: 'relative'
  },
  headerTextPersonalInfo: {
    mt: `${spacing * 2}px`,
    fontSize: `${spacing * 7}px`,
    fontWeight: 700,
    mb: `${spacing * 4}px`,
    fontFamily: `Atkinson Hyperlegible`,
    '@media (max-width: 768px)': { fontSize: `${spacing * 5}px` },
  },
  headerTextStudentInfo: {
    fontSize: `${spacing * 3.5}px`,
    fontWeight: 700,
    mb: `${spacing}px`,
  },
  subHeaderStudentInfo: {
    fontSize: `${spacing * 1.75}px`,
    fontWeight: 400,
    mb: `${spacing * 4}px`,
    fontStyle: 'italic',
  },
  boxMailingAddres: { mt: `${spacing * 6}px` },
  headerTextMailingAddress: {
    fontSize: `${spacing * 3.5}px`,
    fontWeight: 700,
    mb: `${spacing * 4}px`,
  },
  boxBtnNext: {
    mt: `${spacing * 5}px`,
    mb: `${spacing * 5}px`,
    textAlign: 'right',
  },
  btnCancel: {
    fontSize: `${spacing * 1.5}px`,
    border: `1px solid ${themeColors.grey}`,
    borderRadius: `${spacing * 25}px`,
    height: `${spacing * 5}px`,
    letterSpacing: `${spacing * 0.25}px`,
    mr: `${spacing * 1.25}px`,
  },
  btnNext: {
    bgcolor: themeColors.primary,
    border: `1px solid ${themeColors.grey}`,
    color: themeColors.white,
    height: `${spacing * 5}px`,
    width: `${spacing * 25}px`,
    borderRadius: `${spacing * 25}px`,
    '&:hover': {
      bgcolor: themeColors.primary,
      color: themeColors.grey,
    },
    letterSpacing: `${spacing * 0.25}px`,
    fontSize: `${spacing * 1.5}px`,
    transition: 'width 0.5s', // Apply transition to width property
  },
  changePasswordBtn: {
    fontSize: `${spacing * 3.5}px`,
    fontWeight: 700,
    paddingTop: `${spacing * 1.5}px`,
  },
  passwordContainer: {
    backgroundColor: themeColors.lightBlue,
    paddingTop: `${spacing * 2}px`,
    paddingBottom: `${spacing * 3}px`,
    borderRadius: `${spacing * 1}px`,
    marginTop: `${spacing * 4}px`,
  },
  uploadPhotoContainer: {
    fontSize: `${spacing * 3.5}px`,
    fontWeight: 700,
    mb: `${spacing * 3}px`,
  },
  imageContainer: {
    marginBottom: `${spacing * 3}px`,
  },
  avatar: {
    width: `${spacing * 12.5}px`,
    height: `${spacing * 12.5}px`,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  profileAvatar: {
    height: spacing * 12,
    width: spacing * 12,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover  + .fa-solid': {
      display: 'block',
    },
    '& .MuiAvatar-img': {
      objectFit: 'cover',
    },
    '@media (max-width: 768px)': { height: spacing * 10, width: spacing * 10 },
  },
  uploadImageBtn: {
    bgcolor: themeColors.white,
    color: themeColors.primary,
    height: `${spacing * 5}px`,
    width: `${spacing * 31}px`,
    borderRadius: `${spacing * 25}px`,
    '&:hover': {
      bgcolor: themeColors.midGrey,
      color: themeColors.primary,
      boxShadow: 'none',
    },
    letterSpacing: `${spacing * 0.25}px`,
    fontSize: `${spacing * 1.5}px`,
    border: '1px solid black',
    fontWeight: 700,
    boxShadow: 'none',
    marginLeft: `${spacing * 4}px`,
    diplay: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': { width: `${spacing * 25}px` },
    '@media (max-width: 480px)': { ml: 0 },
  },
  imageTypeSpecification: {
    fontSize: `${spacing * 1.75}px`,
    marginLeft: `${spacing * 4}px`,
    marginTop: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': { ml: 0 },
  },
  imageSizeSpecification: {
    fontSize: `${spacing * 1.75}px`,
    marginLeft: `${spacing * 4}px`,
    '@media (max-width: 480px)': { ml: 0 },
  },
  uploadIcon: {
    fontSize: `${spacing * 2}px`,
    mr: `${spacing}px`,
  },
  buttonIcons: {
    color: themeColors.white,
  },
  divider: {
    width: '100%',
    mx: 'auto',
    mb: 2.5,
    mt: 5.5,
    border: `1px solid ${themeColors.midGrey}`,
  },
};

export default Styles;
