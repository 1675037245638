import { ContentType, ListType, interfaceType } from '../types';

export const StudentCodeOfConduct: ContentType = {
  header: 'Student Code of Conduct',
  subHeader: '',
  sections: [
    {
      title: '',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'StraighterLine has an established Student Code of Conduct, the tenets of which apply equally to all students and their conduct, whether academic or administrative. When taking a course at StraighterLine, all students are expected to be familiar with this Code, including the Academic Integrity Statement and the Academic Integrity Policy. Students are expected to uphold the following tenants:',
          points: [
            `answer application or registration information questions truly and accurately.`,
            `keep passwords and other account access information for StraighterLine and its partners confidential.`,
            `complete and submit all assessments personally without unsanctioned use of artificial intelligence tools, resources or other technologies; do not permit another student, a family member, person or technological entity to act on their behalf in the completion of graded work.`,
            `report suspicious or known violations of this Code as soon as possible.`,
            `provide documentation to verify identity, answer questions designed to verify  identity,  and  participate in biometric verification when requested prior to, during, and/or after examinations, assignment submissions, logins, during phone calls or any other interaction with StraighterLine’s staff, faculty, partners, or web-presence.`,
            `participate in proctoring during examinations, and timed writing exercises, as required by StraighterLine, whether applied to all students or in student-specific circumstances.`,
            `understand that information, incidents, and data collected (including electronic device data) from student interactions with the StraighterLine’s web-presence may result in invalidation of course completion or denial of requested transcripts for courses where all activity cannot be verified to have been completed by the student enrolled.`,
            `avoid actions that create a hostile or threatening environment for others.`,
          ],
        },
      ],
    },
    {
      title: 'Academic Integrity Statement',
      header: `Academic Integrity Policy`,
      sections: [
        `Academic integrity is the pursuit of scholarly activity in an honest, truthful and responsible manner. Violations of academic integrity include, but are not limited to, plagiarism, cheating, fabrication and academic misconduct.  Failure to comply with the Academic Integrity Policy can result in a failure and/or zero on the attempted assignment/examination, a removal from the course, disqualification to enroll in future courses, and/or revocation of an academic transcript. 
        `,
      ],
    },
    {
      title: 'Plagiarism',
      header: 'Definitions and Examples of Academic Dishonesty',
      sections: [
        `Plagiarism is the inclusion of someone's previously documented words, ideas, or data in one's own new and original work. When a student submits work related to their academic coursework that includes the words, ideas, or data of others, including one's own previously submitted work, the source of that information must be acknowledged through complete, accurate, and specific references; and, if verbatim statements are included, through quotation marks as well. By placing their name on work submitted, the student certifies the originality of all work not otherwise identified by appropriate acknowledgments.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: `Plagiarism covers unpublished as well as published sources. Examples of plagiarism include, but are not limited to:`,
          points: [
            `Quoting another person's actual words, complete sentences or paragraphs, or an entire piece of written work without acknowledgment of the source.`,
            `Using another person's ideas, opinions, or theory, even if it is completely paraphrased in one's own words, without acknowledgment of the source.`,
            `Using one's own previously submitted work as new and original and/or without appropriate attribution.`,
            `Using facts, statistics, or other illustrative materials that are not clearly common knowledge without acknowledgment of the source.`,
            `Copying another student's work.`,
            `Copying, or allowing another student to copy, a document/assignment that contains another student's assignment and submitting it, in part or in its entirety, as one's own.`,
            `Collaborating on an assignment or sharing work, then submitting individual copies of the assignment as one's own individual work. `,
            `Using images, words, or ideas created by another, (e.g. through the use of DALL-E or another image-generating tool, ChatGPT or another AI text-based tool) when not authorized.`,
          ],
        },
      ],
    },
    {
      title: 'Cheating',
      sections: [
        `Cheating is an act or an attempted act of deception by which a student seeks to misrepresent that they have mastered information or a skill on an academic assessment, such as (by example, not limitation) a test, exam, essay, or quiz, that has not in fact been mastered. Examples include, but are not limited to:`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: ``,
          points: [
            `Copying from another student's work.`,
            `Knowingly and/or actively allowing another student to copy from an assignment, exam, essay, quiz, or similar assessment.`,
            `Unauthorized use of a course textbook, artificial intelligence (AI) writing tool, such as (by example, not by limitation) ChatGPT, or other materials, such as (by example, not limitation) a notebook or website, to complete an examination or other assessment.`,
            `Collaborating on an examination, test, essay, quiz, or other project with any other person(s) or technological entity without authorization.`,
            `Inappropriately hosting, posting, sharing, or accessing materials in online or onsite forums, group chats, or other web-based platforms, that are not authorized.`,
            `Using or processing specifically prepared materials during an examination such as (by example, not limitation) notes of any kind, formula lists, calculators, and/or smart devices that are not authorized.`,
            `Taking an examination or crafting a written assessment for someone else or permitting someone else to take an examination or compose a written assessment for you.`,
            `Using an artificial intelligence (AI) tool to gain an improper advantage on an academic assessment when it has not been authorized.`,
          ],
        },
      ],
    },
    {
      title: 'Fabrication',
      sections: [
        `Fabrication is the use of invented information or the falsification of research or other findings. Examples include, but are not limited to:`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: '',
          points: [
            `Citation of information not taken from the source indicated; this may include the incorrect documentation of secondary source materials.`,
            `Listing sources in a bibliography or reference page not used in the composed assessment.`,
            `Submission in a written work, lab report, or other academic assessment of falsified, invented, or fictitious data or evidence; or deliberate and knowing concealment or distortion of the true nature, origin, or function of such data or evidence.`,
            `Submitting as your own any written work, printing, image, sculpture, or other material prepared in whole or in part by another person,  product or technological entity.`,
            `Other and all forms of scientific/lab misconduct.`,
          ],
        },
      ],
    },
    {
      title: 'Academic Misconduct',
      sections: [
        `Academic misconduct includes academically dishonest acts such as taking part in obtaining or distributing any part of an administered or unadministered examination, test, essay, quiz, project, or similar assessment. Examples include, but are not limited to:`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title: '',
          points: [
            `Stealing, buying, or otherwise obtaining all or part of an administered or unadministered examination or written assessment.`,
            `Selling or distributing all or part of an administered or unadministered test or written assessment, including questions and/or answers.`,
            `Bribing or paying a person or entity to obtain an administered or unadministered assessment or any information about the assessment.`,
            `Logging in as someone else or permitting someone else or an entity to log in for you in any academic setting.`,
            `Failure to comply with assignment, exam, or test-taking parameters or requirements, as outlined in the course or exam situation, including, but not limited to, use of earbuds/headphones, visible cell phone, use of video recording, talking/noise, or having others in the testing/assignment space.`,
            `Buying or otherwise acquiring, in any way, an assessment, essay, exam, computer software, other written work, painting, drawing, sculpture, scholastic artwork, science lab, and/or video/ audio recording, and submitting it as your own work to fulfill academic requirements.`,
            `Selling, distributing, or otherwise supplying in any way an assessment, essay, exam, computer software, other written work, painting, drawing, sculpture, scholastic artwork, science lab, and/or video/ audio recording to another student for that student's use in fulfilling academic requirements.`,
          ],
        },
      ],
    },
    {
      title: 'Methods for Promoting Academic Integrity',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.UpperAlpha,
          title: '',
          points: [
            `Upon registration, students must read and accept Terms and Conditions along with the information stated in the Handbook.`,
            `The Academic Integrity Policy shall be posted to our website along with the Student Code of Conduct.`,
            `The Academic Integrity Statement will be posted on every syllabus.`,
            `Students must review the Academic Honesty and Integrity Lesson at the start of every course.`,
            `Students are required to sign a statement of agreement or “click” of agreement after reviewing the Academic Honesty and  Integrity Lesson.`,
            `We shall subscribe to an electronic plagiarism and artificial intelligence (AI) detection service and shall notify students of the fact that such a service is available for use by our faculty members; we shall instruct both students and faculty on how to use this software.`,
            `Identity Verification: `,
            {
              type: interfaceType.SUB_LIST_TYPE,
              listType: ListType.LowerAlpha,
              // title: `Identity Verification: `,
              points: [
                `Identity verification includes, but is not limited to, out-of-band processes (calling/texting a number and verifying student information), embedded question verification (answering questions based on publicly available information about the student), biometric verification, passive activity monitoring, analysis of voluntarily-provided electronic device data, and account comparison against established publicly available networks (such as social networks) to our authentication system or proctoring services.`,
              ],
            },
            ` We shall subscribe to an online proctoring/monitoring service using one or more of the following methods: browser lockdown, automated proctoring, live proctoring, and/or blended proctoring.   This service will work in conjunction with our Learning Management System.`,
          ],
        },
      ],
    },
    {
      title: 'Reporting',
      sections: [
        `Administration, Faculty and Staff are required to report and record any academic integrity violations they detect on submitted assessments or work fulfilling an academic requirement, including (by example, not by limitation) an assessment, essay, exam, computer software, other written work, painting, drawing, sculpture, scholastic artwork, science lab, and/or video/ audio recording. Documentation of the violation, including the submitted work, is kept on file as a matter of record, and to determine instances where multiple infractions have occurred.`,
      ],
    },
    {
      title: 'Violations of Academic Integrity and Penalties',
      sections: [
        `When our Faculty, administration, staff, or partners encounter a possible or known violation of academic integrity, they will address the matter– in writing– with the student, and in most cases, on the submitted work itself.  StraighterLine reserves the right to ask a student to provide evidence about sources or make other reasonable requests related to the student work. In most cases, StraighterLine will use detection software to guide us in the identification of a violation of academic integrity, but this is not required.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: '',
          points: [
            `While reviewing an assessment/assignment, if a Faculty member concludes that a student has violated the Academic Integrity Policy, they may immediately assign a sanction of a grade, including, but not limited to, a zero for the assignment. The Faculty member will also offer instructional, remedial, and “next step” directives on said student work.`,
            `Further failure to comply with the Academic Integrity Policy may also result in a removal from the course, disqualification to enroll in future courses, and/or revocation of an academic transcript. This determination will be noted and explained in writing by StraighterLine to the student.`,
          ],
        },
      ],
    },
    {
      title: 'The Academic Integrity Committee',
      sections: [
        `The Academic Integrity Committee is made up of Faculty and administrative members. The purpose of the Committee is to review student work on a case-by-case basis. The Committee meets regularly, and any decision imposed by the Committee is final. In addition, our partners and partner schools may review student work, and identify possible violations of the Academic Integrity Policy using plagiarism detection software, Identity Verification records, user-generated electronic device data, and other tools. These partners, and partner schools, reserve the right to review students’ work and conduct at their discretion.
        `,
      ],
    },
  ],
};
