import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_STRING, onBoardingConstants } from '../../../constants';
import Profile from '../../../models/Profile.model';
import { SLICE_PROFILE } from '../../../store/slices';
import {
  getProfile,
  updateProfile,
  uploadProfileImage,
} from './profileAsync.actions';

export interface State {
  profile: Profile | null;
  profileMutable: Profile | null;
  profileUpdateStatus: string;
}

const initialState: State = {
  profile: null,
  profileMutable: null,
  profileUpdateStatus: EMPTY_STRING,
};

export const profileSlice = createSlice({
  name: SLICE_PROFILE,
  initialState,
  reducers: {
    updateProfileInfo: (state, action) => {
      state.profileMutable = { ...action.payload };
    },
    resetProfile: (state) => {
      state.profileMutable = JSON.parse(
        JSON.stringify(state.profile),
      ) as Profile;
    },
    resetProfileUpdateStatus: (state) => {
      state.profileUpdateStatus = EMPTY_STRING;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state: State, action) => {
      state.profile = action?.payload ?? null;
      state.profileMutable = action?.payload ?? null;
    });
    builder.addCase(updateProfile.pending, (state: State) => {
      state.profileUpdateStatus =
        onBoardingConstants.profileUpdationState.pending;
    });
    builder.addCase(updateProfile.rejected, (state: State) => {
      state.profileUpdateStatus =
        onBoardingConstants.profileUpdationState.error;
    });
    builder.addCase(updateProfile.fulfilled, (state: State, action) => {
      state.profileUpdateStatus =
        onBoardingConstants.profileUpdationState.success;
      state.profile = action?.payload ?? null;
      state.profileMutable = action?.payload ?? null;
    });
    builder.addCase(uploadProfileImage.fulfilled, (state: State, action) => {
      if (state.profileMutable) {
        state.profileMutable.avatar = action?.payload ?? '';
      }
    });
  },
});

export const { updateProfileInfo, resetProfile, resetProfileUpdateStatus } =
  profileSlice.actions;

export default profileSlice.reducer;
