import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_STRING } from '../../../constants';
import { SLICE_TRANSCRIPT_HISTORY } from '../../../store/slices';
import { GetTranscriptResponse } from '../../../types/contract';
import { getTranscripts } from './transcriptHistoryAsync.actions';

export interface State {
  transcriptData: Array<GetTranscriptResponse> | [];
}

const initialState: State = {
  transcriptData: [],
};

export const transcriptHistorySlice = createSlice({
  name: SLICE_TRANSCRIPT_HISTORY,
  initialState,
  reducers: {
    resetData: (state) => {
      state.transcriptData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTranscripts.fulfilled, (state, action) => {
      state.transcriptData = action?.payload ?? [];
    });
  },
});

export const { resetData } = transcriptHistorySlice.actions;

export default transcriptHistorySlice.reducer;
