import { configureStore } from '@reduxjs/toolkit';
import dashboardSlice from '../module/dashboard/redux/dashboardSlice';
import profileSlice from '../module/profileHeader/redux/profileSlice';
import authSlice from '../module/auth/redux/auth.slice';
import appSlice from './app.slice';
import transcriptSlice from '../module/transcript/redux/transcriptSlice';
import transcriptHistorySlice from '../module/transcriptHistory/redux/transcriptHistorySlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    profile: profileSlice,
    dashboard: dashboardSlice,
    app: appSlice,
    transcript: transcriptSlice,
    transcriptHistory: transcriptHistorySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
