import { Avatar, Typography, Box } from '@mui/material';
import Styles from './ProgressBar.styles';
import BorderLinearProgress from '../../../common-components/borderline-progress/BorderLinearProgress';
import { VariantLinearProgress } from '../../../themes/properties';
import { spacing } from '../../../themes/theme';
import { CouseModalConstants } from '../../../constants';

interface Props {
  progressValue: number;
  userProfileImg?: string;
  showProfileImage: boolean;
}
const MARGIN_IMAGE = 3.275;
const ProgressBar = ({
  progressValue,
  userProfileImg,
  showProfileImage,
}: Props) => {
  return (
    <Box sx={Styles.root}>
      <BorderLinearProgress
        variant={VariantLinearProgress.determinate}
        value={progressValue}
        sx={Styles.progressBar}
      />
      {/* Line */}
      {progressValue < 90 && showProfileImage && (
        <Box
          sx={{
            ...Styles.progressAfterImage,
            left:
              progressValue *
                (MARGIN_IMAGE - (progressValue <= 50 ? 0.1 : 0.05)) +
              spacing * 6,
          }}
        />
      )}
      {showProfileImage && (
        <Avatar
          src={userProfileImg}
          sx={{ ...Styles.avatar, left: progressValue * MARGIN_IMAGE }}
        />
      )}

      {progressValue < 70 && (
        <Typography sx={Styles.textFinal}>
          {CouseModalConstants.progress.final}
        </Typography>
      )}

      {progressValue < 90 && (
        <Box sx={Styles.boxFinal}>
          <Box sx={Styles.boxFinalInner}>
            <Typography>!</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProgressBar;
