import { ContentType, ListType, interfaceType } from '../types';

export const Accessibility: ContentType = {
  header: 'Accessibility',
  subHeader: '',
  sections: [
    {
      title: 'General',
      sections: [
        `Ensuring that all students with disabilities have equal access to StraighterLine’s products, services, and materials is one of our highest priorities. Our teams strive to use best practices for accessibility, and our goal is to conform to international technical standards and tools for accessibility, such as the Web Content Accessibility Guidelines (WCAG) 2.0 and EPUB3. Existing U.S. laws, such as Section 504 and Section 508 of the Rehabilitation Act, and the Americans with Disabilities Act, promote the use of technical standards such as WCAG 2.0.`,
        `StraighterLine has developed a Voluntary Product Accessibility Template (VPAT) that evaluates the company’s compliance with Section 508 of the Rehabilitation Act. StraighterLine’s VPAT is available upon request.`,
      ],
    },
    {
      title: 'Usability',
      sections: [
        `StraighterLine has an ongoing series of compliance monitoring techniques to ensure that our products, services, and materials are going to be usable by students with disabilities. As part of our ongoing efforts, we conduct manual accessibility inspections utilizing multiple screen readers and automated testing tools. Most StraighterLine videos have been professionally captioned and are presented in a fully accessible media player, though some videos are still in the process of being upgraded. In the near future, we hope to have students with disabilities perform usability testing on our interfaces to ensure the best possible experience.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'At StraighterLine, we take our commitment to our students with disabilities very seriously. Our efforts to ensure an outstanding experience for students with disabilities mean that we use best practices, including, but not limited to, the following techniques:',
          points: [
            `In all course design, StraighterLine limits the use of multiple or hard-to-read fonts and uses high contrast text/background pairs.`,
            `Use of all CAPS, italics, or bold text is limited.`,
            `Generally, we do not use “click here” hyperlinks within courses, which can be problematic for screen reader users.`,
            `When used, graphics have alternative text (alt tags) for students who are screen reader users.`,
            `We provide captioning of video and text transcripts of audio where possible, especially for students who are deaf or hard-of-hearing.`,
            `For students who have motor impairments and may have limited use of pointing devices, our online materials can be fully accessed using a keyboard.`,
          ],
        },
      ],
    },
    {
      title: 'Online Course Environment',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'StraigherLine works with a number of partners and third-party providers, and their accessibility statements or VPATs are listed below.',
          points: [
            `Our learning management system (LMS) provider is Moodle. For more information about the accessibility features of Moodle:
            Moodle <a href="https://docs.moodle.org/dev/Accessibility" title="accessibility" target="_blank">accessibility</a>`,
            `Our publisher provided content is also compliant and generally offers the ability to manage text size, toggle audio narration on/off, and provide text transcripts. Content provider accessibility statements:
            </br>Acrobatiq <a href="https://s3.amazonaws.com/StraighterLine/Docs/ThinkwellADAinfo.pdf" title="accessibility" target="_blank">accessibility</a>
            </br>Thinkwell <a href="https://s3.amazonaws.com/StraighterLine/Docs/ThinkwellADAinfo.pdf" title="accessibility" target="_blank">accessibility</a>
            </br>Rosetta Stone VPAT
            </br>Saylor <a href="https://support.saylor.org/hc/en-us/articles/219123808-Accessibility-of-courses-and-learning-resources" title="accessibility" target="_blank">accessibility</a>
            </br>Zyante <a href="https://www.zybooks.com/accessibility/" title="accessibility" target="_blank">accessibility</a>`,
            `Our proctoring services provide accessibility features and accommodations for test-takers with disabilities. Proctoring provider accessibility statements:
            </br>ProctorU <a href="https://s3.amazonaws.com/StraighterLine/Docs/ProctorU+ADA+Compliance.pdf" title="accessibility" target="_blank">accessibility</a> and <a href="https://s3.amazonaws.com/StraighterLine/Docs/VPAT+-+ProctorU+05172015.pdf" title="VPAT" target="_blank">VPAT</a>`,
            `Our eTextbooks, serviced through VitalSource, provide accessible solutions to learners with disabilities. eTextbook provider accessibility statements:
            </br>VitalSource <a href="https://support.vitalsource.com/hc/en-us/categories/200184597-Accessibility" title="accessibility" target="_blank">accessibility</a> and <a href="https://s3.amazonaws.com/cloudfront.downloads.vitalbook.com/vsti/vpat/Bookshelf%20for%20Windows%206.5%20VPAT.doc" title="VPAT" target="_blank">VPAT</a>`,
          ],
        },
      ],
    },
    {
      title: 'Accommodations',
      sections: [
        `If you have a disability that affects your ability to take an online course, please contact a Student Advisor in order to provide StraighterLine with adequate documentation. Students must submit documentation of their disability prior to completing any course assessments. Once documentation has been received, StraighterLine will work with students on an individual basis.`,
      ],
    },
  ],
};
