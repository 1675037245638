import { themeColors } from '../../../../themes/colors';
import { spacing } from '../../../../themes/theme';

export default {
  root: {
    pt: `${spacing * 5}px`,
    pr: `${spacing * 10}px`,
    '@media (max-width: 1440px)': {
      pr: `${spacing * 3}px`,
    },
    '@media (max-width: 1200px)': {
      pr: `${spacing}px`,
    },
  },
  mainHeading: {
    fontWeight: 700,
    fontSize: `${spacing * 7}px`,
    fontFamily: `Atkinson Hyperlegible`,

    '@media (max-width: 1440px)': {
      width: `${spacing * 86.5}px`,
    },
    '@media (max-width: 1200px)': {
      width: `${spacing * 108}px`,
    },
    '@media (max-width: 1024px)': {
      width: `${spacing * 90}px`,
    },
    '@media (max-width: 768px)': {
      fontSize: `${spacing * 5}px`,
      width: `${spacing * 47.5}px`,
    },
    '@media (max-width: 480px)': {
      width: `${spacing * 37}px`,
    },
  },
  subHeading: {
    fontSize: `${spacing * 2.75}px`,
    mt: `${spacing * 3}px`,
    '@media (max-width: 768px)': {
      fontSize: `${spacing * 2.5}px`,
    },
  },
  boxTutorial: {
    mt: `${spacing * 4}px`,
  },
  tutorialListHeader: {
    mb: `${spacing * 3}px`,
    fontWeight: 600,
    fontSize: `${spacing * 3.5}px`,
  },
  divider: {
    mt: `${spacing * 6}px`,
    border: `1px solid ${themeColors.midGrey}`,
  },
};
