export default class Institute {
  constructor(
    public id: string,
    public name: string,
    public uri: string,
    public email: string,
    public isPartner: boolean,
    public isCustom: boolean,
    public mailTranscript: boolean,
    public emailTranscript: boolean,
    public addresses: Array<InstituteAddress>,
  ) {}
}

export class InstituteAddress {
  constructor(
    public id: string,
    public address: string,
    public address2: string,
    public city: string,
    public state: string,
    public country: string,
    public zip: string,
    public email: string,
    public type: number,
  ) {}
}
