import {
  Typography,
  Box,
  Grid,
  Avatar,
  Button,
  Link,
  Divider,
  styled,
  MenuProps,
  Menu,
  MenuItem,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Styles from './Header.style';
import {
  HeaderLeftPanelList,
  HeaderRightPanelList,
  HeaderCourseOptions,
  CourseOption,
  Constants,
  dataTestIds,
} from '../../constants';
import logo from '../../assets/images/logoWhite.svg';
import logoSmall from '../../assets/images/logoSmall.svg';
import { VariantButton, Origin } from '../../themes/properties';
import { useState } from 'react';
import { Routes, screenBreakpoints } from '../../constants';
import React from 'react';

import { signOut } from '../../store/appAsync.actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark,
  faBars,
  faSignOut,
  faCartShopping,
  faAngleDown,
  faHome,
} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: `${Origin.bottom}`,
      horizontal: `${Origin.left}`,
    }}
    transformOrigin={{
      vertical: `${Origin.top}`,
      horizontal: `${Origin.left}`,
    }}
    disableScrollLock={true}
    {...props}
  />
))(Styles.menuItem);

const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const screenDesktop = useMediaQuery(
    `(max-width:${screenBreakpoints.desktop})`,
  );

  const screenIPad = useMediaQuery(`(max-width:${screenBreakpoints.iPad})`);

  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigation = (route: string) => {
    const newLocation = new URL(route);
    window.location.href = newLocation.href;
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(signOut());
  };

  const homeButton = screenPhone ? (
    <Box
      sx={Styles.buttonHome}
      onClick={() => navigate(Routes.home)}
      data-testid={dataTestIds.header.homeIconDiv}
    >
      <FontAwesomeIcon
        icon={faHome}
        data-testid={dataTestIds.header.homeIcon}
      />
    </Box>
  ) : (
    <Box
      sx={Styles.dashboardButtonContainer}
      data-testid={dataTestIds.header.dashboardButtonDiv}
    >
      <Button
        href={Routes.home}
        variant={VariantButton.contained}
        sx={Styles.dashboardButton}
        data-testid={dataTestIds.header.dashboardButton}
      >
        {HeaderRightPanelList.DASHBOARD}
      </Button>
    </Box>
  );

  const courseMenuOptions = (
    <StyledMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={Styles.styledMenu}
    >
      {HeaderCourseOptions.map((option: CourseOption, index: number) => (
        <MenuItem
          onClick={() => handleNavigation(option.href)}
          disableRipple
          sx={Styles.coursesOption}
          key={index}
          data-testid={`${dataTestIds.header.menuItem}${option.label}`}
        >
          {option.label}
          {screenDesktop && <Divider sx={Styles.courseDivider} />}
        </MenuItem>
      ))}
    </StyledMenu>
  );

  const menuItems = (
    <Box sx={Styles.leftPanel}>
      <>
        {HeaderLeftPanelList.map((item, index) => (
          <Button
            key={item.label}
            sx={Styles.leftPanelButtons}
            onClick={item.label === Constants.COURSES ? handleClick : undefined}
            data-testid={dataTestIds.header.buttonCourseMenu}
          >
            <Link
              key={index}
              href={item.label !== Constants.COURSES ? item.href : undefined}
              sx={Styles.leftPanelOptions}
              data-testid={item.dataTestId}
            >
              <>
                {item.label}
                {item.label === Constants.COURSES && (
                  <Box sx={Styles.angleDownIcon}>
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      data-testid={item.iconDataTestId}
                    />
                  </Box>
                )}
              </>
            </Link>
          </Button>
        ))}
        {courseMenuOptions}
      </>
    </Box>
  );

  const actionItems = (
    <Box sx={Styles.rightPanelContainer}>
      {!screenPhone && (
        <Typography sx={Styles.rightPanelIcons}>
          <Button sx={Styles.rightPanelButtons}>
            <Link
              href={HeaderRightPanelList.SHOPPING_CART_URL}
              sx={Styles.shoppingLink}
            >
              <Box
                sx={Styles.buttons}
                data-testid={dataTestIds.header.cartIconDiv}
              >
                <FontAwesomeIcon
                  icon={faCartShopping}
                  data-testid={dataTestIds.header.cartIcon}
                />
              </Box>
            </Link>
          </Button>
        </Typography>
      )}
      {homeButton}
      <Typography sx={Styles.rightPanelIcons}>
        <Button sx={Styles.rightPanelButtons}>
          <Box
            sx={Styles.buttons}
            onClick={() => logOut()}
            data-testid={dataTestIds.header.logoutIconDiv}
          >
            <FontAwesomeIcon
              icon={faSignOut}
              data-testid={dataTestIds.header.logoutIcon}
            />
          </Box>
        </Button>
      </Typography>
    </Box>
  );

  const headerLogo = (
    <Box sx={Styles.logoContainer} data-testid={dataTestIds.header.logoDiv}>
      <Avatar
        src={screenIPad ? logoSmall : logo}
        sx={Styles.logo}
        alt={dataTestIds.straighterLineLogo}
        data-testid={dataTestIds.header.logoImage}
      />
    </Box>
  );

  if (screenDesktop) {
    return (
      <Box sx={Styles.root} data-testid={dataTestIds.header.divContainer}>
        <Box sx={Styles.logoBox}>
          <Box sx={Styles.iconBarContainer}>
            <Button
              sx={Styles.iconBar}
              onClick={handleClick}
              disableRipple
              data-testid={dataTestIds.header.closeButton}
            >
              <FontAwesomeIcon
                icon={open ? faXmark : faBars}
                data-testid={dataTestIds.header.closeIcon}
              />
            </Button>
          </Box>
          {courseMenuOptions}
          {headerLogo}
          {actionItems}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={Styles.root} data-testid={dataTestIds.header.divContainer}>
      <Grid container sx={Styles.headerContainer}>
        <Grid item md={4} columnSpacing={4}>
          {menuItems}
        </Grid>
        <Grid item md={4}>
          {headerLogo}
        </Grid>
        <Grid item md={4}>
          {actionItems}
        </Grid>
      </Grid>
    </Box>
  );
};
export default Header;
