import { themeColors } from '../../themes/colors';
import { dimensions, screenWidth, spacing } from '../../themes/theme';
const Styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: `${themeColors.primary}`,
    height: dimensions.header.height,
    zIndex: 3,
    width: '100%',
  },
  headerContainer: {
    color: themeColors.white,
    height: dimensions.header.height,
    zIndex: 3,
    px: `${spacing * 2}px`,
  },
  leftPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      paddingLeft: 0,
      alignItems: 'start',
      height: 'auto',
    },
  },
  leftPanelOptions: {
    color: `${themeColors.white}`,
    textAlign: 'center',
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none',
    },
    '@media (max-width: 1350px)': {
      fontSize: `${spacing * 1.4}px`,
    },
    '@media (max-width: 1300px)': {
      fontSize: `${spacing * 1.3}px`,
    },
    '@media (max-width: 1250px)': {
      fontSize: `${spacing * 1.2}px`,
    },
  },

  leftPanelButtons: {
    borderRadius: `50px`,
    px: `${spacing * 1.25}px`,
    margin: `0 ${spacing * 1.25}px`,
    '&:hover': {
      textDecoration: 'none',
      bgcolor: `${themeColors.backgroundGrey}`,
    },
    justifyContent: 'center',
  },
  rightPanelButtons: {
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    minWidth: `${spacing * 4}px`,
    padding: `${spacing * 1.25}px`,
    '&:hover': {
      bgcolor: `${themeColors.backgroundGrey}`,
    },
  },

  logoContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    '@media (max-width: 1200px)': {
      flex: 0,
    },
  },
  logo: {
    '& .MuiAvatar-img': { objectFit: 'fill' },
    width: '200px',
    '@media (max-width: 1024px)': {
      '&.MuiAvatar-root': {
        display: 'flex',
        justifyContent: 'start',
        marginLeft: `${spacing * 2}px`,
      },
    },
    '@media (max-width: 768px)': {
      width: '34px',
    },
  },
  rightPanelContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '100%',
    '@media (max-width: 1200px)': {
      paddingLeft: 0,
      height: 'auto',
    },
  },
  dashboardButtonContainer: {
    textAlign: 'right',
    paddingRight: `${spacing * 1.5}px`,
  },
  buttonHome: {
    fontSize: spacing * 2.25,
    height: spacing * 2.5,
    width: spacing * 2.5,
    padding: `${spacing * 1.25}px`,
    borderRadius: '50%',
    cursor: 'pointer',
    color: themeColors.white,
    bgcolor: themeColors.secondary,
    mr: `${spacing * 2}px`,
    '& .svg-inline--fa': {
      height: spacing * 2.5,
      width: spacing * 2.5,
    },
  },
  dashboardButton: {
    fontSize: `${spacing * 1.5}px`,
    backgroundImage: `${themeColors.greenGradient}`,
    borderRadius: '50px',
    '&:hover': {
      backgroundImage: `${themeColors.greenGradientOpposite}`,
    },
  },
  rightPanelIcons: {
    textAlign: 'right',
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 'bold',
    paddingRight: '12px',
    '@media (max-width: 1200px)': {
      fontSize: '16px',
      color: themeColors.white,
    },
  },
  shoppingLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  shoppingIcon: {
    color: `${themeColors.white}`,
  },
  buttons: {
    color: themeColors.white,
  },
  drawerContainer: {
    height: 1,
    backgroundColor: themeColors.primary,
    width: '100%',
  },
  // Small Screen
  logoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    px: 2,
  },
  iconBarContainer: {
    '& :hover': {
      bgcolor: themeColors.lightGrey,
      borderRadius: '50%',
    },
    '@media (max-width: 1440px)': {
      flex: 1,
    },
    '@media (max-width: 1200px)': {
      flex: 0,
    },
  },
  iconBar: {
    color: themeColors.white,
    fontSize: 20,
    maxWidth: `${spacing * 6}px`,
    minWidth: `${spacing * 6}px`,
    maxHeight: `${spacing * 6}px`,
    minHeight: `${spacing * 6}px`,

    '&.MuiBox-root': {
      '@media (max-width: 1200px)': {
        flex: 1,
      },
      '@media (max-width: 1024px)': {
        flex: 0,
      },
    },
  },
  iconClose: { color: themeColors.white, fontSize: 24 },
  boxIconClose: { textAlign: 'start', mb: 1, p: 2 },
  dividerDrawer: { bgcolor: themeColors.white, mt: 2 },
  coursesOption: {
    fontSize: `${spacing * 1.5}px`,
    '&:hover': {
      backgroundColor: themeColors.lightGrey,
      borderRadius: `${spacing * 3.75}px`,
      width: `90%`,
      marginLeft: `${spacing * 1.25}px`,
    },
    display: 'block',
    textTransform: 'uppercase',
    height: `${spacing * 6}px`,
    fontWeight: 700,
  },
  courseDivider: {
    width: '100%',
    mx: 'auto',
    borderBottomWidth: '2px',
    mt: `${spacing * 1.25}px`,
  },
  menuItem: {
    '& .MuiPaper-root': {
      fontSize: `${spacing * 1.5}px`,
      borderRadius: `${spacing}px`,
      marginTop: `${spacing}px`,
      minWidth: `${spacing * 33}px`,
      minHeight: `${spacing * 18.75}px`,
      color: themeColors.white,
      backgroundColor: themeColors.primary,
      boxShadow: themeColors.boxShadow,
      '@media (max-width: 1200px)': {
        backgroundColor: themeColors.white,
        color: themeColors.black,
      },
    },
  },
  styledMenu: {
    top: `${spacing * 3.5}px`,
    maxHeight: '90vh',
    overflowY: 'auto',
    '& .MuiPaper-root': {
      mt: 0,
    },
    '@media (max-width: 480px)': {
      top: `${spacing * 2.75}px`,
      maxHeight: '100vh',
      width: '109%',
      '& .MuiPaper-root': {
        width: '100%',
        left: '0 !important',
      },
    },
    '@media (max-width: 1200px)': {
      '& .MuiPaper-root': {
        bottom: 0,
      },
    },
  },
  angleDownIcon: { ml: `${spacing * 1.25}px`, display: 'inline' },
};

export default Styles;
