import {
  GetTranscriptResponse,
  TranscriptCourseType,
} from '../../../types/contract';
import { TableCell, TableRow, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { formatData, dateFormats } from '../../../utils/dateUtils';
import { dataTestIds, requestTranscipt } from '../../../constants';
import Styles from './styles';

interface PropsType {
  data: GetTranscriptResponse;
}

const Row = ({ data }: PropsType) => {
  const status =
    Object.entries(requestTranscipt.status).find(
      (pair) =>
        pair[1].value.toLocaleLowerCase() ===
        data.TranscriptStatus.toLocaleLowerCase(),
    )?.[1] ?? requestTranscipt.status.progress;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme?.palette?.action?.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '& .MuiTableCell-root': {
      padding: '12px 16px',
    },
  }));

  return (
    <StyledTableRow>
      <TableCell
        data-testid={`${dataTestIds.transcriptHistory.textTableDateOfRequest}${data.DateRequested}`}
      >
        {formatData(data.DateRequested, dateFormats.MMDDYYYY_SLASH)}
      </TableCell>
      <TableCell>
        {data?.Courses?.map((course: TranscriptCourseType) => {
          return (
            <Box sx={Styles.boxFlex}>
              <Typography
                sx={Styles.textCourseId}
                data-testid={`${dataTestIds.transcriptHistory.textTableDestination}${course.CourseCode}`}
              >
                {course.CourseCode}
              </Typography>
              <Typography
                data-testid={`${dataTestIds.transcriptHistory.textTableDestination}${course.CourseName}`}
              >
                {course.CourseName}
              </Typography>
            </Box>
          );
        })}
      </TableCell>
      <TableCell>
        <Typography
          data-testid={`${dataTestIds.transcriptHistory.textTableDestination}${data.College}`}
        >
          {data.College}
        </Typography>
      </TableCell>
      <TableCell sx={Styles.cell}>
        <Typography
          sx={{
            ...Styles.rowStatus,
            bgcolor: status.bgcolor,
            color: status.textColor,
            border: status.border,
          }}
          data-testid={`${dataTestIds.transcriptHistory.textTableStatus}${status.label}`}
        >
          {status.label}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};

export default Row;
