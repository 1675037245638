import { themeColors } from '../../../../themes/colors';
import { spacing } from '../../../../themes/theme';

export default {
  root: {
    pt: `${spacing * 5}px`,
    pr: `${spacing * 10}px`,
    width: '100%',
    '@media (max-width: 1024px)': {
      width: '90%',
    },
  },
  tableRoot: {
    '& .MuiTableCell-root': {
      fontSize: '16px',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        fontWeight: 700,
      },
    },
  },
  mainHeading: {
    fontWeight: 700,
    fontSize: `${spacing * 7}px`,
    '@media (max-width: 1024px)': {
      fontSize: `${spacing * 5}px`,
    },
  },
  subHeading: {
    fontSize: `${spacing * 3.5}px`,
    mt: `${spacing * 3}px`,
  },
  title: {
    fontSize: `${spacing * 2}px`,
    mt: `${spacing * 3}px`,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    color: themeColors.secondary,
  },
  boxInfo: { fontSize: `${spacing * 2.5}px` },
  textInfo: { ml: `${spacing * 1}px` },
  textInfoLink: {
    ml: `${spacing * 1}px`,
    mt: `${spacing * 1}px`,
    textTransform: 'uppercase',
    fontSize: `${spacing * 1.5}px`,
    '& a': {
      textDecoration: 'none',
      color: 'blue',
    },
  },
  boxSection: {
    mt: `${spacing * 6}px`,
  },
  txtSectionTitle: {
    mb: `${spacing * 1.5}px`,
    fontSize: `${spacing * 3.5}px`,
  },
  textSectionDescription: {
    mb: `${spacing * 5.5}px`,
    fontWeight: 400,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
  divder: {
    borderWidth: '1px',
  },
};
