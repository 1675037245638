import { ContentType, interfaceType, ListType } from '../types';

export const gradingData: ContentType = {
  header: 'Grading',
  subHeader:
    'Learn how to interact with your course and StraighterLine efficiently and effectively.',
  sections: [
    {
      title: 'Grading Overview',
      sections: [
        'As a general rule, grading varies by course, but typically includes a mixture of different kinds of assessments. It’s also common for courses to include a series of ungraded, formative and/or reflective assignments. Some assessments are graded "by-hand" and require Faculty graders. While some assessments have only one attempt, others may have more than one. For more information on our Grading Policy please see the Student Handbook.',
      ],
    },
    {
      title: 'Grading Scale',
      sections: [
        `StraighterLine provides a letter and number grade for each course. Colleges have their own policies with respect to how and whether they'll post grades for transferred coursework. You should check with the college you plan to attend to determine its specific grade-posting policies. `,

        {
          type: interfaceType.TABLE_TYPE,
          title: `Your StraighterLine transcript will reflect the percentage score and corresponding grade as set forth below:`,
          note: `* A grade of at least 70% is required for ACE CREDIT recommendation or credit recognition at most StraighterLine partner colleges. ACE transcripts reflect P/F (Pass/Fail) only.`,
          tableHeader: {
            columns: [`SCORE`, `GRADE`],
          },
          tableData: [
            {
              data: [`90-100`, `A`],
            },
            {
              data: [`80-89`, `B`],
            },
            {
              data: [`70*-79`, `C`],
            },
            {
              data: [`60-69`, `D`],
            },
            {
              data: [`Below 60`, `E`],
            },
          ],
        },
      ],
    },
    {
      title: '<a id="hc_grading_required_assignments"></a>Required Assignments',
      sections: [
        `Students must submit all required assignments in order for a course to be considered “complete”.  As we are a self-paced, self-driven platform, there is no official beginning and end to courses. Thus, finishing all required assignments lets us know when you are done with a course. As well, it is the completion of all required coursework that triggers transcript availability. For more information on our Course Completion Policy please see the <a href="/help-center/student-handbook/student-code-of-conduct" title="Student Handbook">Student Handbook.</a> `,
      ],
    },
    {
      title: 'Academic Honesty and Integrity',
      sections: [
        `Honesty and integrity are as highly valued at StraighterLine as they are at all of StraighterLine’s partner colleges.`,
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Unordered,
          title:
            'Academic integrity violations include all forms of academic dishonesty, including, but not limited to:',
          points: [
            `Cheating - Giving or receiving unauthorized assistance, attempting to give or receive such assistance, or using unauthorized materials in connection with the performance of any academic work. This includes providing your login to another individual to access content and complete work on your behalf.`,
            `Plagiarism - Use of another person’s work or ideas without proper acknowledgment of the source.`,
            `Fabrication - Falsification or invention of any information, citation, data, images, or documentation.`,
            `Collusion - Helping or allowing another student to commit any act of academic dishonesty.`,
          ],
        },
        `Plagiarism, cheating, or other types of academic integrity and policy violations may constitute in immediate failure of the assignment in question. Deliberate or sustained violations will result in automatic failure of the course and could result in a student being removed from all current enrollments and services without refund. StraighterLine reserves the right to audit any and all student work for potential cheating, as well as require additional proctoring/monitoring of students whose accounts have been flagged for suspicious behavior.`,
      ],
    },
    {
      title: 'Identity Verification',
      sections: [
        `StraighterLine works hard to verify student identity to ensure that the student who registers is the student who completes the course, requests a transcript, and receives credit for their completed courses. Identity verification processes also signal that the academic policies of our organization are aligned with the practices of higher education, this lends our courses--and your earned credit--legitimacy and authenticity, which is valuable information for both our current and potential partners, and other institutional recipients of a StraighterLine transcripts. Please understand that Identity Verification systems are not designed to be roadblocks to your academic progress; rather they are put in place to provide our partners, and your future college, with the confidence and validity that you are the capable and trustworthy student who successfully completed the coursework.`,
        `Identity verification includes, but is not limited to, out-of-band processes (calling/texting a number and verifying student information), embedded question verification (answering questions based on publicly available information about the student), biometric verification, passive activity monitoring, analysis of voluntarily-provided electronic device data, and account comparison against established publicly available networks (such as social networks) to StraighterLine’s authentication system or proctoring services.`,
      ],
    },
    {
      title:
        '<a id="hc_grading_completion_criteria"></a>Course Completion Criteria',
      sections: [
        `In order for your course to be considered complete, all <a href="/help-center/academics/grading/#hc_grading_required_assignments" title="Required Coursework">required coursework</a> must be attempted and submitted regardless of your overall score in the course. Required course work may consist of graded and ungraded assignments. You will receive a final score for the course after all of the assessments and required assignments have been completed. Any <a href="${window.location.origin}/help-center/student-handbook/privacy-policy" title="Academic Honesty Policy">Academic Honesty Policy</a> violations may prevent your course from being considered complete.`,
      ],
    },
    {
      title: 'Transcript Eligibility',
      sections: [
        `In order for your course to be <a href="/help-center/credit-transfer/credit-transfer" title="StraighterLine Credit Transfer">eligible for transfer</a>, the course must be complete per our <a href="/help-center/academics/grading/#hc_grading_completion_criteria" title="Completion criteria">completion criteria</a> and must have a final grade of a 70% or above. A grade of at least 70% is required for credit recognition at most StraighterLine partner colleges or ACE CREDIT recommendation. ACE transcripts reflect P/F (Pass/Fail) only.`,
      ],
    },
    {
      title: 'Grade Says “Contact an Advisor”',
      sections: [
        `In the case where an issue is detected with any of your assignments in your course (i.e. incomplete assignment, name discrepancies), your course will be flagged for review. During this time period, we ask that you contact a Student Advisor for information about your course and your grade. If and when the flag is lifted from your course, your grade will be appropriately reflected in your <a href="${window.location.origin}" title="MyLine Dashboard">MyLine Dashboard.`,
      ],
    },
    {
      title:
        '<a id="hc_grading_calculate_grade"></a>How to Calculate Your Grade',
      sections: [
        {
          type: interfaceType.POINT_TYPE,
          listType: ListType.Ordered,
          title: `To calculate the grade in your course follow the instructions below:`,
          points: [
            `Consult your course syllabus for the weighted distribution of points across all assignments (typically 1,000 total points).`,
            `Collect the grades you have received so far on your graded exams and/or assignments. Grades can be found on the back of your course card in your <a href="${window.location.origin}" title="MyLine Dashboard">MyLine Dashboard</a>.`,
            `Take all of your scores and multiply them by the scale as a percentage of the total points listed in your syllabus and add up the results.`,
          ],
        },

        `If you have assignments that are not yet graded, you can estimate your grade for these assignments to determine your final grade in the course.`,
      ],
    },
    {
      title: 'I Failed My Course. What Are My Options?',
      sections: [
        `In the event that you do not pass your course, or if you wish to receive a higher grade, you can <a href="/help-center/academics/course-retakes/" title="Retake your course">retake the course.</a> `,
      ],
    },
  ],
};
