import React, { ReactNode, useEffect } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { onBoardingConstants, onboardingDialogConstants } from '../../constants';
import Styles from './OnboardingDialog.style';
import {VariantButton, VariantTypography} from '../../themes/properties';
import { updateProfile as updateUserProfile } from "../../module/profileHeader/redux/profileAsync.actions";
import Profile from "../../models/Profile.model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { resetProfileUpdateStatus, updateProfileInfo } from "../../module/profileHeader/redux/profileSlice";

interface PropsType {
  completeStep: () => void;
  stepper: ReactNode;
}

const OnboardingDialogAllSet = ({ completeStep, stepper }: PropsType) => {
  const { profileMutable, profileUpdateStatus } = useSelector(
    (state: RootState) => state.profile,
  );
  const dispatch = useDispatch<AppDispatch>();

  const updateProfile = (updatedProfile: Profile) => {
    dispatch(updateProfileInfo(updatedProfile));
  };

  useEffect(() => {
    if (
      profileUpdateStatus === onBoardingConstants.profileUpdationState.success
    ) {
      dispatch(resetProfileUpdateStatus());
      completeStep();
    }
  }, [profileUpdateStatus]);

  const handleClickGoToDashboard = () => {
    if(profileMutable) {
      const updatedProfile = JSON.parse(
        JSON.stringify({ ...profileMutable, onBoardingStatus: onBoardingConstants.status.completed }),
      ) as Profile;
      updateProfile(updatedProfile);
    }
    dispatch(updateUserProfile());
  };

  return (
    <div>
      <DialogContent sx={Styles.content} dividers>
        {stepper}
        <Typography
          variant={VariantTypography.h4}
          sx={{ textAlign: 'center' }}
        >
          <strong>{onboardingDialogConstants.allSet.title}</strong>
        </Typography>

        <Typography
          gutterBottom
          sx={Styles.paragraph}
        >
          {onboardingDialogConstants.allSet.contentOne}
        </Typography>
        <Typography
          gutterBottom
          sx={Styles.paragraph}
          dangerouslySetInnerHTML={{
            __html: onboardingDialogConstants.allSet.contentTwo,
          }}
        />
      </DialogContent>

      <DialogActions sx={Styles.dialogActions}>
        <Button
          autoFocus
          onClick={handleClickGoToDashboard}
          variant={VariantButton.contained}
          sx={{...Styles.button, ...Styles.buttonNext}}
        >
          {onboardingDialogConstants.allSet.button}
        </Button>
      </DialogActions>
    </div>
  );
};

export default OnboardingDialogAllSet;