import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import React from 'react';
import {
  TableRowType,
  TableType,
} from '../../../assets/help-center-data/types';
import { VariantTypography } from '../../../themes/properties';
import Styles from './styles';

interface PropsType {
  data: TableType;
}

const TableSection = ({ data }: PropsType) => (
  <Box>
    {data.title && (
      <Typography
        dangerouslySetInnerHTML={{ __html: data.title }}
        sx={Styles.textTableTitle}
        variant={VariantTypography.body1}
      />
    )}
    <TableContainer>
      <Table sx={{ maxWidth: 200 }}>
        <TableHead>
          <TableRow>
            {data.tableHeader.columns.map((column: string) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.tableData.map((row: TableRowType) => (
            <TableRow>
              {row.data.map((data: string) => (
                <TableCell>{data}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    {data.note && (
      <Typography
        dangerouslySetInnerHTML={{ __html: data.note }}
        sx={Styles.textTableNote}
        variant={VariantTypography.body1}
      />
    )}
  </Box>
);

export default TableSection;
