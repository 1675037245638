import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeColors } from '../../themes/colors';
import { onboardingDialogConstants } from '../../constants';
import Styles from './OnboardingDialog.style';
import { onBoardingConstants } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandWave } from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/system';
import Profile from "../../models/Profile.model";
import OnboardingDialogWelcome from "./OnboardingDialogWelcome.component";
import OnboardingDialogPersonalInformation from "./OnboardingDialogPersonalInformation.component";
import OnboardingDialogAboutYou from "./OnboardingDialogAboutYou.component";
import OnboardingDialogAllSet from "./OnboardingDialogAllSet.component";
import AppStepper from "../../module/onBoarding/stepper/index.component";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: themeColors.primary,
  color: themeColors.white,
  display: 'flex',
  alignItems: 'center',
  '& .MuiIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

interface PropsType {
  status: string;
  profile: Profile | null;
}

const OnboardingDialog = ({ status, profile }: PropsType) => {
  const [open, setOpen] = useState(true);
  const [stepId, setStepId] = useState(0);

  useEffect(() => {
    switch (status) {
      case onBoardingConstants.status.personalInfoCompleted:
        goToStepAboutYou();
        break;
      default:  // notStarted
        goToStepWelcome();
        break;
    }
  }, []);

  const titleText = onboardingDialogConstants.title
    .replace('%s', `${profile?.firstName} ${profile?.lastName}`)
    .toUpperCase();

  const goToStepWelcome = () => {
    setStepId(0);
  }

  const goToStepPersonalInformation = () => {
    setStepId(1);
  }

  const goToStepAboutYou = () => {
    setStepId(2);
  }

  const goToStepAllSet = () => {
    setStepId(3);
  }

  const closeDialog = () => {
    setOpen(false);
  }

  const stepper = (id: number) => {
    return (
      <AppStepper steps={onBoardingConstants.steps} activeStep={id - 1} />
    )
  }

  const getContent = (id: number) => {
    switch (id) {
      case 3:
        return (
          <OnboardingDialogAllSet
            completeStep={closeDialog}
            stepper={stepper(id)}
          />
        )
      case 2:
        return (
          <OnboardingDialogAboutYou
            goBack={goToStepPersonalInformation}
            completeStep={goToStepAllSet}
            stepper={stepper(id)}
          />
        )
      case 1:
        return (
          <OnboardingDialogPersonalInformation
            completeStep={goToStepAboutYou}
            stepper={stepper(id)}
          />
        )
      default:  // 0
        return (
          <OnboardingDialogWelcome completeStep={goToStepPersonalInformation} />
        )
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        sx={Styles.dialog}
        PaperProps={{
          sx: Styles.root,
        }}
      >
        <StyledDialogTitle sx={Styles.title}>
          <Box sx={Styles.icon}>
            <FontAwesomeIcon icon={faHandWave} />
          </Box>
          {titleText}
        </StyledDialogTitle>

        {getContent(stepId)}
      </Dialog>
    </div>
  );
};

export default OnboardingDialog;