import { Routes, Urls } from '../../constants';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface AccountType {
  title: string;
  route?: string;
  url?: string;
  icon?: IconProp;
}

export const accountMenu: Array<AccountType> = [
  {
    title: 'Account Settings',
    route: Routes.accountSettings,
  },
  {
    title: 'Profile Details',
    route: Routes.profileDetails,
  },
  {
    title: 'Billing Preferences',
    url: Urls.BILLING_PREFERENCE,
    icon: faUpRightFromSquare,
  },
];
