import { themeColors, hexToRGB } from '../../themes/colors';
import { spacing } from '../../themes/theme';
const Styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
  },
  root: {
    display:'flex',
    flexDirection: 'column',
    boxShadow: 24,
    outline: 'none',
    maxWidth: 640,
    minWidth: 640,
    position: 'relative',
    bgcolor: themeColors.white,
    borderRadius: `${spacing * 1}px`,
    height: '95vh',
    '@media (max-width: 700px)': {
      maxWidth: '95%',
      minWidth: '95%',
    },
    '@media (max-width: 480px)': {
      height: '85vh',
    },
  },
  boxHeader: {
    backgroundColor: themeColors.primary,
    color: themeColors.white,
    display: 'flex',
    alignItems: 'baseline',
    px: `${spacing * 3}px`,
    py: `${spacing * 2}px`,
    borderTopLeftRadius: `${spacing * 1}px`,
    borderTopRightRadius: `${spacing * 1}px`,
  },
  boxCourseInfo: { ml: `${spacing * 2}px`, flexGrow: 1 },
  textCourseName: {
    fontSize: `${spacing * 2}px`,
    fontWeight: '700',
    textTransform: 'capitalize',
  },
  textCourseCode: {
    fontSize: `${spacing * 1.75}px`,
    textTransform: 'capitalize',
  },
  iconClose: {
    alignSelf: 'center',
    cursor: 'pointer',
  },

  boxSubHeader: {
    display: 'flex',
    alignItems: 'center',
    px: `${spacing * 3}px`,
    py: `${spacing * 2}px`,
    bgcolor: themeColors.white,
  },
  boxProgress: {
    backgroundColor: themeColors.primary,
    height: '74px',
    width: '100%',
    borderRadius: `${spacing * 1}px`,
    px: `${spacing * 2}px`,
    paddingRight: `${spacing * 4}px`,
    display: 'flex',
    alignItems: 'center',
  },
  boxProgressText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  textCourse: {
    color: '#A9EB20',
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 700,
  },
  textProgress: {
    color: themeColors.white,
    fontSize: `${spacing * 1.5}px`,
    fontWeight: 700,
  },
  boxCurrentGrade: {
    border: `1px solid black`,
    ml: `${spacing * 3}px`,
    p: `${spacing * 2}px ${spacing * 0.5}px`,
    maxWidth: `${spacing * 10}px`,
    minWidth: `${spacing * 10}px`,
    textAlign: 'center',
    borderRadius: `${spacing * 0.5}px`,
    '& .MuiTypography-root': {
      fontSize: `${spacing * 1.5}px`,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  boxCurrentGradeValue: {
    bgcolor: themeColors.secondary,
    p: `${spacing * 0.5}px`,
    mt: `${spacing * 1.5}px`,
    mx: 'auto',
    borderRadius: `${spacing * 0.5}px`,
    width: '80%',
    '& .MuiTypography-root': {
      color: themeColors.white,
    },
  },
  rootScheduleProctor: {
    bgcolor: themeColors.white,
    width: `calc(100% - ${spacing * 6}px)`,
    alignSelf: 'center',
    px: `${spacing * 3}px`,
    py: `${spacing * 2}px`,
  },
  containerScheduleProctor: {
    border: '2px solid black',
    borderRadius: '8px',
    height: '100%',
  },
  boxScheduleProctor: { display: 'flex', height: '100%' },
  imgScheduleProctor: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  boxScheduleProctorText: {
    p: 2,
    '@media (max-height: 800px)': {
      px: 2,
      py: 1,
    },
  },
  linkProctoring: { textDecoration: 'none' },
  textProctoring: { fontSize: '16px', fontWeight: 600, cursor: 'pointer' },
  textProctoringDesc: { fontSize: '14px', mt: 1 },
  boxContainerBottomView: {
    display: 'flex',
    gap: `${spacing * 1.25}px`,
    justifyContent: 'flex-end',
    alignItems: 'center',
    px: `${spacing * 2}px`,
    py: `${spacing * 2}px`,
  },
  textAPH: {
    color: themeColors.warning,
    fontSize: `${spacing * 1.75}px`,
    fontWeight: 700,
    width: '50%',
  },
  button: {
    backgroundImage: `${themeColors.greenGradient}`,
    borderRadius: '50px',
    height: '100%',
    '& .fa-solid': {
      fontSize: `${spacing * 2}px !important`,
      mx: `${spacing * 0.5}px`,
    },
    '&:hover': {
      backgroundImage: `${themeColors.greenGradientOpposite}`,
    },
    '&:disabled': {
      border: `1px solid ${hexToRGB(themeColors.blueLight, 0.5)}`,
      color: hexToRGB(themeColors.blueLight, 0.5),
      background: themeColors.white,
    },
    fontSize: `${spacing * 1.5}px`,
    '@media (max-width: 480px)': {
      width: '100%',
    },
    '&.MuiButtonBase-root': {
      fontWeight: 900,
    },
  },
  buttonPurchaseCourse: {
    backgroundImage: 'none',
    '&:hover': {
      backgroundImage: 'none',
    },
  }
};

export default Styles;
