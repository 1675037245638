import { GetLmsProviderDetailsResponse } from '../types/contract';

export const moodleFormHtml = (providerData: GetLmsProviderDetailsResponse) => `
<!doctype html>
<html>
<body class="page-bare  lti-launch-index">
        <div style="display:none;">
            <form id="ltiLaunchForm" name="ltiLaunchForm" method="POST"
                action="${providerData?.FormAction}"
                encType="application/x-www-form-urlencoded">
                <input type="hidden" name="user_id" value="${providerData?.UserId}">
                <input type="hidden" name="roles" value="${providerData?.Roles}">
                <input type="hidden" name="context_id" value="${providerData?.ContextId}">
                <input type="hidden" name="context_title" value="">
                <input type="hidden" name="context_label" value="">
                <input type="hidden" name="resource_link_id" value="${providerData?.ResourceLinkId}">
                <input type="hidden" name="lis_person_contact_email_primary" value="${providerData?.Email}">
                <input type="hidden" name="lis_person_name_given" value="${providerData?.FirstName}">
                <input type="hidden" name="lis_person_name_family" value="${providerData?.LastName}">
                <input type="hidden" name="lis_person_sourcedid" value="">
                <input type="hidden" name="tool_consumer_instance_guid" value="${providerData?.ConsumerInstanceGuid}">
                <input type="hidden" name="tool_consumer_instance_description" value="${providerData?.ConsumerInstanceDescription}">
                <input type="hidden" name="lti_version" value="${providerData?.LtiVersion}">
                <input type="hidden" name="lti_message_type" value="${providerData?.LtiMessageType}">
                <input type="hidden" name="custom_resource_type" value="${providerData?.CustomResourceType}">
                <input type="hidden" name="custom_service" value="">
                <input type="hidden" name="oauth_callback" value="">
                <input type="hidden" name="oauth_consumer_key" value="${providerData?.OAuthConsumerKey}">
                <input type="hidden" name="oauth_version" value="${providerData?.OAuthVersion}">
                <input type="hidden" name="oauth_nonce" value="${providerData?.OAuthNonce}">
                <input type="hidden" name="oauth_timestamp" value="${providerData?.OAuthTimestamp}">
                <input type="hidden" name="oauth_signature_method" value="${providerData?.OAuthSignatureMethod}">
                <input type="hidden" name="oauth_signature" value="${providerData?.OAuthSignature}">
                <button type="submit" name="LTIForm">Click to continue to your course.</button>
            </form>
        </div>
        <script type="text/javascript">
	        document.ltiLaunchForm.submit();
        </script>
</body>
</html>
    `;
