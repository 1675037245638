import { themeColors } from '../../../themes/colors';
import { spacing } from '../../../themes/theme';
const Styles = {
  rootTopics: {
    boxSizing: 'border-box',
    px: 3,
    bgcolor: 'white',
    overflowY: 'auto',
  },
  rootTopic: { bgcolor: themeColors.white, my: `${spacing * 3}px` },
  boxTopic: { display: 'flex', alignItems: 'center' },
  textTopic: {
    fontWeight: 500,
    fontSzie: `${spacing * 1.75}px`,
    ml: `${spacing * 1}px`,
    cursor: 'pointer',
    '&:hover': {
      color: `${themeColors.darkBlue}`,
    },
  },
  boxAssignments: {
    my: `${spacing * 1.5}px`,
    display: 'flex',
    justifyContent: 'space-between',
    pl: `${spacing * 3.25}px`,
  },
  textAissignmentName: {
    maxWidth: '70%',
    fontSzie: `${spacing * 1.5}px`,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    '& a': {
      textDecoration: 'none',
      color: themeColors.blueLight,
    },
    color: themeColors.tertiaryBlack,
  },
  boxGadeValue: {
    padding: `${spacing * 0.5}px ${spacing * 3}px`,
    border: `1px solid ${themeColors.offWhite}`,
    borderRadius: `${spacing * 0.5}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `${spacing * 3}px`,
    width: `${spacing * 5}px`,
  },
  textGradeValue: {
    color: themeColors.secondary,
    fontSzie: `${spacing * 1.75}px`,
    fontWeight: 700,
  },
};

export default Styles;
