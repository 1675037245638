import { Box, Typography } from '@mui/material';
import Assignment from '../../../models/Assignment.model';
import TopicModel from '../../../models/Topic.model';
import Styles from './topic.styles';
import { themeColors } from '../../../themes/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { getLMSProviderDetails } from '../../dashboard/redux/dashboardAsync.actions';
import Course from '../../../models/Course.model';
import {
  isAcrobatiqCourse,
  acrobatiqProviderHandler,
} from '../../../utils/acrobatiqLogin.util';

interface Props {
  course: Course | null;
  topic: TopicModel;
}

const Topic = ({ topic, course }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const courseProviderHandler = (
    providerCourseName: string,
    providerCourseId: string,
    cwpCode: string,
    courseIdentifier: string,
    resourceLinkId: string,
    resume: boolean,
  ) => {
    if (isAcrobatiqCourse(cwpCode)) {
      acrobatiqProviderHandler(
        providerCourseName,
        providerCourseId,
        resourceLinkId,
        resume,
      );
    } else {
      moodleProviderHandler(courseIdentifier, resourceLinkId, resume);
    }
  };

  const moodleProviderHandler = (
    courseIdentifier: string,
    linkId: string,
    resume: boolean,
  ) => {
    dispatch(
      getLMSProviderDetails({
        courseIdentifier: courseIdentifier,
        linkId: linkId,
        resume: resume,
      }),
    );
  };
  const assignmentView = topic.assignments.map((assignment: Assignment) => (
    <Box key={assignment.id} sx={Styles.boxAssignments}>
      <Typography sx={Styles.textAissignmentName}>
        {assignment.name}
        {/* <a href={assignment.url}>{assignment.name}</a> */}
      </Typography>
      <Box sx={Styles.boxGadeValue}>
        <Typography sx={Styles.textGradeValue}>
          {assignment.grade ? parseFloat(assignment.grade).toFixed(0) : 0}%
        </Typography>
      </Box>
    </Box>
  ));
  return (
    <Box sx={Styles.rootTopic}>
      <Box sx={Styles.boxTopic}>
        <Box
          sx={{
            color: topic.isComplete ? themeColors.secondary : themeColors.black,
          }}
        >
          <FontAwesomeIcon icon={topic.isComplete ? faCheck : faCircle} />
        </Box>
        <Typography
          sx={Styles.textTopic}
          onClick={() =>
            courseProviderHandler(
              course?.providerCourseName ?? '',
              course?.providerCourseId ?? '',
              topic.cwpCode,
              topic.code,
              topic.resourceLinkId.toString(),
              false,
            )
          }
        >
          {topic.name}
        </Typography>
      </Box>

      {assignmentView}
    </Box>
  );
};

export default Topic;
